import {
  SET_PENDING_LETTER_SWITCH,
  CLEAR_PENDING_LETTER_SWITCH,
  SET_HAS_UNSAVED_LETTER_CHANGES,
} from './actionTypes';

export const setPendingLetterSwitch = (letterId, templateId) => ({
  type: SET_PENDING_LETTER_SWITCH,
  payload: { letterId, templateId },
});

export const clearPendingLetterSwitch = () => ({
  type: CLEAR_PENDING_LETTER_SWITCH,
});

export const setHasUnsavedChanges = (hasChanges) => ({
  type: SET_HAS_UNSAVED_LETTER_CHANGES,
  payload: hasChanges,
});
