import moment from 'moment';
import PropTypes from 'prop-types';
import { omit, isEqual } from 'lodash';
import React, { useState } from 'react';
import { isInclusivelyAfterDay } from 'react-dates';

import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import ToggleSwitch from 'components/common/toggle';

import { BUTTON_TYPE } from 'components/common/button/Button';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import closeIcon from 'img/close-modal.svg';
import calendar from 'img/calendar-icon.svg';
import settingsIcon from 'img/settings-black.svg';

import { DatePicker } from 'components/common/datepicker';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

const InProgressSubmissionPopupFilter = (props) => {
  const {
    setShowPopupFilter,
    filters,
    onFilterChange,
    options,
    defaultFilters,
    getSelectedValue,
    userPractices,
    loading,
  } = props;
  const [inProgressFilters, setInProgressFilters] = useState(filters);
  const [error, setError] = useState();

  const keysToOmit = ['search', 'submissionType'];
  const isFilterEmpty = Object.keys(omit(inProgressFilters, keysToOmit)).every(
    (filterKey) => filters[filterKey] === defaultFilters[filterKey]
  );

  const filtersWithoutOmittedKeys = (filters) => omit(filters, keysToOmit);
  const inProgressFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(filters);
  const inProgressPopupFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(inProgressFilters);
  const isApplyFilterDisabled = isEqual(
    Object.values(inProgressFiltersWithoutOmittedKeys),
    Object.values(inProgressPopupFiltersWithoutOmittedKeys)
  );

  const resetFilters = () => onFilterChange(omit(defaultFilters, keysToOmit));

  const handleConfirmFilters = () => {
    onFilterChange(inProgressFilters);
    setShowPopupFilter(false);
  };

  const handleFilterChange = (key, value) => {
    setInProgressFilters({ ...inProgressFilters, [key]: value });
  };

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt="More filters" />
            <h6>More Filters </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    setInProgressFilters(defaultFilters);
                    resetFilters();
                    setShowPopupFilter(false);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowPopupFilter(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>
        <div className="appealio-popup-filter__options">
          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                options={options.payers}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('payerId', option.value)
                }
                isLoading={loading}
                value={getSelectedValue(
                  options.payers,
                  Number(inProgressFilters.payerId)
                )}
                label="Payer"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                options={options.providers}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('providerId', option.value)
                }
                isLoading={loading}
                label="Provider"
                value={getSelectedValue(
                  options.providers,
                  inProgressFilters.providerId
                )}
              />
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                options={options.agents}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('agentId', option.value)
                }
                isLoading={loading}
                label="Agent"
                value={getSelectedValue(
                  options.agents,
                  inProgressFilters.agentId
                )}
              />
            </div>
            <div className="col-md-6">
              {userPractices && userPractices.length > 1 && (
                <Dropdown
                  options={options.practices}
                  placeholder="All"
                  onChange={(option) =>
                    handleFilterChange('practiceIdentifier', option.value)
                  }
                  isLoading={loading}
                  label="Practice"
                  value={getSelectedValue(
                    options.practices,
                    inProgressFilters.practiceIdentifier
                  )}
                />
              )}
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                options={options.batchIds}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('batchId', option.value)
                }
                isLoading={loading}
                label="Batch #"
                value={getSelectedValue(
                  options.batchIds,
                  inProgressFilters.batchId
                )}
              />
            </div>
            <div className="col-md-6">
              <div className="dropdown__label">Created At</div>
              <DatePicker
                displayFormat="LL"
                customInputIcon={
                  <img className="form-icon" src={calendar} alt="Calendar" />
                }
                isOutsideRange={(d) =>
                  isInclusivelyAfterDay(d, moment().add(1, 'days'))
                }
                date={
                  inProgressFilters?.createdAt
                    ? moment(inProgressFilters?.createdAt)
                    : null
                }
                placeholder="Select Date"
                verticalSpacing={10}
                onDateChange={(date) => {
                  if (!date) {
                    setError({
                      ...error,
                      createdAt: null,
                    });
                  }
                  date = moment(date);
                  if (!date.isValid()) {
                    handleFilterChange('createdAt', null);
                    return;
                  }
                  setError({
                    ...error,
                    createdAt: null,
                  });
                  handleFilterChange(
                    'createdAt',
                    date.format(DATE_FORMAT_YYYY_MM_DD)
                  );
                }}
                numberOfMonths={1}
                orientation="horizontal"
                block={true}
              />
              {error && error.createdAt && (
                <p className="error mt-8">{error.createdAt}</p>
              )}
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-md-6">
              <ToggleSwitch
                className="mt-28"
                optionClassName="fs-12"
                options={{
                  option1: 'All',
                  option2: 'In Progress (More than 3 days)',
                }}
                checked={inProgressFilters.notificationRedirect || false}
                handleToggle={(e) =>
                  handleFilterChange('notificationRedirect', e.target.checked)
                }
              />
            </div>
          </div>
        </div>
        <div className="appealio-popup-filter-buttons mt-24">
          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button ap-button--block"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

InProgressSubmissionPopupFilter.propTypes = {
  setShowPopupFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  getSelectedValue: PropTypes.func,
};

export default InProgressSubmissionPopupFilter;
