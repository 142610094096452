import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { bindActionCreators } from 'redux';
import { push, replace } from 'connected-react-router';
import {
  Field,
  reduxForm,
  FieldArray,
  formValueSelector,
  SubmissionError,
} from 'redux-form/immutable';

import {
  setAppealData,
  getAppealById,
  clearCurrentAppealData,
} from '../../../redux/actions/createAppealActions';
import {
  getAppealFromState,
  getEOBInitialValuesFromAppealState,
} from '../../../redux/reducers/AppealStore';

import {
  normalizeMoney,
  readAmountsFromCPTForm,
  format as formatMoney,
  readDeniedAmountFromCPTForm,
} from '../../CreateAppeal/DenialInformation/CPTRowConfig';
import validate from './validate';
import AppealsAPI from '../../../API/AppealsAPI';
import { MASK_DATE } from '../../../helpers/masks';
import CreateAppealAPI from '../../../API/CreateAppealAPI';
import { handleError } from '../../../helpers/errorHandler';
import { dateIsInThePast } from '../../../helpers/validators';
import { AppealStatusCode } from '../../../API/AppealStatusCode';
import { EOBProcedureTransform } from '../../../API/Serializers/EOB';
import { sumMoney, parseMoney, formatToMoney } from '../../../helpers/money';

import {
  getFormattedDate,
  VALIDATION_DATE_FORMAT,
} from '../../../helpers/dateFormats';
import { transformDateString } from '../../../API/Serializers/Transforms';
import { SUBMISSION_FAILED_ERROR } from '../../Shared/Errors/errorMessages';

import { renderInput } from './renderInput';
import * as toast from '../../Shared/toast';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import {
  PastDeadlinePopUp,
  ConfirmClosingAppealPopup,
} from '../../CreateAppeal/Popups/Popups';
import ErrorBannerSection from '../../Shared/Errors/ErrorBannerSection';
import { renderReduxMaskedInput } from '../../CreateAppeal/reduxMaskedInput';

import checkIcon from '../../../img/check-icon.svg';
import closeIcon from '../../../img/close-icon.svg';
import MultiValueInput from '../../Shared/MultiValueInput';

class AppealAction extends Component {
  static propTypes = {
    appealID: PropTypes.number.isRequired,
    onDimRegionPressed: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formErrors: {
        displayErrors: [],
      },
      deadlinePassedPopup: {
        acceptPassedDeadline: false,
        deadline: null,
        isOpen: false,
      },
      closeAppealPopup: {
        isOpen: false,
      },
      isViewOnlyInfoToastShown: false,
    };
  }

  componentDidMount() {
    this.props.actions.clearCurrentAppealData();
    this.props.actions.getAppealById(this.props.appealID);
  }

  setFormErrors = (formErrors, cb) => this.setState({ formErrors }, cb);

  setDeadlinePassedPopup = (deadlinePassedPopup, cb) =>
    this.setState({ deadlinePassedPopup }, cb);

  openCloseAppealPopup = (cb) =>
    this.setState(
      {
        closeAppealPopup: {
          isOpen: true,
        },
      },
      cb
    );

  resetDeadlinePassedPopup = () =>
    this.setDeadlinePassedPopup({
      deadline: null,
      isOpen: false,
      acceptPassedDeadline: false,
    });

  resetFormErrors = (cb) =>
    this.setState(
      {
        formErrors: {
          displayErrors: [],
        },
      },
      cb
    );

  validateForm = (formValues) => {
    const errors = validate(formValues);
    const nonEmptyErrors = _.pickBy(errors, _.identity);

    if (!_.isEmpty(nonEmptyErrors.displayErrors)) {
      this.resetFormErrors(() => this.setFormErrors(nonEmptyErrors));

      throw new SubmissionError(errors);
    }
  };

  handleFormSubmitError = (error) => {
    handleError(error);

    this.resetFormErrors(() =>
      this.setFormErrors({
        displayErrors: [SUBMISSION_FAILED_ERROR],
      })
    );
  };

  constructRequestPayload = (formValues) => {
    const { appealID } = this.props;

    const { cpts, denialDate } = formValues.toJS();

    return {
      id: appealID,
      eob_procedures: cpts.map(EOBProcedureTransform),
      denied_at: transformDateString(denialDate),
    };
  };

  onSaveClick = async (values) => {
    this.validateForm(values);

    try {
      const { appealID } = this.props;

      const requestPayload = this.constructRequestPayload(values);

      const appeal = await CreateAppealAPI.saveResponse(
        appealID,
        requestPayload
      );

      const isAppealDenied = appeal.statusCode === AppealStatusCode.Denied;

      const message = isAppealDenied
        ? `The status of Appeal with Claim ID ${this.props.appeal.claimNumber} changed to denied.`
        : `The status of Appeal with Claim ID ${this.props.appeal.claimNumber} changed to successful.`;

      toast.success({
        title: 'Success',
        message,
      });

      this.props.closeAppealDetails();
      this.props.reloadSubmissionPage && this.props.reloadSubmissionPage();
    } catch (error) {
      this.handleFormSubmitError(error);
    }
  };

  onSaveAndCloseClick = async (values) => {
    const { appealID } = this.props;

    this.validateForm(values);

    try {
      const requestPayload = this.constructRequestPayload(values);

      const appeal = await CreateAppealAPI.saveResponseAndClose(
        appealID,
        requestPayload
      );

      const isAppealDenied = appeal.statusCode === AppealStatusCode.Denied;
      const message = isAppealDenied
        ? `The status of Appeal with Claim ID ${this.props.appeal.claimNumber} changed to denied and is closed.`
        : `The status of Appeal with Claim ID ${this.props.appeal.claimNumber} changed to successful and is closed.`;

      toast.success({
        title: 'Success',
        message,
      });

      this.props.closeAppealDetails();
      this.props.reloadSubmissionPage && this.props.reloadSubmissionPage();
    } catch (error) {
      this.handleFormSubmitError(error);
    }
  };

  closeClaim = async () => {
    const { appeal } = this.props;
    try {
      await AppealsAPI.closeAppeal(appeal.id);

      toast.success({
        title: 'Success',
        message: `The status of Appeal with Claim ID ${appeal.claimNumber} is closed.`,
      });

      this.props.closeAppealDetails();
      this.props.reloadSubmissionPage && this.props.reloadSubmissionPage();
    } catch (error) {
      this.handleFormSubmitError(error);
    }
  };

  onCloseClaimClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.openCloseAppealPopup();
  };

  closeCloseAppealPopup = (cb) =>
    this.setState(
      {
        closeAppealPopup: {
          isOpen: false,
        },
      },
      cb
    );

  onReAppealClick = async (values) => {
    this.validateForm(values);

    try {
      const { appeal, denialDate, appealID } = this.props;
      const { deadlinePassedPopup } = this.state;

      const { deadline } = await CreateAppealAPI.calculateDeadline(
        appealID,
        denialDate,
        appeal.appealRound + 1
      );

      const isInThePast = dateIsInThePast(deadline);

      if (!deadlinePassedPopup.acceptPassedDeadline && isInThePast) {
        return this.setDeadlinePassedPopup({
          deadline,
          isOpen: true,
          acceptPassedDeadline: false,
        });
      }

      const requestPayload = await this.constructRequestPayload(values);
      const appealData = await CreateAppealAPI.reAppeal(appealID, {
        deadline,
        ...requestPayload,
      });
      this.props.actions.setAppealData({
        appeals: [
          {
            ...appealData,
            step: 2,
          },
        ],
        name: appealData.patientName,
      });

      toast.success({
        title: 'Success',
        message: `Re-appealed appeal with Claim ID ${this.props.appeal.claimNumber}`,
      });
      this.props.actions.push('/createappeal/patientinfo');
    } catch (error) {
      this.handleFormSubmitError(error);
    }

    this.props.closeAppealDetails();
  };

  renderMoneyInputField = (cpt, cptProperty) => {
    return (
      <Field
        name={`${cpt}.${cptProperty}`}
        component={renderInput}
        normalize={normalizeMoney}
        format={formatMoney}
      />
    );
  };

  getAmountWithDelta = (amount, deltaAmount) => {
    if (amount === undefined || amount === null) {
      return '--';
    }

    if (!deltaAmount || !parseMoney(deltaAmount.toString())) {
      return `${formatToMoney(amount)}`;
    }

    const isNegativeDelta = deltaAmount.toString().includes('-');
    const formattedDeltaAmount = formatToMoney(deltaAmount);
    const renderDeltaAmount = isNegativeDelta
      ? formattedDeltaAmount
      : `+${formattedDeltaAmount}`;

    return (
      <div>
        {formatToMoney(amount)}
        <span className={isNegativeDelta ? 'color-red' : 'color-green'}>
          ({renderDeltaAmount})
        </span>
      </div>
    );
  };

  renderCPTS = ({ fields, meta: { error, submitFailed } }) => {
    const cpts = this.props.cpts;
    const { isViewOnlyForm } = this.props;

    if (!cpts) {
      return <React.Fragment></React.Fragment>;
    }

    return fields.map((cpt, index) => {
      const cptValues = cpts.toJS()[index];

      return (
        <tr key={index}>
          <td>{cptValues.serv_date || '--'}</td>
          <td>{cptValues.cpt_code || '--'}</td>
          <td>{cptValues.cpt_modifiers_code || '--'}</td>
          <td>{cptValues.quantity || '--'}</td>
          <td>{this.getAmountWithDelta(cptValues.billed_amount)}</td>
          <td>
            {isViewOnlyForm
              ? this.getAmountWithDelta(
                  cptValues.amount_allowed,
                  cptValues.amount_allowed_delta
                )
              : this.renderMoneyInputField(cpt, 'amount_allowed')}
          </td>
          <td>
            {isViewOnlyForm
              ? this.getAmountWithDelta(
                  cptValues.deductible,
                  cptValues.deductible_delta
                )
              : this.renderMoneyInputField(cpt, 'deductible')}
          </td>
          <td>
            {isViewOnlyForm ? (
              cptValues.adj_codes || '--'
            ) : (
              <div className="width-130">
                <Field
                  name={`${cpt}.adj_codes`}
                  component={MultiValueInput}
                  formName="recordResponse"
                />
              </div>
            )}
          </td>
          <td>
            {isViewOnlyForm
              ? this.getAmountWithDelta(
                  cptValues.co_insurance,
                  cptValues.co_insurance_delta
                )
              : this.renderMoneyInputField(cpt, 'co_insurance')}
          </td>
          <td>
            {isViewOnlyForm
              ? this.getAmountWithDelta(
                  cptValues.payment_amount,
                  cptValues.payment_amount_delta
                )
              : this.renderMoneyInputField(cpt, 'payment_amount')}
          </td>
          <td>
            {isViewOnlyForm ? (
              cptValues.remark_code || '--'
            ) : (
              <div className="width-130">
                <Field
                  name={`${cpt}.remark_codes`}
                  component={MultiValueInput}
                  formName="recordResponse"
                />
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  renderConfirmationTitle = () => {
    const { isViewOnlyForm, appeal } = this.props;

    if (isViewOnlyForm) {
      const icon = appeal.status === 'Denied' ? closeIcon : checkIcon;

      return (
        <div className="d-flex">
          <div>
            <img alt="Close" src={icon} />
          </div>
          <div className="d-flex mr-16 justify-content-center flex-direction--column">
            <div className="mr-16">Your Appeal was {appeal.status}!</div>
            <div className="fw-normal fs-14">
              How would you like to proceed?
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="mr-16">How would you like to proceed?</div>
      </div>
    );
  };

  renderSaveClaimButtons = () => {
    const { isViewOnlyForm } = this.props;

    if (isViewOnlyForm) {
      return (
        <button
          className="re-appeal-action-button mr-8"
          onClick={this.onCloseClaimClick}
        >
          Close Claim
        </button>
      );
    }

    return (
      <React.Fragment>
        <button
          className="re-appeal-action-button mr-8"
          onClick={this.props.handleSubmit(this.onSaveClick)}
        >
          Save Response
        </button>
        <button
          className="re-appeal-action-button mr-8"
          onClick={this.props.handleSubmit(this.onSaveAndCloseClick)}
        >
          Save Response & Close Claim
        </button>
      </React.Fragment>
    );
  };

  renderConfirmationBox = () => {
    return (
      <div className="col-xs-12 d-flex justify-content-center">
        <div
          className={classNames(
            're-appeal-action-button-container justify-content--space-around',
            {
              'border-color-red': this.props.appeal.status === 'Denied',
              'border-color-green': this.props.appeal.status === 'Successful',
            }
          )}
        >
          {this.renderConfirmationTitle()}
          <div className="d-flex justify-content--space-between">
            <button
              className="re-appeal-action-button mr-8"
              onClick={this.props.handleSubmit(this.onReAppealClick)}
            >
              <FaRedoAlt className="mr-8" />
              Re-appeal
            </button>
            {this.renderSaveClaimButtons()}
          </div>
        </div>
      </div>
    );
  };

  renderPopups() {
    const { deadlinePassedPopup, closeAppealPopup } = this.state;

    if (closeAppealPopup.isOpen) {
      const handleActionButtonPress = (index) => {
        if (index === 0) {
          this.closeCloseAppealPopup();
        } else {
          this.closeCloseAppealPopup(this.closeClaim);
        }
      };

      return (
        <ConfirmClosingAppealPopup
          onClosePressed={() => this.closeCloseAppealPopup()}
          onActionButtonPressed={handleActionButtonPress}
        />
      );
    }

    if (deadlinePassedPopup.isOpen) {
      const formattedDeadline = getFormattedDate(
        deadlinePassedPopup.deadline,
        'LL'
      );

      const handleActionButtonPressed = (index) => {
        if (index === 0) {
          return this.resetDeadlinePassedPopup();
        }

        this.setDeadlinePassedPopup(
          {
            ...deadlinePassedPopup,
            acceptPassedDeadline: true,
          },
          this.props.handleSubmit(this.onReAppealClick)
        );
      };

      return (
        <PastDeadlinePopUp
          onClosePressed={this.resetDeadlinePassedPopup}
          onActionButtonPressed={handleActionButtonPressed}
          errorMessage={formattedDeadline}
        />
      );
    }
  }

  isFieldVisible(name) {
    const COLUMNS_TO_EXCLUDE = [];

    return !COLUMNS_TO_EXCLUDE.includes(name);
  }

  renderViewOnlyInfoToast() {
    if (!this.state.isViewOnlyInfoToastShown && this.props.isViewOnlyForm) {
      this.setState({
        isViewOnlyInfoToastShown: true,
      });

      toast.info({
        title: 'Info',
        message:
          'All fields on this page are pre-filled. You cannot change them.',
      });
    }
  }

  render() {
    const { isViewOnlyForm, appeal } = this.props;

    if (this.props.isLoading) {
      return (
        <React.Fragment>
          <LoadingIndicator />
        </React.Fragment>
      );
    }

    return (
      <div className="appeal-action__container">
        <div className="appeal-action__title">
          {!isViewOnlyForm ? 'RECORD RESPONSE' : 'RESOLVE CLAIM'}
        </div>

        <div className="record-response__patient-info">
          <table className="record-response__patient-info-table">
            <thead>
              <tr>
                <td>Patient</td>
                <td>Provider</td>
                <td>Payer</td>
                <td>Payer Claim ID</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {_.get(appeal, 'patientName', '')} (
                  {_.get(appeal, 'patient.customPatientId', '')})
                </td>
                <td>{_.get(appeal, 'providerName', '')}</td>
                <td>{_.get(appeal, 'payer.name', '')}</td>
                <td>{_.get(appeal, 'claimNumber', '')}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {!_.isEmpty(this.state.formErrors.displayErrors) && (
          <div className="row record-response-error-banner">
            <ErrorBannerSection
              className="col-xs-10"
              errors={this.state.formErrors.displayErrors}
            />
          </div>
        )}

        {!this.props.isViewOnlyForm && (
          <div className="pl-24 mt-40 mb-24 fs-18">
            Please enter Payer’s Response below to update this Appeal.
          </div>
        )}
        <form className="pl-24 appeal-action__form col-xs-12">
          <div className="row">
            <div className="col-xs-2 record-response__eob-date-input">
              <Field
                name="denialDate"
                label="Most Recent EOB:"
                size="10"
                type="string"
                mask={MASK_DATE}
                placeholder="MM/DD/YYYY"
                required={false}
                component={renderReduxMaskedInput}
                disabled={isViewOnlyForm}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 position-relative">
              <table className="appeal-cpt-info-table mb-30">
                <thead>
                  <tr>
                    <td>Service Date</td>
                    <td>Procedure</td>
                    <td>Modifier</td>
                    <td>Quantity</td>
                    <td>Billed Amount</td>
                    <td>Allowed Amount</td>
                    <td>Deductible</td>
                    <td>Adjustment Code</td>
                    <td>Coinsurance</td>
                    <td>Payment Amount</td>
                    <td>Remark Code</td>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name="cpts" component={this.renderCPTS} />
                </tbody>
              </table>
              <div className="record-response-cpt-info-table__divider"></div>
            </div>
          </div>

          <div className="row mb-40">
            <div className="col-xs-12">
              <div className="record-response-summary-table__container">
                <div
                  className={classNames(
                    'record-response-summary-table__title record-response-summary-table__title--pl-2x'
                  )}
                >
                  Totals
                </div>
                <table className="appeal-cpt-info-table">
                  <thead>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Billed Amount</td>
                      <td>Allowed Amount</td>
                      <td>Deductible</td>
                      <td></td>
                      <td>Coinsurance</td>
                      <td>Payment Amount</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {this.getAmountWithDelta(
                          this.props.sumAmounts.billedAmount
                        )}
                      </td>
                      <td>
                        {this.getAmountWithDelta(
                          this.props.sumAmounts.amountAllowed,
                          this.props.sumAmounts.amountAllowedDelta
                        )}
                      </td>
                      <td>
                        {this.getAmountWithDelta(
                          this.props.sumAmounts.deductible,
                          this.props.sumAmounts.deductibleDelta
                        )}
                      </td>
                      <td></td>
                      <td>
                        {this.getAmountWithDelta(
                          this.props.sumAmounts.coInsurance,
                          this.props.sumAmounts.coInsuranceDelta
                        )}
                      </td>
                      <td>
                        {this.getAmountWithDelta(
                          this.props.sumAmounts.paymentAmount,
                          this.props.sumAmounts.paymentAmountDelta
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mb-40 record-response__total-denied-amount">
            Total Denied Amount:{' '}
            {this.getAmountWithDelta(this.props.sumAmounts.totalDeniedAmount)}
          </div>
          <div className="row">{this.renderConfirmationBox()}</div>
        </form>
        {this.renderPopups()}
        {this.renderViewOnlyInfoToast()}
      </div>
    );
  }
}

const extractSumAmounts = (cpts) => {
  if (!cpts) {
    return {
      billedAmount: 0,
      amountAllowed: 0,
      amountAllowedDelta: 0,
      deductible: 0,
      deductibleDelta: 0,
      coInsurance: 0,
      coInsuranceDelta: 0,
      paymentAmount: 0,
      paymentAmountDelta: 0,
      totalDeniedAmount: 0,
    };
  }

  return readAmountsFromCPTForm(cpts);
};

const selector = formValueSelector('recordResponse');

function mapStateToProps(state, ownProps) {
  const appealID = ownProps.appealID;
  const appealState = getAppealFromState(state, appealID);

  if (!appealState || appealState.isLoading || appealState.isRejected) {
    return {
      isLoading: true,
      appealID,
    };
  }

  const { appeal } = appealState;
  const isImportedAppeal = appeal.imported === true;

  const isAllEobProcedureValuesPresent =
    appeal.isAllEobProcedureValuesPresent === true;
  const isViewOnlyForm = ['Denied', 'Successful'].includes(appeal.status);

  const initialValues = {
    ...getEOBInitialValuesFromAppealState(appealState),
  };

  initialValues.denialDate = !isViewOnlyForm
    ? undefined
    : getFormattedDate(initialValues.deniedAt, VALIDATION_DATE_FORMAT);

  const cpts = selector(state, 'cpts');

  let totalDeniedAmount;

  let amountAllowed;
  if (cpts) {
    amountAllowed = parseFloat(sumMoney(cpts, 'amount_allowed'));
    totalDeniedAmount = readDeniedAmountFromCPTForm(cpts);
  }

  const denialDate = transformDateString(selector(state, 'denialDate'));

  return {
    appealID,
    appeal,
    isViewOnlyForm,
    isImportedAppeal,
    isLoading: false,
    initialValues,
    totalDeniedAmount,
    amountAllowed,
    cpts,
    sumAmounts: extractSumAmounts(cpts),
    denialDate,
    isAllEobProcedureValuesPresent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        push,
        replace,
        getAppealById,
        clearCurrentAppealData,
        setAppealData,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'recordResponse',
    touchOnBlur: false,
    destroyOnUnmount: true,
  })(AppealAction)
);
