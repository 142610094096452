import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import React, { useState, useEffect } from 'react';

import { getUserInfo } from 'redux/reducers/loginStore';

import {
  APPEAL_RECORD_ACTIONS,
  REASONS_CHARACTER_LIMIT,
} from 'constants/appConstants';

import { useFetchActionList } from '../hooks/useFetchActionList';

const ActionList = (props) => {
  const { setAction } = props;
  const [actionDetail, setActionDetail] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [isTextAreaVisible, setIsTextAreaVisible] = useState(false);
  const [isActionCharacterExceeds, setACtionCharacterExceeds] = useState(false);

  useEffect(() => {
    setAction(null);
  }, [setAction]);

  const { actionList, isLoading } = useFetchActionList();

  const handleOnChange = (e) => {
    const { value } = e;
    const isTextAreaVisible = value === 0;
    setSelectedOption(
      actionList.find(({ value: optionValue }) => optionValue === value)
    );
    setIsTextAreaVisible(isTextAreaVisible);
    setAction(isTextAreaVisible ? null : value);
  };

  const handleTextAreaChange = (e) => {
    const { value } = e.target;

    if (value.length > REASONS_CHARACTER_LIMIT) {
      setACtionCharacterExceeds(true);
      setActionDetail(value);
      setAction(null);
      return;
    }

    if (isEmpty(value)) {
      setActionDetail('');
      setAction(null);
      return;
    }

    setACtionCharacterExceeds(false);
    setActionDetail(value);
    setAction(value);
  };
  return (
    <div className="dq-reason-to-remove-container" datacy="reason-to-remove">
      <div
        className="dq-reason-to-remove__select-wrapper"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ReactSelect
          className={'ap-select'}
          placeholder={'Select action'}
          options={actionList.filter(
            (option) => !APPEAL_RECORD_ACTIONS.includes(option.label)
          )}
          onChange={handleOnChange}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={selectedOption}
          isLoading={isLoading}
          datacy="reason-to-remove-ReactSelect"
        />
      </div>
      {isTextAreaVisible && (
        <div className="dq-reason-to-remove__custom-reason">
          <textarea
            name="claimRemovalInfo"
            className={classnames('textarea__input textarea__input--dark', {
              'textarea__input--error': !actionDetail,
            })}
            value={actionDetail}
            onChange={handleTextAreaChange}
            placeholder={'Please add your action here...'}
          />
          {isActionCharacterExceeds && (
            <span className="error">
              {' '}
              The action cannot be more than 25 characters.{' '}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

ActionList.propTypes = {
  setAction: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { practice, secretKey } = getUserInfo(state);
  return {
    practice,
    secretKey,
  };
}

export default connect(mapStateToProps, {})(ActionList);
