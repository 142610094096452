import React from 'react';
import ReactTooltip from 'react-tooltip';

import resolveIcon from 'img/arrow-white-dotted.svg';
import searchDarkIcon from 'img/search-icon-dark.svg';

import { SUBMISSION_TYPE } from 'constants/appConstants';
import { STATUS_OPTIONS_TYPE } from 'components/Submissions/DeliveryTracking/DeliveryTrackerActionBar/constant';

const SuccessfulDeniedActionsCell = ({
  data,
  setRecordResponseFlyover,
  openUhcClaimStatusWidget,
  openAvailityClaimStatusWidget,
}) => {
  const { readOnly, type: submissionType, status, reAppealId } = data;
  const showResolveResponseOption =
    reAppealId &&
    submissionType === SUBMISSION_TYPE.APPEAL &&
    status === STATUS_OPTIONS_TYPE.DELIVERED &&
    !readOnly;

  return (
    <td>
      <div className="global-search-v2__search-result-table__data-td--action">
        {showResolveResponseOption && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setRecordResponseFlyover({
                isOpen: true,
                appealId: reAppealId,
                isResolveClaim: true,
              });
            }}
            className="ap-button ap-button--secondary"
          >
            <img src={resolveIcon} alt="resolve-response" className="mr-4" />
            <span>Resolve Claim</span>
          </button>
        )}
        {(data?.uhcClaimStatusSearchData ||
          data?.availityClaimStatusSearchData) && (
          <div className="d-flex justify-content-flex-end mr-8">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (data?.availityClaimStatusSearchData) {
                  return openAvailityClaimStatusWidget(
                    data?.availityClaimStatusSearchData
                  );
                }
                openUhcClaimStatusWidget(data?.uhcClaimStatusSearchData);
              }}
              data-tip={`Claim Status Check via ${
                data?.availityClaimStatusSearchData ? 'Availity' : 'UHC'
              }`}
              className="global-search-v2__search-result-table__data-td--action-btn"
            >
              <img src={searchDarkIcon} alt="search claim" />
            </button>
            <ReactTooltip effect="solid" place="top" multiline={true} />
          </div>
        )}
      </div>
    </td>
  );
};

export default SuccessfulDeniedActionsCell;
