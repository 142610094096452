import React from 'react';
import ReactTooltip from 'react-tooltip';

import recordResponseIcon from 'img/edit-box-white.svg';
import searchDarkIcon from 'img/search-icon-dark.svg';

import { SUBMISSION_TYPE } from 'constants/appConstants';
import { STATUS_OPTIONS_TYPE } from 'components/Submissions/DeliveryTracking/DeliveryTrackerActionBar/constant';

const SubmittedActionsCell = ({
  data,
  setRecordResponseFlyover,
  openUhcClaimStatusWidget,
  openAvailityClaimStatusWidget,
}) => {
  const {
    readOnly,
    recordResponseAppealId,
    type: submissionType,
    status,
  } = data;
  const showRecordResponseOption =
    recordResponseAppealId &&
    submissionType === SUBMISSION_TYPE.APPEAL &&
    [STATUS_OPTIONS_TYPE.DELIVERED, STATUS_OPTIONS_TYPE.IN_TRANSIT].includes(
      status
    ) &&
    !readOnly;

  return (
    <td>
      <div className="global-search-v2__search-result-table__data-td--action">
        {showRecordResponseOption && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setRecordResponseFlyover({
                isOpen: true,
                appealId: recordResponseAppealId,
                isResolveClaim: false,
              });
            }}
            className="ap-button ap-button--secondary"
          >
            <img
              src={recordResponseIcon}
              alt="record-response"
              className="mr-4"
            />
            <span> Record Response</span>
          </button>
        )}
        {(data?.uhcClaimStatusSearchData ||
          data?.availityClaimStatusSearchData) && (
          <div className="d-flex justify-content-flex-end mr-8">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (data?.availityClaimStatusSearchData) {
                  return openAvailityClaimStatusWidget(
                    data?.availityClaimStatusSearchData
                  );
                }
                openUhcClaimStatusWidget(data?.uhcClaimStatusSearchData);
              }}
              data-tip={`Claim Status Check via ${
                data?.availityClaimStatusSearchData ? 'Availity' : 'UHC'
              }`}
              className="global-search-v2__search-result-table__data-td--action-btn"
            >
              <img src={searchDarkIcon} alt="search claim" />
            </button>
            <ReactTooltip effect="solid" place="top" multiline={true} />
          </div>
        )}
      </div>
    </td>
  );
};

export default SubmittedActionsCell;
