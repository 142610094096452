/**
 * Created by alex on 10/19/16.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { TAG_CLASS_NAME } from './TemplateFiller';

export const EDITOR_CONFIG = {
  menubar: 'false',
  plugins: 'link image wordcount textcolor hr template media paste',
  toolbar:
    'bold italic underline forecolor |        | link bullist image blockquote |     | formatselect alignleft aligncenter alignright |       | undo redo | fontsizeselect',
  skin: 'appealio',
  height: 560,
  toolbar_items_size: 'small',
  fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
  elementpath: false,
  object_resizing: false,
  content_style: `.${TAG_CLASS_NAME} {
    object-fit: contain;
    border-radius: 3px;
    background-color: #b2d7fc;
    padding: 2px 10px;
    outline: none;
    border: none;
    color: #000;
  }`,
  force_br_newlines: true,
  force_p_newlines: false,
  forced_root_block: '',
  extended_valid_elements: 'input[type=button|class|value|disabled]',
  newline_behavior: '',
};

export const EDITOR_CHANGE_TYPE = {
  EDITOR_INITIALIZED: 'EDITOR_INITIALIZED',
  KEY_UP_EVENT: 'KEY_UP_EVENT',
  EDITOR_CONTENT_CHANGED: 'EDITOR_CONTENT_CHANGED',
  CONTENT_UPDATED: 'CONTENT_UPDATED',
};

export default class RichTextEditor extends Component {
  static propTypes = {
    onContentChange: PropTypes.func,
    content: PropTypes.string,
    autoFocus: PropTypes.bool,
    datacy: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
  };

  getContent() {
    return this.tinyMCE.getContent();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.tinyMCE &&
      nextProps.content &&
      nextProps.content !== this.props.content
    ) {
      this.tinyMCE.setContent(nextProps.content);

      if (this.props.onContentChange) {
        this.props.onContentChange(
          this.tinyMCE.getContent(),
          EDITOR_CHANGE_TYPE.EDITOR_CONTENT_CHANGED
        );
      }
    }
  }

  render() {
    return (
      <Editor
        init={EDITOR_CONFIG}
        onChange={this.handleEditorChange}
        onKeyUp={this.handleKeyDown}
        onInit={this.handleEditorInit}
        datacy="rich-text-editor"
      />
    );
  }

  handleEditorChange = (e) => {
    if (this.props.onContentChange) {
      this.props.onContentChange(
        e.target.getContent(),
        EDITOR_CHANGE_TYPE.EDITOR_CONTENT_CHANGED
      );
    }
  };

  handleKeyDown = (keyEvent, editor) => {
    if (this.props.onContentChange) {
      this.props.onContentChange(
        editor.getContent(),
        EDITOR_CHANGE_TYPE.KEY_UP_EVENT
      );
    }
  };

  handleEditorInit = (e) => {
    this.tinyMCE = e.target;
    this.tinyMCE.getDoc().body.style.fontFamily = 'Arial';
    setTimeout(() => {
      if (this.props.content) {
        this.tinyMCE.setContent(this.props.content);
        this.props.onContentChange(
          this.tinyMCE.getContent(),
          EDITOR_CHANGE_TYPE.EDITOR_INITIALIZED
        );
      }
      if (this.props.autoFocus) {
        this.tinyMCE.editorCommands.execCommand(
          'mceFocus',
          false,
          this.tinyMCE.id
        );
      }
    }, 200);
  };
}
