import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';

import { loginStore } from './loginStore';
import { sopStore } from './sopStore';
import { appealStore } from './AppealStore';
import { deadlineStore } from './DeadlineStore';
import { rpaWidgetStore } from './RpaWidgetStore';
import { notificationStore } from './notificationStore';
import { createAppealStore } from './createAppealStore';
import { appealLettersStore } from './AppealLettersStore';
import dropdownOptionsStore from './dropdownOptionsStore';
import { forgotPasswordStore } from './ForgotPasswordStore';
import { appealFormPrefillStore } from './AppealFormPrefillStore';
import { batchUploadWidgetStore } from './batchUploadWidgetStore';
import { uhcClaimStatusStoreWidgetStore } from './UhcClaimStatusWidgetStore';
import { availityClaimStatusStoreWidgetStore } from './AvailityClaimStatusWidgetStore';

const createRootReducer = (history) =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    loginStore,
    createAppealStore,
    forgotPasswordStore,
    deadlineStore,
    appealStore,
    rpaWidgetStore,
    uhcClaimStatusStoreWidgetStore,
    availityClaimStatusStoreWidgetStore,
    dropdownOptionsStore,
    sopStore,
    appealFormPrefillStore,
    notificationStore,
    appealLettersStore,
    batchUploadWidgetStore,
  });

export default createRootReducer;
