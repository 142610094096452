import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ScreenDimmerComponent from './ScreenDimmerComponent';
import closeIcon from '../../img/close-modal.svg';
import SubmitButton from './SubmitButton';
import classNames from 'classnames';
import LoadingBlockHelper from './LoadingBlockHelper';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

export const TYPE_ACCEPT = 1;
export const TYPE_DISABLED = 2;

export default class ActionPopup extends Component {
  static propTypes = {
    icon: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actionButtons: PropTypes.array,
    isLoading: PropTypes.bool,
    onClosePressed: PropTypes.func,
    onActionButtonPressed: PropTypes.func,
    errorMessage: PropTypes.string,
    actionPopupClassName: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    includeOutSideClickWrapper: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
    errorMessage: null,
    handleOutsideClick: () => ({}),
    includeOutSideClickWrapper: true,
  };

  renderActionPopupComponent = () => (
    <div
      className={classNames('action_popup', this.props.actionPopupClassName)}
      datacy="actionPopup"
      onClick={(e) => e.stopPropagation()}
    >
      {!this.props.hideCloseButton && (
        <div className="row" datacy="close-x">
          <img
            alt="Close"
            onClick={(e) => {
              e.stopPropagation();
              this.props.onClosePressed();
            }}
            className="action_popup__close"
            src={closeIcon}
          />
        </div>
      )}
      <div className="row action_popup__row">
        <img
          alt="Popup Icon"
          className="action_popup__icon"
          src={this.props.icon}
        />
      </div>
      <div className="row action_popup__row">
        <div className="action_popup__title">{this.props.title}</div>
      </div>
      {this.renderError()}
      <div className="row action_popup__row">
        <div className="action_popup__subtitle">{this.props.subtitle}</div>
      </div>
      {this.props.children && (
        <div className="row action_popup__row">{this.props.children}</div>
      )}
      <div className="row modal__alingnemt" />
      <div className="row action_popup__row">
        <div className="modal__separator action_poup__separator" />
      </div>
      <div className="row modal__alingnemt" />

      <div className="row action_popup__row">
        <LoadingBlockHelper isLoading={this.props.isLoading}>
          {this.renderActionButtons()}
        </LoadingBlockHelper>
      </div>
    </div>
  );

  render() {
    return (
      <ScreenDimmerComponent
        {...(!this.props.includeOutSideClickWrapper && {
          onClick: (e) => {
            e.stopPropagation();
          },
        })}
      >
        {this.props.includeOutSideClickWrapper ? (
          <OutsideClickWrapper
            handleOutsideClick={this.props.handleOutsideClick}
          >
            {this.renderActionPopupComponent()}
          </OutsideClickWrapper>
        ) : (
          this.renderActionPopupComponent()
        )}
      </ScreenDimmerComponent>
    );
  }

  renderError = () => {
    if (this.props.errorMessage) {
      return (
        <div className="row action_popup__row">
          <div className="action_popup__error">{this.props.errorMessage}</div>
        </div>
      );
    }
  };

  renderActionButtons = () => {
    const numberOfButtons = this.props.actionButtons.length;
    return this.props.actionButtons.map((btnConfig, index) => {
      return (
        <div
          key={index}
          className={classNames({
            'col-xs-8 col-xs-offset-2': numberOfButtons === 1,
            'col-xs-6': numberOfButtons === 2,
          })}
        >
          <SubmitButton
            onClick={(e) => {
              e.stopPropagation();
              this.props.onActionButtonPressed(index);
            }}
            title={btnConfig.title}
            wrapInDiv={false}
            disabled={btnConfig.type === TYPE_DISABLED}
            className={classNames(
              'submit__button',
              'submit__button--fullwidth',
              {
                'submit__button--cancel': btnConfig.type !== TYPE_ACCEPT,
                'submit__button--transparent': btnConfig.type !== TYPE_ACCEPT,
                'submit__button--disabled':
                  btnConfig.type && btnConfig.type === TYPE_DISABLED,
              }
            )}
            datacy="action-popup-SubmitButton"
          />
        </div>
      );
    });
  };
}
