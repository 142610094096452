import classnames from 'classnames';
import { connect } from 'react-redux';
import { get, debounce } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';

import SearchInput from 'components/common/input/SearchInput';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import SearchWithOption from 'components/common/search/SearchWithOption/SearchWithOption';

import { attachCountsToArrayOfObjects } from '../utils';

import { getUserInfo } from 'redux/reducers/loginStore';

import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import { isClientAdmin } from 'Auth/AuthUtils';
import {
  useFetchPayersList,
  useFetchPayersWithCptCount,
  useFetchProcedureCodes,
  useFilterPractices,
} from '../hook';

import emptyIcon from 'img/empty.svg';

import './style.css';
import FeeScheduleTable from './FeeScheduleTable';

const PAYER_OPTION = {
  label: 'Payer',
  value: 'PAYER',
  isDefault: true,
};

const PROCEDURE_CODE_OPTION = {
  label: 'Procedure Code',
  value: 'PROCEDURE CODE',
};

const SEARCH_TYPES = [PAYER_OPTION, PROCEDURE_CODE_OPTION];

const FeeSchedules = (props) => {
  const [showAddFeeScheduleForm, setShowAddFeeScheduleForm] = useState(false);
  const [editingFeeScheduleId, setEditingFeeScheduleId] = useState(false);

  const [selectedPractice, setSelectedPractice] = useState(
    props.userPractices[0]
  );
  const [searchQuery, setSearchQuery] = useState({
    query: '',
    type: PAYER_OPTION.value,
  });
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [searchPractice, setSearchPractice] = useState('');
  const isPayerSearch = searchQuery.type === PAYER_OPTION.value;
  const procedureCodeSearchQuery =
    searchQuery.type === PROCEDURE_CODE_OPTION.value ? searchQuery.query : '';

  const { loading: isPayersLoading, payers } = useFetchPayersList();

  const [listedPayers, setListedPayers] = useState(payers);

  const { loading: isProcedureCodesLoading, procedureCodes } =
    useFetchProcedureCodes();

  const { payersWithCptCount, fetch: fetchPayersWithCptCount } =
    useFetchPayersWithCptCount(selectedPractice?.id, procedureCodeSearchQuery);

  const clearSearchPractice = () => setSearchPractice('');

  const handlePracticeSearch = debounce((event) => {
    setSearchPractice(event.target?.value);
  }, 300);

  const handlePayerProcedureSearch = ({ query, type }) => {
    setSearchQuery({
      query,
      type,
    });
  };

  const { practices } = useFilterPractices(props.userPractices, searchPractice);

  const handlePracticeClick = (practice) => {
    setSelectedPractice(practice);
    setEditingFeeScheduleId(null);
    setShowAddFeeScheduleForm(false);
  };
  const handlePayerClick = (payer) => {
    setSelectedPayer(payer);
    setEditingFeeScheduleId(null);
    setShowAddFeeScheduleForm(false);
  };

  const searchBoxPlaceholder = isPayerSearch
    ? 'Search by Payer'
    : 'Search by Procedure Code';

  const selectedPayerId = selectedPayer ? selectedPayer.id : null;

  const filteredPayers = useMemo(() => {
    if (isPayerSearch) {
      if (!searchQuery.query) {
        if (payersWithCptCount?.length > 0) {
          const payersWithCounts = attachCountsToArrayOfObjects(
            payers,
            payersWithCptCount,
            'payerId',
            'feeScheduleCount'
          ).sort((a, b) => b.feeScheduleCount - a.feeScheduleCount);
          if (!selectedPayerId) {
            setSelectedPayer(payersWithCounts[0]);
            return payersWithCounts;
          }
          return payersWithCounts;
        }
      }

      const search = searchQuery?.query?.toUpperCase();
      return payers?.filter((payer) =>
        payer?.name?.toUpperCase().includes(search)
      );
    }
    if (
      payersWithCptCount?.length > 0 &&
      searchQuery.query.length >= SEARCH_QUERY_CHARACTERS_OFFSET
    ) {
      const payersWithCounts = attachCountsToArrayOfObjects(
        payers,
        payersWithCptCount,
        'payerId',
        'feeScheduleCount'
      )
        .filter((payer) => payer.feeScheduleCount)
        .sort((a, b) => b.feeScheduleCount - a.feeScheduleCount);

      const selectedHasContacts = payersWithCounts.some(
        (payer) => payer.id === selectedPayerId
      );

      if (
        (payers.length < 1 &&
          payersWithCounts.length > 0 &&
          !selectedHasContacts) ||
        payersWithCounts.length === 1
      ) {
        setSelectedPayer(payersWithCounts[0]);
      }

      return payersWithCounts;
    }
    return payers;
  }, [isPayerSearch, searchQuery, payers, selectedPayerId, payersWithCptCount]);

  useEffect(() => {
    setListedPayers(filteredPayers);
    if (!selectedPayerId && filteredPayers?.length > 0) {
      setSelectedPayer(filteredPayers[0]);
    }
  }, [filteredPayers, searchQuery, payersWithCptCount, selectedPayerId]);

  if (payers?.length === 0 && practices?.length === 0) {
    return (
      <div className="no-data-container mt-40">
        <img src={emptyIcon} alt="No Data Found" />
        <h4>No Data Found</h4>
      </div>
    );
  }

  return (
    <div className="settings-content-wrapper settings-content-wrapper-fee-schedules">
      <div
        className={classnames(
          'd-flex  align-items-center settings-content__top-action mb-40'
        )}
      >
        <h5 className="settings-content-wrapper__title">
          Fee Schedules for {selectedPractice?.name}
        </h5>
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setShowAddFeeScheduleForm(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            disabled={showAddFeeScheduleForm || editingFeeScheduleId}
          >
            <span className="mr-4">+</span> Add Contracted Rate
          </button>
        )}
      </div>
      <div className="row d-flex mt-12">
        <div className="col-lg-2 border-right-grey settings-content__left-sidebar settings-content__top-action settings-content__top-action--full-width">
          <SearchInput
            placeholder="Search by Practice"
            onSearch={handlePracticeSearch}
            onReset={clearSearchPractice}
          />
          {practices?.length > 0 ? (
            practices.map((practice, idx) => (
              <div
                key={idx}
                onClick={() => handlePracticeClick(practice)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPractice && selectedPractice.id === practice.id,
                })}
              >
                <span> {practice.name}</span>
              </div>
            ))
          ) : (
            <p className="fw-bold"> No Practice found </p>
          )}
        </div>
        <div className="col-lg-3 border-right-grey settings-content__left-sidebar">
          <div className="mb-8 sticky-search-with-option-wrapper">
            <SearchWithOption
              onSearch={handlePayerProcedureSearch}
              options={SEARCH_TYPES}
              placeholder={searchBoxPlaceholder}
              handleReset={() => setSelectedPayer(payers[0])}
              hideSearchIcon
            />
          </div>
          {listedPayers?.length > 0 ? (
            listedPayers.map((payer, idx) => (
              <div
                key={idx}
                onClick={() => handlePayerClick(payer)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPayer && selectedPayer.id === payer.id,
                  'd-flex justify-content--space-between align-items-center':
                    payersWithCptCount?.length > 0,
                })}
              >
                <span>{payer.name}</span>
                {payer?.feeScheduleCount > 0 && (
                  <span>({payer.feeScheduleCount})</span>
                )}
              </div>
            ))
          ) : isPayersLoading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={isPayersLoading}
            />
          ) : (
            <p className="fw-bold"> No Payer found </p>
          )}
        </div>
        <div className="col-lg-8 settings-content__left-sidebar">
          <FeeScheduleTable
            showAddFeeScheduleForm={showAddFeeScheduleForm}
            closeAddFeeScheduleForm={() => setShowAddFeeScheduleForm(false)}
            cptCodes={procedureCodes}
            isCptCodesLoading={isProcedureCodesLoading}
            practiceId={selectedPractice?.id}
            payerId={selectedPayer?.id}
            cptCode={procedureCodeSearchQuery}
            editingFeeScheduleId={editingFeeScheduleId}
            setEditingFeeScheduleId={setEditingFeeScheduleId}
            form={
              editingFeeScheduleId
                ? `addOrEditFeeScheduleForm-{${editingFeeScheduleId}}`
                : 'addOrEditFeeScheduleForm'
            }
            fetchPayersWithCptCount={fetchPayersWithCptCount}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
  };
}

export default connect(mapStateToProps, {})(FeeSchedules);
