import React from 'react';
import { isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';

import Counter from 'components/common/counter';
import Indicator from 'components/common/indicator';

import { TOTAL_CREATE_SUBMISSION_STEPS } from 'constants/appConstants';
import { calculateDateDifference, getFormattedDate } from 'helpers/dateFormats';

import ReactDOMServer from 'react-dom/server';

import batchIcon from 'img/batch-icon.svg';

export const CustomCell = ({ value, accessor }) => {
  const isEmptyString = value === '--';
  let cellValue = isEmptyString ? 'N/A' : value;
  const toolTip = isEmptyString ? '' : value;
  if (accessor === 'agentName') {
    const splitData = value.split(' ');
    cellValue = splitData[0] + ' ' + splitData.at(-1)[0] + '.';
  }

  if (accessor === 'submitterClaimId' || accessor === 'claimNumber') {
    return (
      <div
        className="appealio-table__custom-cell"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          data-tip={toolTip}
          data-for="claimId"
          data-class="interactive-tooltip"
        >
          {cellValue}
        </div>
        <ReactTooltip
          id="claimId"
          effect="solid"
          className="interactive-tooltip"
          delayHide={600}
          place="top"
        />
      </div>
    );
  }

  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={toolTip}>{cellValue}</span>
      <ReactTooltip effect="solid" />
    </div>
  );
};

export const AgentCell = (props) => (
  <CustomCell {...props} accessor="agentName" />
);

export const BuildDateCell = (props) => {
  const date = props.value;
  let formattedDate;
  if (date === '--') {
    formattedDate = 'N/A';
  } else {
    formattedDate = getFormattedDate(date);
  }

  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={formattedDate} data-delay-show="600">
        {formattedDate}
      </span>
    </div>
  );
};

export const ClaimIDCell = (props) => (
  <CustomCell value={props.value} accessor="submitterClaimId" />
);

export const ClaimNumberCell = (props) => (
  <CustomCell value={props.value} accessor="claimNumber" />
);

export const BuildArrayCell = (props) => {
  const array = props.value;
  if (isEmpty(array)) {
    return <div className="appealio-table__custom-cell">None</div>;
  }

  const firstArrayItem = array[0];
  const arrayLength = array.length;

  const dataToRender =
    arrayLength === 1
      ? firstArrayItem
      : `${firstArrayItem} +${arrayLength - 1}`;

  const dataTip = array.length === 1 ? undefined : array.join(', ');

  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={dataTip} data-delay-show="600">
        {dataToRender}
      </span>
    </div>
  );
};

export const ProcedureModifierCellHeader = () => {
  return (
    <div>
      Procedure
      <br />
      (Modifier)
    </div>
  );
};

export const BuildArrayStringCell = ({ value = [] }) => {
  return <BuildArrayCell value={value} />;
};

export const BuildProcedureModifierCell = ({ row }) => {
  const data = row.original;
  const cptCode = data.cptCode || '';
  const cptModifiersCode = data.cptModifiersCode || '';

  return (
    <>
      <BuildArrayStringCell value={cptCode} />
      <div className="d-flex">
        (<BuildArrayStringCell value={cptModifiersCode} />)
      </div>
    </>
  );
};

export const SubmissionTypeCell = ({ row }) => {
  const { original: appeal } = row;
  const submissionType = appeal.isMedicalRecordsSubmission
    ? 'Record'
    : 'Appeal';
  const { step, batchJobId, batchCreatedBy, batchCreatedOn } = appeal;

  const activeStepForRecord = step > 4 ? step - 2 : step > 1 ? step - 1 : step; // If the submission step is the last one, reduce it by 2 for the record; otherwise, if the step is greater than 1, reduce it by 1, and if not, keep the step unchanged.
  const activeStepForAppeal = step > 4 ? step - 1 : step; // if submission step is the last one, reduce it by 1 for the appeal; otherwise, keep the step unchanged.
  const activeStep = appeal?.isMedicalRecordsSubmission
    ? activeStepForRecord
    : activeStepForAppeal;

  const dataTipContent = ReactDOMServer.renderToStaticMarkup(
    <div>
      {batchJobId && (
        <div>
          <span className="fw-bold">Batch#:</span> {batchJobId}, Created by{' '}
          {batchCreatedBy} on{' '}
          {batchCreatedOn && getFormattedDate(batchCreatedOn)}.
        </div>
      )}
    </div>
  );

  return (
    <div className="d-flex align-items-center">
      <div className="counter-wrapper-cell d-flex  mr-4">
        {appeal.appealRound > 1 && <Counter value={appeal.appealRound} />}
        {!isNaN(batchJobId) && (
          <img
            className="ml-2"
            src={batchIcon}
            alt="batch-icon"
            data-tip={dataTipContent}
            data-html={true}
          />
        )}
      </div>
      <div className="d-flex flex-direction--column ">
        <CustomCell value={submissionType} />
        <Indicator
          activeCount={activeStep}
          totalCount={
            appeal?.isMedicalRecordsSubmission
              ? TOTAL_CREATE_SUBMISSION_STEPS.RECORD
              : TOTAL_CREATE_SUBMISSION_STEPS.APPEAL
          }
          containerClassName="submission-type-inprogress-counter"
        />
      </div>
    </div>
  );
};

export const DeadlineEobDateCellHeader = () => {
  return (
    <div>
      Until Deadline
      <br />
      (EOB Date)
    </div>
  );
};

export const BuildDeadlineEobDateCell = ({ row }) => {
  const data = row.original;

  const { deniedAt, deadline: appealDeadline } = data;

  const eobDate =
    deniedAt && deniedAt !== '--' ? getFormattedDate(deniedAt) : null;
  const deadline =
    appealDeadline && appealDeadline !== '--'
      ? getFormattedDate(appealDeadline)
      : null;
  const daysUntilDeadline =
    eobDate && deadline
      ? calculateDateDifference(getFormattedDate(new Date()), deadline)
      : null;
  const isDeadlinePassed = daysUntilDeadline <= 0;
  return (
    <div className="appealio-table__custom-cell">
      <span
        {...(!deadline ? {} : { 'data-tip': deadline })}
        data-for="eob-date-tooltip"
      >
        {daysUntilDeadline
          ? `${isDeadlinePassed ? 0 : daysUntilDeadline} Day(s)`
          : 'N/A'}
      </span>
      <br />
      {eobDate && (
        <span
          {...(!eobDate ? {} : { 'data-tip': eobDate })}
          data-for="eob-date-tooltip"
        >
          ({eobDate || 'N/A'})
        </span>
      )}
      <ReactTooltip
        id="eob-date-tooltip"
        className="interactive-tooltip"
        delayHide={600}
        effect="solid"
        place="left"
      />
    </div>
  );
};

export const ProviderNameCustomCell = () => {
  return (
    <div>
      Provider
      <br />
      (Practice)
    </div>
  );
};

export const CustomProviderCell = ({ row }) => {
  const providerName =
    row.original.providerName === '--'
      ? 'N/A'
      : row.original.providerName || 'N/A';
  const practiceName =
    row.original.practiceName === '--'
      ? 'N/A'
      : row.original.practiceName || 'N/A';
  return (
    <div className={'appealio-table__custom-cell'}>
      <span
        {...(providerName === 'N/A' ? {} : { 'data-tip': providerName })}
        data-for="practice-provider-tooltip"
      >
        {providerName}
      </span>
      <br />
      {practiceName && (
        <span
          {...(practiceName === 'N/A' ? {} : { 'data-tip': practiceName })}
          data-for="practice-provider-tooltip"
        >
          ({practiceName})
        </span>
      )}
      <ReactTooltip
        id="practice-provider-tooltip"
        className="interactive-tooltip"
        delayHide={600}
        effect="solid"
        place="left"
      />
    </div>
  );
};
