import { get, isEqual, sortBy } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import DashboardFilter from '../DashboardFilter';
import AgentPerformance from './AgentPerformance';
import AppealDeadlineMet from './AppealDeadlineMet';
import AppealsByStatus from '../Overview/AppealsByStatus';

import AppealsAPI from 'API/AppealsAPI';
import DashboardAPI from 'API/DashboardAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';
import { fetchTeams } from 'API/AccountSettingsAPI';

import { SEARCH_DATE_RANGE_OPTIONS } from '../../../../constants/options';
import { getUserInfo } from '../../../../redux/reducers/loginStore';

import { handleError } from 'helpers/errorHandler';

import { isAuthorizedForTeams } from 'Auth/AuthUtils';
import { createObjectMap, sortDropdownOptionsByLabel } from 'helpers/utils';

class ManagerTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOptions: {
        teams: [],
        agents: [],
        payers: [],
        practices: [],
        renderingProviders: [],
        dateRange: SEARCH_DATE_RANGE_OPTIONS,
      },
      filters: this.getDefaultFilters(),
      isFetchingFilterOptions: false,
    };
  }

  getDefaultFilters = () => ({
    dateRange: SEARCH_DATE_RANGE_OPTIONS,
    payer: null,
    agent: null,
    renderingProvider: null,
    practice: null,
    team: null,
  });

  setIsFetchingFilterOptions = (isFetchingFilterOptions) =>
    this.setState({ isFetchingFilterOptions });

  setFilterOptions = (filterOptions, cb = false) =>
    this.mounted &&
    this.setState({ filterOptions }, cb ? this.attachFilterOptionsCount : null);

  setFilters = (filters) =>
    this.setState({ filters }, this.attachFilterOptionsCount);

  componentDidMount = () => {
    this.mounted = true;
    this.fetchAndSetFilterOptions();
  };

  fetchAndSetFilterOptions = async () => {
    try {
      this.setIsFetchingFilterOptions(true);
      const [agentsResponse, payersResponse, providerResponse, teamResponse] =
        await Promise.all([
          AppealsAPI.getDropdownOptions('users'),
          AppealsAPI.getDropdownOptions('payers'),
          AppealsAPI.getDropdownOptions('providers'),
          this.props.isAuthorizedForTeamsOptions
            ? isAuthorizedForTeams(this.props.userInfo) &&
              fetchTeams({
                excludeEmptyTeams: 1,
              })
            : null,
        ]);
      const agents = CreateAppealAPI.parseAgents(agentsResponse);

      const payers = AppealsAPI.filterValidPayerOptions(payersResponse);
      const providers = sortDropdownOptionsByLabel(
        CreateAppealAPI.parseProviders(providerResponse, true).map(
          ({ key, value, practiceIdentifier }) => ({
            value: key,
            label: value,
            practiceIdentifier,
            name: value,
          })
        )
      );

      const extractSelectOptions = (array) => {
        const reactSelectOptions = array.map(({ id, name }) => ({
          id,
          name,
          label: name,
          value: id,
        }));

        return [{ value: undefined, label: 'All' }, ...reactSelectOptions];
      };

      const practiceOptions = [
        { value: undefined, label: 'All' },
        ...this.props.userPractices.map(({ practiceIdentifier, name, id }) => ({
          value: practiceIdentifier,
          label: name,
          name,
          practiceId: id,
        })),
      ];

      const agentsOptions = [
        { label: 'All', value: '' },
        ...sortBy(agents, ({ isActive }) => !isActive).map(
          ({ id, name, isActive }) => ({
            label: !isActive ? `${name} (Inactive)` : name,
            value: id,
            isActive,
            name,
          })
        ),
      ];

      const teamOptions =
        teamResponse && extractSelectOptions(teamResponse?.data);

      const filterOptions = {
        teams: teamOptions,
        agents: agentsOptions,
        payers: extractSelectOptions(payers),
        practices: practiceOptions,
        renderingProviders: [{ value: undefined, label: 'All' }, ...providers],
        dateRange: SEARCH_DATE_RANGE_OPTIONS,
      };
      this.setFilterOptions(filterOptions, true);
      this.setIsFetchingFilterOptions(false);
    } catch (error) {
      handleError(error);
      this.setIsFetchingFilterOptions(false);
    }
  };

  attachFilterOptionsCount = async () => {
    const { filters, filterOptions } = this.state;
    const filtersForFetch = {
      payer_id: get(filters, 'payer.value'),
      provider_id: get(filters, 'renderingProvider.value'),
      practice_identifier: get(filters, 'practice.value'),
      agent_id: get(filters, 'agent.value'),
      from_date: filters?.dateRange?.fromDate,
      to_date: filters?.dateRange?.toDate,
      team_id: get(filters, 'team.value'),
    };

    const { agent, payer, practice, provider, team } =
      await DashboardAPI.fetchTeamDashboardCounts(filtersForFetch);

    const updateFilterOptions = (options, countsMap, keyName) =>
      options
        .map((option) => ({
          ...option,
          label: countsMap[option[keyName]]
            ? `${option.name} (${countsMap[option[keyName]]})`
            : `${option.name} (0)`,
          count: countsMap[option[keyName]] || 0,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter((option) => option.value)
        .sort((a, b) => {
          // First sort by count (descending)
          const countDiff = b.count - a.count;
          // If counts are equal, sort by label (alphabetically)
          return countDiff !== 0 ? countDiff : a.label.localeCompare(b.label);
        });

    const updatedAgents = updateFilterOptions(
      filterOptions.agents,
      createObjectMap(agent, 'id', 'count'),
      'value'
    );

    const updatedPayers = updateFilterOptions(
      filterOptions.payers,
      createObjectMap(payer, 'id', 'count'),
      'value'
    );
    const updatedPractices = updateFilterOptions(
      filterOptions.practices,
      createObjectMap(practice, 'practiceIdentifier', 'count'),
      'value'
    );

    const updatedRenderingProviders = updateFilterOptions(
      filterOptions.renderingProviders,
      createObjectMap(provider, 'id', 'count'),
      'value'
    );

    const updatedTeams = updateFilterOptions(
      filterOptions.teams,
      createObjectMap(team, 'id', 'count'),
      'value'
    );

    this.setFilterOptions({
      ...filterOptions,
      agents: updatedAgents,
      payers: updatedPayers,
      practices: updatedPractices,
      renderingProviders: updatedRenderingProviders,
      teams: updatedTeams,
    });
  };

  handleFilterChange = (filters) => {
    this.setFilters(filters);
  };

  handleResetFilter = () => {
    this.setFilters(this.getDefaultFilters());
  };

  extractFilterOptions = (filterOptions, appliedFilters) => {
    if (!appliedFilters.practice) {
      return filterOptions;
    }

    const renderingProviders = filterOptions.renderingProviders.filter(
      ({ practiceIdentifier }) =>
        !practiceIdentifier ||
        practiceIdentifier === appliedFilters.practice.value
    );

    return {
      ...filterOptions,
      renderingProviders,
    };
  };

  render() {
    const isFiltersApplied = !isEqual(
      this.state.filters,
      this.getDefaultFilters()
    );

    const isPracticeFilterVisible = this.props.userPractices.length > 1;
    const filterOptions = this.extractFilterOptions(
      this.state.filterOptions,
      this.state.filters
    );

    return (
      <div className="manager-team__container">
        <div className="manager-team">
          <div className="row ap-dashboard mb-16 no-gutter">
            <div className="col-lg-12 mb-24">
              <DashboardFilter
                filterOptions={filterOptions}
                filters={this.state.filters}
                isPracticeFilterVisible={isPracticeFilterVisible}
                handleFilterChange={this.handleFilterChange}
                handleResetFilter={this.handleResetFilter}
                isFiltersApplied={isFiltersApplied}
                isAuthorizedForTeamsOptions={
                  this.props.isAuthorizedForTeamsOptions
                }
              />
            </div>

            <div className="row no-gutter  ap-dashboard mb-16">
              <div className="col-lg-12 ap-dashboard--height-sm pr-8">
                <div className="mb-24">
                  <AgentPerformance
                    filters={this.state.filters}
                    isFetchingFilterOptions={this.state.isFetchingFilterOptions}
                  />
                </div>
                {/* <div className="mb-24">
                  <TimeInEachState
                    filters={this.state.filters}
                    isFetchingFilterOptions={this.state.isFetchingFilterOptions}
                  />
                </div> */}
              </div>
            </div>
            <div className="row no-gutter d-flex mb-24 ap-dashboard">
              <div className="col-lg-6 ap-dashboard--height-sm pr-8">
                <AppealsByStatus
                  filters={this.state.filters}
                  isFetchingFilterOptions={this.state.isFetchingFilterOptions}
                />
                {/* <DaysFromEobDate
                    filters={this.state.filters}
                    isFetchingFilterOptions={this.state.isFetchingFilterOptions}
                  /> */}
              </div>
              <div className="col-lg-6 ap-dashboard--height-sm pl-8">
                <AppealDeadlineMet
                  filters={this.state.filters}
                  isFetchingFilterOptions={this.state.isFetchingFilterOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
    isAuthorizedForTeamsOptions: isAuthorizedForTeams(userInfo),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerTeam);
