import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { omit, isEqual, debounce } from 'lodash';
import { isInclusivelyAfterDay } from 'react-dates';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import Input from 'components/common/input';
import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import ToggleSwitch from 'components/common/toggle';
import { DatePicker } from 'components/common/datepicker';
import { BUTTON_TYPE } from 'components/common/button/Button';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import heartIcon from 'img/heart.svg';
import trashIcon from 'img/trash.svg';
import closeIcon from 'img/close-modal.svg';
import calendar from 'img/calendar-icon.svg';
import floppyDiskIcon from 'img/floppy-disk.svg';
import settingsIcon from 'img/settings-black.svg';

import * as toast from 'components/Shared/toast';

import {
  UNASSIGNED_OPTION,
  ALL_AGENTS_OPTION_VALUE,
} from 'constants/appConstants';
import { ZERO_PAY_DENIALS_OPTIONS } from 'constants/options';

import {
  deleteFavoriteFilter,
  fetchFavoriteFilters,
  createFavoriteFilter,
  updateFavoriteFilter,
} from 'API/FavouriteFilterAPI';
import { handleError } from 'helpers/errorHandler';
import { fetchDenialsQueueCount } from 'API/DenialsAPI';

const NEW_FILTER_OPTION = {
  label: '+ New Favorite Filter',
  value: 'new',
  className: 'link-color--blue',
};
const MAX_FAVORITE_FILTERS_OPTION = {
  label:
    'You already have 10 Favorite Filters. Remove one to create a new Favorite.',
  value: 'max-filters',
  className: 'opacity-50',
};

const DenialPopupFilter = (props) => {
  const {
    setShowDenialFilters,
    filters,
    onFilterChange,
    filterOptions,
    defaultFilters,
    isArchivedPage,
  } = props;
  const filterPage = 'DENIALS_QUEUE';
  const [error, setError] = useState(null);
  const [denialFilters, setDenialFilters] = useState(filters);
  const [favorites, setFavorites] = useState([]);
  const [favoriteName, setFavoriteName] = useState('');
  const [isSavingFavoriteFilter, setIsSavingFavoriteFilter] = useState(false);
  const [denialsQueueCount, setDenialsQueueCount] = useState(0);
  const [isFetchingDenialsQueueCount, setIsFetchingDenialsQueueCount] =
    useState(false);

  const [selectedFavoriteFilter, setSelectedFavoriteFilter] = useState(
    props.selectedFavoriteFilter
  );
  const keysToOmit = useMemo(() => ['applied_rule', 'search'], []);
  const isFilterEmpty = Object.keys(omit(denialFilters, keysToOmit)).every(
    (filterKey) => filters[filterKey] === defaultFilters[filterKey]
  );

  const filtersWithoutOmittedKeys = useCallback(
    (filters) => omit(filters, keysToOmit),
    [keysToOmit]
  );

  const denialsQueueCountAbortControllerRef = useRef(null);
  const abortFileListRequest = () => {
    if (denialsQueueCountAbortControllerRef.current) {
      denialsQueueCountAbortControllerRef.current.abort();
    }
  };

  const debouncedFetch = debounce((callback) => callback(), 2000);

  useEffect(() => {
    const loadDenialsCount = async () => {
      try {
        abortFileListRequest();

        denialsQueueCountAbortControllerRef.current = new AbortController();

        setIsFetchingDenialsQueueCount(true);

        const data = await fetchDenialsQueueCount(
          denialFilters,
          isArchivedPage,
          denialsQueueCountAbortControllerRef.current.signal
        );

        setDenialsQueueCount(data);
      } catch (error) {
        // if abort error, do nothing
        if (error.name !== 'AbortError') {
          handleError(error);
        }
      } finally {
        setIsFetchingDenialsQueueCount(false);
      }
    };

    setIsFetchingDenialsQueueCount(true);
    debouncedFetch(loadDenialsCount);

    return () => {
      abortFileListRequest();
      debouncedFetch.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denialFilters, isArchivedPage]);

  const dqFiltersWithoutOmittedKeys = filtersWithoutOmittedKeys(filters);
  const getCleanedFilters = useCallback(
    (filters) => {
      return Object.entries(filtersWithoutOmittedKeys(filters)).reduce(
        (acc, [key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
    },
    [filtersWithoutOmittedKeys]
  );
  const dqPopupFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(denialFilters);

  const isApplyFilterDisabled = useMemo(() => {
    if (!isEqual(selectedFavoriteFilter, props.selectedFavoriteFilter)) {
      return false;
    }

    return isEqual(
      getCleanedFilters(dqFiltersWithoutOmittedKeys),
      getCleanedFilters(dqPopupFiltersWithoutOmittedKeys)
    );
  }, [
    selectedFavoriteFilter,
    props.selectedFavoriteFilter,
    dqFiltersWithoutOmittedKeys,
    dqPopupFiltersWithoutOmittedKeys,
    getCleanedFilters,
  ]);

  const hasFilterChanges = useCallback(() => {
    if (!selectedFavoriteFilter) return false;
    return !isEqual(
      getCleanedFilters(denialFilters),
      getCleanedFilters(selectedFavoriteFilter.filterJson)
    );
  }, [denialFilters, getCleanedFilters, selectedFavoriteFilter]);

  useEffect(() => {
    const loadFavorites = async () => {
      try {
        const data = await fetchFavoriteFilters(filterPage);
        setFavorites(data);
      } catch (err) {
        handleError(err);
      }
    };
    loadFavorites();
  }, [filterPage]);

  const handleSaveFavorite = async () => {
    if (!isDateFiltersValid()) return;

    try {
      setIsSavingFavoriteFilter(true);

      const filterData = {
        name: favoriteName,
        filterPage,
        filterJson: getCleanedFilters(denialFilters),
      };

      const isUpdating =
        selectedFavoriteFilter?.value !== NEW_FILTER_OPTION.value;

      if (!isUpdating) {
        const isDuplicate = favorites.some(
          (f) => f.label.toLowerCase() === favoriteName.toLowerCase()
        );

        if (isDuplicate) {
          toast.error({
            message: 'Filter with this name already exists',
            title: '',
          });
          setIsSavingFavoriteFilter(false);
          return;
        }
      }
      const { data } = isUpdating
        ? await updateFavoriteFilter(selectedFavoriteFilter.value, filterData)
        : await createFavoriteFilter(filterData);

      const formattedFilter = {
        value: data.id,
        label: data.name,
        filterJson: data.filter_json,
      };

      setFavorites((prev) =>
        isUpdating
          ? prev.map((f) =>
              f.value === formattedFilter.value ? formattedFilter : f
            )
          : [...prev, formattedFilter]
      );

      setSelectedFavoriteFilter(formattedFilter);

      toast.success({
        message: `Filter ${isUpdating ? 'updated' : 'saved'} successfully`,
        title: '',
      });
    } catch (err) {
      handleError(err);
    } finally {
      setIsSavingFavoriteFilter(false);
    }
  };

  const handleDeleteFavorite = async () => {
    if (!selectedFavoriteFilter) return;
    try {
      await deleteFavoriteFilter(selectedFavoriteFilter.value);
      setFavorites((prev) =>
        prev.filter((f) => f.value !== selectedFavoriteFilter.value)
      );
      setSelectedFavoriteFilter(null);
      props.setSelectedFavoriteFilter(null);
      setFavoriteName('');
      setError(null);
      toast.success({ message: 'Filter deleted successfully', title: '' });
      setDenialFilters(defaultFilters);
    } catch (err) {
      handleError(err);
    }
  };

  const getFavoriteOptions = () => {
    const options = favorites
      .map((f) => ({
        label: f.label,
        value: f.value,
        filterJson: f.filterJson,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

    const matchesAnyFavoriteFilter = favorites.some((favorite) =>
      isEqual(
        getCleanedFilters(denialFilters),
        getCleanedFilters(favorite.filterJson)
      )
    );

    if (
      !matchesAnyFavoriteFilter &&
      (!isEqual(getCleanedFilters(denialFilters), getCleanedFilters(filters)) ||
        (isEqual(
          getCleanedFilters(denialFilters),
          getCleanedFilters(filters)
        ) &&
          !isFilterEmpty))
    ) {
      if (options.length >= 10) {
        return [MAX_FAVORITE_FILTERS_OPTION, ...options];
      }
      return [NEW_FILTER_OPTION, ...options];
    }

    return options;
  };

  const renderFavoritesSection = () => {
    const hasActiveFilters = !isEqual(
      filtersWithoutOmittedKeys(denialFilters),
      filtersWithoutOmittedKeys(defaultFilters)
    );

    const favoriteOptions = getFavoriteOptions();

    return (
      <div className="favorites-section mb-16">
        <div className="dropdown-container">
          <div className="dropdown__label">Saved Filters</div>
          <div className="favorite-filter-row">
            {selectedFavoriteFilter?.value === NEW_FILTER_OPTION.value ? (
              <>
                <div className="favorite-filter-row__input">
                  <Input
                    type="text"
                    placeholder="Enter filter name"
                    value={favoriteName}
                    onChange={(e) => setFavoriteName(e.target.value)}
                    disabled={isSavingFavoriteFilter || !hasActiveFilters}
                    hideLabel
                    autoFocus
                  />
                </div>
                <Button
                  className="appealio-filter-button ml-8"
                  disabled={
                    !favoriteName || isSavingFavoriteFilter || !hasActiveFilters
                  }
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={handleSaveFavorite}
                  dataTip="Save"
                >
                  {isSavingFavoriteFilter ? (
                    <div className="position-relative favorite-filter-row__loading-indicator">
                      <LoadingIndicator isLightIndicator showing />
                    </div>
                  ) : (
                    <img src={heartIcon} alt="Save" />
                  )}
                </Button>
                <Button
                  className="appealio-filter-button ml-8"
                  disabled={isSavingFavoriteFilter}
                  type={BUTTON_TYPE.SECONDARY}
                  dataTip="Cancel"
                  onClick={() => {
                    setFavoriteName('');
                    setSelectedFavoriteFilter(null);
                  }}
                >
                  <IoMdCloseCircleOutline size={24} />
                </Button>
              </>
            ) : (
              <>
                <Dropdown
                  className="favorite-filter-row__input"
                  placeholder="No Favorite Filter Selected"
                  onChange={(option) => {
                    if (option.value === MAX_FAVORITE_FILTERS_OPTION.value) {
                      return toast.error({
                        message: MAX_FAVORITE_FILTERS_OPTION.label,
                        title: '',
                      });
                    }
                    if (option.value === NEW_FILTER_OPTION.value) {
                      setSelectedFavoriteFilter(option);
                      setFavoriteName('');
                    } else {
                      setSelectedFavoriteFilter(option);
                      setDenialFilters(option.filterJson);
                      setFavoriteName(option.label);
                    }
                    setError(null);
                  }}
                  value={selectedFavoriteFilter}
                  options={favoriteOptions}
                  isDisabled={!hasActiveFilters && favorites.length === 0}
                  hideLabel
                />

                {selectedFavoriteFilter ? (
                  <>
                    {hasFilterChanges() && (
                      <Button
                        className="ml-8"
                        onClick={handleSaveFavorite}
                        disabled={isSavingFavoriteFilter}
                        type={BUTTON_TYPE.SECONDARY}
                        dataTip="Update"
                      >
                        {isSavingFavoriteFilter ? (
                          <div className="position-relative favorite-filter-row__loading-indicator">
                            <LoadingIndicator isLightIndicator showing />
                          </div>
                        ) : (
                          <img src={floppyDiskIcon} alt="Update" />
                        )}
                      </Button>
                    )}
                    <Button
                      className="ml-8"
                      onClick={handleDeleteFavorite}
                      disabled={isSavingFavoriteFilter}
                      type={BUTTON_TYPE.SECONDARY}
                      title=""
                      dataTip="Delete"
                    >
                      <img src={trashIcon} alt="Delete" />
                    </Button>
                  </>
                ) : (
                  <Button
                    className="appealio-filter-button ml-8"
                    disabled
                    type={BUTTON_TYPE.SECONDARY}
                  >
                    <img src={heartIcon} alt="Save" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <ReactTooltip effect="solid" place="top" />
      </div>
    );
  };

  const agentSelectOptions = filterOptions?.agents?.data;
  const payerSelectOptions = filterOptions?.payers?.data;
  const reasonCodeSelectOptions = filterOptions?.reasonCodes?.data;
  const cptCodeSelectOptions = filterOptions?.cptCodes?.data;
  const procedureModifiersSelectOptions =
    filterOptions?.procedureModifiers?.data;
  const remarkCodeOptions = filterOptions?.remarkCodes?.data;
  const practicesOptions = filterOptions?.practices?.data;
  const providerOptions = filterOptions?.providers?.data;

  const revenueCodesSelectOptions = filterOptions?.revenueCodes?.data;
  const handleFilterChange = ({ value }, name) => {
    setDenialFilters({
      ...denialFilters,
      [name]: value,
    });
  };

  const handleToggleZeroPay = (e) => {
    setDenialFilters({ ...denialFilters, zero_pay: e.target.checked });
  };

  const handleAgentChange = ({ value }) => {
    let agentValue;

    if (!value || value === ALL_AGENTS_OPTION_VALUE) {
      agentValue = [ALL_AGENTS_OPTION_VALUE];
    } else {
      agentValue = [value];
    }

    return handleFilterChange({ value: agentValue }, 'agents');
  };

  const selectedPayerOption =
    payerSelectOptions.find((option) => denialFilters.payer === option.value) ||
    false;

  const selectedPracticeId =
    practicesOptions.find(
      ({ value }) => value === denialFilters.practice_identifier
    ) || false;

  const selectedReasonCodeOption =
    reasonCodeSelectOptions.find(
      (option) => denialFilters.reason_code === option.value
    ) || false;

  const selectedRemarkCodeOption =
    remarkCodeOptions.find(
      (option) => denialFilters.remark_codes === option.value
    ) || false;

  const selectedCptCodeOption =
    cptCodeSelectOptions.find(
      (option) => denialFilters.cpt_code === option.value
    ) || false;

  const selectedRevenueCodeOption =
    revenueCodesSelectOptions.find(
      (option) => denialFilters.revenue_code === option.value
    ) || false;

  const selectedCptModifierCodeOption =
    procedureModifiersSelectOptions.find(
      (option) => denialFilters.cpt_modifiers_code === option.value
    ) || false;

  const [allOption, ...options] = providerOptions;
  const providerOptionsWithUnassigned = providerOptions.length
    ? [allOption, UNASSIGNED_OPTION, ...options]
    : providerOptions;
  const selectedProviderOption =
    providerOptionsWithUnassigned.find(
      (option) => denialFilters.provider === option.value
    ) || false;
  /**
   * Info: Hide practice filter if there is only one practice as option.
   * filterOptions.practices = [{label: 'Any'}, ...practice];
   */
  const isPracticeFilterVisible =
    filterOptions.practices && filterOptions.practices?.data?.length > 2;

  const [allOptions, ...agentOptions] = agentSelectOptions;
  const agentOptionsWithUnassignedOption = [
    allOptions,
    UNASSIGNED_OPTION,
    ...agentOptions,
  ];
  const selectedAgentOption =
    agentOptionsWithUnassignedOption.find(
      (option) =>
        denialFilters.agents && denialFilters.agents[0] === option.value
    ) || false;

  const validateDateFields = (
    fromDate,
    toDate,
    fromDateErrorKey,
    toDateErrorKey
  ) => {
    if (!fromDate && !toDate) {
      return true;
    }
    if (!fromDate && toDate) {
      setError({
        [fromDateErrorKey]: 'Please choose the start date.',
      });
      return false;
    } else if (fromDate && !toDate) {
      setError({
        [toDateErrorKey]: 'Please choose the end date.',
      });
      return false;
    }
    return true;
  };

  const isDateFiltersValid = () => {
    const isValidServiceDateFields = validateDateFields(
      denialFilters.service_date_from,
      denialFilters.service_date_to,
      'fromDate',
      'toDate'
    );

    const isValidAddedDateFields = !isArchivedPage
      ? validateDateFields(
          denialFilters.date_added_from,
          denialFilters.date_added_to,
          'dateAddedFromError',
          'dateAddedToError'
        )
      : validateDateFields(
          denialFilters.date_archived_from,
          denialFilters.date_archived_to,
          'dateArchivedFromError',
          'dateArchivedToError'
        );

    return isValidServiceDateFields && isValidAddedDateFields;
  };

  const handleConfirmFilters = () => {
    if (isDateFiltersValid()) {
      onFilterChange(denialFilters);
      setShowDenialFilters(false);
      if (
        selectedFavoriteFilter &&
        isEqual(
          getCleanedFilters(selectedFavoriteFilter.filterJson),
          getCleanedFilters(denialFilters)
        )
      ) {
        props.setSelectedFavoriteFilter(selectedFavoriteFilter);
      }
    }
  };

  const resetFilters = () =>
    onFilterChange(omit(defaultFilters, ['applied_rule', 'search']));

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter overflow-y-auto">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt={'More filters'} />
            <h6>More Denial Queue Filters </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    setDenialFilters(defaultFilters);
                    resetFilters();
                    setShowDenialFilters(false);
                    props.setSelectedFavoriteFilter(null);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowDenialFilters(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>
        <div className="appealio-popup-filter__options">
          {renderFavoritesSection()}
          <div className="row mb-12">
            <div className="col-md-12">
              <div className="dropdown-container">
                <div className="dropdown__label">
                  {!isArchivedPage ? 'Date Added' : 'Date Archived'}
                </div>
                {!isArchivedPage ? (
                  <div className="row">
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        placeholder="From"
                        verticalSpacing={10}
                        date={
                          denialFilters.date_added_from
                            ? moment(denialFilters.date_added_from)
                            : null
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isAfter(moment(denialFilters.date_added_to), 'day')
                        }
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateAddedToError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_added_from'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateAddedFromError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_added_from'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateAddedFromError && (
                        <p className="error mt-8">{error.dateAddedFromError}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isBefore(
                            moment(denialFilters.date_added_from),
                            'day'
                          )
                        }
                        date={
                          denialFilters.date_added_to
                            ? moment(denialFilters.date_added_to)
                            : null
                        }
                        placeholder="To"
                        verticalSpacing={10}
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateAddedFromError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_added_to'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateAddedToError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_added_to'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateAddedToError && (
                        <p className="error mt-8">{error.dateAddedToError}</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        placeholder="From"
                        verticalSpacing={10}
                        date={
                          denialFilters.date_archived_from
                            ? moment(denialFilters.date_archived_from)
                            : null
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isAfter(
                            moment(denialFilters.date_archived_to),
                            'day'
                          )
                        }
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateArchivedToError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_archived_from'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateArchivedFromError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_archived_from'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateArchivedFromError && (
                        <p className="error mt-8">
                          {error.dateArchivedFromError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isBefore(
                            moment(denialFilters.date_archived_from),
                            'day'
                          )
                        }
                        date={
                          denialFilters.date_archived_to
                            ? moment(denialFilters.date_archived_to)
                            : null
                        }
                        placeholder="To"
                        verticalSpacing={10}
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateArchivedFromError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_archived_to'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateArchivedToError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_archived_to'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateArchivedToError && (
                        <p className="error mt-8">
                          {error.dateArchivedToError}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="dropdown-container">
                <div className="dropdown__label">Service Date</div>
              </div>
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <DatePicker
                displayFormat="LL"
                customInputIcon={
                  <img className="form-icon" src={calendar} alt="Calendar" />
                }
                placeholder="From"
                verticalSpacing={10}
                date={
                  denialFilters.service_date_from
                    ? moment(denialFilters.service_date_from)
                    : null
                }
                isOutsideRange={(d) =>
                  isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                  d.isAfter(moment(denialFilters.service_date_to), 'day')
                }
                onDateChange={(date) => {
                  if (!date) {
                    setError({
                      ...error,
                      toDate: null,
                    });
                  }
                  date = moment(date);
                  if (!date.isValid()) {
                    handleFilterChange({ value: null }, 'service_date_from');
                    return;
                  }
                  setError({
                    ...error,
                    fromDate: null,
                  });
                  handleFilterChange(
                    { value: date.format('YYYY-MM-DD') },
                    'service_date_from'
                  );
                }}
                numberOfMonths={1}
                orientation="horizontal"
                block={true}
              />
              {error && error.fromDate && (
                <p className="error mt-8">{error.fromDate}</p>
              )}
            </div>
            <div className="col-md-6">
              <DatePicker
                displayFormat="LL"
                customInputIcon={
                  <img className="form-icon" src={calendar} alt="Calendar" />
                }
                isOutsideRange={(d) =>
                  isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                  d.isBefore(moment(denialFilters.service_date_from), 'day')
                }
                date={
                  denialFilters.service_date_to
                    ? moment(denialFilters.service_date_to)
                    : null
                }
                placeholder="To"
                verticalSpacing={10}
                onDateChange={(date) => {
                  if (!date) {
                    setError({
                      ...error,
                      fromDate: null,
                    });
                  }
                  date = moment(date);
                  if (!date.isValid()) {
                    handleFilterChange({ value: null }, 'service_date_to');
                    return;
                  }
                  setError({
                    ...error,
                    toDate: null,
                  });
                  handleFilterChange(
                    { value: date.format('YYYY-MM-DD') },
                    'service_date_to'
                  );
                }}
                numberOfMonths={1}
                orientation="horizontal"
                block={true}
              />
              {error && error.toDate && (
                <p className="error mt-8">{error.toDate}</p>
              )}
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                label="Payer"
                name="payer"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'payer')}
                value={selectedPayerOption}
                isClearable={false}
                options={payerSelectOptions}
                isLoading={filterOptions?.payers?.isFetching}
                datacy="payer-Dropdown"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                label="Provider"
                name="provider"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'provider')}
                value={selectedProviderOption}
                isClearable={false}
                options={providerOptionsWithUnassigned}
                isLoading={filterOptions?.providers?.isFetching}
                datacy="providers-Dropdown"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                label="Procedure"
                name="cptCode"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'cpt_code')}
                value={selectedCptCodeOption}
                isClearable={false}
                options={cptCodeSelectOptions}
                isLoading={filterOptions?.cptCodes?.isFetching}
                datacy="procedure-Dropdown"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                label="Reason"
                name="reasonCode"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'reason_code')}
                value={selectedReasonCodeOption}
                isClearable={false}
                options={reasonCodeSelectOptions}
                isLoading={filterOptions?.reasonCodes?.isFetching}
                datacy="reason-Dropdown"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-12">
              <Dropdown
                label="Modifier"
                name="procedureModifier"
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange(option, 'cpt_modifiers_code')
                }
                value={selectedCptModifierCodeOption}
                isClearable={false}
                options={procedureModifiersSelectOptions}
                isLoading={filterOptions?.procedureModifiers?.isFetching}
                datacy="modifier-Dropdown"
              />
            </div>
            <div className="col-md-6 mb-12">
              <Dropdown
                label="Remark"
                name="remarkCode"
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange(option, 'remark_codes')
                }
                value={selectedRemarkCodeOption}
                isClearable={false}
                options={remarkCodeOptions}
                isLoading={filterOptions?.remarkCodes?.isFetching}
                datacy="remark-Dropdown"
              />
            </div>
            <div className="col-md-6 mb-12">
              <Dropdown
                label="Rev Code"
                name="revenueCode"
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange(option, 'revenue_code')
                }
                value={selectedRevenueCodeOption}
                isClearable={false}
                options={revenueCodesSelectOptions}
                isLoading={filterOptions?.revenueCodes?.isFetching}
                datacy="revenue-Dropdown"
              />
            </div>
            <div className="col-md-6 mb-12">
              <Dropdown
                label="Assigned To"
                name="agent"
                placeholder="All"
                onChange={handleAgentChange}
                value={selectedAgentOption}
                isClearable={false}
                options={agentOptionsWithUnassignedOption}
                isLoading={filterOptions?.agents?.isFetching}
                datacy="agents-Dropdown"
              />
            </div>
            {isPracticeFilterVisible && (
              <div className="col-md-6 mb-12">
                <Dropdown
                  label="Practice"
                  name="practice"
                  placeholder="All"
                  options={practicesOptions}
                  onChange={(option) =>
                    handleFilterChange(option, 'practice_identifier')
                  }
                  value={selectedPracticeId}
                  isClearable={false}
                  isLoading={filterOptions?.practices?.isFetching}
                  datacy="practice-Dropdown"
                />
              </div>
            )}

            <div className="col-md-6">
              <ToggleSwitch
                className="mt-28"
                options={ZERO_PAY_DENIALS_OPTIONS}
                checked={denialFilters.zero_pay || false}
                handleToggle={handleToggleZeroPay}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content--space-between align-items-center appealio-popup-filter-buttons mt-16">
          <div className="d-flex">
            <div>Filtered Results:</div>{' '}
            {isFetchingDenialsQueueCount ? (
              <div className="position-relative ml-12">
                <LoadingIndicator showing />
              </div>
            ) : (
              <div className="ml-8">{denialsQueueCount}</div>
            )}
          </div>

          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button ap-button--block width-180"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

DenialPopupFilter.propTypes = {
  userRole: PropTypes.number,
  setShowDenialFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  filterOptions: PropTypes.object,
};

export default DenialPopupFilter;
