const LETTER_TYPE = {
  HTML: 'html',
  PDF: 'pdf',
};

const getSortedLettersAndForms = (data) => {
  const sortedData = data.sort((a, b) => {
    const dateA = new Date(a.updated_at || a.created_at);
    const dateB = new Date(b.updated_at || b.created_at);
    return dateB - dateA;
  });

  // Separate letters and forms based on `letter_type`
  const letters = sortedData?.filter(
    (item) => item.letter_type === LETTER_TYPE.HTML
  );
  const forms = sortedData?.filter(
    (item) => item.letter_type === LETTER_TYPE.PDF
  );

  // For single letters only
  if (letters.length === 1 && forms.length === 0) {
    return letters[0];
  }

  // For single forms only
  else if (letters.length === 0 && forms.length === 1) {
    return forms[0];
  }

  // For multiple letters only
  else if (letters.length > 1 && forms.length === 0) {
    return letters[0];
  }

  // For multiple forms only
  else if (letters.length === 0 && forms.length > 1) {
    return forms[0];
  }

  // For multiple Letters and multiple Forms
  else if (letters.length > 0 && forms.length > 0) {
    return data[0];
  }
  return null;
};

export default getSortedLettersAndForms;
