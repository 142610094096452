import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import React, { useState, useEffect, useCallback } from 'react';

import * as toast from 'components/Shared/toast';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import DenialsAPI from 'API/DenialsAPI';
import { moneyFormatter } from 'helpers/money';
import { getFormattedDate } from 'helpers/dateFormats';

import closeIcon from 'img/close-modal-white.svg';

export const useFetchDenialClaimInformation = (claimId, claimControlNumber) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [claimInformation, setClaimInformation] = useState({});

  const fetch = useCallback(async () => {
    setLoading(true);
    setClaimInformation({});
    try {
      const queryParam = {
        claimId,
        claimControlNumber,
      };

      const { data } = await DenialsAPI.fetchClaimInfo(queryParam);
      setClaimInformation(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [claimId, claimControlNumber]);

  useEffect(() => {
    fetch();
  }, [claimId, claimControlNumber, fetch]);

  return { loading, error, claimInformation };
};

const DenialClaimInformationPopup = (props) => {
  const {
    closePopup,
    showCloseButton = false,
    denialClaimInformation: { claimId, claimControlNumber },
  } = props;

  const { loading, claimInformation, error } = useFetchDenialClaimInformation(
    claimId,
    claimControlNumber
  );

  if (error) {
    toast.error('Something went wrong.');
    closePopup();
  }

  const {
    payer,
    eobDate,
    patient,
    provider,
    totals,
    eobProcedures,
    claimControlNumber: claimNumber = '-',
  } = claimInformation;

  const renderContent = () => {
    if (loading) {
      return (
        <div>
          <LoadingIndicator showing />
        </div>
      );
    }

    const patientMemId = patient.memberId || 'N/A';
    const providerFullName =
      provider?.organizationName ||
      [provider.firstName, provider.lastName, provider.organizationName]
        .filter((v) => v)
        .join(' ') ||
      'N/A';
    const patientFullName =
      [patient.firstName, patient.middleName, patient.lastName]
        .filter((v) => v)
        .join(' ') || 'N/A';

    const ReasonCodeAndInsuranceAmount = ({ data }) => {
      return (
        <>
          {data.map(({ code, insuranceAmount }, index) => {
            return (
              <span
                data-tip={moneyFormatter().format(insuranceAmount || 0)}
                key={index}
              >
                {code || ''} {index < data.length - 1 && ', '}
              </span>
            );
          })}
          <ReactTooltip effect="solid" place="top" />
        </>
      );
    };

    return (
      <React.Fragment>
        <div className="appealio-info-flyover__patient-info">
          <div className="appealio-info-flyover__info-table">
            <table width="70%">
              <thead>
                <tr>
                  <th>Patient</th>
                  <th>Member ID</th>
                  <th>Provider</th>
                  <th>Payer</th>
                  <th>Payer Claim ID</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {patientFullName}{' '}
                    {patient.number ? `(${patient.number})` : ''}{' '}
                  </td>
                  <td>{patientMemId}</td>
                  <td>{providerFullName}</td>
                  <td>{payer.name}</td>
                  <td>{claimNumber || '-'}</td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                  <td>EDI Payer ID: {payer?.ediPayerId || 'N/A'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="appealio-info-flyover__details-table">
          <div className="top-section">
            <p className="fw-bold">
              EOB date:{' '}
              <span>{eobDate ? getFormattedDate(eobDate) : 'MM/DD/YYYY'}</span>
            </p>
          </div>
          <table width="100%">
            <thead>
              <tr>
                <th>Service Date</th>
                <th>Procedure</th>
                <th>Modifier(s)</th>
                <th>Rev Code</th>
                <th>Quantity</th>
                <th>Billed Amount</th>
                <th>Allowed Amount</th>
                <th>Deductible</th>
                <th>Adjustment Code(s)</th>
                <th>Coinsurance</th>
                <th>Payment Amount</th>
                <th>Remark Code</th>
              </tr>
            </thead>
            <tbody>
              {eobProcedures.map((procedure, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {procedure.serviceDate
                        ? getFormattedDate(procedure.serviceDate)
                        : 'N/A'}
                    </td>
                    <td>{procedure.cptCode || 'N/A'}</td>
                    <td>{procedure.cptModifiersCode || 'N/A'}</td>
                    <td>{procedure.revenueCode || 'N/A'}</td>
                    <td>{procedure.quantity || 0}</td>
                    <td>
                      {moneyFormatter().format(procedure.amountBilled || 0)}
                    </td>
                    <td>
                      {moneyFormatter().format(procedure.amountAllowed || 0)}
                    </td>
                    <td>
                      {moneyFormatter().format(procedure.deductableAmount || 0)}
                    </td>
                    <td>
                      {' '}
                      <ReasonCodeAndInsuranceAmount
                        data={procedure.reasonCodeInsuranceAmounts}
                      />{' '}
                    </td>
                    <td>
                      {moneyFormatter().format(
                        procedure.coinsuranceAmount || 0
                      )}
                    </td>
                    <td>
                      {moneyFormatter().format(procedure.paymentAmount || 0)}
                    </td>
                    <td>{procedure?.remarkCodes.join(', ') || 'N/A'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="appealio-info-flyover__summary-section">
          <div className="appealio-info-flyover__summary-table">
            <div className="section-title">Totals</div>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Billed Amount</th>
                    <th>Allowed Amount</th>
                    <th>Deductible</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moneyFormatter().format(totals.amountBilled || 0)}</td>
                    <td>
                      {moneyFormatter().format(totals.amountAllowed || 0)}
                    </td>
                    <td>
                      {moneyFormatter().format(totals.deductableAmount || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Coinsurance</th>
                    <th>Payment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moneyFormatter().format(totals.coinsurance || 0)}</td>
                    <td>
                      {moneyFormatter().format(totals.paymentAmount || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      className={classnames('appealio-info-flyover', {
        'appealio-info-flyover--md': showCloseButton,
      })}
      datacy="denial-claim-information-popup"
    >
      <div className="appealio-info-flyover__title">CLAIM DETAILS</div>
      {showCloseButton && (
        <img
          alt="Close"
          onClick={() => closePopup()}
          className="appealio-info-flyover__close ml-8"
          src={closeIcon}
        />
      )}
      {renderContent()}
    </div>
  );
};

export default DenialClaimInformationPopup;
