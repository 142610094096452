import { connect } from 'react-redux';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import SearchBox from './SearchBox';
import SearchResultContainer from './SearchResultContainer';

import { getUserInfo } from 'redux/reducers/loginStore';

import { useActiveTabs, useTabCounts, useFetchSearchHistory } from './hooks';

import { createURLWithParams } from 'API/Config';

import { isUserAuthorizedForDenialsPage } from 'Auth/AuthUtils';

import { globalSearchTabConfig, TAB_NAME_CONFIG } from './config';
import AppealsAPI from 'API/AppealsAPI';

const GlobalSearchV2 = (props) => {
  const { userInfo } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const { searchHistoryData, isSearchHistoryLoading } = useFetchSearchHistory();
  const [selectedHistoryTerm, setSelectedHistoryTerm] = useState('');

  const handleSearch = (query) => {
    const trimmedQuery = query?.trim();
    setSearchQuery(trimmedQuery);
    if (trimmedQuery) {
      AppealsAPI.createSearchHistory(trimmedQuery);
    }
  };

  const handleSearchHistoryItemClick = (item) => {
    const text = item.searchText;
    setSelectedHistoryTerm(text);
    setSearchQuery(text);
  };

  const isAuthorizedForDenialsPage = isUserAuthorizedForDenialsPage(userInfo);
  const searchResultsTab = isAuthorizedForDenialsPage
    ? globalSearchTabConfig
    : globalSearchTabConfig.filter(
        (tab) =>
          tab.key !== TAB_NAME_CONFIG.WORK_LIST ||
          tab.key !== TAB_NAME_CONFIG.ARCHIVED
      );

  const handleRedirect = (url, queryParam) => {
    props.actions.push(createURLWithParams(url, queryParam, true, '', true));
    props.closeSearch();
  };

  const {
    counts,
    loading: isLoadingCount,
    fetchCounts,
    handleActionForCount,
  } = useTabCounts(searchQuery, props.userInfo);

  const { activeParentTab, activeChildTab } = useActiveTabs(
    searchResultsTab,
    counts
  );

  return (
    <div className="global-search-v2-container">
      <SearchBox
        onSearch={handleSearch}
        selectedHistoryTerm={selectedHistoryTerm}
      />
      <SearchResultContainer
        closeSearch={props.closeSearch}
        searchQuery={searchQuery}
        handleSearchHistoryItemClick={handleSearchHistoryItemClick}
        searchHistoryData={searchHistoryData}
        isSearchHistoryLoading={isSearchHistoryLoading}
        counts={counts}
        isLoadingCount={isLoadingCount}
        userInfo={props.userInfo}
        handleRedirect={handleRedirect}
        fetchCounts={fetchCounts}
        handleActionForCount={(actionType, activeTab, source) =>
          handleActionForCount(actionType, activeTab, source)
        }
        searchResultsTab={searchResultsTab}
        activeParentTab={activeParentTab}
        activeChildTab={activeChildTab}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchV2);
