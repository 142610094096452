import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { push, replace } from 'connected-react-router';
import React, { useState, useEffect, useMemo } from 'react';
import { debounce, isEmpty, pickBy, identity, get } from 'lodash';

import addCircleIcon from 'img/add-circle.svg';

import * as routes from 'constants/routes';

import { getUserInfo } from 'redux/reducers/loginStore';
import { openUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';
import {
  setAppealData,
  setAppealPreFillData,
  clearCurrentAppealData,
} from 'redux/actions/createAppealActions';

import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import * as toast from 'components/Shared/toast';
import GlobalSearchTable from './GlobalSearchTable';
import RightSlideOut from 'components/Shared/RightSlideOut';
import GlobalSearchResultsTab from './GlobalSearchResultsTab';
import RecordResponse from 'components/Dashboard/RecordResponse';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import AssignClaimPopup from 'components/DenialQueue/AssignClaimPopup';
import MissingPayerPopup from 'components/DenialQueue/MissingPayerPopup';
import PendingApprovalPopup from 'components/DenialQueue/PendingApprovalPopup';
import MissingManualClaimInfo from 'components/DenialQueue/MissingManualClaimInfo';
import { checkIfAppealExists } from 'components/Dashboard/CreateAppealModal/CreateAppealModal';
import MissingRenderingProviderPopup from 'components/DenialQueue/MissingRenderingProviderPopup';
import {
  PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
  SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
} from 'components/Shared/Errors/errorMessages';

import {
  fetchDenials,
  fetchActionLogs,
  fetchInProgressAppeals,
  fetchDeliveryTracker,
  fetchUnflaggedClaims,
} from './hooks';

import { handleError } from 'helpers/errorHandler';
import { validatePayerClaimId } from 'helpers/validators';
import { GLOBAL_SEARCH_CLAIM_ACTIONS } from 'constants/appConstants';

import { extractUrlAndQueryParam, getDefaultTab } from './util';

import {
  globalSearchTabConfig,
  TAB_NAME_CONFIG,
  SUCCESSFUL_DENIED_OPTIONS,
} from './config';

import DenialsAPI from 'API/DenialsAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';
import { appealManualClaim } from 'API/ManualClaimAPI';

import { getRouteByAppealStep } from 'helpers/route';
import { isUserAuthorizedForAutoArchiveDQ } from 'Auth/FeatureFlags';

export const ACTION_TYPE = {
  ASSIGN_TO_AGENT: 'ASSIGN_TO_AGENT',
  MARK_AS_PENDING: 'MARK_AS_PENDING',
  MARK_AS_COMPLETE: 'MARK_AS_COMPLETE',
  ARCHIVE: 'ARCHIVE',
  DELETE: 'DELETE',
  UNARCHIVE: 'UNARCHIVE',
  ADD_TO_DQ: 'ADD_TO_DQ',
};

const SearchResults = (props) => {
  const {
    counts,
    userInfo,
    isLoadingCount,
    searchQuery,
    handleRedirect,
    fetchCounts,
    handleActionForCount,
    closeSearch,
    searchResultsTab,
    activeParentTab,
    activeChildTab,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [searchResultsData, setSearchResultsData] = useState({});
  const [selectedTab, setSelectedTab] = useState(activeParentTab);
  const [selectedChildTab, setSelectedChildTab] = useState(activeChildTab);
  const [workListDenialActions, setWorkListDenialActions] = useState({
    isRequestingPayersAndProviders: false,
    missingManualClaimInfo: {
      isOpen: false,
      manualClaimId: null,
      isMissingClaimNumber: false,
      isMissingClaimControlNumber: false,
      isMedicalRecord: false,
    },
    missingRenderingProviderPopup: {
      isOpen: false,
      providerName: null,
      practiceId: null,
    },
    missingImportClaimInfo: {
      claimId: null,
      isSubmitting: false,
      isMissingProvider: false,
      isMissingPayer: false,
      assignedRenderingProviderId: null,
      requestedRenderingProviderInfo: {},
      assignedPayerId: null,
      requestedPayerInfo: {},
    },
    missingPayerPopup: {
      isOpen: false,
      payerName: null,
    },
    isAppealingDeniedClaim: false,
    claimAssignmentPopup: {
      isOpen: false,
      selectedClaims: [],
      markComplete: false,
      selectedAgents: [],
    },
  });

  const [recordResponseFlyover, setRecordResponseFlyover] = useState({
    isOpen: false,
    appealId: null,
    isResolveClaim: false,
  });

  const handleApiErrors = async (error, shouldRedirect = true) => {
    handleError(error);

    const errorResponse = error.response;

    if (shouldRedirect && errorResponse.status === 400) {
      props.actions.replace(routes.SUBMISSIONS);
    }
  };

  const openPendingApprovalPopup = (cb) => {
    setWorkListDenialActions(
      (prevState) => ({
        ...prevState,
        pendingApprovalPopup: {
          ...prevState.pendingApprovalPopup,
          isOpen: true,
        },
      }),
      cb
    );
  };

  const resetPopupsAndMissingImportClaimInfo = (cb) => {
    setWorkListDenialActions(
      (prevState) => ({
        ...prevState,
        missingImportClaimInfo: {
          ...prevState.missingImportClaimInfo,
          claimId: null,
          isMissingProvider: false,
          isMissingPayer: false,
          assignedRenderingProviderId: null,
          requestedRenderingProviderInfo: {},
          assignedPayerId: null,
          requestedPayerInfo: {},
        },
        missingRenderingProviderPopup: {
          ...prevState.missingRenderingProviderPopup,
          isOpen: false,
          providerName: null,
          practiceId: null,
        },
        missingPayerPopup: {
          ...prevState.missingPayerPopup,
          isOpen: false,
          payerName: null,
        },
      }),
      cb
    );
  };

  const requestNewPayerAndRenderingProvider = async (
    payerInfo,
    renderingProviderInfo
  ) => {
    const requests = [];

    if (!isEmpty(payerInfo)) {
      requests.push(DenialsAPI.requestAddPayer(payerInfo));
    }

    if (!isEmpty(renderingProviderInfo)) {
      requests.push(
        DenialsAPI.requestAddRenderingProvider(renderingProviderInfo)
      );
    }

    try {
      setWorkListDenialActions((prevState) => ({
        ...prevState,
        isRequestingPayersAndProviders: true,
      }));
      await Promise.all(requests);

      resetPopupsAndMissingImportClaimInfo(() => openPendingApprovalPopup());

      setWorkListDenialActions((prevState) => ({
        ...prevState,
        isRequestingPayersAndProviders: false,
      }));
    } catch (error) {
      handleError(error);
      setWorkListDenialActions((prevState) => ({
        ...prevState,
        isRequestingPayersAndProviders: false,
      }));
    }
  };

  const handleManualClaim = async (claim, params) => {
    const { claimNumber, claimControlNumber, manualClaimId } = claim;
    const { isMedicalRecord } = params;

    if (isMedicalRecord && !claimNumber) {
      return setWorkListDenialActions((prevState) => ({
        ...prevState,
        missingManualClaimInfo: {
          ...prevState.missingManualClaimInfo,
          isOpen: true,
          manualClaimId,
          isMissingClaimNumber: true,
          isMissingClaimControlNumber: false,
          isMedicalRecord: true,
        },
      }));
    }

    if (!isMedicalRecord && !claimControlNumber) {
      return setWorkListDenialActions((prevState) => ({
        ...prevState,
        missingManualClaimInfo: {
          ...prevState.missingManualClaimInfo,
          isOpen: true,
          manualClaimId,
          isMissingClaimNumber: false,
          isMissingClaimControlNumber: true,
          isMedicalRecord: false,
        },
      }));
    }

    applyManualClaim(
      manualClaimId,
      isMedicalRecord ? claimNumber : claimControlNumber,
      params
    );
  };

  const handleMissingImportClaimInfoError = ({
    claim,
    isMissingPayer,
    isMissingProvider,
  }) => {
    setWorkListDenialActions((prevState) => ({
      ...prevState,
      missingImportClaimInfo: {
        ...prevState.missingImportClaimInfo,
        claim,
        isMissingProvider,
        isMissingPayer,
        assignedRenderingProviderId: null,
        requestedRenderingProviderInfo: {},
        assignedPayerId: null,
        requestedPayerInfo: {},
      },
      ...(isMissingProvider
        ? {
            missingRenderingProviderPopup: {
              isOpen: true,
              providerName: claim?.providerName || '',
              practiceId:
                props.userPractices.find(
                  (practice) =>
                    practice.practiceIdentifier === claim?.practiceId
                )?.id || '',
            },
          }
        : {
            missingPayerPopup: {
              isOpen: true,
              payerName: claim?.payerName || '',
            },
          }),
    }));
  };

  const handleAppealDeniedClaim = async (claim, extraParams = {}) => {
    try {
      const { claimNumber, claimControlNumber } = claim;
      const requestBody = {
        ...pickBy(extraParams, identity),
        claimNumber,
        claimControlNumber,
      };

      setWorkListDenialActions((prevState) => ({
        ...prevState,
        isAppealingDeniedClaim: true,
      }));

      const { appeal_id: appealId } = await DenialsAPI.appealDeniedClaim(
        requestBody
      );
      toast.success({
        title: 'Success',
        message: `${
          extraParams?.isMedicalRecord ? 'Record' : 'Appeal'
        } of claim id ${claimNumber} is now in progress.`,
      });

      setWorkListDenialActions((prevState) => ({
        ...prevState,
        isAppealingDeniedClaim: false,
        missingRenderingProviderPopup: {
          isOpen: false,
          providerName: null,
          practiceId: null,
        },
      }));

      setAppealDataAndRedirect(appealId);
      closeSearch();
    } catch (error) {
      setWorkListDenialActions((prevState) => ({
        ...prevState,
        isAppealingDeniedClaim: false,
        isMedicalRecord: extraParams?.isMedicalRecord,
      }));
      if (error.response?.status === 404) {
        const errorBody = await error.response.json();
        const missingEntities = get(errorBody, 'missing_entity', []);

        if (isEmpty(missingEntities)) {
          return handleError(error);
        }

        const isMissingPayer = missingEntities.includes('PAYER');
        const isMissingProvider = missingEntities.includes('PROVIDER');

        return handleMissingImportClaimInfoError({
          claim,
          isMissingPayer,
          isMissingProvider,
        });
      }

      handleApiErrors(error, false);
    }
  };

  const openRenderingProviderPopup = (claim) => {
    setWorkListDenialActions((prevState) => ({
      ...prevState,
      missingRenderingProviderPopup: {
        isOpen: true,
        providerName: claim?.providerName || '',
        practiceId:
          props.userPractices.find(
            (practice) => practice.practiceIdentifier === claim?.practiceId
          )?.id || '',
      },
    }));
  };

  const handleMissingImportClaimInfo = async (missingImportClaimInfo) => {
    setWorkListDenialActions((prevState) => ({
      ...prevState,
      missingImportClaimInfo,
    }));

    const isProviderDataAvailable =
      !missingImportClaimInfo.isMissingProvider ||
      missingImportClaimInfo.assignedRenderingProviderId ||
      !isEmpty(missingImportClaimInfo.requestedRenderingProviderInfo);

    const isPayerDataAvailable =
      !missingImportClaimInfo.isMissingPayer ||
      missingImportClaimInfo.assignedPayerId ||
      !isEmpty(missingImportClaimInfo.requestedPayerInfo);

    if (!isPayerDataAvailable) {
      setWorkListDenialActions((prevState) => ({
        ...prevState,
        missingRenderingProviderPopup: {
          isOpen: false,
          providerName: null,
          practiceId: null,
        },
        missingPayerPopup: {
          isOpen: true,
          payerName: missingImportClaimInfo.claim?.payerName,
        },
      }));
      return;
    }

    if (!isProviderDataAvailable) {
      setWorkListDenialActions((prevState) => ({
        ...prevState,
        missingPayerPopup: { isOpen: false, payerName: null },
      }));
      return openRenderingProviderPopup(missingImportClaimInfo.claim);
    }

    if (
      (!missingImportClaimInfo.isMissingPayer ||
        missingImportClaimInfo.assignedPayerId) &&
      (!missingImportClaimInfo.isMissingProvider ||
        missingImportClaimInfo.assignedRenderingProviderId)
    ) {
      await handleAppealDeniedClaim(missingImportClaimInfo.claim, {
        providerId: +missingImportClaimInfo.assignedRenderingProviderId,
        payerId: +missingImportClaimInfo.assignedPayerId,
        isMedicalRecord: workListDenialActions?.isMedicalRecord,
      });
      return;
    }

    requestNewPayerAndRenderingProvider(
      missingImportClaimInfo.requestedPayerInfo,
      missingImportClaimInfo.requestedRenderingProviderInfo
    );
  };

  const setAppealDataAndRedirect = async (appealId) => {
    const appeal = await CreateAppealAPI.getAppealById(appealId);

    props.actions.setAppealData({
      appeals: [
        {
          ...appeal,
          step: 0,
        },
      ],
      name: appeal.patientName,
    });

    props.actions.push('/createappeal/patientinfo');
  };

  const applyManualClaim = async (manualClaimId, claimNumber, params) => {
    try {
      const requestBody = {
        ...pickBy(params, identity),
        manualClaimId,
      };

      const { appeal_id: appealId } = await appealManualClaim(requestBody);
      toast.success({
        title: 'Success',
        message: `${
          params?.isMedicalRecord ? 'Record' : 'Appeal'
        } of claim id ${claimNumber} is now in progress.`,
      });

      setAppealDataAndRedirect(appealId);
      closeSearch();
    } catch (error) {
      handleError(error);
    }
  };

  const handleAssignMissingPayerId = (payerId) => {
    handleMissingImportClaimInfo({
      ...workListDenialActions?.missingImportClaimInfo,
      assignedPayerId: payerId,
    });
  };

  const handleAssignMissingRenderingProvider = (
    assignedRenderingProviderId
  ) => {
    handleMissingImportClaimInfo({
      ...workListDenialActions.missingImportClaimInfo,
      assignedRenderingProviderId,
    });
  };

  const handleRequestNewPayer = (requestedPayerInfo) => {
    handleMissingImportClaimInfo({
      ...workListDenialActions.missingImportClaimInfo,
      requestedPayerInfo,
    });
  };

  const openClaimAssignmentPopup = (claims, markComplete = false) => {
    const claimAssignmentPopup = {
      isOpen: true,
      selectedClaims: claims,
      markComplete,
      selectedAgents: [],
      action: null,
    };

    setWorkListDenialActions((prevState) => ({
      ...prevState,
      claimAssignmentPopup,
    }));
  };

  const currentSelectedTabData = useMemo(
    () =>
      searchResultsData?.[selectedTab]?.[selectedChildTab] ||
      searchResultsData?.[selectedTab],
    [searchResultsData, selectedTab, selectedChildTab]
  );

  const isCurrentSelectedTabDataExists =
    currentSelectedTabData?.data?.length > 0;
  const activeTab = selectedChildTab || selectedTab;

  const debouncedFetchTabData = useMemo(() => {
    return debounce(async (tab, query) => {
      try {
        setSearchResultsData((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const fetchActions = {
          [TAB_NAME_CONFIG.DENIALS]: () => fetchDenials(query, userInfo),
          [TAB_NAME_CONFIG.PENDING_ACTIONS]: () =>
            fetchActionLogs(query, userInfo),
          [TAB_NAME_CONFIG.COMPLETED_ACTIONS]: () =>
            fetchActionLogs(query, userInfo, 1),
          [TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS]: () =>
            fetchInProgressAppeals(query, userInfo),
          [TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS]: () =>
            fetchDeliveryTracker(query, userInfo),
          [TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS]: () =>
            fetchDeliveryTracker(
              query,
              userInfo,
              SUCCESSFUL_DENIED_OPTIONS[TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS]
            ),
          [TAB_NAME_CONFIG.DENIED_SUBMISSIONS]: () =>
            fetchDeliveryTracker(
              query,
              userInfo,
              SUCCESSFUL_DENIED_OPTIONS[TAB_NAME_CONFIG.DENIED_SUBMISSIONS]
            ),
          [TAB_NAME_CONFIG.ARCHIVE_DENIALS]: () =>
            fetchDenials(query, userInfo, true),
          [TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS]: () =>
            fetchActionLogs(query, userInfo, 0, true),
          [TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS]: () =>
            fetchActionLogs(query, userInfo, 1, 1),
          [TAB_NAME_CONFIG.UNFLAGGED]: () =>
            fetchUnflaggedClaims(query, userInfo),
        };

        if (fetchActions[tab]) {
          const { data, total } = await fetchActions[tab]();

          setSearchResultsData((prevState) => ({
            ...prevState,
            [selectedTab]: {
              ...prevState[selectedTab],
              [tab]: {
                data,
                total,
              },
            },
            isLoading: false,
          }));
        }
      } catch (err) {
        handleError(err);
      } finally {
        setSearchResultsData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }, 200);
  }, [userInfo, setSearchResultsData, selectedTab]);

  useEffect(() => {
    return () => {
      debouncedFetchTabData.cancel();
    };
  }, [debouncedFetchTabData]);

  useEffect(() => {
    if (counts[selectedTab] === 0 || counts[activeTab] === 0) {
      const isParentTabEmpty = counts[selectedTab] === 0;
      const targetTab = isParentTabEmpty
        ? searchResultsTab.find(({ key }) => counts[key] > 0)
        : searchResultsTab.find((tab) => tab.key === selectedTab);

      const childTabs = targetTab?.tabs || [];
      const defaultChildTabKey =
        getDefaultTab(childTabs, counts)?.key || targetTab?.key;

      if (isParentTabEmpty) {
        setSelectedTab(targetTab?.key);
      }

      setSelectedChildTab(defaultChildTabKey);
      setSearchResultsData({});
    }

    if (counts[activeTab] > 0 && !isCurrentSelectedTabDataExists) {
      debouncedFetchTabData(activeTab, searchQuery);
    }
  }, [
    activeTab,
    counts,
    selectedTab,
    selectedChildTab,
    isCurrentSelectedTabDataExists,
    debouncedFetchTabData,
    searchQuery,
    searchResultsTab,
  ]);

  const isDataNotFound =
    Object.values(counts).reduce((sum, value) => sum + value, 0) === 0;

  const selectedTabLabel = globalSearchTabConfig.find(
    (tab) => tab.key === selectedTab
  )?.label;

  const redirectLabel = selectedTabLabel ? `Go to ${selectedTabLabel}` : '';

  const onRedirectLinkClick = (includeSearchValue) => {
    const searchValue =
      typeof includeSearchValue === 'string'
        ? includeSearchValue
        : includeSearchValue === true
        ? searchQuery
        : null;

    const { url, queryParam } = extractUrlAndQueryParam(activeTab, searchValue);
    handleRedirect(url, queryParam);
  };

  const isLoadingState =
    isLoadingCount || searchResultsData?.isLoading === true;

  const handleReloadTableForWorkList = (
    id,
    action = GLOBAL_SEARCH_CLAIM_ACTIONS.ASSIGN
  ) => {
    const validPaths = [routes.DENIED_QUEUE, routes.DENIED_QUEUE_LOGS];
    if (validPaths.includes(location.pathname)) {
      history.push({
        pathname: location.pathname,
        search: `?action=${action}&actionId=${id}`,
      });
    }
  };

  const handleAction = async (
    actionType,
    { id = null, claimControlNumber = null } = {}
  ) => {
    const resetData = {
      data: [],
      total: 0,
    };

    switch (actionType) {
      case ACTION_TYPE.MARK_AS_COMPLETE:
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
            [TAB_NAME_CONFIG.COMPLETED_ACTIONS]: resetData,
          },
          isLoading: true,
        }));
        handleActionForCount(actionType, activeTab);
        handleReloadTableForWorkList(id);
        break;

      case ACTION_TYPE.MARK_AS_PENDING:
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
            [TAB_NAME_CONFIG.PENDING_ACTIONS]: resetData,
          },
          isLoading: true,
        }));
        handleActionForCount(actionType, activeTab);
        handleReloadTableForWorkList(id);
        break;

      case ACTION_TYPE.ASSIGN_TO_AGENT:
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
          },
          isLoading: true,
        }));
        handleReloadTableForWorkList(id);
        break;

      case ACTION_TYPE.ARCHIVE:
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
          },
          isLoading: true,
        }));
        handleActionForCount(actionType, activeTab);
        break;

      case ACTION_TYPE.DELETE:
        await fetchCounts();
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
            [TAB_NAME_CONFIG.PENDING_ACTIONS]: resetData,
            [TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS]: resetData,
            [TAB_NAME_CONFIG.UNFLAGGED]: resetData,
          },
          [TAB_NAME_CONFIG.WORK_LIST]: resetData,
          isLoading: true,
        }));
        break;

      case ACTION_TYPE.UNARCHIVE:
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
          },
          isLoading: true,
        }));
        handleActionForCount(actionType, activeTab);
        break;

      case ACTION_TYPE.ADD_TO_DQ:
        setSearchResultsData((prevState) => ({
          ...prevState,
          [selectedTab]: {
            ...prevState[selectedTab],
            [activeTab]: resetData,
            [TAB_NAME_CONFIG.UNFLAGGED]: resetData,
            [TAB_NAME_CONFIG.DENIALS]: resetData,
          },
          isLoading: true,
        }));
        handleActionForCount(actionType, activeTab);
        handleReloadTableForWorkList(id, GLOBAL_SEARCH_CLAIM_ACTIONS.ADD_TO_DQ);
        break;

      default:
        break;
    }

    setIsPerformingAction(false);
  };

  const handleMarkAsComplete = async (id) => {
    try {
      setIsPerformingAction(true);
      await DenialsAPI.completeActionLogs([id]);
      toast.success({
        title: 'Success',
        message: 'Claim marked as complete successfully.',
      });
      handleAction(ACTION_TYPE.MARK_AS_COMPLETE, {
        id,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleArchive = async (id) => {
    try {
      setIsPerformingAction(true);
      await DenialsAPI.archiveDeniedClaim([id]);
      toast.success({
        title: 'Success',
        message: 'Claim(s) archived successfully.',
      });
      handleAction(ACTION_TYPE.ARCHIVE, {
        id,
      });
      const validPaths = [
        routes.DENIED_QUEUE,
        routes.DENIED_QUEUE_LOGS,
        routes.DENIED_QUEUE_COMPLETED_LOGS,
        routes.DENIED_QUEUE_ARCHIVED,
        routes.DENIED_QUEUE_LOGS_ARCHIVED,
        routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
      ];

      if (validPaths.includes(location.pathname)) {
        history.push({
          pathname: location.pathname,
          search: `?action=${GLOBAL_SEARCH_CLAIM_ACTIONS.ARCHIVE}&claimId=${id}`,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleAddClaimsToDenialsQueue = async (
    id,
    claimNumber,
    claimControlNumber
  ) => {
    try {
      setIsPerformingAction(true);
      const payload = {
        claims: [
          {
            claimControlNumber: claimControlNumber || '',
            claimNumber: claimNumber || '',
          },
        ],
      };
      await DenialsAPI.addClaimsToDenialQueue(payload);
      toast.success({
        title: 'Success',
        message: 'Claim added to Denial Queue Successfully',
      });
      handleAction(ACTION_TYPE.ADD_TO_DQ, {
        id,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleUnArchive = async (id) => {
    try {
      setIsPerformingAction(true);
      await DenialsAPI.unArchiveDeniedClaim([id]);
      toast.success({
        title: 'Success',
        message: 'Claim(s) unarchived successfully.',
      });
      handleAction(ACTION_TYPE.UNARCHIVE, {
        id,
      });
      const validPaths = [
        routes.DENIED_QUEUE,
        routes.DENIED_QUEUE_LOGS,
        routes.DENIED_QUEUE_COMPLETED_LOGS,
        routes.DENIED_QUEUE_ARCHIVED,
        routes.DENIED_QUEUE_LOGS_ARCHIVED,
        routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
      ];

      if (validPaths.includes(location.pathname)) {
        history.push({
          pathname: location.pathname,
          search: `?action=${GLOBAL_SEARCH_CLAIM_ACTIONS.UNARCHIVE}&claimId=${id}`,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleCompleteClaim = async (appealId) => {
    if (!appealId) {
      return;
    }
    try {
      const appeal = await CreateAppealAPI.getAppealById(appealId);

      const route = getRouteByAppealStep(
        appeal.step,
        appeal.isMedicalRecordsSubmission
      );

      window.location.href = `${route}?appealId=${appealId}`;
    } catch (error) {
      handleError(error);
    }
  };

  const closeRecordResponseFlyover = () => {
    setRecordResponseFlyover({
      isOpen: false,
      appealId: null,
      isResolveClaim: false,
    });
  };

  const createManualAppeal = async (manualPayerId, isMedicalRecord) => {
    if (!manualPayerId) {
      const errorMessage = isMedicalRecord
        ? 'Claim ID is required'
        : 'Payer  Claim ID is required';

      return toast.error({
        title: '',
        message: errorMessage,
        extraParams: {
          position: 'center',
        },
      });
    }

    const invalidPayerClaimIdMessage = validatePayerClaimId(
      manualPayerId,
      isMedicalRecord
        ? SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT
        : PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT
    );
    if (manualPayerId && invalidPayerClaimIdMessage) {
      return toast.error({
        title: '',
        message: invalidPayerClaimIdMessage,
      });
    }

    props.actions.clearCurrentAppealData();

    let appealExists = false;
    let appealStatus;
    try {
      const { isExisting, status } = isMedicalRecord
        ? { isExisting: false, status: null }
        : await checkIfAppealExists(manualPayerId);
      appealExists = isExisting;
      appealStatus = status;
    } catch (error) {
      return handleError(error);
    }

    if (appealExists) {
      return toast.error({
        title: '',
        message: `*We found an existing ${
          isMedicalRecord ? 'record' : 'appeal'
        } with this ${
          isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'
        }. Status: ${appealStatus}`,
        extraParams: {
          position: 'center',
        },
      });
    }

    try {
      // TODO: Use API to get prefill data with submitter claim id once API supports it
      let apiData = null;
      if (!isMedicalRecord) {
        apiData = await CreateAppealAPI.getPrefillAppealData(manualPayerId);
        if (apiData) {
          toast.success({
            message:
              'Data found in claim pool, we have filled in the forms. Please verify.',
            title: '',
            extraParams: {
              extraParams: {
                position: 'center',
              },
              timeout: 6000,
              class: 'create-appeal-toast',
            },
          });
        }
      }
      const claimNumber = manualPayerId;
      const submitterClaimId = isMedicalRecord ? manualPayerId : '';
      props.actions.setAppealPreFillData({
        ...(apiData || {}),
        claimNumber,
        submitterClaimId,
        isMedicalRecordsSubmission: isMedicalRecord,
      });
      props.actions.push('/createappeal/patientinfo');
    } catch (error) {
      handleError(error);
    }
    closeSearch();
  };

  const isAuthorizedUserForArchive = isUserAuthorizedForAutoArchiveDQ(userInfo);

  return (
    <div className="global-search-v2__search-results">
      <div className="global-search-v2__search-tab-container">
        <GlobalSearchResultsTab
          counts={counts}
          userInfo={userInfo}
          selectedTab={selectedTab}
          selectedChildTab={selectedChildTab}
          setSelectedTab={setSelectedTab}
          setSelectedChildTab={setSelectedChildTab}
          searchResultsTab={searchResultsTab}
          isDataNotFound={isDataNotFound}
          setSearchResultsData={setSearchResultsData}
        />
        <div className="global-search-v2__search-result-content">
          {(isLoadingState !== false && !isDataNotFound) ||
          isPerformingAction ? (
            <LoadingIndicator showing />
          ) : isDataNotFound ? (
            <div>
              <p className="mt-20">
                We were unable to find any matches for “{searchQuery}”.
              </p>
              <div className="mt-20 d-flex align-items-center">
                <span>
                  {' '}
                  If you would like to create an Appeal or Record, use one of
                  these Buttons.
                </span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    createManualAppeal(searchQuery, false);
                  }}
                  className="ap-button ap-button--secondary ml-4 mr-8"
                >
                  <img src={addCircleIcon} alt="add-appeal" />
                  <span> Appeal</span>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    createManualAppeal(searchQuery, true);
                  }}
                  className="ap-button ap-button--secondary"
                >
                  <img src={addCircleIcon} alt="add-record" />
                  <span> Record</span>
                </button>
              </div>
            </div>
          ) : (
            <GlobalSearchTable
              activeParentTab={selectedTab}
              activeTab={selectedChildTab || selectedTab}
              data={searchResultsData}
              handleRedirect={onRedirectLinkClick}
              fetchCounts={fetchCounts}
              handleAction={handleAction}
              setIsPerformingAction={setIsPerformingAction}
              closeSearch={closeSearch}
              handleManualClaim={handleManualClaim}
              handleAppealDeniedClaim={handleAppealDeniedClaim}
              handleCompleteClaim={handleCompleteClaim}
              handleArchive={handleArchive}
              handleUnArchive={handleUnArchive}
              handleMarkAsComplete={handleMarkAsComplete}
              handleAddClaimsToDenialsQueue={handleAddClaimsToDenialsQueue}
              isAuthorizedUserForArchive={isAuthorizedUserForArchive}
              handleAssignClaims={openClaimAssignmentPopup}
              userInfo={userInfo}
              openAvailityClaimStatusWidget={
                props.actions.openAvailityClaimStatusWidget
              }
              openUhcClaimStatusWidget={props.actions.openUhcClaimStatusWidget}
              setRecordResponseFlyover={setRecordResponseFlyover}
            />
          )}
        </div>
        {redirectLabel && (
          <div
            className="global-search-v2__search-redirection"
            onClick={onRedirectLinkClick}
          >
            <span>{redirectLabel}</span>
            <MdKeyboardArrowRight className="global-search-v2__search-redirection-icon" />
          </div>
        )}
      </div>
      {workListDenialActions?.missingRenderingProviderPopup?.isOpen && (
        <MissingRenderingProviderPopup
          onClosePressed={() => {
            setWorkListDenialActions((prevState) => ({
              ...prevState,
              missingRenderingProviderPopup: {
                isOpen: false,
                providerName: null,
                practiceId: null,
              },
            }));
          }}
          providerName={
            workListDenialActions?.missingRenderingProviderPopup.providerName
          }
          practiceId={
            workListDenialActions?.missingRenderingProviderPopup.practiceId
          }
          integrationType={props.integrationType}
          onAssignRenderingProvider={handleAssignMissingRenderingProvider}
          isSubmittingData={
            workListDenialActions?.isRequestingPayersAndProviders ||
            workListDenialActions?.isAppealingDeniedClaim
          }
        />
      )}
      {workListDenialActions?.missingManualClaimInfo.isOpen && (
        <MissingManualClaimInfo
          onClosePressed={() =>
            setWorkListDenialActions((prevState) => ({
              ...prevState,
              missingManualClaimInfo: {
                ...prevState.missingManualClaimInfo,
                isOpen: false,
              },
            }))
          }
          claimInfo={workListDenialActions?.missingManualClaimInfo}
          applyManualClaim={applyManualClaim}
        />
      )}

      {workListDenialActions?.missingPayerPopup.isOpen && (
        <MissingPayerPopup
          payerName={workListDenialActions?.missingPayerPopup.payerName}
          onAssignPayer={handleAssignMissingPayerId}
          onRequestNewPayer={handleRequestNewPayer}
          onClosePressed={resetPopupsAndMissingImportClaimInfo}
          isSubmittingData={
            workListDenialActions?.isRequestingPayersAndProviders ||
            workListDenialActions?.isAppealingDeniedClaim
          }
        />
      )}
      {workListDenialActions?.pendingApprovalPopup?.isOpen && (
        <PendingApprovalPopup
          onClosePressed={() =>
            setWorkListDenialActions((prevState) => ({
              ...prevState,
              pendingApprovalPopup: {
                ...prevState.pendingApprovalPopup,
                isOpen: false,
              },
            }))
          }
        />
      )}
      {workListDenialActions?.claimAssignmentPopup?.isOpen && (
        <AssignClaimPopup
          userInfo={userInfo}
          markComplete={
            workListDenialActions?.claimAssignmentPopup?.markComplete
          }
          resetClaimAssignmentPopup={() => {
            setWorkListDenialActions((prevState) => ({
              ...prevState,
              claimAssignmentPopup: {
                ...prevState.claimAssignmentPopup,
                isOpen: false,
                selectedClaims: [],
                selectedAgents: [],
              },
            }));
          }}
          handleApiErrors={handleApiErrors}
          isAppealioUsersFetched={props?.isAppealioUsersFetched}
          selectedClaims={
            workListDenialActions?.claimAssignmentPopup?.selectedClaims
          }
          setIsPerformingAction={setIsPerformingAction}
          handleAction={handleAction}
        />
      )}
      {recordResponseFlyover.isOpen && (
        <RightSlideOut
          isOpen={recordResponseFlyover.isOpen}
          onDimRegionPressed={(e) => {
            e.stopPropagation();
            closeRecordResponseFlyover();
          }}
        >
          <RecordResponse
            appealID={recordResponseFlyover.appealId}
            isViewOnlyForm={recordResponseFlyover.isResolveClaim}
            onDimRegionPressed={closeRecordResponseFlyover}
            reloadSubmissionPage={() => {
              const validPaths = [routes.SUBMISSIONS];

              if (validPaths.includes(location.pathname)) {
                history.push({
                  pathname: location.pathname,
                  search: `?action=${GLOBAL_SEARCH_CLAIM_ACTIONS.RECORD_RESPONSE}&appealId=${recordResponseFlyover.appealId}`,
                });
              }
            }}
            closeAppealDetails={() => {
              closeRecordResponseFlyover();
              closeSearch();
            }}
          />
        </RightSlideOut>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);
  const { integrationType } = userInfo;
  const userPractices = get(userInfo, 'relatedPractices', []);
  const agentDropdownOptions = getDropdownOptions(
    state,
    DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
  );
  const isAppealioUsersFetched = agentDropdownOptions?.isFetching === false;
  return {
    userInfo,
    integrationType,
    userPractices,
    isAppealioUsersFetched,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
      replace,
      setAppealData,
      setAppealPreFillData,
      clearCurrentAppealData,
      openUhcClaimStatusWidget,
      openAvailityClaimStatusWidget,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
