import React from 'react';
import ReactTooltip from 'react-tooltip';

import pencilImg from '../../img/ic-create.svg';

import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

const LettersAndFormSelectWithCustomDropdown = ({
  input = {},
  isDisabled = false,
  isExclusionDropdown = false,
  placeholder = 'No Letters or Forms',
  labelForAllSelectedOption = '',
  options,
  handleAssociateLettersOrFormsRedirection,
  step,
  ...rest
}) => {
  const LettersAndFormsContent = (x) => {
    const { label, value: letterId, templateId } = x;
    const handleLetterAndFormsEdit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleAssociateLettersOrFormsRedirection(letterId, templateId);
    };

    const shouldRenderEditButton = step === 2;

    return (
      <div className="letter-form-selection-option">
        <span>{label}</span>
        {shouldRenderEditButton && (
          <div
            className="d-flex align-items-center px-2 py-2 cursor-pointer edit-letters-and-forms-container"
            onClick={handleLetterAndFormsEdit}
            data-for="edit-letters-tooltip"
            data-tip="Edit"
          >
            <img
              alt="Edit"
              data-tip="Edit"
              className="edit-letters-and-forms"
              onClick={handleAssociateLettersOrFormsRedirection}
              src={pencilImg}
            />
            <ReactTooltip effect="solid" id="edit-letters-tooltip" />
          </div>
        )}
      </div>
    );
  };

  const Option = ({ item }) => {
    return (
      <div>
        <LettersAndFormsContent
          label={item.label}
          value={item.value}
          templateId={item.templateId}
        />
      </div>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <CustomDropdown
        options={options}
        {...rest}
        isMulti
        isDisabled={isDisabled}
        components={{ Option }}
        placeholder={placeholder}
        input={input}
        hideClearAllButton
        labelForAllSelectedOption={labelForAllSelectedOption}
      />
    </div>
  );
};

export default LettersAndFormSelectWithCustomDropdown;
