/* eslint-disable no-unused-vars */
import { debounce } from 'lodash';
import classnames from 'classnames';
import React, { Component } from 'react';

import * as toast from '../Shared/toast';
import Dropdown from '../Shared/Dropdown';
import AppealioPopup from '../Shared/AppealioPopup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import * as AccountsAPI from 'API/AccountSettingsAPI';
import CreateAppealAPI from '../../API/CreateAppealAPI';

import { snakeCaseKeys } from 'helpers/object';
import { handleError } from '../../helpers/errorHandler';

class MissingRenderingProviderPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderingProviderOptions: [],
      selectedRenderingProvider: null,
      isFetchingRenderingProviderOptions: false,
    };
  }

  setSelectedFormType = (selectedFormType) =>
    this.setState({ selectedFormType });

  setSelectedRenderingProvider = (selectedRenderingProvider) =>
    this.setState({ selectedRenderingProvider });

  componentDidMount = async () => {
    toast.warning({
      title: 'Info',
      message: 'Missing Rendering Provider',
    });

    this.fetchAndSetRenderingProviderOptions();
  };

  fetchAndSetRenderingProviderOptions = async () => {
    try {
      this.setState({
        isFetchingRenderingProviderOptions: true,
      });

      const queryParams = {
        disable_pagination: true,
      };

      if (this.props.practiceId) {
        queryParams.practice_id = this.props.practiceId;
      }

      const { data: renderingProviders } =
        await AccountsAPI.fetchRenderingProviders(queryParams);

      const renderingProviderOptions = renderingProviders
        .filter((provider) => provider.billingProviders.length > 0)
        .map((provider) => ({
          label: CreateAppealAPI.extractProviderName(snakeCaseKeys(provider)),
          value: provider.id,
        }));

      this.setState({
        renderingProviderOptions,
        isFetchingRenderingProviderOptions: false,
      });
    } catch (error) {
      this.setState({
        isFetchingRenderingProviderOptions: false,
      });

      handleError(error);
    }
  };

  assignRenderingProvider = debounce(async () => {
    const { selectedRenderingProvider } = this.state;

    if (!selectedRenderingProvider) {
      return;
    }

    const { value: selectedRenderingProviderId } = selectedRenderingProvider;

    this.props.onAssignRenderingProvider(selectedRenderingProviderId);
  }, 600);

  render() {
    const { submitting: isSubmittingAddRenderingProvider } = this.props;

    const { selectedRenderingProvider } = this.state;

    const isAssignRenderingProviderBtnDisabled =
      !selectedRenderingProvider ||
      isSubmittingAddRenderingProvider ||
      this.props.isSubmittingData === true;

    return (
      <AppealioPopup
        title="Missing Rendering Provider"
        className={classnames(
          'missing-rendering-provider-popup',
          this.props.className
        )}
        onClosePressed={this.props.onClosePressed}
        datacy="missing-rendering-provider-AppealioPopup"
      >
        {this.props.providerName && (
          <div className="previous-provider-info mb-8">
            {this.props.integrationType} Provider Name:{' '}
            {this.props.providerName}
          </div>
        )}
        <div className="add-provider-form">
          <div className="assign-new-provider">
            <div>
              <div className="add-provider-sub-label">
                Please confirm the Rendering Provider, if available.
              </div>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                className="mb-22"
                placeholder="Select Rendering Provider"
                isLoading={this.state.isFetchingRenderingProviderOptions}
                options={this.state.renderingProviderOptions}
                onChange={this.setSelectedRenderingProvider}
                value={selectedRenderingProvider}
                datacy="select-rendering-provider-Dropdown"
              />

              <button
                disabled={isAssignRenderingProviderBtnDisabled}
                onClick={this.assignRenderingProvider}
                className={classnames('add-provider-submit-button', {
                  'add-provider-submit-button--disabled':
                    isAssignRenderingProviderBtnDisabled,
                })}
                datacy="assign-rendering-provider-button"
              >
                {!this.props.isSubmittingData ? (
                  'Assign'
                ) : (
                  <div>
                    <LoadingIndicator showing isLightIndicator />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </AppealioPopup>
    );
  }
}

export default MissingRenderingProviderPopup;
