import moment from 'moment';
import download from 'downloadjs';
import { camelCase, isNull, omitBy, identity, pickBy } from 'lodash';

import * as endpoints from 'constants/endpoints';

import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  createRequest as createPostRequest,
  INTEGRATION_API_BASE_URL,
  extractIntegrationAPIHeaders,
} from './Config';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

import {
  REMINDER_STATUS,
  REMINDER_STATUS_OPTIONS,
} from 'constants/appConstants';
import {
  DATE_FORMAT_YYYY_MM_DD,
  getBusinessDaysFromToday,
} from 'helpers/dateFormats';

const processReminderStatus = (value) => {
  const today = moment().format(DATE_FORMAT_YYYY_MM_DD);
  let reminderDateFrom, reminderDateTo;

  switch (value) {
    case REMINDER_STATUS.PAST_DUE:
      reminderDateTo = moment()
        .subtract(1, 'days')
        .format(DATE_FORMAT_YYYY_MM_DD);
      return [{ key: 'reminder_date_to', value: reminderDateTo }];
    case REMINDER_STATUS.TODAY:
      return [
        { key: 'reminder_date_from', value: today },
        { key: 'reminder_date_to', value: today },
      ];
    case REMINDER_STATUS.UPCOMING:
      reminderDateFrom = moment().add(1, 'days').format(DATE_FORMAT_YYYY_MM_DD);
      reminderDateTo = getBusinessDaysFromToday(3).format(
        DATE_FORMAT_YYYY_MM_DD
      );
      return [
        { key: 'reminder_date_from', value: reminderDateFrom },
        { key: 'reminder_date_to', value: reminderDateTo },
      ];
    default:
      return [];
  }
};

export default class DenialsAPI {
  /**
   * Fetches denials.
   * @param {Object} params
   * @param {Number} params.offset
   * @param {Object} params.filters
   * @param {String} params.sort
   * @param {String} params.practiceId
   * @param {String} params.secretKey
   * @param {String} params.practiceIdentifier
   * @returns {Promise}
   */
  static fetchDenials = async ({
    limit,
    offset,
    filters = {},
    sort,
    search = [],
    practiceId,
    secretKey,
    practiceIdentifier,
    completed,
    action = null,
    onlyTotal = false,
    fetchArchivedItems = false,
    signal = null,
  }) => {
    const searchParams = search.length
      ? processFilters(search)
      : processFilters(filters);
    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      search: searchParams,
      offset,
      sort,
      limit,
      practice_id: practiceId,
      ...(onlyTotal && { only_total: true }),
    };

    const queryParams = {
      action,
      completed,
      archive: fetchArchivedItems ? 1 : 0,
    };

    if (practiceIdentifier) {
      requestBody.practice_identifier = practiceIdentifier;
    }

    const request = createPostRequest('denials', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
      ...((action || fetchArchivedItems) && { params: queryParams }),
    });

    const { data } = await fetch(request, { ...(signal ? { signal } : {}) })
      .then(handleErrors)
      .then(handleJSONParse);

    return {
      ...data,
      rows: data.rows ? data.rows.map(camelizeKeys) : [],
    };
  };

  static async fetchDenialsCounts(queryParams = {}) {
    const request = createGetRequest(
      'search/counts',
      queryParams,
      INTEGRATION_API_BASE_URL
    );

    const { counts } = await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse);

    return camelizeKeys(counts);
  }

  /**
   * Fetches denied claims.
   *
   * @param {Object} params
   * @param {Object} params.search
   * @param {Number} params.sortParams
   * @param {Number} params.offset
   * @param {String} params.practiceId
   * @param {String} params.secretKey
   * @returns {Promise}
   */
  static async fetchDeniedClaims({
    search,
    sortParams,
    offset,
    limit = null,
    practiceId,
    secretKey,
    practiceIdentifier,
    signal = null,
  }) {
    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      search,
      offset,
      limit,
      practice_id: practiceId,
      ...sortParams,
    };

    if (practiceIdentifier) {
      requestBody.practice_identifier = practiceIdentifier;
    }

    const request = createPostRequest('search', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    });

    const data = await fetch(request, { ...(signal ? { signal } : {}) })
      .then(handleErrors)
      .then(handleJSONParse);

    const { denials, total } = data;

    return {
      ...denials,
      ...(total && { total }),
      rows: denials?.rows.map(camelizeKeys),
    };
  }

  /**
   * Fetches payers options.
   *
   * @param {Object} params
   * @param {Object} params.practiceId
   * @returns {Promise}
   */
  static async fetchPayers() {
    const request = createGetRequest(
      'payers-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches providers options.
   *
   * @param {Object} params
   * @param {Object} params.practiceId
   * @param {Object} params.secretKey
   * @returns {Promise}
   */
  static async fetchProviders() {
    const request = createGetRequest(
      'providers-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches clinic options.
   *
   * @param {Object} params
   * @param {Object} params.practiceId
   * @param {Object} params.secretKey
   * @returns {Promise}
   */
  static async fetchClinics() {
    const request = createGetRequest(
      'clinics-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches reason code options.
   *
   * @param {Object} params
   * @param {Object} params.filter
   * @param {Number} params.offset
   * @param {Object} params.practiceId
   * @param {Object} params.secretKey
   * @returns {Promise}
   */
  static async fetchReasonCodes() {
    const request = createGetRequest(
      'reason-codes-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches cpt code options.
   *
   * @param {Object} params
   * @param {Object} params.filter
   * @param {Number} params.offset
   * @param {Object} params.practiceId
   * @param {Object} params.secretKey
   * @returns {Promise}
   */
  static async fetchCptCodes() {
    const request = createGetRequest(
      'cpt-codes-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches procedure modifier options.
   *
   * @param {Object} params
   * @param {Object} params.filter
   * @param {Number} params.offset
   * @param {Object} params.practiceId
   * @param {Object} params.secretKey
   * @returns {Array}
   */
  static async fetchProcedureModifier() {
    const request = createGetRequest(
      'modifier-codes-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches remark code options.
   */
  static async fetchRemarkCodes() {
    const request = createGetRequest(
      'remark-codes-list',
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Fetches revenue code options.
   */
  static async fetchRevenueCodes() {
    const request = createGetRequest(
      endpoints.REVENUE_CODES,
      [],
      INTEGRATION_API_BASE_URL
    );

    const data = await fetch(request).then(handleErrors).then(handleJSONParse);

    return data;
  }

  /**
   * Adds claims to the denial queue.
   *
   * @param {Array} claimIds
   * @returns {Promise}
   */
  static async addClaimsToDenialQueue(requestBody) {
    const request = createPostRequest('claims/queue', requestBody);

    return fetch(request);
  }

  /**
   * Appeal denied claim.
   *
   * @param {Object} requestBody
   * @returns {Promise}
   */
  static async appealDeniedClaim(requestBody = {}) {
    const request = createPostRequest(
      `claims/import`,
      snakeCaseKeys(requestBody)
    );

    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  static async archiveDeniedClaim(denialItemIds = []) {
    const request = createPostRequest(
      `archive-denials/`,
      {
        ids: denialItemIds,
      },
      {
        baseUrl: INTEGRATION_API_BASE_URL,
      }
    );

    return fetch(request).then(handleErrors);
  }

  static async unArchiveDeniedClaim(denialItemIds = []) {
    const request = createPostRequest(
      `unarchive-denials/`,
      {
        ids: denialItemIds,
      },
      {
        baseUrl: INTEGRATION_API_BASE_URL,
      }
    );

    return fetch(request).then(handleErrors);
  }

  /**
   * Imports claim with new provider attached.
   *
   * @param {Number} claimNumber
   * @param {Number} renderingProviderId
   * @returns {Promise}
   */
  static async importClaimWithProvider(claimNumber, renderingProviderId) {
    const request = createPostRequest(
      `import-claim-with-provider/${claimNumber}`,
      {
        provider_id: renderingProviderId,
      }
    );

    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  /**
   * Requests new rendering provider to be addded to appealio.
   *
   * @param {Object} body
   * @returns {Promise}
   */
  static async requestAddRenderingProvider(body) {
    const request = createPostRequest(`request-providers`, snakeCaseKeys(body));

    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  /**
   * Requests new payer to be addded to appealio.
   *
   * @param {Object} body
   * @returns {Promise}
   */
  static async requestAddPayer(body) {
    const request = createPostRequest(`request-payers`, snakeCaseKeys(body));

    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  /**
   * Closes denied claim.
   *
   * @param {Object} requestBody
   * @returns {Promise}
   */
  static async closeDeniedClaim(requestBody) {
    const request = createPostRequest(
      'close-claims',
      snakeCaseKeys(requestBody),
      {
        baseUrl: INTEGRATION_API_BASE_URL,
      }
    );

    return fetch(request).then(handleErrors);
  }

  /**
   * Closes denied claim.
   *
   * @param {Object} requestBody
   * @returns {Promise}
   */
  static async fetchClaimInfo(requestBody) {
    const request = createGetRequest(
      'claim-info',
      snakeCaseKeys(requestBody),
      INTEGRATION_API_BASE_URL
    );
    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  }

  /**
   * Fetches denied claims removal logs info.
   *
   * @param {Object} params
   * @param {Object} params.search
   * @param {Number} params.sortParams
   * @param {Number} params.offset
   * @param {String} params.practiceId
   * @param {String} params.secretKey
   * @returns {Promise}
   */
  static async fetchDeniedClaimsRemovalLogs({
    search,
    sort,
    offset,
    limit,
    practiceId,
    secretKey,
    practiceIdentifier,
    completed,
  }) {
    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      search,
      limit,
      offset,
      practice_id: practiceId,
      sort,
      completed,
    };

    if (practiceIdentifier) {
      requestBody.practice_identifier = practiceIdentifier;
    }

    const request = createPostRequest(endpoints.ACTION_LOGS, requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    });

    const {
      data: { rows: removedClaims, totals: totalLogCounts },
    } = await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
    return {
      removedClaims,
      totalLogCounts,
    };
  }

  /***
   * fetches the action reason list and count.
   */
  static async fetchActionLogs(queryParam) {
    const url = endpoints.DENIALS;
    const request = createGetRequest(
      url,
      {
        ...queryParam,
      },
      INTEGRATION_API_BASE_URL
    );

    const { data } = await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);

    return {
      actionList: data.reasons,
      counts: data.counts,
    };
  }

  /***
   * completes the action logs
   */
  static async completeActionLogs(ids) {
    const request = createPostRequest(
      endpoints.ACTION_LOGS_COMPLETE,
      {
        complete: ids,
      },
      {
        baseUrl: INTEGRATION_API_BASE_URL,
      }
    );

    return await fetch(request).then(handleErrors);
  }

  /**
   * assigns the claims to agent
   * @param {Object} requestBody
   * @returns {Promise}
   */
  static assignAgents = async (requestBody) => {
    const request = createPostRequest(
      endpoints.DENIALS_QUEUE_AGENT_ASSIGNMENT,
      snakeCaseKeys(requestBody),
      {
        baseUrl: INTEGRATION_API_BASE_URL,
      }
    );
    return fetch(request).then(handleErrors);
  };

  /**
   * fetch all denials queue/action logs counts
   * @param {Object} queryParam
   * @returns {object}
   */
  static async fetchDenialsQueueCounts(
    filteredData = {},
    queryParam = {},
    fetchArchivedItemsCount = false
  ) {
    const requestBody = {
      search: filteredData,
    };
    queryParam.archive = fetchArchivedItemsCount ? 1 : 0;
    const url = `${endpoints.DENIALS_QUEUE_COUNTS}`;
    const request = createPostRequest(url, requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: {},
      params: queryParam,
    });
    const { counts } = await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse);
    return camelizeKeys(counts);
  }

  /**
   * fetches the notes list based on the Appeal ID/Denials ID
   * @param {Object} queryParams
   * @returns {object}
   */
  static async fetchSubmissionsNotes(queryParams) {
    const request = createGetRequest(
      endpoints.SUBMISSIONS_NOTES,
      {
        ...queryParams,
      },
      INTEGRATION_API_BASE_URL
    );

    const { data } = await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);

    return {
      data,
    };
  }

  /**
   * Create the notes
   * @param {Object} data
   * @param {int} id
   * @param {Object} queryParams
   * @returns {Promise}
   */
  static createNote(data, id, queryParams) {
    const url = `${endpoints.DENIALS}/${id}/notes`;
    const request = createPostRequest(url, snakeCaseKeys(data), {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'POST',
      ...(queryParams && { params: queryParams }),
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys)
      .then((res) => res.data);
  }

  /**
   * Download CSV.
   * @param {Object} params
   * @param {Number} params.offset
   * @param {Object} params.filters
   * @param {String} params.sort
   * @param {String} params.practiceId
   * @param {String} params.secretKey
   * @param {String} params.practiceIdentifier
   * @returns {Promise}
   */
  static downloadReportForDenialsQueue = async ({
    filters = {},
    sort,
    search = [],
    practiceId,
    secretKey,
    completed,
    action = null,
    fetchArchivedItems = false,
    onlyExport = false,
    fileName = 'download.csv',
    mimeType = 'text/csv',
  }) => {
    const searchRequestBodyParams = Object.entries(filters).map(
      ([key, value]) => ({ key, value })
    );

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const searchParams = search.length ? search : searchRequestBodyParams;
    const requestBody = {
      search: searchParams,
      sort,
      practice_id: practiceId,
      ...(onlyExport && { only_export: true }),
    };

    const queryParams = {
      action,
      completed,
      archive: fetchArchivedItems ? 1 : 0,
    };

    const request = createPostRequest('denials', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
      ...((action || fetchArchivedItems) && { params: queryParams }),
    });

    if (onlyExport) {
      return await fetch(request)
        .then(handleErrors)
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          download(blob, fileName, mimeType);
        });
    }
  };

  /**
   * Copies a PM note for the specified denials queue ID.
   *
   * @param {number} id - The ID of the denials queue for which to copy the PM note.
   * @returns {Promise<any>} - A Promise that resolves with the response data after copying the PM note.
   */
  static copyPmNote = (id) => {
    const request = createPostRequest(
      endpoints.COPY_PM_NOTE,
      {
        denials_queue_id: id,
      },
      {
        baseUrl: INTEGRATION_API_BASE_URL,
      }
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  };
}

export const exportAllClaims = async (filters) => {
  const searchRequestBodyParams = Object.entries(snakeCaseKeys(filters)).map(
    ([key, value]) => ({ key, value })
  );
  const requestBody = {
    search: snakeCaseKeys(searchRequestBodyParams),
  };
  const request = createPostRequest(
    `${endpoints.EXPORT_ALL_CLAIMS}`,
    requestBody,
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: {},
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(
        blob,
        `all_claims_${moment().format('YYYY-MM-DD')}.zip`,
        'application/zip'
      );
    });
};

export const fetchPmNotesInfo = async (appealId) => {
  const requestBody = {
    search: [
      {
        key: 'appeal_id',
        value: appealId,
      },
    ],
    only_pm_notes: true,
  };

  const request = createPostRequest(endpoints.DENIALS, requestBody, {
    baseUrl: INTEGRATION_API_BASE_URL,
    params: {
      action: 1,
      completed: 1,
    },
  });

  const response = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
  if (response?.data.length === 0) {
    throw new Error('No data found');
  }
  return response?.data?.[0];
};

/**
 * Prepare data for bulk assignment.
 * @param {Object} filters
 * @param {Array} search
 * @param {String} practiceId
 * @param {String} practiceIdentifier
 * @param {Number} isArchive
 * @param {Number} completed
 * @param {Number} secretKey
 * @param {Number} action
 * @returns {queryParams}
 * @returns {requestBody}
 * @returns {requestHeaders}
 */
export const setRequestDataForSelectAll = ({
  filters = {},
  search = [],
  practiceId,
  practiceIdentifier,
  isArchive,
  completed,
  secretKey,
  action = null,
}) => {
  const processFilters = (inputFilters) => {
    const filterProcessor = ({ key, value }) => {
      if (camelCase(key) === REMINDER_STATUS_OPTIONS.REMINDER_STATUS) {
        return processReminderStatus(value);
      }
      return { key, value };
    };

    return Array.isArray(inputFilters)
      ? inputFilters.flatMap(filterProcessor)
      : Object.entries(inputFilters).flatMap(([key, value]) =>
          filterProcessor({ key, value })
        );
  };

  const searchParams = search.length
    ? processFilters(search)
    : processFilters(filters);
  const requestHeaders = extractIntegrationAPIHeaders({
    practiceId,
  });

  const requestBody = {
    search: searchParams,
    practice_id: practiceId,
  };

  if (practiceIdentifier) {
    requestBody.practice_identifier = practiceIdentifier;
  }

  const queryParams = omitBy(
    {
      action,
      completed,
      archive: isArchive ? 1 : 0,
    },
    isNull
  );

  return {
    queryParams,
    requestBody,
    requestHeaders,
  };
};

/**
 * Bulk assigns action and agents to claims based on the filter parameters.
 *
 * @param {Object} filters - The filter parameters
 * @param {Number} practiceId - The practice ID
 * @param {String} practiceIdentifier - The practice identifier, if applicable
 * @param {Boolean} isArchive - Whether the action is for archiving or not
 * @param {Number} action - The action ID
 * @param {String} secretKey - The secret key
 * @param {Array<Number>} agents - The IDs of the agents to assign
 * @param {Number} completed - The completed status
 * @param {String} reason - The reason for the action, if applicable
 * @returns {Promise<Boolean>}
 */
export const bulkAssignActionAndAgents = async ({
  filters = {},
  practiceId,
  practiceIdentifier,
  isArchive,
  action = null,
  secretKey,
  agents = [],
  completed = null,
  reason = null,
}) => {
  const {
    queryParams,
    requestBody: payload,
    requestHeaders,
  } = setRequestDataForSelectAll({
    filters,
    search: [],
    practiceId,
    practiceIdentifier,
    completed,
    action,
    isArchive,
    secretKey,
  });

  if (agents) {
    payload.agents = agents;
  }

  if (reason) {
    payload.reason = reason;
  }
  const request = createPostRequest(
    endpoints.BULK_ASSIGN_AGENT_ACTION,
    payload,
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
      ...(queryParams && { params: queryParams }),
    }
  );
  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Bulk assigns completed actions to claims based on the filter parameters.
 *
 * @param {Object} filters - The filter parameters
 * @param {Number} practiceId - The practice ID
 * @param {String} practiceIdentifier - The practice identifier, if applicable
 * @param {Boolean} isArchive - Whether the action is for archiving or not
 * @param {Number} action - The action ID
 * @param {String} secretKey - The secret key
 * @param {Array<Number>} agents - The IDs of the agents to assign
 * @param {Number} completed - The completed status
 * @param {String} reason - The reason for the action, if applicable
 * @returns {Promise<Boolean>}
 */
export const bulkAssignCompletedActions = async ({
  filters = {},
  practiceId,
  practiceIdentifier,
  isArchive,
  action = null,
  secretKey,
  agents = [],
  completed = null,
  reason = null,
}) => {
  const {
    queryParams,
    requestBody: payload,
    requestHeaders,
  } = setRequestDataForSelectAll({
    filters,
    search: [],
    practiceId,
    practiceIdentifier,
    completed,
    action,
    isArchive,
    secretKey,
  });

  if (reason) {
    payload.reason = reason;
  }

  const request = createPostRequest(endpoints.BULK_COMPLETED, payload, {
    baseUrl: INTEGRATION_API_BASE_URL,
    headers: requestHeaders,
    ...(queryParams && { params: queryParams }),
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Archives/Unarchive multiple claims based on the filter parameters.
 *
 * @param {Object} [filters={}] - The filter parameters
 * @param {Number} practiceId - The practice ID
 * @param {String} secretKey - The secret key
 * @param {String} practiceIdentifier - The practice identifier, if applicable
 * @param {Boolean} isArchive - Whether the action is for archiving or not
 * @param {Number} action - The action ID
 * @param {Number} completed - The completed status
 * @returns {Promise<Boolean>}
 */
export const bulkAssignArchiveAction = async ({
  filters = {},
  practiceId,
  secretKey,
  practiceIdentifier,
  isArchive,
  action = null,
  completed = null,
}) => {
  const {
    queryParams,
    requestBody: payload,
    requestHeaders,
  } = setRequestDataForSelectAll({
    filters,
    search: [],
    practiceId,
    practiceIdentifier,
    completed,
    action,
    isArchive,
    secretKey,
  });

  const request = createPostRequest(endpoints.BULK_ARCHIVE, payload, {
    baseUrl: INTEGRATION_API_BASE_URL,
    headers: requestHeaders,
    ...(queryParams && { params: queryParams }),
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

const processFilters = (inputFilters) => {
  const filterProcessor = ({ key, value }) => {
    if (camelCase(key) === 'reminderStatus') {
      return processReminderStatus(value);
    }
    return { key, value };
  };

  return Array.isArray(inputFilters)
    ? inputFilters.map(filterProcessor).flat()
    : Object.entries(inputFilters)
        .map(([key, value]) => filterProcessor({ key, value }))
        .flat();
};

/**
 * Fetches the total count of denials queue items.
 * @param {Object} filters  - The filter parameters
 * @param {Boolean} isArchive - Whether the is archive page or not
 * @param {AbortSignal} signal - Optional signal to abort the request if needed.
 * @returns {Promise<Number>} - The total count of denials queue items.
 */
export const fetchDenialsQueueCount = async (filters, isArchive, signal) => {
  const requestBody = {
    search: processFilters(pickBy(filters, identity)),
    only_total: true,
  };
  const request = createPostRequest('denials', requestBody, {
    baseUrl: INTEGRATION_API_BASE_URL,
    params: {
      archive: isArchive ? 1 : 0,
      action: 0,
      completed: 0,
    },
  });

  const { data } = await fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse);

  return data?.total || 0;
};
