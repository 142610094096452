import { useEffect, useCallback } from 'react';

const isRefArray = (r) => {
  return 'length' in r;
};

const isTarget = (ref, event) =>
  ref && ref.current && ref.current.contains(event.target);

const trueForAny = (array, condition) => {
  return array.reduce((conditionAlreadyMet, value) => {
    return conditionAlreadyMet || condition(value);
  }, false);
};

const useClickOutside = (ref, onMouseDown) => {
  const handleMouseDown = useCallback(
    (event) => {
      if (isRefArray(ref)) {
        if (trueForAny(ref, (ref) => isTarget(ref, event))) {
          return;
        }
      } else {
        if (isTarget(ref, event)) {
          return;
        }
      }
      onMouseDown();
    },
    [onMouseDown, ref]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown]);
  return ref;
};

export default useClickOutside;
