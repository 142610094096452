import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';

import * as routes from 'constants/routes';
import { GLOBAL_SEARCH_CLAIM_ACTIONS } from 'constants/appConstants';

import searchDarkIcon from 'img/search-icon-dark.svg';
import crossCircleOutlineIcon from 'img/cross-circle-outline.svg';

import DeleteSubmission from 'components/Submissions/InProgressSubmissions/DeleteSubmission';

import { ACTION_TYPE } from '../SearchResults';

const InProgressActionsCell = (props) => {
  const {
    data,
    handleContinueSubmission,
    handleAction,
    openUhcClaimStatusWidget,
    openAvailityClaimStatusWidget,
    setIsPerformingAction,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [deleteSubmissionPopup, setDeleteSubmissionPopup] = useState({
    isOpen: false,
    appeal: null,
    source: null,
  });

  const resetDeleteSubmissionPopup = () => {
    setDeleteSubmissionPopup({
      isOpen: false,
      appeal: null,
      source: null,
    });
  };

  return (
    <td>
      <div className="global-search-v2__search-result-table__data-td--action">
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleContinueSubmission(data?.id);
          }}
          className="ap-button ap-button--secondary"
        >
          <span>Continue</span>
          <IoIosArrowRoundForward size="20" />
        </button>
        <button
          onClick={async (e) => {
            e.stopPropagation();
            setDeleteSubmissionPopup({
              isOpen: true,
              appeal: {
                ...data,
                claimNumber: data?.claimControlNumber,
              },
            });
          }}
          data-tip="Delete"
          className="global-search-v2__search-result-table__data-td--action-btn mr-4"
        >
          <img src={crossCircleOutlineIcon} alt="delete" />
        </button>
        {(data?.uhcClaimStatusSearchData ||
          data?.availityClaimStatusSearchData) && (
          <div className="d-flex justify-content-flex-end mr-8">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (data?.availityClaimStatusSearchData) {
                  return openAvailityClaimStatusWidget(
                    data?.availityClaimStatusSearchData
                  );
                }
                openUhcClaimStatusWidget(data?.uhcClaimStatusSearchData);
              }}
              data-tip={`Claim Status Check via ${
                data?.availityClaimStatusSearchData ? 'Availity' : 'UHC'
              }`}
              className="global-search-v2__search-result-table__data-td--action-btn"
            >
              <img src={searchDarkIcon} alt="search claim" />
            </button>
          </div>
        )}
      </div>
      {deleteSubmissionPopup.isOpen && (
        <DeleteSubmission
          appeal={deleteSubmissionPopup.appeal}
          onClosePressed={resetDeleteSubmissionPopup}
          onDeleteSubmissionSuccess={async () => {
            resetDeleteSubmissionPopup();
            setIsPerformingAction && setIsPerformingAction(true);
            handleAction(
              ACTION_TYPE.DELETE,
              deleteSubmissionPopup?.appeal?.id,
              {
                claimControlNumber: data?.claimControlNumber,
              }
            );
            const validPaths = [
              routes.SUBMISSIONS_IN_PROGRESS,
              routes.DENIED_QUEUE,
              routes.DENIED_QUEUE_LOGS,
            ];

            if (validPaths.includes(location.pathname)) {
              history.push({
                pathname: location.pathname,
                search: `?action=${GLOBAL_SEARCH_CLAIM_ACTIONS.DELETE}&claimId=${data?.id}`,
              });
            }
          }}
          inCludeOutSideClickWrapper={false}
        />
      )}
    </td>
  );
};

InProgressActionsCell.propTypes = {
  data: PropTypes.object,
  handleContinueSubmission: PropTypes.func,
  handleAction: PropTypes.func,
};

export default InProgressActionsCell;
