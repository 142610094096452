import moment from 'moment';
import PropTypes from 'prop-types';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline, MdSave } from 'react-icons/md';
import React, { useEffect, useCallback, useState } from 'react';
import './style.css';

import CurrencyInput from 'components/common/input/CurrencyInput';
import ActionButton from 'components/common/actionButton/ActionButton';
import ReduxSingleDatePicker from 'components/common/datepicker/ReduxSingleDatePicker';

import calendar from 'img/calendar-icon.svg';

import { floatNormalizer } from 'helpers/money';
import { VALIDATION_DATE_FORMAT } from 'helpers/dateFormats';

import { Field, reset, reduxForm } from 'redux-form/immutable';
import Select from 'components/common/select/Select';

let AddOrEditFeeScheduleForm = (props) => {
  const {
    onSubmit,
    handleSubmit,
    cptCodes,
    cptModifiers,
    initialize,
    initialValues,
    isCptCodesLoading,
    isCptModifiersLoading,
    closeAddFeeScheduleForm,
  } = props;

  const getSelectedCptCodeOption = useCallback(
    (cptCode) => cptCodes.find((option) => option.value === cptCode),
    [cptCodes]
  );

  const getSelectedCptModifiersOption = (cptModifiers) => {
    const modifierFirst = cptModifiers?.cptModifierFirst || 'N/A';
    const modifierSecond = cptModifiers?.cptModifierSecond || 'N/A';
    return {
      modifierFirst: { label: modifierFirst, value: modifierFirst },
      modifierSecond: { label: modifierSecond, value: modifierSecond },
    };
  };

  const [selectedModifiers, setSelectedModifiers] = useState({
    modifierFirst: null,
    modifierSecond: null,
  });
  const [filteredModifiers, setFilteredModifiers] = useState(cptModifiers);

  const handleModifierChange = (modifierName, selectedOption) => {
    setSelectedModifiers((prevModifiers) => ({
      ...prevModifiers,
      [modifierName]: selectedOption,
    }));
  };

  useEffect(() => {
    const selectedValues = [
      selectedModifiers.modifierFirst?.value,
      selectedModifiers.modifierSecond?.value,
    ].filter(Boolean);

    const newFilteredModifiers = cptModifiers.filter(
      (modifier) => !selectedValues.includes(modifier.value)
    );

    setFilteredModifiers(newFilteredModifiers);
  }, [selectedModifiers, cptModifiers]);

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      const selectedCptCode = getSelectedCptCodeOption(values.cptCode);
      const cptModifiers = values?.cptModifiers || {};
      const selectedCptModifiers = getSelectedCptModifiersOption(cptModifiers);

      setSelectedModifiers({
        modifierFirst: selectedCptModifiers.modifierFirst,
        modifierSecond: selectedCptModifiers.modifierSecond,
      });

      initialize({
        ...values,
        cptCode: selectedCptCode,
        allowedAmount: floatNormalizer(values.allowedAmount),
        cptModifierFirst: selectedCptModifiers.modifierFirst,
        cptModifierSecond: selectedCptModifiers.modifierSecond,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <>
      <tr className="add-fee-schedule-form fee-schedule-table__tr">
        <td className="fee-schedule-table__td">
          <Field
            component={Select}
            name="cptCode"
            placeholder="Select Procedure"
            options={cptCodes}
            isLoading={isCptCodesLoading}
            classNamePrefix="fee-schedule-select"
            reactSelectProps={{
              maxMenuHeight: 150,
              menuPlacement: 'bottom',
            }}
          />
        </td>
        <td className="fee-schedule-table__td fee-schedule-table__th__allowed-amount">
          <div className="position-relative">
            <Field
              component={CurrencyInput}
              name="allowedAmount"
              normalize={floatNormalizer}
              placeholder="$00.00"
            />
            <MdInfoOutline
              size="18"
              className="ml-8 fee-schedule-table__info-icon"
              data-tip="Enter expected reimbursement amount per payer contract"
            />
            <ReactTooltip effect="solid" place="top" />
          </div>
        </td>
        <td className="fee-schedule-table__td fee-schedule-table__th__modifier">
          <Field
            component={Select}
            name="cptModifierFirst"
            placeholder="Select Modifier 1"
            options={filteredModifiers}
            isLoading={isCptModifiersLoading}
            classNamePrefix="fee-schedule-select"
            onChange={(selectedOption) =>
              handleModifierChange('modifierFirst', selectedOption)
            }
            reactSelectProps={{
              maxMenuHeight: 150,
              menuPlacement: 'bottom',
              isClearable: true,
            }}
          />
        </td>
        <td className="fee-schedule-table__td fee-schedule-table__th__modifier">
          <Field
            component={Select}
            name="cptModifierSecond"
            placeholder="Select Modifier 2"
            options={filteredModifiers}
            isLoading={isCptModifiersLoading}
            classNamePrefix="fee-schedule-select"
            onChange={(selectedOption) =>
              handleModifierChange('modifierSecond', selectedOption)
            }
            reactSelectProps={{
              maxMenuHeight: 150,
              menuPlacement: 'bottom',
              isClearable: true,
            }}
          />
        </td>
        <td className="fee-schedule-table__td">
          <Field
            component={ReduxSingleDatePicker}
            name="expirationDate"
            placeholder="MM/DD/YYYY"
            block={true}
            displayFormat={VALIDATION_DATE_FORMAT}
            verticalSpacing={10}
            isOutsideRange={(d) => d.isBefore(moment(), 'day')}
            customInputIcon={
              <img className="form-icon" src={calendar} alt="Calendar" />
            }
          />
        </td>
        <td colSpan={2} className="fee-schedule-table__td">
          <div className="d-flex">
            <ActionButton
              Icon={MdSave}
              className="ap-button--action-delete"
              dataTip="Save"
              onClick={handleSubmit(onSubmit)}
            />

            <ActionButton
              Icon={BiX}
              className="ml-16 ap-button--action-delete"
              dataTip="Cancel"
              onClick={(e) => {
                e.preventDefault();
                closeAddFeeScheduleForm();
              }}
              iconClassName="appeal__action--appeal"
            />
          </div>
        </td>
      </tr>
      <tr className="spacer fee-schedule-table__tr">
        <td colSpan="100" className="fee-schedule-table__td"></td>
      </tr>
    </>
  );
};

AddOrEditFeeScheduleForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  cptCodes: PropTypes.array,
  cptModifiers: PropTypes.array,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
  isCptCodesLoading: PropTypes.bool,
  isCptModifiersLoading: PropTypes.bool,
  closeAddFeeScheduleForm: PropTypes.func,
};

AddOrEditFeeScheduleForm = reduxForm({
  form: 'addOrEditFeeScheduleForm',
  enableReinitialize: false,
})(AddOrEditFeeScheduleForm);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: () => dispatch(reset('addOrEditFeeScheduleForm')),
  };
};

AddOrEditFeeScheduleForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrEditFeeScheduleForm);

export default AddOrEditFeeScheduleForm;
