import { Map } from 'immutable';

import {
  SET_PENDING_LETTER_SWITCH,
  CLEAR_PENDING_LETTER_SWITCH,
  SET_HAS_UNSAVED_LETTER_CHANGES,
} from 'redux/actions/actionTypes';

const initialState = Map({
  pendingLetterSwitch: null,
  hasUnsavedLetterChanges: false,
});

export const appealLettersStore = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PENDING_LETTER_SWITCH:
      return state.set(
        'pendingLetterSwitch',
        Map({
          letterId: action.payload.letterId,
          templateId: action.payload.templateId,
        })
      );

    case CLEAR_PENDING_LETTER_SWITCH:
      return state.set('pendingLetterSwitch', null);

    case SET_HAS_UNSAVED_LETTER_CHANGES:
      return state.set('hasUnsavedLetterChanges', action.payload);

    default:
      return state;
  }
};

export const getPendingLetterSwitch = (state) => {
  return state.getIn(['appealLettersStore', 'pendingLetterSwitch']);
};

export const getHasUnsavedLetterChanges = (state) => {
  return state.getIn(['appealLettersStore', 'hasUnsavedLetterChanges']);
};
