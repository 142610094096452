export const RESULTS_DATA_OFFSET = 10;

export const TAB_NAME_CONFIG = {
  WORK_LIST: 'worklists',
  SUBMISSIONS: 'submissions',
  ARCHIVED: 'archived',
  UNFLAGGED: 'unflaggedClaims',
  DENIALS: 'denials',
  PENDING_ACTIONS: 'pendingActions',
  COMPLETED_ACTIONS: 'completedActions',
  INPROGRESS_SUBMISSIONS: 'inProgressSubmissions',
  SUBMITTED_SUBMISSIONS: 'submittedSubmissions',
  SUCCESSFUL_SUBMISSIONS: 'successfulSubmissions',
  DENIED_SUBMISSIONS: 'deniedSubmissions',
  ARCHIVE_DENIALS: 'archivedDenials',
  ARCHIVE_PENDING_ACTIONS: 'archivedPendingActions',
  ARCHIVE_COMPLETED_ACTIONS: 'archivedCompletedActions',
};
export const SUCCESSFUL_DENIED_OPTIONS = {
  [TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS]: 'Successful',
  [TAB_NAME_CONFIG.DENIED_SUBMISSIONS]: 'Denied',
};

export const globalSearchTabConfig = [
  {
    key: TAB_NAME_CONFIG.WORK_LIST,
    label: 'Worklists',
    tabs: [
      {
        key: TAB_NAME_CONFIG.DENIALS,
        label: 'Denials',
      },
      {
        key: TAB_NAME_CONFIG.PENDING_ACTIONS,
        label: 'Pending Actions',
      },
      {
        key: TAB_NAME_CONFIG.COMPLETED_ACTIONS,
        label: 'Completed Actions',
      },
    ],
  },
  {
    key: TAB_NAME_CONFIG.SUBMISSIONS,
    label: 'Submissions',
    tabs: [
      {
        key: TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS,
        label: 'In Progress',
      },
      {
        key: TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS,
        label: 'Submitted',
      },
      {
        key: TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS,
        label: 'Successful',
      },
      {
        key: TAB_NAME_CONFIG.DENIED_SUBMISSIONS,
        label: 'Denied',
      },
    ],
  },
  {
    key: TAB_NAME_CONFIG.ARCHIVED,
    label: 'Archives',
    tabs: [
      {
        key: TAB_NAME_CONFIG.ARCHIVE_DENIALS,
        label: 'Denials Queue',
      },
      {
        key: TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS,
        label: 'Pending Actions',
      },
      {
        key: TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS,
        label: 'Completed Actions',
      },
    ],
  },
  {
    key: TAB_NAME_CONFIG.UNFLAGGED,
    label: 'Unflagged Claims',
  },
];

const workListCommonColumns = [
  {
    name: 'Name',
    accessor: 'patientName',
    className: 'global-search-v2__search-result-table__patient-cell',
  },
  {
    name: 'Claim Control Number',
    accessor: 'claimControlNumber',
  },
  {
    name: 'Service Date',
    accessor: 'serviceDate',
  },
  {
    name: 'EOB Date',
    accessor: 'eobDate',
  },
];

const denialColumns = [
  ...workListCommonColumns,
  {
    name: 'Action',
    accessor: 'action',
  },
];

const actionLogColumns = [
  ...workListCommonColumns,
  {
    name: 'Assigned Action',
    accessor: 'actionReasonName',
    className: 'global-search-v2__search-result-table__action-reason-cell',
  },
  {
    name: 'Action',
    accessor: 'action',
  },
];

const inProgressColumns = [
  {
    name: 'Name',
    accessor: 'patientName',
  },
  {
    name: 'Claim Control Number',
    accessor: 'claimControlNumber',
  },
  {
    name: 'Type',
    accessor: 'type',
  },
  {
    name: 'Service Date',
    accessor: 'serviceDate',
  },
  {
    name: 'EOB Date',
    accessor: 'eobDate',
  },
  {
    name: 'Action',
    accessor: 'action',
  },
];

const submittedColumns = [
  {
    name: 'Name',
    accessor: 'patientName',
    className: 'global-search-v2__search-result-table__patient-cell',
  },
  {
    name: 'Claim Control Number',
    accessor: 'claimControlNumber',
  },
  {
    name: 'Type',
    accessor: 'type',
  },
  {
    name: 'Submitted On',
    accessor: 'submittedAt',
  },
  {
    name: 'Status',
    accessor: 'statusMethod',
  },
  {
    name: 'Action',
    accessor: 'action',
  },
];

const successfulSubmissions = [
  {
    name: 'Name',
    accessor: 'patientName',
    className: 'global-search-v2__search-result-table__patient-cell',
  },
  {
    name: 'Claim Control Number',
    accessor: 'claimControlNumber',
  },
  {
    name: 'Type',
    accessor: 'type',
  },
  {
    name: 'Submitted On',
    accessor: 'submittedAt',
  },
  {
    name: 'Action',
    accessor: 'action',
  },
];

export const columnsConfig = {
  [TAB_NAME_CONFIG.WORK_LIST]: {
    [TAB_NAME_CONFIG.DENIALS]: denialColumns,
    [TAB_NAME_CONFIG.PENDING_ACTIONS]: actionLogColumns,
    [TAB_NAME_CONFIG.COMPLETED_ACTIONS]: actionLogColumns,
  },
  [TAB_NAME_CONFIG.SUBMISSIONS]: {
    [TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS]: inProgressColumns,
    [TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS]: submittedColumns,
    [TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS]: successfulSubmissions,
    [TAB_NAME_CONFIG.DENIED_SUBMISSIONS]: successfulSubmissions,
  },
  [TAB_NAME_CONFIG.ARCHIVED]: {
    [TAB_NAME_CONFIG.ARCHIVE_DENIALS]: denialColumns,
    [TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS]: actionLogColumns,
    [TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS]: actionLogColumns,
  },
  [TAB_NAME_CONFIG.UNFLAGGED]: {
    [TAB_NAME_CONFIG.UNFLAGGED]: denialColumns,
  },
};
