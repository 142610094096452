import classnames from 'classnames';
import { isNil, isEqual } from 'lodash';
import React, { useState, useCallback } from 'react';

import AgentList from './AgentList';
import ActionList from './ActionList';
import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';
import { ACTION_TYPE } from 'components/GlobalSearchV2/SearchResults';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import { canAssignAgent } from 'Auth/AuthUtils';
import { MARK_COMPLETE, MARK_INCOMPLETE } from './DenialQueueContainer';

import DenialsAPI from 'API/DenialsAPI';

const RenderActionList = ({ setClaimAssignment }) => {
  const setAction = useCallback(
    (action) =>
      setClaimAssignment((prevState) => ({
        ...prevState,
        action,
      })),
    [setClaimAssignment]
  );
  return (
    <div className="bulk-delete-reason-container">
      <ActionList setAction={setAction} />
    </div>
  );
};

const AssignClaimPopup = (props) => {
  const {
    markComplete,
    userInfo,
    selectedClaims,
    isAppealioUsersFetched,
    resetClaimAssignmentPopup,
    handleApiErrors,
    handleAction,
    setIsPerformingAction,
  } = props;
  const [claimAssignment, setClaimAssignment] = useState({
    action: null,
    selectedAgents: [],
  });
  const allowAgentAssignment = canAssignAgent(userInfo);
  const title = allowAgentAssignment
    ? `Assign Action and/or Agent(s)`
    : ` Assign Action`;

  const isActionEmpty = isNil(claimAssignment?.action);

  const getCompletedByOption = [
    {
      label: userInfo.firstName + ' ' + userInfo.lastName,
      value: userInfo.username,
    },
  ];

  const assignAgents = async (claims, agents) => {
    try {
      const requestBody = {
        ids: claims,
        agents,
      };
      setIsPerformingAction && setIsPerformingAction(true);
      await DenialsAPI.assignAgents(requestBody);

      toast.success({
        title: 'Success',
        message: 'Agent(s) assigned successfully',
      });
      handleAction &&
        handleAction(ACTION_TYPE.ASSIGN_TO_AGENT, {
          id: requestBody?.ids?.[0] + agents?.length,
        });
    } catch (error) {
      handleApiErrors(error, false);
    }
  };

  /**
   * Handles close denied claim.
   *
   * @param {Object} claim
   * @param {Boolean} shouldRemoveFromDenialsFlyover
   */
  const handleCloseDeniedClaim = async (
    selectedClaims,
    markComplete = MARK_INCOMPLETE,
    action = ''
  ) => {
    try {
      const requestBody = {
        claims: selectedClaims.map((claim) => {
          return {
            claim: claim.claim,
            claimControlNumber: claim.claimControlNumber,
            id: claim.id,
          };
        }),
        action: {
          reason: action,
          complete: markComplete ? MARK_COMPLETE : MARK_INCOMPLETE,
        },
      };
      setIsPerformingAction && setIsPerformingAction(true);

      await DenialsAPI.closeDeniedClaim(requestBody);

      toast.success({
        title: 'Success',
        message: markComplete
          ? 'Action completed successfully.'
          : 'Action successfully assigned.',
      });
      handleAction &&
        handleAction(
          markComplete === MARK_COMPLETE
            ? ACTION_TYPE.MARK_AS_COMPLETE
            : ACTION_TYPE.MARK_AS_PENDING,
          {
            id: requestBody?.claims?.[0]?.id,
          }
        );
    } catch (error) {
      this.handleApiErrors(error, false);
    }
  };

  const handleAssignButtonClick = () => {
    const claimIds = selectedClaims.map(({ id }) => id);
    const agentValues = claimAssignment?.selectedAgents
      .filter(({ value }) => value)
      .map(({ value }) => value);
    const actionType = markComplete ? MARK_COMPLETE : MARK_INCOMPLETE;

    // Handle Bulk Update logic
    try {
      if (!markComplete) {
        assignAgents(claimIds, agentValues);
      }
      if (!isActionEmpty) {
        handleCloseDeniedClaim(
          selectedClaims,
          actionType,
          claimAssignment?.action
        );
      }
    } catch (error) {
      handleApiErrors(error, false);
    }

    resetClaimAssignmentPopup();
  };

  const disableHandleAssignButton = markComplete
    ? isActionEmpty
    : isActionEmpty &&
      selectedClaims.length === 1 &&
      isEqual(
        claimAssignment?.selectedAgents.map(({ value }) => value),
        selectedClaims[0].agentsUsername
      );

  return (
    <ScreenDimmerComponent>
      <div className={'appealio-popup appealio-action-popup'}>
        <div className="appealio-action-popup__header">
          <h4 className="appealio-action-popup__title">
            {markComplete ? 'Mark as Completed' : title}
          </h4>
        </div>
        <div className="appealio-action-popup__content">
          <p className="appealio-action-popup__details">
            You have selected
            <span className="fw-bold"> {selectedClaims.length} </span>
            Claim(s).
          </p>
          <p className="fw-bold mb-2">
            {markComplete ? 'What action was completed?' : 'Select action:'}
          </p>
          <RenderActionList setClaimAssignment={setClaimAssignment} />

          {markComplete ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="fw-bold mb-2 mt-12">Mark as completed by:</p>
              <Select
                options={getCompletedByOption}
                value={getCompletedByOption}
                isMulti
                hideMultiValueRemove
                isDisabled
                className="mb-16"
              />
            </div>
          ) : (
            allowAgentAssignment && (
              <>
                <p className="fw-bold mb-2 mt-12">
                  Select agent(s) for assignment.
                </p>
                <div className="bulk-delete-reason-container mb-16">
                  <AgentList
                    selectedClaims={selectedClaims}
                    setAgent={setClaimAssignment}
                    assignAgentDialog={claimAssignment}
                    isFetchingAgents={!isAppealioUsersFetched}
                  />
                </div>
              </>
            )
          )}

          <button
            className={classnames(
              'ap-button mt-12 ap-button--secondary ap-button--block',
              {
                'ap-button--disabled': disableHandleAssignButton,
              }
            )}
            onClick={(e) => {
              e.stopPropagation();
              handleAssignButtonClick();
            }}
            disabled={disableHandleAssignButton}
          >
            {markComplete ? 'Mark as completed' : 'Assign'}
          </button>
          <button
            className="ap-button ap-button--tertiary-outline ap-button--block"
            onClick={(e) => {
              e.stopPropagation();
              resetClaimAssignmentPopup();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

export default AssignClaimPopup;
