import React from 'react';
import PropTypes from 'prop-types';

import binIcon from 'img/bin-blue.svg';
import addCircleIcon from 'img/add-circle.svg';
import assignIcon from 'img/assign-icon-dark.svg';
import searchDarkIcon from 'img/search-icon-dark.svg';
import completeIconBlue from 'img/complete-icon-blue.svg';

const DenialActionsCell = (props) => {
  const {
    data,
    handleManualClaim,
    handleAppealDeniedClaim,
    handleArchive,
    isAuthorizedUserForArchive,
    handleAssignClaims,
    openUhcClaimStatusWidget,
    openAvailityClaimStatusWidget,
  } = props;
  const { id } = data;

  const handleAppealRecordClick = async (e, isMedicalRecord = false) => {
    e.stopPropagation();
    e.preventDefault();
    const params = {
      ...(isMedicalRecord && { isMedicalRecord }),
    };
    return data?.manualClaimId
      ? await handleManualClaim(data, params)
      : await handleAppealDeniedClaim(data, params);
  };

  return (
    <td>
      <div className="global-search-v2__search-result-table__data-td--action">
        <button
          onClick={(e) => handleAppealRecordClick(e, false)}
          className="ap-button ap-button--secondary"
        >
          <img src={addCircleIcon} alt="add-appeal" />
          <span> Appeal</span>
        </button>
        <button
          onClick={(e) => handleAppealRecordClick(e, true)}
          className="ap-button ap-button--secondary"
        >
          <img src={addCircleIcon} alt="add-record" />
          <span> Record</span>
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleAssignClaims([data]);
          }}
          data-tip="Assign"
          className="global-search-v2__search-result-table__data-td--action-btn mr-4"
        >
          <img src={assignIcon} alt="Assign" />
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleAssignClaims([data], true);
          }}
          data-tip="Mark as Complete"
          className="global-search-v2__search-result-table__data-td--action-btn mr-4"
        >
          <img src={completeIconBlue} alt="circle-check" />
        </button>
        {isAuthorizedUserForArchive && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleArchive(id);
            }}
            data-tip="Archive"
            className="global-search-v2__search-result-table__data-td--action-btn"
          >
            <img src={binIcon} alt="bin" />
          </button>
        )}
        {(data?.uhcClaimStatusSearchData ||
          data?.availityClaimStatusSearchData) && (
          <div className="d-flex justify-content-flex-end ml-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (data?.availityClaimStatusSearchData) {
                  return openAvailityClaimStatusWidget(
                    data?.availityClaimStatusSearchData
                  );
                }
                openUhcClaimStatusWidget(data?.uhcClaimStatusSearchData);
              }}
              data-tip={`Claim Status Check via ${
                data?.availityClaimStatusSearchData ? 'Availity' : 'UHC'
              }`}
              className="global-search-v2__search-result-table__data-td--action-btn"
            >
              <img src={searchDarkIcon} alt="search claim" />
            </button>
          </div>
        )}
      </div>
    </td>
  );
};

DenialActionsCell.propTypes = {
  data: PropTypes.object,
  handleAction: PropTypes.func,
  handleManualClaim: PropTypes.func,
  handleAppealDeniedClaim: PropTypes.func,
};

export default DenialActionsCell;
