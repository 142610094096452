import {
  handleErrors,
  BASE_API_URL,
  createRequest,
  handleJSONParse,
  createGetRequest,
} from './Config';

import * as endpoints from 'constants/endpoints';

/**
 * Fetches all favorite filters for the current user
 * @param {string} filterPage - The page identifier for filtering
 * @returns {Promise} Resolves to array of favorite filters
 */
export const fetchFavoriteFilters = (filterPage) => {
  const url = endpoints.FAVOURITE_FILTERS;
  const request = createGetRequest(
    url,
    {
      filter_page: filterPage,
    },
    BASE_API_URL
  );

  return (
    fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => {
        return res?.data?.map((data) => ({
          value: data.id,
          label: data.name,
          filterJson: data.filter_json,
        }));
      }) || []
  );
};

/**
 * Creates a new favorite filter
 * @param {Object} filterData - The filter data to create
 * @param {string} filterData.name - Name of the filter
 * @param {string} filterData.filterPage - Page identifier
 * @param {Object} filterData.filterJson - Filter criteria
 * @returns {Promise} Resolves to created filter object
 */
export const createFavoriteFilter = (filterData) => {
  const url = endpoints.FAVOURITE_FILTERS;
  const request = createRequest(
    url,
    {
      name: filterData.name,
      filter_page: filterData.filterPage,
      filter_json: filterData.filterJson,
    },
    'POST'
  );

  return fetch(request).then(handleErrors).then(handleJSONParse);
};

/**
 * Updates an existing favorite filter
 * @param {number} filterId - The ID of the filter to update
 * @param {Object} filterData - The updated filter data
 * @param {string} [filterData.name] - Name of the filter
 * @param {string} [filterData.filterPage] - Page identifier
 * @param {Object} [filterData.filterJson] - Filter criteria
 * @returns {Promise} Resolves to updated filter object
 */
export const updateFavoriteFilter = (filterId, filterData) => {
  const url = `${endpoints.FAVOURITE_FILTERS}${filterId}/`;
  const request = createRequest(
    url,
    {
      ...(filterData.name && { name: filterData.name }),
      ...(filterData.filterPage && { filter_page: filterData.filterPage }),
      ...(filterData.filterJson && { filter_json: filterData.filterJson }),
    },
    {
      method: 'PUT',
    }
  );

  return fetch(request).then(handleErrors).then(handleJSONParse);
};

/**
 * Deletes a favorite filter
 * @param {number} filterId - The ID of the filter to delete
 * @returns {Promise} Resolves when deletion is complete
 */
export const deleteFavoriteFilter = (filterId) => {
  const url = `${endpoints.FAVOURITE_FILTERS}${filterId}/`;
  const request = createRequest(
    url,
    {},
    {
      method: 'DELETE',
    }
  );

  return fetch(request).then(handleErrors);
};
