export const LOGIN_AUTH = 'LOGIN_AUTH';

export const FORGOT_PASSWORD_SEND_EMAIL = 'FORGOT_PASSWORD_SEND_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SEND_HELP_INFO = 'SEND_HELP_INFO';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const GET_DEADLINE_DATE = 'GET_DEADLINE_DATE';
export const SAVE_DEADLINE_DATE = 'SAVE_DEADLINE_DATE';
export const CREATE_APPEAL_SEND_PATIENT_INFO =
  'CREATE_APPEAL_SEND_PATIENT_INFO';
export const CREATE_DENIAL_INFORMATION = 'CREATE_DENIAL_INFORMATION';
export const SET_APPEAL_DATA = 'SET_APPEAL_DATA';
export const CLEAR_CURRENT_APPEAL_DATA = 'CLEAR_CURRENT_APPEAL_DATA';
export const SET_APPEAL_PREFILL_DATA = 'SET_APPEAL_PREFILL_DATA';
export const SET_BILLING_AGENT = 'SET_BILLING_AGENT';
export const REMOVE_DOCUMENT_FROM_APPEAL = 'REMOVE_DOCUMENT_FROM_APPEAL';
export const REMOVE_LETTER_FROM_APPEAL = 'REMOVE_LETTER_FROM_APPEAL'; // Letter & Form (html/pdf)

export const GET_APPEAL_BY_ID = 'GET_APPEAL_BY_ID';

export const ADD_APPEAL_LETTER = 'ADD_APPEAL_LETTER';

export const CREATE_REAPPEAL = 'CREATE_REAPPEAL';

export const CREATE_FINAL_APPEAL = 'CREATE_FINAL_APPEAL';

export const CLEAR_AUTH_TOKEN = 'CLEAR_AUTH_TOKEN';

export const LOGIN_GET_USER_INFO = 'LOGIN_GET_USER_INFO';
export const LOGIN_SET_AUTOLOGIN_INFO = 'LOGIN_SET_AUTOLOGIN_INFO';

export const LOGOUT = 'LOGOUT';

export const OPEN_RPA_WIDGET = 'OPEN_RPA_WIDGET';
export const CLOSE_RPA_WIDGET = 'CLOSE_RPA_WIDGET';
export const SET_RELATED_PRACTICES = 'SET_RELATED_PRACTICES';

export const CREATE_PACKAGE_SUBMISSION = 'CREATE_PACKAGE_SUBMISSION';
export const SET_STANDALONE_SUBMISSION_ID = 'SET_STANDALONE_SUBMISSION_ID';
export const SET_SUBMIT_CONFIRM_PREFILL_DATA =
  'SET_SUBMIT_CONFIRM_PREFILL_DATA';

export const SET_TOTAL_DOCUMENTS_PAGE_NUMBERS =
  'SET_TOTAL_DOCUMENTS_PAGE_NUMBERS';
export const SET_DOCUMENTS_UPLOAD_FOR_STANDALONE =
  'SET_DOCUMENTS_UPLOAD_FOR_STANDALONE';

export const OPEN_UHC_CLAIM_STATUS_WIDGET = 'OPEN_UHC_CLAIM_STATUS_WIDGET';
export const CLOSE_UHC_CLAIM_STATUS_WIDGET = 'CLOSE_UHC_CLAIM_STATUS_WIDGET';

export const OPEN_AVAILITY_CLAIM_STATUS_WIDGET =
  'OPEN_AVAILITY_CLAIM_STATUS_WIDGET';
export const CLOSE_AVAILITY_CLAIM_STATUS_WIDGET =
  'CLOSE_AVAILITY_CLAIM_STATUS_WIDGET';

export const SET_INCLUDE_EOB_DOCUMENT = 'SET_INCLUDE_EOB_DOCUMENT';
export const SET_INCLUDE_CMS_1500_DOCUMENT = 'SET_INCLUDE_CMS_1500_DOCUMENT';

export const FETCH_SUBMISSION_PREVIEW_START = 'FETCH_SUBMISSION_PREVIEW_START';
export const FETCH_SUBMISSION_PREVIEW_SUCCESS =
  'FETCH_SUBMISSION_PREVIEW_SUCCESS';
export const FETCH_SUBMISSION_PREVIEW_FAILURE =
  'FETCH_SUBMISSION_PREVIEW_FAILURE';
export const FETCH_SUBMISSION_PREVIEW_CANCEL =
  'FETCH_SUBMISSION_PREVIEW_CANCEL';
export const RESET_SUBMISSION_PREVIEW = 'RESET_SUBMISSION_PREVIEW';

export const FETCH_SOP_REQUEST = 'FETCH_SOP_REQUEST';
export const FETCH_SOP_SUCCESS = 'FETCH_SOP_SUCCESS';
export const FETCH_SOP_FAILURE = 'FETCH_SOP_FAILURE';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const VIEW_ALL_NOTIFICATIONS = 'VIEW_ALL_NOTIFICATIONS';
export const CLICK_NOTIFICATION = 'CLICK_NOTIFICATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const RESET_LATEST_NOTIFICATIONS = 'RESET_LATEST_NOTIFICATIONS';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const RESET_SHIPPING_ADDRESS = 'RESET_SHIPPING_ADDRESS';

export const OPEN_BATCH_UPLOAD_WIDGET = 'OPEN_BATCH_UPLOAD_WIDGET';
export const MINIMIZE_BATCH_UPLOAD_WIDGET = 'MINIMIZE_BATCH_UPLOAD_WIDGET';
export const MAXIMIZE_BATCH_UPLOAD_WIDGET = 'MAXIMIZE_BATCH_UPLOAD_WIDGET';
export const CLOSE_BATCH_UPLOAD_WIDGET = 'CLOSE_BATCH_UPLOAD_WIDGET';

export const SET_PENDING_LETTER_SWITCH = 'SET_PENDING_LETTER_SWITCH';
export const CLEAR_PENDING_LETTER_SWITCH = 'CLEAR_PENDING_LETTER_SWITCH';
export const SET_HAS_UNSAVED_LETTER_CHANGES = 'SET_HAS_UNSAVED_LETTER_CHANGES';
