import React from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { GrUserManager } from 'react-icons/gr';

import DenialActionsCell from './DenialActionsCell';
import SubmittedActionsCell from './SubmittedActionsCell';
import InProgressActionsCell from './InProgressActionsCell';
import SuccessfulDeniedActionsCell from './SuccessfulDeniedActionsCell';

import EmptyTableMessage from 'components/common/table/EmptyTableMessage';

import './style.css';

import { getFormattedDate } from 'helpers/dateFormats';
import { columnsConfig, TAB_NAME_CONFIG, RESULTS_DATA_OFFSET } from '../config';

import binIcon from 'img/bin-blue.svg';
import unArchiveIcon from 'img/bin-white.svg';
import addCircleIcon from 'img/add-circle.svg';
import searchDarkIcon from 'img/search-icon-dark.svg';
import checkCircleWhiteIcon from 'img/check-white-circle.svg';

const GlobalSearchTable = (props) => {
  const {
    activeTab,
    activeParentTab,
    data,
    handleAction,
    handleRedirect,
    handleManualClaim,
    handleAppealDeniedClaim,
    handleCompleteClaim,
    handleArchive,
    handleUnArchive,
    handleMarkAsComplete,
    isAuthorizedUserForArchive,
    handleAssignClaims,
    userInfo,
    openUhcClaimStatusWidget,
    openAvailityClaimStatusWidget,
    setRecordResponseFlyover,
    setIsPerformingAction,
    handleAddClaimsToDenialsQueue,
  } = props;
  const columns = columnsConfig?.[activeParentTab]?.[activeTab] || [];
  const columnsData = data?.[activeParentTab]?.[activeTab]?.data || [];
  const columnsTotal = data?.[activeParentTab]?.[activeTab]?.total || 0;

  if (columnsData?.length === 0) {
    return (
      <div className="global-search-v2__search-result-table-container">
        <div className="global-search-v2__search-result-table-empty-workListDenialActions">
          <EmptyTableMessage />
        </div>
      </div>
    );
  }
  const BuildArrayCell = ({ value: array, isLink = false }) => {
    if (isEmpty(array)) {
      const handleClick = (e) => {
        if (!isLink) return;

        e.preventDefault();
        e.stopPropagation();
      };

      return (
        <div onClick={handleClick} className="appealio-table__custom-cell">
          <span>N/A</span>
        </div>
      );
    }
    const firstArrayItem = array[0];
    const arrayLength = array.length;

    const dataToRender =
      arrayLength === 1
        ? firstArrayItem
        : `${firstArrayItem} +${arrayLength - 1}`;

    const dataTip = array.length === 1 ? array : array.join(', ');

    return (
      <div
        className={classnames('appealio-table__custom-cell', {
          'appealio-table__custom-cell--is-link': isLink,
        })}
      >
        <span data-for="array-tooltip" data-tip={dataTip}>
          {dataToRender}
        </span>
      </div>
    );
  };

  const BuildDateArrayCell = ({ value: array }) => {
    if (isEmpty(array) || array?.every((item) => item === '--')) {
      return <div className="appealio-table__custom-cell">N/A</div>;
    }
    const formattedDate = array?.map((date) => getFormattedDate(date));

    return <BuildArrayCell value={formattedDate} />;
  };

  const handleRowClick = (data) => {
    const claimFields = ['claimControlNumber', 'claimNumber'];

    const validClaim = claimFields
      .filter((field) => field in data)
      .map((field) => data[field])
      .find((value) => value && value !== '--');

    const searchQuery = validClaim || data?.patientName || '';

    handleRedirect(searchQuery);
  };

  const PendingActionsCell = (data) => {
    const { id, actionReasonName, mappedAppealId: appealId } = data;

    if (['Appeal', 'Record'].includes(actionReasonName)) {
      return (
        <td>
          <div className="global-search-v2__search-result-table__data-td--action">
            {appealId && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleCompleteClaim(appealId);
                }}
                className="ap-button ap-button--secondary"
              >
                <img src={checkCircleWhiteIcon} alt="circle-check" />
                <span> Complete {actionReasonName}</span>
              </button>
            )}
          </div>
        </td>
      );
    }

    return (
      <td>
        <div className="global-search-v2__search-result-table__data-td--action">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleMarkAsComplete(id);
            }}
            className="ap-button ap-button--secondary"
          >
            <img src={checkCircleWhiteIcon} alt="circle-check" />
            <span> Mark as Complete</span>
          </button>
          {isAuthorizedUserForArchive && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleArchive(id);
              }}
              data-tip="Archive"
              className="global-search-v2__search-result-table__data-td--action-btn"
            >
              <img src={binIcon} alt="bin" />
              <ReactTooltip effect="solid" />
            </button>
          )}
        </div>
      </td>
    );
  };

  const CompletedActionsCell = (data) => {
    const { id, actionReasonName } = data;
    if (['Appeal', 'Record'].includes(actionReasonName) || data?.hasReminder) {
      return <td></td>;
    }
    return (
      <td>
        <div className="global-search-v2__search-result-table__data-td--action">
          {isAuthorizedUserForArchive && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleArchive(id);
              }}
              data-tip="Archive"
              className="global-search-v2__search-result-table__data-td--action-btn"
            >
              <img src={binIcon} alt="bin" />
            </button>
          )}
        </div>
      </td>
    );
  };

  const getActionCell = (activeTab, data) => {
    if (!activeTab) return 'N/A';

    if (activeTab === TAB_NAME_CONFIG.DENIALS) {
      return (
        <DenialActionsCell
          key={data?.claimControlNumber}
          data={data}
          handleManualClaim={handleManualClaim}
          handleAppealDeniedClaim={handleAppealDeniedClaim}
          handleArchive={handleArchive}
          isAuthorizedUserForArchive={isAuthorizedUserForArchive}
          handleAssignClaims={handleAssignClaims}
          openUhcClaimStatusWidget={openUhcClaimStatusWidget}
          openAvailityClaimStatusWidget={openAvailityClaimStatusWidget}
        />
      );
    }

    if (activeTab === TAB_NAME_CONFIG.PENDING_ACTIONS) {
      return <PendingActionsCell key={data?.claimControlNumber} {...data} />;
    }

    if (activeTab === TAB_NAME_CONFIG.COMPLETED_ACTIONS) {
      return <CompletedActionsCell key={data?.claimControlNumber} {...data} />;
    }

    if (activeTab === TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS) {
      return (
        <InProgressActionsCell
          key={data?.claimControlNumber}
          data={data}
          handleContinueSubmission={handleCompleteClaim}
          handleAction={handleAction}
          userInfo={userInfo}
          openUhcClaimStatusWidget={openUhcClaimStatusWidget}
          openAvailityClaimStatusWidget={openAvailityClaimStatusWidget}
          setIsPerformingAction={setIsPerformingAction}
        />
      );
    }

    if (activeTab === TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS) {
      return (
        <SubmittedActionsCell
          key={data?.claimControlNumber}
          data={data}
          setRecordResponseFlyover={setRecordResponseFlyover}
          openUhcClaimStatusWidget={openUhcClaimStatusWidget}
          openAvailityClaimStatusWidget={openAvailityClaimStatusWidget}
        />
      );
    }
    if (
      activeTab === TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS ||
      activeTab === TAB_NAME_CONFIG.DENIED_SUBMISSIONS
    ) {
      return (
        <SuccessfulDeniedActionsCell
          key={data?.claimControlNumber}
          data={data}
          setRecordResponseFlyover={setRecordResponseFlyover}
          openUhcClaimStatusWidget={openUhcClaimStatusWidget}
          openAvailityClaimStatusWidget={openAvailityClaimStatusWidget}
        />
      );
    }

    if (
      activeTab === TAB_NAME_CONFIG.ARCHIVE_DENIALS ||
      activeTab === TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS ||
      activeTab === TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS
    ) {
      const { id } = data;
      return (
        <td>
          <div className="global-search-v2__search-result-table__data-td--action">
            {isAuthorizedUserForArchive && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleUnArchive(id);
                }}
                className="ap-button ap-button--secondary"
              >
                <img src={unArchiveIcon} alt="unarchive" className="mr-4" />
                <span> Unarchive</span>
              </button>
            )}
            {(data?.uhcClaimStatusSearchData ||
              data?.availityClaimStatusSearchData) && (
              <div className="d-flex justify-content-flex-end ml-4">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (data?.availityClaimStatusSearchData) {
                      return openAvailityClaimStatusWidget(
                        data?.availityClaimStatusSearchData
                      );
                    }
                    openUhcClaimStatusWidget(data?.uhcClaimStatusSearchData);
                  }}
                  data-tip={`Claim Status Check via ${
                    data?.availityClaimStatusSearchData ? 'Availity' : 'UHC'
                  }`}
                  className="global-search-v2__search-result-table__data-td--action-btn"
                >
                  <img src={searchDarkIcon} alt="search claim" />
                </button>
              </div>
            )}
          </div>
        </td>
      );
    }
    if (activeTab === TAB_NAME_CONFIG.UNFLAGGED) {
      const { id, claimControlNumber, claimNumber } = data;
      return (
        <td>
          <div className="global-search-v2__search-result-table__data-td--action">
            <div className="d-flex justify-content-space-between ml-4">
              {(data?.uhcClaimStatusSearchData ||
                data?.availityClaimStatusSearchData) && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (data?.availityClaimStatusSearchData) {
                      return openAvailityClaimStatusWidget(
                        data?.availityClaimStatusSearchData
                      );
                    }
                    openUhcClaimStatusWidget(data?.uhcClaimStatusSearchData);
                  }}
                  data-tip={`Claim Status Check via ${
                    data?.availityClaimStatusSearchData ? 'Availity' : 'UHC'
                  }`}
                  className="global-search-v2__search-result-table__data-td--action-btn"
                >
                  <img src={searchDarkIcon} alt="search claim" />
                </button>
              )}

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddClaimsToDenialsQueue(
                    id,
                    claimNumber,
                    claimControlNumber
                  );
                }}
                className="global-search-v2__search-result-table__data-td--add-dq-btn ml-8"
                data-tip="Add to Denials Queue"
              >
                <img src={addCircleIcon} alt="search claim" />
                <ReactTooltip effect="solid" />
              </button>
            </div>
          </div>
        </td>
      );
    }

    return <td> </td>;
  };

  return (
    <div className="global-search-v2__search-result-table-container">
      <table className="global-search-v2__search-result-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={classnames(
                  'global-search-v2__search-result-table__header-cell',
                  column?.className
                )}
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody key={data?.id}>
          {columnsData.map((data) => (
            <tr
              key={data?.id}
              className="global-search-v2__search-result-table__data-row"
              onClick={() => handleRowClick(data)}
            >
              {columns.map((column, index) => {
                const { accessor } = column;

                if (accessor === 'serviceDate') {
                  return (
                    <td
                      key={index}
                      className="global-search-v2__search-result-table__data-td appealio-table__custom-cell"
                      {...(data[column.accessor] &&
                      data[column.accessor]?.every((item) => item !== '--')
                        ? { 'data-tip': data[column.accessor] }
                        : {})}
                    >
                      <BuildDateArrayCell value={data[column.accessor]} />
                      <ReactTooltip effect="solid" place="top" />
                    </td>
                  );
                }

                if (accessor === 'eobDate') {
                  return (
                    <td
                      key={index}
                      className="global-search-v2__search-result-table__data-td appealio-table__custom-cell"
                      {...(data[column.accessor] &&
                      data[column.accessor] !== '--'
                        ? { 'data-tip': data[column.accessor] }
                        : {})}
                    >
                      <span>
                        {data[column.accessor] && data[column.accessor] !== '--'
                          ? getFormattedDate(data[column.accessor])
                          : 'N/A'}
                      </span>
                      <ReactTooltip effect="solid" place="top" />
                    </td>
                  );
                }

                if (accessor === 'type') {
                  return (
                    <td
                      key={index}
                      className="global-search-v2__search-result-table__data-td appealio-table__custom-cell"
                    >
                      <div>
                        <span>
                          {data[column.accessor] &&
                          data[column.accessor] !== '--'
                            ? data[column.accessor]
                            : 'N/A'}
                        </span>
                        {data[column.accessor] && !data?.isImported && (
                          <span className="global-search-v2__search-result-table__data-td__imported-icon">
                            <GrUserManager
                              data-tip="Manual"
                              className="ingested-appeal-indicator"
                            />
                          </span>
                        )}
                      </div>
                      <ReactTooltip effect="solid" place="top" />
                    </td>
                  );
                }

                if (accessor === 'action') {
                  return getActionCell(activeTab, data);
                }

                return (
                  <td
                    key={accessor}
                    className={classnames(
                      'global-search-v2__search-result-table__data-td appealio-table__custom-cell',
                      column?.className
                    )}
                    {...(data[column.accessor] && data[column.accessor] !== '--'
                      ? { 'data-tip': data[column.accessor] }
                      : {})}
                  >
                    <span>
                      {data[column.accessor] && data[column.accessor] !== '--'
                        ? data[column.accessor]
                        : 'N/A'}
                    </span>
                    <ReactTooltip effect="solid" place="top" />
                  </td>
                );
              })}
            </tr>
          ))}
          {columnsTotal > RESULTS_DATA_OFFSET && (
            <tr onClick={() => handleRedirect(true)}>
              <td
                colSpan={columns.length}
                className="global-search-v2__search-result-table__data-td"
              >
                <p className="global-search-v2__show-all-results">
                  Show All Results
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GlobalSearchTable;
