import { filter } from 'lodash';
import moment from 'moment';
import { applyGlobalFilterForActionLogs, FILTER_KEYS } from './hooks';
import { PM_NOTE_STATUS } from 'constants/appConstants';

/**
 * Converts a UTC date and time string to the local time zone.
 *
 * @param {string} dateTimeString - The date and time string in UTC format, expected in 'MM/DD/YYYY hh:mm A' format.
 * @returns {string} - The date and time string converted to the local time zone, formatted as 'MM/DD/YYYY hh:mm A'.
 *
 * @example
 * const localTime = convertToLocalTime('08/06/2024 02:30 PM');
 * console.log(localTime); // Outputs the equivalent local time
 */
export const convertToLocalTime = (dateTimeString) => {
  const utcDate = moment.utc(dateTimeString, 'MM/DD/YYYY hh:mm A');
  const localDateString = utcDate.local().format('MM/DD/YYYY hh:mm A');
  return localDateString;
};

/**
 * Converts all UTC date-time strings within the input string to the client's local time zone.
 * @param {string} inputString - The input string containing UTC date-time strings.
 * @returns {string} - The string with UTC date-times converted to local time.
 */
function replaceUTCDateTimesWithLocal(inputString) {
  const dateTimeRegex = /\b\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2} [AP]M\b/g;

  if (!dateTimeRegex.test(inputString)) {
    return inputString;
  }

  const resultString = inputString.replace(dateTimeRegex, convertToLocalTime);

  return resultString;
}

/**
 * Generates a formatted note based on the provided parameters.
 *
 * @param {Object} params - The parameters for generating the note.
 * @param {boolean} params.pmNoteData - The presence of PM note data.
 * @param {string} params.claimNumber - The claim number.
 * @param {string} params.actionReason - The reason for the action.
 * @param {string} params.actionCompletedBy - The person who completed the action.
 * @param {string} params.actionCompletedAt - The time the action was completed.
 * @returns {string} - The formatted note.
 */
export const generatePmNote = (pmNoteData) => {
  if (!pmNoteData) return '';
  const { pmNoteStr } = pmNoteData;
  return replaceUTCDateTimesWithLocal(pmNoteStr);
};

export const getSearchParams = (
  filters,
  globalFilters,
  currentUsername,
  sourceFilters
) => {
  const searchRequestBodyParams = Object.entries(
    applyGlobalFilterForActionLogs(
      filters,
      globalFilters,
      currentUsername,
      sourceFilters
    )
  ).flatMap(([key, value]) => {
    if (key === 'agents' && value && !Array.isArray(value)) {
      return [{ key, value: [value] }];
    }
    return [{ key, value }];
  });

  const search = filter(
    searchRequestBodyParams,
    (o) => o.value || o.value === 0
  ).map(({ key, value }) => ({
    key: FILTER_KEYS[key] || key,
    value:
      key === FILTER_KEYS.pmNotesCopied
        ? value === PM_NOTE_STATUS.COPIED
        : value,
  }));

  const result = search.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
  return result;
};
