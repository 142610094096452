import './style.css';
import React, { useState } from 'react';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

const SelectAllClaims = (props) => {
  const {
    isSelectAllOptionAvailable,
    totalDenialsCount,
    isBulkClaimSelected,
    onClickBulkSelect,
    claimCountOnCurrentPage,
    selectAllClaimsOnCurrentPage = () => ({}),
  } = props;

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const handleOnClickSelectAll = (selectAllClaims) => {
    if (selectAllClaims) {
      setShowConfirmationPopup(!showConfirmationPopup);
    } else {
      selectAllClaimsOnCurrentPage();
      onClickBulkSelect();
    }
  };

  if (!isSelectAllOptionAvailable) {
    return null;
  }

  const handleOnOkayClick = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
    onClickBulkSelect();
  };

  const resetShowConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  const renderAssignClaimsDialog = () => {
    if (!showConfirmationPopup) {
      return null;
    }

    return (
      <ScreenDimmerComponent>
        <div className="appealio-popup appealio-action-popup">
          <div className="appealio-action-popup__header"></div>
          <div className="appealio-action-popup__content">
            <p className="appealio-action-popup__details">
              Are you sure you want to select all the claims?
            </p>

            <p className="appealio-action-popup__details"></p>

            <button
              className="ap-button mt-12 ap-button--secondary ap-button--block"
              onClick={handleOnOkayClick}
            >
              Okay
            </button>
            <button
              className="ap-button ap-button--tertiary-outline ap-button--block"
              onClick={resetShowConfirmationPopup}
            >
              Cancel
            </button>
          </div>
        </div>
      </ScreenDimmerComponent>
    );
  };

  return (
    <>
      {isSelectAllOptionAvailable && totalDenialsCount > 0 && (
        <div>
          {isBulkClaimSelected && claimCountOnCurrentPage > 0 ? (
            <span
              className="select-all-claims-box"
              onClick={() => handleOnClickSelectAll(false)}
            >
              Select {claimCountOnCurrentPage} Claims
            </span>
          ) : (
            <span
              className="select-all-claims-box"
              onClick={() => handleOnClickSelectAll(true)}
            >
              Select {totalDenialsCount} Claims
            </span>
          )}
        </div>
      )}

      {!isBulkClaimSelected && renderAssignClaimsDialog()}
    </>
  );
};

export default SelectAllClaims;
