import { get } from 'lodash';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import AppealsAPI from 'API/AppealsAPI';
import DenialsAPI from 'API/DenialsAPI';
import {
  fetchDeliveryTrackingCount,
  fetchDeliveryLogs,
} from 'API/DeliveryLogsAPI';
import { fetchAndPrepareRemindersData } from 'hooks/components/useRemindersData';

import { ACTION_TYPE } from './SearchResults';

import { getDefaultTab } from './util';
import { isUserAuthorizedForDenialsPage } from 'Auth/AuthUtils';

import { TAB_NAME_CONFIG, RESULTS_DATA_OFFSET } from './config';

import { IN_PROGRESS_STATUS } from 'components/Submissions/InProgressSubmissions/constant';
import {
  CLAIM_IMPORTED_FROM_TYPE,
  SUCCESSFUL_DENIED_OPTIONS,
} from 'constants/appConstants';
import { handleError } from 'helpers/errorHandler';

import {
  getAvailityClaimStatusSearchDataForAppeal,
  getUhcClaimStatusSearchDataForAppeal,
  getAvailityClaimStatusSearchDataForDenialsQueue,
  getUHCClaimStatusSearchDataForDenialsQueue,
  getUHCClaimStatusSearchDataForDeliveryTracker,
  getAvailityClaimStatusSearchDataForDeliveryTracker,
  getAvailityClaimStatusSearchDataForClaims,
  getUHCClaimStatusSearchDataForClaims,
} from 'components/GlobalSearch/hook';

const SUCCESSFUL_STATUS = SUCCESSFUL_DENIED_OPTIONS[0]?.value;
const DENIED_STATUS = SUCCESSFUL_DENIED_OPTIONS[1]?.value;

export const SEARCH_QUERY_CHARACTER_OFFSET = 3;

const fetchDenialsCount = async (
  searchTerm,
  userInfo,
  fetchArchivedItems = false,
  signal = null
) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      [TAB_NAME_CONFIG.DENIALS]: 0,
      [TAB_NAME_CONFIG.ARCHIVE_DENIALS]: 0,
    };
  }
  const { total } = await DenialsAPI.fetchDenials({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    fetchArchivedItems,
    onlyTotal: true,
    signal,
  });

  if (fetchArchivedItems) {
    return {
      [TAB_NAME_CONFIG.ARCHIVE_DENIALS]: total,
    };
  }

  return {
    [TAB_NAME_CONFIG.DENIALS]: total,
  };
};

const fetchActionLogsCount = async (
  searchTerm,
  userInfo,
  fetchArchivedItems = false,
  signal = null
) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      [TAB_NAME_CONFIG.COMPLETED_ACTIONS]: 0,
      [TAB_NAME_CONFIG.PENDING_ACTIONS]: 0,
    };
  }

  const { total } = await DenialsAPI.fetchDenials({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    action: 1,
    fetchArchivedItems,
    onlyTotal: true,
    signal,
  });

  if (fetchArchivedItems) {
    return {
      [TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS]: total.completed,
      [TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS]: total.todo,
    };
  }

  return {
    [TAB_NAME_CONFIG.COMPLETED_ACTIONS]: total.completed,
    [TAB_NAME_CONFIG.PENDING_ACTIONS]: total.todo,
  };
};

const fetchAppealsCount = async (searchTerm, signal = null) => {
  const queryParams = {
    search: searchTerm,
    status: IN_PROGRESS_STATUS,
  };
  const { data } = await AppealsAPI.fetchAppealsCount(queryParams, signal);
  return {
    [TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS]: data?.count,
  };
};

const fetchDeliveryLogsCount = async (
  searchTerm,
  status = null,
  signal = null
) => {
  const queryParams = {
    search: searchTerm,
    ...(status && { recordResponseStatus: status }),
  };
  const { count } = await fetchDeliveryTrackingCount(queryParams, signal);
  if (status) {
    const key =
      status === SUCCESSFUL_STATUS
        ? TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS
        : TAB_NAME_CONFIG.DENIED_SUBMISSIONS;
    return {
      [key]: count,
    };
  }

  return {
    [TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS]: count,
  };
};

const fetchClaimsCount = async (searchTerm, userInfo, signal = null) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      [TAB_NAME_CONFIG.UNFLAGGED]: 0,
    };
  }

  const { total } = await DenialsAPI.fetchDeniedClaims({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    sortParams: {
      only_total: true,
    },
    signal,
  });

  return {
    [TAB_NAME_CONFIG.UNFLAGGED]: total || 0,
  };
};

export const useTabCounts = (searchTerm, userInfo) => {
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchAbortControllerRef = useRef(null);
  const fetchCounts = useCallback(async () => {
    setError(null);

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }
    const currentController = new AbortController();
    fetchAbortControllerRef.current = currentController;

    try {
      const [
        denialCounts,
        denialArchivedCounts,
        actionLogCounts,
        actionLogArchivedCounts,
        inProgressAppealsCount,
        deliveryLogsCount,
        deliveryLogsSuccessfulCount,
        deliveryLogsDeniedCount,
        unflaggedClaimsCount,
      ] = await Promise.all([
        fetchDenialsCount(
          searchTerm,
          userInfo,
          false,
          fetchAbortControllerRef.current.signal
        ),
        fetchDenialsCount(
          searchTerm,
          userInfo,
          true,
          fetchAbortControllerRef.current.signal
        ),
        fetchActionLogsCount(
          searchTerm,
          userInfo,
          false,
          fetchAbortControllerRef.current.signal
        ),
        fetchActionLogsCount(
          searchTerm,
          userInfo,
          true,
          fetchAbortControllerRef.current.signal
        ),
        fetchAppealsCount(searchTerm, fetchAbortControllerRef.current.signal),
        fetchDeliveryLogsCount(
          searchTerm,
          null,
          fetchAbortControllerRef.current.signal
        ),
        fetchDeliveryLogsCount(
          searchTerm,
          SUCCESSFUL_STATUS,
          fetchAbortControllerRef.current.signal
        ),
        fetchDeliveryLogsCount(
          searchTerm,
          DENIED_STATUS,
          fetchAbortControllerRef.current.signal
        ),
        fetchClaimsCount(
          searchTerm,
          userInfo,
          fetchAbortControllerRef.current.signal
        ),
      ]);
      setCounts({
        [TAB_NAME_CONFIG.WORK_LIST]:
          denialCounts[TAB_NAME_CONFIG.DENIALS] +
          actionLogCounts[TAB_NAME_CONFIG.COMPLETED_ACTIONS] +
          actionLogCounts[TAB_NAME_CONFIG.PENDING_ACTIONS],
        [TAB_NAME_CONFIG.SUBMISSIONS]:
          inProgressAppealsCount[TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS] +
          deliveryLogsCount[TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS] +
          deliveryLogsSuccessfulCount[TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS] +
          deliveryLogsDeniedCount[TAB_NAME_CONFIG.DENIED_SUBMISSIONS],
        [TAB_NAME_CONFIG.ARCHIVED]:
          denialArchivedCounts[TAB_NAME_CONFIG.ARCHIVE_DENIALS] +
          actionLogArchivedCounts[TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS] +
          actionLogArchivedCounts[TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS],
        [TAB_NAME_CONFIG.UNFLAGGED]:
          unflaggedClaimsCount[TAB_NAME_CONFIG.UNFLAGGED],
        ...denialCounts,
        ...denialArchivedCounts,
        ...actionLogCounts,
        ...actionLogArchivedCounts,
        ...inProgressAppealsCount,
        ...deliveryLogsCount,
        ...deliveryLogsSuccessfulCount,
        ...deliveryLogsDeniedCount,
        ...unflaggedClaimsCount,
      });
    } catch (err) {
      setError(err.message || 'Failed to fetch counts');
    } finally {
      if (!currentController.signal.aborted) {
        setLoading(false);
      }
    }
  }, [searchTerm, userInfo]);

  useEffect(() => {
    if (searchTerm?.length < 3) {
      setCounts({});
      setLoading(false);
      return;
    }
    setLoading(true);
    fetchCounts();
  }, [searchTerm, fetchCounts, userInfo]);

  const handleActionForCount = (actionType, activeTab, source = null) => {
    const getCount = (count) => (count > 0 ? count : 0);
    switch (actionType) {
      case ACTION_TYPE.MARK_AS_COMPLETE:
        setCounts((prevCounts) => {
          return {
            ...prevCounts,
            [activeTab]: getCount(prevCounts[activeTab] - 1),
            [TAB_NAME_CONFIG.COMPLETED_ACTIONS]:
              prevCounts[TAB_NAME_CONFIG.COMPLETED_ACTIONS] + 1,
          };
        });
        break;

      case ACTION_TYPE.MARK_AS_PENDING:
        setCounts((prevCounts) => {
          return {
            ...prevCounts,
            [activeTab]: getCount(prevCounts[activeTab] - 1),
            [TAB_NAME_CONFIG.PENDING_ACTIONS]:
              prevCounts[TAB_NAME_CONFIG.PENDING_ACTIONS] + 1,
          };
        });
        break;

      case ACTION_TYPE.ARCHIVE:
        setCounts((prevCounts) => {
          const archiveKey =
            activeTab === TAB_NAME_CONFIG.DENIALS
              ? TAB_NAME_CONFIG.ARCHIVE_DENIALS
              : activeTab === TAB_NAME_CONFIG.COMPLETED_ACTIONS
              ? TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS
              : TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS;

          return {
            ...prevCounts,
            [activeTab]: getCount(prevCounts[activeTab] - 1),
            [TAB_NAME_CONFIG.WORK_LIST]: getCount(
              prevCounts[TAB_NAME_CONFIG.WORK_LIST] - 1
            ),
            [TAB_NAME_CONFIG.ARCHIVED]:
              prevCounts[TAB_NAME_CONFIG.ARCHIVED] + 1,
            [archiveKey]: prevCounts[archiveKey] + 1,
          };
        });
        break;

      case ACTION_TYPE.DELETE:
        setCounts((prevCounts) => {
          return {
            ...prevCounts,
            [activeTab]: getCount(prevCounts[activeTab] - 1),
            [TAB_NAME_CONFIG.SUBMISSIONS]: getCount(
              prevCounts[TAB_NAME_CONFIG.SUBMISSIONS] - 1
            ),
            [TAB_NAME_CONFIG.PENDING_ACTIONS]: getCount(
              prevCounts[TAB_NAME_CONFIG.PENDING_ACTIONS] - 1
            ),
            ...(source &&
              source === CLAIM_IMPORTED_FROM_TYPE.FLYOVER && {
                [TAB_NAME_CONFIG.UNFLAGGED]:
                  prevCounts[TAB_NAME_CONFIG.UNFLAGGED] + 1,
                [TAB_NAME_CONFIG.WORK_LIST]: getCount(
                  prevCounts[TAB_NAME_CONFIG.WORK_LIST] - 1
                ),
              }),
            ...(source &&
              source === CLAIM_IMPORTED_FROM_TYPE.DENIALS_QUEUE && {
                [TAB_NAME_CONFIG.DENIALS]:
                  prevCounts[TAB_NAME_CONFIG.DENIALS] + 1,
              }),
          };
        });
        break;

      case ACTION_TYPE.UNARCHIVE:
        setCounts((prevCounts) => {
          const unarchiveKey =
            activeTab === TAB_NAME_CONFIG.ARCHIVE_DENIALS
              ? TAB_NAME_CONFIG.DENIALS
              : activeTab === TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS
              ? TAB_NAME_CONFIG.COMPLETED_ACTIONS
              : TAB_NAME_CONFIG.PENDING_ACTIONS;

          return {
            ...prevCounts,
            [activeTab]: getCount(prevCounts[activeTab] - 1),
            [TAB_NAME_CONFIG.ARCHIVED]: getCount(
              prevCounts[TAB_NAME_CONFIG.ARCHIVED] - 1
            ),
            [TAB_NAME_CONFIG.WORK_LIST]:
              prevCounts[TAB_NAME_CONFIG.WORK_LIST] + 1,
            [unarchiveKey]: prevCounts[unarchiveKey] + 1,
          };
        });
        break;

      case ACTION_TYPE.ADD_TO_DQ:
        setCounts((prevCounts) => ({
          ...prevCounts,
          [activeTab]: getCount(prevCounts[activeTab] - 1),
          [TAB_NAME_CONFIG.DENIALS]: getCount(
            prevCounts[TAB_NAME_CONFIG.DENIALS] + 1
          ),
          [TAB_NAME_CONFIG.WORK_LIST]: getCount(
            prevCounts[TAB_NAME_CONFIG.WORK_LIST] + 1
          ),
        }));
        break;
      default:
        break;
    }
  };
  return { counts, loading, error, fetchCounts, handleActionForCount };
};

export const useActiveTabs = (searchResultTabs, counts) => {
  const activeTabs = useMemo(() => {
    const defaultParentTab = getDefaultTab(searchResultTabs, counts);
    const activeParentTabKey =
      defaultParentTab?.key || searchResultTabs[0]?.key;
    const activeChildTab = defaultParentTab?.tabs
      ? getDefaultTab(defaultParentTab.tabs, counts)?.key
      : activeParentTabKey;

    return { activeParentTab: activeParentTabKey, activeChildTab };
  }, [searchResultTabs, counts]);

  return activeTabs;
};

export const fetchDenials = async (
  searchTerm,
  userInfo,
  fetchArchivedItems = false
) => {
  if (
    !isUserAuthorizedForDenialsPage(userInfo) ||
    searchTerm?.length < SEARCH_QUERY_CHARACTER_OFFSET
  ) {
    return {
      data: [],
      total: 0,
    };
  }
  const { rows, total } = await DenialsAPI.fetchDenials({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    limit: RESULTS_DATA_OFFSET,
    fetchArchivedItems,
  });

  const data = rows.map((row) => {
    const {
      id,
      patientName,
      ediPaymentDate: eobDate,
      claim: claimNumber,
      manualClaimId,
      claimControlNumber,
      serviceDate,
      payerName,
      providerName,
      agents,
      practiceId,
    } = row;
    return {
      id,
      agentsUsername: agents,
      patientName,
      claimControlNumber,
      claimNumber,
      manualClaimId,
      eobDate,
      serviceDate,
      payerName,
      practiceId,
      providerName,
      availityClaimStatusSearchData:
        getAvailityClaimStatusSearchDataForDenialsQueue(row, userInfo),
      uhcClaimStatusSearchData: getUHCClaimStatusSearchDataForDenialsQueue(
        row,
        userInfo
      ),
    };
  });

  return {
    data,
    total,
  };
};

export const fetchActionLogs = async (
  searchTerm,
  userInfo,
  completed = 0,
  fetchArchivedItems = false
) => {
  if (
    !isUserAuthorizedForDenialsPage(userInfo) ||
    searchTerm?.length < SEARCH_QUERY_CHARACTER_OFFSET
  ) {
    return {
      data: [],
      total: 0,
    };
  }

  const { rows, total } = await DenialsAPI.fetchDenials({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    completed,
    action: 1,
    fetchArchivedItems,
    limit: RESULTS_DATA_OFFSET,
  });

  const ids = rows?.map((row) => row.id) || [];
  const { remindersData } = await fetchAndPrepareRemindersData(ids, false);

  const data = rows?.map(
    ({
      id,
      claimControlNumber,
      patientName,
      ediPaymentDate: eobDate,
      serviceDate,
      actionReasonName,
      mappedAppealId,
      claim: claimNumber,
      claimImportedFromType,
    }) => ({
      id,
      patientName,
      claimControlNumber,
      eobDate,
      serviceDate,
      actionReasonName,
      mappedAppealId,
      claimImportedFromType,
      claimNumber,
      hasReminder: Boolean(get(remindersData, `${id}.reminderId`)),
    })
  );

  return {
    data,
    total,
  };
};

export const fetchInProgressAppeals = async (
  searchTerm,
  userInfo,
  isMedicalRecord = false
) => {
  const { data, totalRows } = await AppealsAPI.getAppealsOnPage(1, [
    { name: 'search', query: searchTerm, useAsURLParam: true },
    { name: 'status', query: IN_PROGRESS_STATUS, useAsURLParam: true },
    { name: 'limit', query: RESULTS_DATA_OFFSET, useAsURLParam: true },
  ]);

  const appealsData = data.map(({ appeals }) => {
    const [appeal] = appeals;
    return {
      id: appeal?.id,
      patientName: appeal?.patientName,
      claimControlNumber: isMedicalRecord
        ? appeal?.submitterClaimId
        : appeal?.claimNumber,
      type: appeal?.isMedicalRecordsSubmission ? 'Record' : 'Appeal',
      serviceDate: [appeal?.serviceDateFrom, appeal?.serviceDateTo],
      eobDate: appeal?.deniedAt,
      isImported: appeal?.imported,
      submitterClaimId: appeal?.submitterClaimId,
      claimNumber: appeal?.submitterClaimId,
      isMedicalRecordsSubmission: appeal?.isMedicalRecordsSubmission,
      availityClaimStatusSearchData: getAvailityClaimStatusSearchDataForAppeal(
        appeal,
        userInfo
      ),
      uhcClaimStatusSearchData: getUhcClaimStatusSearchDataForAppeal(
        appeal,
        userInfo
      ),
    };
  });
  return {
    data: appealsData,
    total: totalRows,
  };
};

export const fetchDeliveryTracker = async (searchTerm, userInfo, status) => {
  const { results, count } = await fetchDeliveryLogs({
    search: searchTerm,
    ordering: 'patient',
    limit: RESULTS_DATA_OFFSET,
    ...(status && { record_response_status: status }),
  });

  const data = results
    .map((result) => {
      const {
        patient: patientName,
        claimNumber: claimControlNumber,
        deniedAt: eobDate,
        submissionType: type,
        submittedAt,
        method,
        status,
        isImported,
        readOnly,
        reAppealId,
        recordResponseAppealId,
        submitterClaimId: claimNumber,
      } = result;
      return {
        patientName,
        claimControlNumber,
        claimNumber,
        eobDate,
        type,
        submittedAt,
        status,
        method,
        isImported,
        readOnly,
        reAppealId,
        recordResponseAppealId,
        statusMethod: `(${method}) ${status}`,
        availityClaimStatusSearchData:
          getAvailityClaimStatusSearchDataForDeliveryTracker(result, userInfo),
        uhcClaimStatusSearchData: getUHCClaimStatusSearchDataForDeliveryTracker(
          result,
          userInfo
        ),
      };
    })
    .filter(
      ({ patientName, claimNumber, eobDate }) =>
        patientName?.trim() || claimNumber?.trim() || eobDate?.trim()
    ); // Filter out standalone submission data

  return {
    data,
    total: count,
  };
};

export const fetchUnflaggedClaims = async (searchTerm, userInfo) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      data: [],
      total: 0,
    };
  }

  const { rows, total } = await DenialsAPI.fetchDeniedClaims({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    limit: RESULTS_DATA_OFFSET,
  });

  const data = rows.map((row) => {
    const {
      id,
      patientName,
      ediPaymentDate: eobDate,
      claimControlNumber,
      serviceDate,
      claimNumber,
    } = row;

    return {
      id,
      patientName,
      claimControlNumber,
      eobDate,
      serviceDate,
      claimNumber,
      availityClaimStatusSearchData: getAvailityClaimStatusSearchDataForClaims(
        row,
        userInfo
      ),
      uhcClaimStatusSearchData: getUHCClaimStatusSearchDataForClaims(
        row,
        userInfo
      ),
    };
  });

  return {
    data,
    total,
  };
};

/**
 * Helper function to fetch the claim source.
 * @param {string} searchTerm - The search term for fetching the source.
 * @param {object} userInfo - User information required for the request.
 * @param {boolean} fetchArchivedItems - Whether to include archived items.
 * @returns {Promise<string>} - Resolves to the source type.
 */
export const getClaimSource = async (
  searchTerm,
  userInfo,
  fetchArchivedItems = false
) => {
  try {
    const { data } = await fetchActionLogs(
      searchTerm,
      userInfo,
      0,
      fetchArchivedItems
    );
    if (data.length > 0 && data[0]?.claimImportedFromType) {
      return data[0].claimImportedFromType;
    }
    return null;
  } catch (error) {
    handleError(error);
    return null;
  }
};

/**
 * Custom hook to fetch the claim source.
 * @param {string} searchTerm - The search term for fetching the source.
 * @param {object} userInfo - User information required for the request.
 * @returns {{ source: string, isLoading: boolean, fetchSource: () => Promise<void> }}
 */
export const useFetchClaimSource = (searchTerm, userInfo) => {
  const [source, setSource] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchSource = useCallback(async () => {
    if (!searchTerm) return;

    setIsLoading(true);
    try {
      const isSourceSet =
        (await getClaimSource(searchTerm, userInfo, false)) ||
        (await getClaimSource(searchTerm, userInfo, true));

      setSource(isSourceSet || CLAIM_IMPORTED_FROM_TYPE.MANUAL_CLAIM);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, userInfo]);

  useEffect(() => {
    fetchSource();
  }, [fetchSource]);

  return { source, isLoading, fetchSource };
};

export const useFetchSearchHistory = () => {
  const [searchHistoryData, setSearchHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await AppealsAPI.getSearchHistory();
      const data = result?.data || [];
      setSearchHistoryData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    searchHistoryData,
    isSearchHistoryLoading: loading,
    searchHistoryError: error,
    fetchSearchHistory: fetchData,
  };
};
