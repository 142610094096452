import { useCallback, useEffect, useState } from 'react';
import { isEmpty, filter, get, pickBy, omit, isEqual, snakeCase } from 'lodash';

import DenialsAPI from 'API/DenialsAPI';

import { TABLE_PAGE_LIMIT, PM_NOTE_STATUS } from 'constants/appConstants';

import identity from 'lodash/identity';
import { handleError } from 'helpers/errorHandler';

export const FILTER_KEYS = {
  agents: 'agents',
  payers: 'payer',
  cptCodes: 'cpt_code',
  remarkCodes: 'remark_codes',
  providers: 'provider',
  practices: 'practice_identifier',
  reasonCodes: 'reason_code',
  procedureModifiers: 'cpt_modifiers_code',
  removalReason: 'reason',
  appliedRule: 'applied_rule',
  actionCompletedBy: 'action_completed_by',
  actionAssignedBy: 'action_created_by',
  pmNotesCopied: 'pm_notes_copied',
  reminderStatus: 'reminder_status', // Reminder status
};

const DEFAULT_TOTAL_ACTION_LOGS = {
  completed: 0,
  todo: 0,
};
export const applyGlobalFilterForActionLogs = (
  filters,
  globalFilters,
  username,
  sourceFilters
) => {
  const agentFilters = [];
  const { edi, manualClaims } = sourceFilters;
  if (globalFilters?.assignedToMe) {
    agentFilters.push(username);
  }
  if (globalFilters?.unassigned) {
    agentFilters.push('n/a');
  }
  if (globalFilters?.assignedToOthers) {
    agentFilters.push('others');
  }

  if (filters?.agents) {
    return filters;
  }

  return {
    ...filters,
    agents: agentFilters,
    ...(!(edi && manualClaims) && {
      is_manual_claim: manualClaims,
    }),
  };
};
export const useFetchDenialClaimsLogs = (
  sortBy,
  filters,
  page,
  userPracticeId,
  userSecretKey,
  userPractices,
  logType,
  globalFilters,
  currentUsername,
  fetchArchivedItems = false,
  sourceFilters
) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [totalActionLogs, setTotalActionLogs] = useState(
    DEFAULT_TOTAL_ACTION_LOGS
  );
  const [actionLogs, setActionLogs] = useState([]);
  const updatePmNoteStatus = useCallback((id, resetTimestamp = false) => {
    setActionLogs((prevState) => {
      const index = prevState.findIndex((item) => item.id === id);
      if (index !== -1) {
        prevState[index] = {
          ...prevState[index],
          pmNote: {
            ...prevState[index].pmNote,
            pmNoteCopiedAt: resetTimestamp ? null : new Date().toISOString(),
          },
        };
      }
      return [...prevState];
    });
  }, []);
  // #TODO: remove this logic and replaced it with getSearchParams
  const searchRequestBodyParams = Object.entries(
    applyGlobalFilterForActionLogs(
      filters,
      globalFilters,
      currentUsername,
      sourceFilters
    )
  )
    .map(([key, value]) => {
      if (key === 'agents' && value && !Array.isArray(value)) {
        return { key, value: [value] };
      }

      return { key, value };
    })
    .flat();

  const userPracticesNameMap = userPractices.reduce((acc, practice) => {
    const { practiceIdentifier, name } = practice;

    return {
      ...acc,
      [practiceIdentifier]: name,
    };
  }, {});

  const attachPracticeNameToRemovalClaim = useCallback(
    (removedClaim) => {
      const practiceIdentifier = removedClaim.practiceId;

      const practiceName = practiceIdentifier
        ? userPracticesNameMap[practiceIdentifier] || ''
        : '';

      return {
        ...removedClaim,
        practiceName,
      };
    },
    [userPracticesNameMap]
  );

  // #TODO: remove this logic and replaced it with getSearchParams
  const fetch = async () => {
    setLoading(true);
    setActionLogs([]);
    setTotalActionLogs(DEFAULT_TOTAL_ACTION_LOGS);
    try {
      const completedValue = logType.completed || 0;
      const sort = !isEmpty(sortBy)
        ? sortBy.desc
          ? `-${snakeCase(sortBy.id)}`
          : snakeCase(sortBy.id)
        : '';
      const search = filter(
        searchRequestBodyParams,
        (o) => o.value || o.value === 0
      ).map(({ key, value }) => ({
        key: FILTER_KEYS[key] || key,
        value:
          key === FILTER_KEYS.pmNotesCopied
            ? value === PM_NOTE_STATUS.COPIED
            : value,
      }));

      const offset = (page - 1) * TABLE_PAGE_LIMIT;

      const { rows, counts } = await DenialsAPI.fetchDenials({
        filters: search,
        search,
        offset,
        sort,
        practiceId: userPracticeId,
        secretKey: userSecretKey,
        completed: completedValue,
        action: 1,
        fetchArchivedItems,
      });
      setActionLogs(rows.map(attachPracticeNameToRemovalClaim));
      setTotalActionLogs(counts);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortBy,
    filters,
    page,
    logType,
    globalFilters,
    sourceFilters,
    fetchArchivedItems,
  ]);

  return {
    loading,
    error,
    actionLogs,
    totalActionLogs,
    fetch,
    updatePmNoteStatus,
  };
};

export const useFetchAndSetActionLogsCounts = (
  filters,
  logType,
  globalFilters,
  currentUsername,
  isArchivedPage = false,
  sourceFilters
) => {
  const [filterWithCountOptions, setFilterWithCountOptions] = useState({});
  const [initialLogType, setInitialLogType] = useState();
  const [initialRuleCount, setInitialRuleCount] = useState({});
  const [initialGlobalFilters, setInitialGlobalFilters] = useState();

  const fetch = useCallback(async () => {
    try {
      const extractFilter = (_filter) => {
        return Object.entries(_filter)
          .filter(([key, value]) => value || value === 0)
          .map(([key, value]) => {
            const mappedKey = FILTER_KEYS[key] || key;
            if (mappedKey === FILTER_KEYS.pmNotesCopied) {
              return {
                key: mappedKey,
                value: value === PM_NOTE_STATUS.COPIED,
              };
            }
            return { key: mappedKey, value };
          });
      };

      const IS_ACTION = 1;
      const queryParam = { action: IS_ACTION, completed: logType.completed };
      const isRulesApplied = Boolean(get(filters, 'appliedRule'));

      const filtersObj = applyGlobalFilterForActionLogs(
        filters,
        globalFilters,
        currentUsername,
        sourceFilters
      );

      const [actionLogsFilterOptionsCounts, rulesOptionsCount] =
        await Promise.all([
          DenialsAPI.fetchDenialsQueueCounts(
            extractFilter(filtersObj),
            queryParam,
            isArchivedPage
          ),
          isRulesApplied
            ? DenialsAPI.fetchDenialsQueueCounts(
                extractFilter(omit(filtersObj, ['appliedRule'])),
                queryParam,
                isArchivedPage
              )
            : {},
        ]);

      if (
        !initialGlobalFilters ||
        !isEqual(initialGlobalFilters, globalFilters)
      ) {
        setInitialGlobalFilters(globalFilters);
      }

      const shouldSetInitialCount =
        !initialLogType ||
        initialLogType !== logType ||
        !isEqual(initialGlobalFilters, globalFilters);

      if (!initialLogType || initialLogType !== logType) {
        setInitialLogType(logType);
      }

      if (isEmpty(initialRuleCount) || shouldSetInitialCount) {
        setInitialRuleCount({
          ruleCounts: actionLogsFilterOptionsCounts.ruleCounts,
          completed: logType.completed,
        });
      }
      const nonEmptyFilters = pickBy(filtersObj, identity);

      const shouldUseCurrentRuleCount =
        Boolean(get(filtersObj, 'appliedRule')) &&
        Object.keys(nonEmptyFilters).length === 1;

      setFilterWithCountOptions(() => {
        const ret = {
          payerCounts: actionLogsFilterOptionsCounts.payerCounts, // payers
          practicesCounts:
            actionLogsFilterOptionsCounts.practiceIdentifierCounts, // practices
          agentsCounts: actionLogsFilterOptionsCounts.assignedAgentsCounts,
          actionCompletedByCounts:
            actionLogsFilterOptionsCounts.actionCompletedByCounts,
          actionAssignedByCounts:
            actionLogsFilterOptionsCounts.actionCreatedByCounts,
          ruleCounts: shouldUseCurrentRuleCount
            ? initialRuleCount.ruleCounts
            : isRulesApplied
            ? rulesOptionsCount.ruleCounts
            : actionLogsFilterOptionsCounts.ruleCounts,
        };
        return ret;
      });
    } catch (error) {
      handleError(error);
    }
    // eslint-disable-next-line
  }, [
    filters,
    logType,
    globalFilters,
    sourceFilters,
    currentUsername,
    isArchivedPage,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch, filters, logType, globalFilters, sourceFilters, isArchivedPage]);

  return { filterWithCountOptions };
};

export const useFetchTodoAndCompletedCounts = (
  sortBy,
  filters,
  page,
  userPracticeId,
  userSecretKey,
  userPractices,
  logType,
  globalFilters,
  currentUsername,
  fetchArchivedItems = false,
  sourceFilters
) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [actionsLogsCount, setActionsLogsCount] = useState(
    DEFAULT_TOTAL_ACTION_LOGS
  );

  const searchRequestBodyParams = Object.entries(
    applyGlobalFilterForActionLogs(
      filters,
      globalFilters,
      currentUsername,
      sourceFilters
    )
  ).flatMap(([key, value]) => {
    if (key === 'agents' && value && !Array.isArray(value)) {
      return [{ key, value: [value] }];
    }
    return [{ key, value }];
  });

  const fetch = async () => {
    setLoading(true);
    setActionsLogsCount(DEFAULT_TOTAL_ACTION_LOGS);
    try {
      const completedValue = logType.completed || 0;
      const sort = !isEmpty(sortBy)
        ? sortBy.desc
          ? `-${snakeCase(sortBy.id)}`
          : snakeCase(sortBy.id)
        : '';
      const searchBodyParams = filter(
        searchRequestBodyParams,
        (o) => o.value || o.value === 0
      ).map(({ key, value }) => ({
        key: FILTER_KEYS[key] || key,
        value:
          key === FILTER_KEYS.pmNotesCopied
            ? value === PM_NOTE_STATUS.COPIED
            : value,
      }));

      const search = [
        ...searchBodyParams,
        {
          key: 'exclude_appeal',
          value: true,
        },
        {
          key: 'exclude_record',
          value: true,
        },
      ];
      const { total } = await DenialsAPI.fetchDenials({
        filters: search,
        search,
        sort,
        practiceId: userPracticeId,
        secretKey: userSecretKey,
        completed: completedValue,
        action: 1,
        onlyTotal: true,
        fetchArchivedItems,
      });

      setActionsLogsCount({
        todo: total.todo,
        completed: total.completed,
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortBy,
    filters,
    page,
    logType,
    globalFilters,
    sourceFilters,
    fetchArchivedItems,
  ]);

  return {
    loading,
    error,
    actionsLogsCount,
    fetch,
  };
};
