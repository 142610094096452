import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import {
  generateKeyFromDropdownActionType,
  getActionTypeFromStateKey,
} from '../utils/dropdownOptionsCommon';

import * as optionsAPI from 'API/DropdownOptions';

/**
 * Creates an action creator for fetching dropdown options.
 *
 * @param {string} actionType - The type of the action to be dispatched.
 * @param {Function} fetchOptions - A function that returns a Promise for fetching dropdown options.
 * @param {boolean} [bypassCache=false] - Flag to bypass cache and always fetch new options.
 * @returns {Function} The action creator function.
 */
function createFetchDropdownActionCreator(
  actionType,
  fetchOptions,
  bypassCache = false
) {
  /**
   * Action creator function that fetches dropdown options and dispatches an action.
   *
   * @param {Function} dispatch - Redux dispatch function.
   * @param {Function} getState - Redux getState function.
   * @returns {Promise} A Promise that resolves when the action is dispatched.
   */
  return (dispatch, getState) => {
    const state = getState();
    const key = generateKeyFromDropdownActionType(actionType);
    const dropdownOptions = getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS[key]
    );

    let payload = Promise.resolve(dropdownOptions.data);

    if (bypassCache || !dropdownOptions.data?.length) {
      payload = fetchOptions();
    }

    return dispatch({
      type: actionType,
      payload,
    });
  };
}

/**
 * Fetches denial provider options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsProviderOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PROVIDER_OPTIONS
    ),
    optionsAPI.fetchProviders
  );
}
/**
 * Fetches denial payer options.
 *
 * @returns {Function} A Redux action creator function.
 */

export function fetchDenialsPayerOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PAYER_OPTIONS
    ),
    optionsAPI.fetchDenialPayers,
    bypassCache
  );
}

/**
 * Fetches denial reason options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsReasonOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REASON_OPTIONS
    ),
    optionsAPI.fetchDenialReasons
  );
}

/**
 * Fetches denial remark options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsRemarkOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REMARK_OPTIONS
    ),
    optionsAPI.fetchDenialRemarkCodes
  );
}

/**
 * Fetches denial revenue options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsRevenueCodesOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REVENUE_CODE_OPTIONS
    ),
    optionsAPI.fetchDenialRevenueCodes
  );
}

/**
 * Fetches denial cpt code options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsCptCodeOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_CPT_CODE_OPTIONS
    ),
    optionsAPI.fetchDenialCptCodes
  );
}

/**
 * Fetches denial procedure modifier options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsProcedureModifierOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PROCEDURE_MODIFIER_OPTIONS
    ),
    optionsAPI.fetchDenialProcedureModifiers
  );
}

/**
 * Fetches denial rule options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsRuleOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_RULE_OPTIONS),
    optionsAPI.fetchDenialRuleOptions
  );
}

/**
 * Fetches agent options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchAgentOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS),
    optionsAPI.fetchAgents,
    bypassCache
  );
}

/**
 * Fetches team options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchTeamOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(DROPDOWN_OPTIONS_STATE_KEYS.TEAM_OPTIONS),
    optionsAPI.fetchTeamOptions,
    bypassCache
  );
}

/**
 * Fetches fetchActionLogsOptions options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchActionLogActions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DQ_ACTION_LOG_OPTIONS
    ),
    optionsAPI.fetchActionLogsOptions
  );
}

/**
 * Fetches practice reason options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchDenialsPracticeOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
    optionsAPI.fetchPracticeOptions,
    bypassCache
  );
}

/**
 * Fetches practice group options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchPracticeGroupOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.PRACTICE_GROUP_OPTIONS
    ),
    optionsAPI.fetchPracticeGroupOptions,
    bypassCache
  );
}

/**
 * Fetches Appeals Payer options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchPayerOptions() {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(DROPDOWN_OPTIONS_STATE_KEYS.PAYER_OPTIONS),
    optionsAPI.fetchPayerOptions
  );
}

/**
 * Fetches Appeals Provider options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchProviderOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(DROPDOWN_OPTIONS_STATE_KEYS.PROVIDER_OPTIONS),
    optionsAPI.fetchProviderOptions,
    bypassCache
  );
}

/**
 * Fetches Appeals Batch Ids options.
 *
 * @returns {Function} A Redux action creator function.
 */
export function fetchAppealsBatchIdOptions(bypassCache = false) {
  return createFetchDropdownActionCreator(
    getActionTypeFromStateKey(
      DROPDOWN_OPTIONS_STATE_KEYS.APPEALS_BATCH_ID_OPTIONS
    ),
    optionsAPI.fetchAppealBatchIdOptions,
    bypassCache
  );
}
