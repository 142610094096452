import { TAB_NAME_CONFIG, SUCCESSFUL_DENIED_OPTIONS } from './config';

import {
  SUBMISSIONS,
  DENIED_QUEUE,
  DENIED_QUEUE_LOGS,
  DENIED_QUEUE_ARCHIVED,
  SUBMISSIONS_IN_PROGRESS,
  DENIED_QUEUE_LOGS_ARCHIVED,
  DENIED_QUEUE_COMPLETED_LOGS,
  DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
} from 'constants/routes';

export const extractUrlAndQueryParam = (activeTab, searchValue) => {
  let url;
  let queryParam;

  if (
    activeTab === TAB_NAME_CONFIG.DENIALS ||
    activeTab === TAB_NAME_CONFIG.WORK_LIST
  ) {
    url = DENIED_QUEUE;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
    };
  }
  if ([TAB_NAME_CONFIG.PENDING_ACTIONS].includes(activeTab)) {
    url = DENIED_QUEUE_LOGS;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
      completed: 0,
    };
  }
  if ([TAB_NAME_CONFIG.COMPLETED_ACTIONS].includes(activeTab)) {
    url = DENIED_QUEUE_COMPLETED_LOGS;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
      completed: 1,
    };
  }
  if (activeTab === TAB_NAME_CONFIG.INPROGRESS_SUBMISSIONS) {
    url = SUBMISSIONS_IN_PROGRESS;
    queryParam = {
      set_filters: true,
      ...(searchValue ? { search: searchValue } : {}),
    };
  }
  if (
    activeTab === TAB_NAME_CONFIG.SUBMITTED_SUBMISSIONS ||
    activeTab === TAB_NAME_CONFIG.SUBMISSIONS
  ) {
    url = SUBMISSIONS;
    queryParam = {
      ...(searchValue ? { search2: searchValue } : {}),
    };
  }
  if (activeTab === TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS) {
    url = SUBMISSIONS;
    queryParam = {
      ...(searchValue
        ? {
            search2: searchValue,
            record_response_status:
              SUCCESSFUL_DENIED_OPTIONS[TAB_NAME_CONFIG.SUCCESSFUL_SUBMISSIONS],
          }
        : {}),
    };
  }
  if (activeTab === TAB_NAME_CONFIG.DENIED_SUBMISSIONS) {
    url = SUBMISSIONS;
    queryParam = {
      ...(searchValue
        ? {
            search2: searchValue,
            record_response_status:
              SUCCESSFUL_DENIED_OPTIONS[TAB_NAME_CONFIG.DENIED_SUBMISSIONS],
          }
        : {}),
    };
  }
  if (
    activeTab === TAB_NAME_CONFIG.ARCHIVE_DENIALS ||
    activeTab === TAB_NAME_CONFIG.ARCHIVED
  ) {
    url = DENIED_QUEUE_ARCHIVED;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
    };
  }
  if (activeTab === TAB_NAME_CONFIG.ARCHIVE_PENDING_ACTIONS) {
    url = DENIED_QUEUE_LOGS_ARCHIVED;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
    };
  }
  if (activeTab === TAB_NAME_CONFIG.ARCHIVE_COMPLETED_ACTIONS) {
    url = DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
    };
  }

  if (activeTab === TAB_NAME_CONFIG.UNFLAGGED) {
    url = DENIED_QUEUE;
    queryParam = {
      ...(searchValue ? { search: searchValue } : {}),
      flyover: true,
    };
  }

  return { url, queryParam };
};

export const getDefaultTab = (tabs, counts) =>
  tabs.find((tab) => counts[tab.key] > 0);

export const getActiveTabs = (parentTabs, counts) => {
  const defaultParentTab = getDefaultTab(parentTabs, counts);
  const activeParentTab = defaultParentTab?.key || parentTabs[0]?.key;
  const activeChildTab = defaultParentTab?.tabs
    ? getDefaultTab(defaultParentTab.tabs, counts)?.key
    : activeParentTab;

  return { activeParentTab, activeChildTab };
};
