import { isEmpty, snakeCase } from 'lodash';
import { useEffect, useState } from 'react';

import AppealsAPI from 'API/AppealsAPI';
import { fetchClaimStatusHistory } from 'API/PayerAPI';

import { TABLE_PAGE_LIMIT } from 'constants/appConstants';
import { sortDropdownOptionsByLabel } from 'helpers/utils';

export const useFetchClaimStatusHistory = (sortBy, page, filters) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [claimStatusHistory, setClaimStatusHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setClaimStatusHistory([]);
      setTotalCount(0);
      try {
        const offset = (page - 1) * TABLE_PAGE_LIMIT;
        const sort = !isEmpty(sortBy)
          ? sortBy.desc
            ? `-${snakeCase(sortBy.id)}`
            : snakeCase(sortBy.id)
          : '';

        const { data, total } = await fetchClaimStatusHistory({
          offset,
          sort,
          filters,
        });
        setClaimStatusHistory(data);
        setTotalCount(total);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetch();
  }, [sortBy, filters, page]);

  return { loading, error, claimStatusHistory, totalCount, fetch };
};

const DEFAULT_OPTIONS = {
  payers: [],
  providers: [],
};

export const useFetchStatusTrackerFilterOptions = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);

  const fetch = async () => {
    setLoading(true);
    setError(null);
    setOptions(DEFAULT_OPTIONS);

    try {
      const [payerResponse, providerResponse] = await Promise.all([
        AppealsAPI.getPayersOptions(),
        AppealsAPI.getDropdownOptions('rendering-provider-options'),
      ]);

      setOptions({
        payers: [
          {
            label: 'All',
            value: '',
          },
          sortDropdownOptionsByLabel(
            ...payerResponse.map(({ name: label, id: value }) => ({
              label,
              value,
            }))
          ),
        ],
        providers: [
          {
            label: 'All',
            value: '',
          },
          ...sortDropdownOptionsByLabel(
            providerResponse?.data?.map(({ id: value, name: label }) => ({
              value,
              label,
            }))
          ),
        ],
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, options };
};
