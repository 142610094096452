/**
 * Created by alex on 10/26/16.
 */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';

import SubmitButton from '../../Shared/SubmitButton';
import FooterButtons from '../../Shared/SaveContinueFooterComponent';
import ScreenDimmerComponent from '../../Shared/ScreenDimmerComponent';

import checkIcon from '../../../img/check.svg';
import successIcon from '../../../img/success.svg';
import closeIcon from '../../../img/close-modal.svg';

export default class LetterModalComponent extends Component {
  static propTypes = {
    documentType: PropTypes.oneOf(['pdf', 'html']).isRequired,
    fileNames: PropTypes.array.isRequired,
    onClosePressed: PropTypes.func.isRequired,
    onAddPressed: PropTypes.func.isRequired,
    onContinuePressed: PropTypes.func.isRequired,
    onCancelPressed: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  render() {
    const documentType = this.props.documentType === 'pdf' ? 'Form' : 'Letter';
    const submissionType = this.props.isMedicalRecordsSubmission
      ? 'Medical Record'
      : 'Appeal';
    const title = `${submissionType} ${documentType} ${
      this.props.letterToEdit ? 'Edited' : 'Added'
    } `;

    const addDocumentButtonTitle =
      this.props.documentType === 'pdf'
        ? 'Add Another Form or Letter'
        : 'Add Another Letter or Form';

    return (
      <ScreenDimmerComponent>
        <div className="modal__dialog" datacy="letter-modal-component">
          <div className="row">
            <img
              alt="Close"
              onClick={this.props.onClosePressed}
              className="modal__close"
              src={closeIcon}
            />
          </div>
          <div className="row">
            <img alt="Success" className="modal__success" src={successIcon} />
          </div>
          <div className="row">
            <div className="modal__title">{title}</div>
          </div>
          <div className="row">
            <div className="col-xs-offset-2 col-xs-8 modal__description">
              {documentType} has been{' '}
              {this.props.letterToEdit ? 'edited' : 'added'} to the{' '}
              {this.props.isMedicalRecordsSubmission
                ? 'medical record'
                : 'appeal'}
              . Please select below to add another letter or form, or continue
              to documents.
            </div>
          </div>
          <div className="row modal__alingnemt">{this.renderFileNames()}</div>
          <div className="modal__add-button--layout">
            <div
              className={classNames({
                invisible: this.props.isLoading === true,
              })}
            >
              <SubmitButton
                title={addDocumentButtonTitle}
                className={'modal__add-button'}
                onClick={this.props.onAddPressed}
              />
            </div>
          </div>
          <div className="row">
            <div className="modal__separator" />
          </div>
          <div className="modal__continue">
            <div
              className={classNames('row save_continue__footer', {
                'save_continue__footer-padding': this.props.isLoading !== true,
              })}
            >
              <div
                className={classNames({
                  'col-xs-offset-3': this.props.isLoading !== true,
                })}
              >
                <FooterButtons
                  submitBtnTitle="Continue to Documents"
                  onSavePressed={this.props.onContinuePressed}
                  shouldShowCancelButton={false}
                  isLoading={this.props.isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </ScreenDimmerComponent>
    );
  }

  renderFileNames() {
    return this.props.fileNames.map((name, index) => {
      return (
        <div className="row" key={index}>
          <img alt="Check" className="modal__check" src={checkIcon} />
          <div className="modal__filename">{name}</div>
        </div>
      );
    });
  }
}
