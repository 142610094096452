/* eslint-disable guard-for-in */
import _ from 'lodash';
import URI from 'urijs';
import download from 'downloadjs';
import camelCase from 'lodash/camelCase';

import appealFilters from '../components/Dashboard/appeal-filters';

import {
  handleErrors,
  createRequest,
  handleJSONParse,
  createGetRequest,
  BASE_API_URL,
} from './Config';

import Util from './UtilAPI';

import { getFormattedDate } from 'helpers/dateFormats';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';
import { SORT_GREATEST, SORT_LEAST } from '../helpers/sortValues';

import {
  APPEALS_FILTER_OPTIONS_COUNTS,
  APPEALS,
  APPEALS_COUNT,
  APPEAL_LETTER_PDFS,
  USER_SEARCH_HISTORY,
} from 'constants/endpoints';

export default class AppealsAPI {
  static getAppealsOnPage = (page, filters) => {
    const request = createGetRequest(
      'appeals',
      AppealsAPI.buildRequestParams(filters, page)
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => {
        return AppealsAPI.parseAppealsData(res);
      });
  };

  static appealFilterOptionsCount = (filters) => {
    const request = createGetRequest(
      APPEALS_FILTER_OPTIONS_COUNTS,
      AppealsAPI.buildRequestParams(filters)
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => camelizeKeys(res.data));
  };

  static getAppealLetterPdfPreview = (html) => {
    const request = createRequest('letter-pdf', {
      html,
    });

    return fetch(request)
      .then(handleErrors)
      .then(async (res) => {
        const file = await res.blob();
        const fileUrl = window.URL.createObjectURL(file);
        return fileUrl;
      });
  };

  static getAppealLetterPdfPreviewById = (id) => {
    const request = createRequest(`${APPEAL_LETTER_PDFS}${id}`, {
      type: 'preview',
    });

    return fetch(request)
      .then(handleErrors)
      .then(async (res) => {
        const file = await res.blob();
        const fileUrl = window.URL.createObjectURL(file);
        return fileUrl;
      });
  };

  static getMedicalRecords = (page, filters) => {
    const request = createGetRequest('appeals', {
      page,
      medical_records: 1,
      ...snakeCaseKeys(filters),
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(AppealsAPI.parseAppealsData);
  };

  static getInProgressSubmissions = (page, filters) => {
    const request = createGetRequest(APPEALS, {
      page,
      ...snakeCaseKeys(filters),
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(AppealsAPI.parseAppealsData);
  };

  static recordsFilterOptionsCount = (filters) => {
    const request = createGetRequest(APPEALS_FILTER_OPTIONS_COUNTS, {
      medical_records: 1,
      ...snakeCaseKeys(filters),
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => camelizeKeys(res.data));
  };

  static getInProgressFilterOptionsCount = (filters) => {
    const request = createGetRequest(APPEALS_FILTER_OPTIONS_COUNTS, {
      ...snakeCaseKeys(filters),
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => camelizeKeys(res.data));
  };

  static buildRequestParams(filters, page = -1) {
    let params = {};

    // If Params will be used for a request,
    // as opposed to being used for display only
    if (page > 0) {
      params = {
        page,
      };
    }

    for (const i in filters) {
      const filter = filters[i];
      if (filter.useAsURLParam && filter.query !== false) {
        params[filter.name] = filter.query;
      }
    }

    return params;
  }

  static getDropdownOptions(path, queryParams) {
    const request = createGetRequest(path, queryParams);

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => {
        return res;
      });
  }

  static getSearchHistory() {
    const request = createGetRequest(USER_SEARCH_HISTORY);
    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  }

  static createSearchHistory(searchText) {
    const request = createRequest(USER_SEARCH_HISTORY, {
      search_text: searchText,
    });
    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  }

  static getPayersOptions() {
    return AppealsAPI.getDropdownOptions('payer-list');
  }

  static filterValidAgentOptions(agentData) {
    const agentDropdownOptions = [];
    if (typeof agentData.data !== 'undefined') {
      for (const a in agentData.data) {
        const agentId = agentData.data[a].id;
        const agentDetails = agentData.data[a].attributes;
        if (agentDetails.first_name !== '' && agentDetails.last_name !== '') {
          const agentName =
            agentDetails.first_name + ' ' + agentDetails.last_name;
          agentDropdownOptions.push({ name: agentName, id: agentId });
        }
      }
    }
    return agentDropdownOptions;
  }

  static filterValidPayerOptions(payerData) {
    const payerDropdownOptions = [];
    if (typeof payerData !== 'undefined') {
      for (const p in payerData.data) {
        const payerId = payerData.data[p].id;
        const payerDetails = payerData.data[p].attributes;
        if (payerDetails.name !== '') {
          const payerName = payerDetails.name;
          payerDropdownOptions.push({ name: payerName, id: payerId });
        }
      }
    }
    return payerDropdownOptions;
  }

  static formatAppealRow(
    row,
    includedData = {
      payerContacts: [],
    }
  ) {
    if (typeof row === 'undefined') {
      return;
    }

    const includedPayerContactId = _.get(
      row,
      ['relationships', 'payer_contact', 'data', 'id'],
      null
    );

    const { payerContacts } = includedData;
    const payerContactData = payerContacts.find(
      (payerContact) => payerContact.id === includedPayerContactId
    );

    const payerContact = payerContactData
      ? {
          id: payerContactData.id,
          ...payerContactData.attributes,
        }
      : {};

    const parsedObject = {
      deadline: Util.preventNullValue(
        Util.formatDate(
          row.attributes.deadline,
          row.attributes.status,
          row.attributes.submitted_at
        ),
        '--'
      ),
      billedAmount: Util.preventNullValue(
        Util.formatAmount(row.attributes.billed_amount),
        '--'
      ),
      deniedAmount: Util.preventNullValue(
        Util.formatAmount(row.attributes.denied_amount),
        '--'
      ),
      id: Util.preventNullValue(row.id, '--'),
      eob: {
        id: row.relationships.eob.data ? row.relationships.eob.data.id : null,
      },
    };

    for (const field in row.attributes) {
      if (_.has(row.attributes, field) && !parsedObject[camelCase(field)]) {
        parsedObject[camelCase(field)] = Util.preventNullValue(
          row.attributes[field],
          '--'
        );
      }
    }

    parsedObject.payerContact = payerContact;

    return parsedObject;
  }

  static parseAppealsData(data) {
    const displayArray = [];
    const dataArray = _.get(data, 'included', []);
    const relationArray = data.data;

    // Parse payerContacts
    const includedPayerContacts = dataArray.filter(
      (includedData) => includedData.type === 'PayerContact'
    );

    const appealDictionary = {};
    for (const i in dataArray) {
      const appealIdForHash = dataArray[i].id;
      appealDictionary[appealIdForHash] = dataArray[i];
    }
    for (const d in relationArray) {
      const appealIdForHash2 = relationArray[d].id;
      appealDictionary[appealIdForHash2] = relationArray[d];
    }

    for (const j in relationArray) {
      const row = {};
      row.appeals = [];
      const appealIdForMain = relationArray[j].id;

      // add info on top level appeal
      row.name = relationArray[j].attributes.patient_name;
      row.appeals.push(
        AppealsAPI.formatAppealRow(appealDictionary[appealIdForMain], {
          payerContacts: includedPayerContacts,
        })
      );

      displayArray.push(row);
    }

    const convertedData = {
      totalRows: data.meta.pagination.count,
      totalPages: data.meta.pagination.pages,
      data: displayArray,
    };

    return convertedData;
  }

  static appendNewAppeals(currentAppeals, newAppeals) {
    const currentAppealsClone = Util.cloneObject(currentAppeals);
    const newAppealsClone = Util.cloneObject(newAppeals);
    if (
      typeof currentAppealsClone.totalRows === 'undefined' ||
      typeof currentAppealsClone.data === 'undefined'
    ) {
      return newAppealsClone;
    } else {
      if (currentAppealsClone.totalPages === 0) {
        currentAppealsClone.totalPages = newAppealsClone.totalPages;
      }
      if (currentAppealsClone.totalRows === 0) {
        currentAppealsClone.totalRows = newAppealsClone.totalRows;
      }
      currentAppealsClone.data = currentAppealsClone.data.concat(
        newAppealsClone.data
      );

      return currentAppealsClone;
    }
  }

  static dashboardHasExisitingFilter = (filters) => {
    let hasExistingFilter = false;
    filters.forEach((filter) => {
      if (filter.useAsURLParam && filter.query && filter.name === 'status') {
        return filter.query && !_.isEmpty(filter.query);
      }

      if (filter.useAsURLParam && filter.query) {
        hasExistingFilter = true;
      }
    });
    return hasExistingFilter;
  };

  static getUpdatedFilters(filters, newFilter, newQuery) {
    const updatedFilters = Util.cloneObject(filters);
    const resetClaimFilter =
      newFilter === 'patient' || newFilter === 'custom_patient_id';
    const resetPatientFilter =
      newFilter === 'claim' || newFilter === 'custom_patient_id';
    const resetPatientIDFilter =
      newFilter === 'claim' || newFilter === 'patient';
    const resetSortFilters = newFilter.includes('Sort');

    let newOrderingQuery = false;

    updatedFilters.forEach((filter) => {
      // handle string type toggle (searchbar)
      if (resetClaimFilter && filter.name === 'claim') {
        filter.query = false;
      }
      if (resetPatientFilter && filter.name === 'patient') {
        filter.query = false;
      }
      if (resetPatientIDFilter && filter.name === 'custom_patient_id') {
        filter.query = false;
      }

      // If we are to build a new value for the ordering param,
      // it will be done here
      if (filter.name.includes('Sort')) {
        if ((resetSortFilters && newFilter !== filter.name) || !newQuery) {
          filter.query = false;
        } else if (newQuery === SORT_GREATEST || newQuery === SORT_LEAST) {
          filter.query = newQuery;
          newOrderingQuery = AppealsAPI.convertSortIdToURLParam(
            filter.name,
            newQuery
          );
        }
      }

      // If a new ordering query has been built, we assign it here
      if (filter.name === 'ordering' && newFilter.includes('Sort')) {
        filter.query = newOrderingQuery;
      }
      if (filter.name === newFilter) {
        filter.query = newQuery;
      }
      // this is to reset dropdown if search is performed
      if (
        ['claim', 'patient', 'custom_patient_id', 'search'].includes(newFilter)
      ) {
        if (filter.name === newFilter) {
          filter.query = newQuery;
        } else if (filter.name === 'ordering') {
          // do nothing
        } else {
          filter.query = false;
        }
      }
    });

    return updatedFilters;
  }

  static convertSortIdToURLParam(id, query) {
    let prefix;
    if (query === SORT_GREATEST) {
      prefix = '-';
    } else {
      prefix = '';
    }
    const param = prefix + _.snakeCase(id.replace('Sort', ''));
    return param;
  }

  static convertOrderURLParamToSortId(param) {
    const prefix = param[0];
    let value;
    let sortType;
    if (prefix === '-') {
      sortType = SORT_GREATEST;
      value = param.slice(1);
    } else {
      sortType = SORT_LEAST;
      value = param;
    }
    const filterName = _.camelCase(value) + 'Sort';

    return {
      name: filterName,
      sortType,
    };
  }

  static buildOrderingParamString(exisiting, valueToAdd) {
    if (!valueToAdd && !exisiting) {
      exisiting = false;
    } else {
      if (exisiting) {
        exisiting = exisiting + ',' + valueToAdd;
      } else {
        exisiting = valueToAdd;
      }
    }
    return exisiting;
  }

  static getDefaultFilters() {
    return Util.cloneObject(appealFilters);
  }

  static convertQueryParamsToFilters(params) {
    const filters = Util.cloneObject(appealFilters);
    let orderingVal = false;

    // CONVERT ALL FILTERS, EXCEPT THOSE PERTAINING TO 'ordering'
    filters.forEach((filter) => {
      if (filter.useAsURLParam) {
        for (const key in params) {
          if (_.has(params, key)) {
            if (filter.name === key) {
              filter.query = params[key];
              if (filter.name === 'ordering') {
                orderingVal = filter.query;
              }
            }
          }
        }
      }
    });

    // CONVERT ONLY FILTERS THAT PERTAIN TO 'ordering'
    if (orderingVal) {
      const convertedOrderingVal =
        AppealsAPI.convertOrderURLParamToSortId(orderingVal);
      filters.forEach((filter) => {
        if (!filter.useAsURLParam) {
          if (filter.name === convertedOrderingVal.name) {
            filter.query = convertedOrderingVal.sortType;
          } else {
            filter.query = false;
          }
        }
      });
    }

    return filters;
  }

  static getFilterQuery(filterData, filterName) {
    return filterData.filter((f) => {
      return f.name === filterName;
    })[0].query;
  }

  static downloadAppealsExport(
    params,
    filename = 'download.csv',
    mimeType = 'text/csv'
  ) {
    const request = createGetRequest('export/appeals', params);

    return fetch(request)
      .then(handleErrors)
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        download(blob, filename, mimeType);
      });
  }

  /**
   * Closes appeal.
   *
   * @param {Number} appealId
   * @returns {Promise}
   */
  static async closeAppeal(appealId) {
    const request = createRequest(`appeals/${appealId}/close`, {});

    return fetch(request).then(handleErrors);
  }

  static async moveClaimBack(appealId) {
    const request = createRequest(`move-claim-back/`, { appeal_id: appealId });
    const response = await fetch(request);
    return handleErrors(response);
  }

  static async createPayerContact(payload) {
    const requestBody = {
      ...snakeCaseKeys(payload),
    };

    const request = createRequest('manual-payer-contact-create', requestBody);

    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  /**
   * Fetches signed URL for s3 upload.
   * @param {File} file
   * @returns {Promise}
   */
  static async getS3SignedUrl(file, extraQueryParams = {}) {
    const queryParam = {
      objectName: file.name,
      contentType: file.type,
      ...extraQueryParams,
    };

    const request = createGetRequest('sign_s3', queryParam);

    const { signedUrl } = await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse);

    return signedUrl;
  }

  /**
   * Upload file to s3.
   * @param {String} preSignedUrl
   * @param {File}  file
   * @returns {String} filePath
   */
  static async uploadFileToS3(preSignedUrl, file) {
    const contentType = file.type;

    const response = await fetch(
      new Request(preSignedUrl, {
        method: 'PUT',
        body: file,
        headers: new Headers({
          'Content-Type': contentType,
          'x-amz-acl': 'private',
        }),
      })
    );

    if (response.status !== 200) {
      throw Error('Error while uploading file to S3');
    }

    return response;
  }

  /**
   * Upload file to s3.
   * @param {File} file
   */
  static async uploadRequestedTemplateToS3(file) {
    const fileExtension = file.name.split('.').pop();
    Object.defineProperty(file, 'name', {
      writable: true,
      value: `upload-file.${fileExtension}`,
    });

    const signedUrl = await AppealsAPI.getS3SignedUrl(file, {
      isTemplate: '1',
    });

    await AppealsAPI.uploadFileToS3(signedUrl, file);

    const linkPath = new URI(signedUrl).pathname(true);

    if (linkPath[0] === '/') {
      return linkPath.substring(1);
    }

    return linkPath;
  }

  /**
   * Request new appeal template.
   * @param {Object} requestBody
   * @returns {Object}
   */
  static async requestAppealTemplate(requestBody) {
    const request = createRequest('request-template-upload/', requestBody, {
      baseUrl: BASE_API_URL,
    });

    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  /**
   * Fetches and returns sorted service dates for a given appeal ID.
   *
   * @param {number} appealId - The ID of the appeal.
   * @returns {Promise<string[]>} A promise that resolves to an array of sorted service dates.
   */
  static getServiceDates = async (appealId) => {
    const request = createGetRequest(`appeals/${appealId}`, {
      include: 'eob.eob_procedures',
    });

    try {
      const res = await fetch(request)
        .then(handleErrors)
        .then(handleJSONParse)
        .then((data) =>
          data.included.filter((item) => item.type === 'EOBProcedure')
        );

      const sortedServDates = res
        .filter((item) => Boolean(item?.attributes?.serv_date))
        .map((item) => getFormattedDate(new Date(item.attributes.serv_date)))
        .sort((a, b) => a - b);

      return sortedServDates;
    } catch (error) {
      handleErrors(error);
    }
  };

  /**
   * Fetches and returns the count of appeals based on the provided filters.
   *
   * @param {Object} queryParams - The filters to apply when counting appeals.
   * @returns {Promise<Object>} A promise that resolves to an object containing the count of appeals.
   * The object has a single property: 'count', which is a number representing the count of appeals.
   *
   */
  static fetchAppealsCount = (queryParams, signal = null) => {
    const request = createGetRequest(APPEALS_COUNT, {
      ...snakeCaseKeys(queryParams),
    });
    return fetch(request, { ...(signal ? { signal } : {}) })
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  };
}
