import React from 'react';
import classnames from 'classnames';

import { TAB_NAME_CONFIG } from './config';

import { getDefaultTab } from './util';

const GlobalSearchResultsTab = (props) => {
  const {
    searchResultsTab,
    selectedTab,
    selectedChildTab,
    setSelectedTab,
    setSelectedChildTab,
    counts,
    isDataNotFound = false,
    setSearchResultsData,
  } = props;
  const handleParentTabClick = (tabKey) => {
    const childTabs = searchResultsTab.find((tab) => tab.key === tabKey)?.tabs;
    const defaultChildTab = childTabs
      ? getDefaultTab(childTabs, counts)?.key
      : tabKey;
    setSearchResultsData((prevData) => ({
      ...prevData,
      isLoading: !prevData?.[tabKey]?.[defaultChildTab],
    }));
    setSelectedTab(tabKey);
    setSelectedChildTab(defaultChildTab);
  };

  const handleChildTabClick = (childTabKey) => {
    setSearchResultsData((prevData) => ({
      ...prevData,
      isLoading: !prevData?.[selectedTab]?.[childTabKey],
    }));
    setSelectedChildTab(childTabKey);
  };

  const activeParentTab = searchResultsTab.find(
    (tab) => tab.key === selectedTab
  );

  return (
    <div className="global-search-v2">
      <div className="global-search-v2__search-result-tabs">
        {searchResultsTab.map((parentTab) => (
          <div
            key={parentTab.key}
            className={classnames('global-search-v2__search-result-tab', {
              'global-search-v2__search-result-tab--active':
                selectedTab === parentTab.key && !isDataNotFound,
              'global-search-v2__search-result-tab--disabled':
                (isDataNotFound &&
                  parentTab.key === TAB_NAME_CONFIG.WORK_LIST) ||
                counts[parentTab.key] === 0,
            })}
            onClick={() => {
              if (counts[parentTab.key] > 0) {
                handleParentTabClick(parentTab.key);
              }
            }}
          >
            <span>
              {parentTab.label}({counts[parentTab.key] || 0})
            </span>
          </div>
        ))}
      </div>
      {activeParentTab &&
        selectedChildTab &&
        activeParentTab.tabs &&
        !isDataNotFound && (
          <div className="global-search-v2__search-result-tabs">
            {activeParentTab.tabs.map((childTab) => (
              <div
                key={childTab.key}
                className={classnames('global-search-v2__search-result-tab', {
                  'global-search-v2__search-result-tab--active':
                    selectedChildTab === childTab.key,
                  'global-search-v2__search-result-tab--disabled':
                    counts[childTab.key] === 0,
                })}
                onClick={() => {
                  if (counts[childTab.key] > 0) {
                    handleChildTabClick(childTab.key);
                  }
                }}
              >
                <span>
                  {childTab.label}({counts[childTab.key] || 0})
                </span>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default GlobalSearchResultsTab;
