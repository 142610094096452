import React from 'react';
import { parse } from 'qs';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, replace } from 'connected-react-router';
import {
  pickBy,
  identity,
  snakeCase,
  get,
  isEmpty,
  isNil,
  uniqWith,
  isEqual,
  xorWith,
  sortBy as _sortBy,
  orderBy,
  omit,
  filter,
  debounce,
} from 'lodash';
import { Switch, Redirect, Route } from 'react-router-dom';

import { getUserInfo } from '../../redux/reducers/loginStore';
import { setAppealData } from '../../redux/actions/createAppealActions';

import DenialsAPI, {
  bulkAssignActionAndAgents,
  bulkAssignArchiveAction,
  bulkAssignCompletedActions,
} from 'API/DenialsAPI';
import { appealManualClaim } from 'API/ManualClaimAPI';
import { canAssignAgent, isAuthorizedForPracticeGroup } from 'Auth/AuthUtils';

import {
  isUserAuthorizedForAutoArchiveDQ,
  isUserIsAuthorizedForManualEraUpload,
} from 'Auth/FeatureFlags';
import { fetchImportRulesCount } from 'API/ImportRules';
import CreateAppealAPI from 'API/CreateAppealAPI';

import * as routes from '../../constants/routes';

import { handleError } from '../../helpers/errorHandler';

import chargerIcon from '../../img/charger.svg';
import { ReactComponent as ActiveIcon } from '../../img/active-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../img/archive-icon.svg';

import {
  TABLE_PAGE_LIMIT,
  CLIENT_CONFIG_KEYS,
  UNASSIGNED_AGENT_LABEL,
  ALL_AGENTS_OPTION_VALUE,
  UNASSIGNED_OPTION,
  DENIALS_FLYOVER_TITLE,
  EXPORT_CSV_MESSAGE,
  PM_NOTE_STATUS,
  GLOBAL_SEARCH_CLAIM_ACTIONS,
} from 'constants/appConstants';

import AgentList from './AgentList';
import ActionList from './ActionList';
import * as toast from '../Shared/toast';
import CodesInfoPopup from './CodesInfoPopup';
import Button from 'components/common/button';
import Avatar from 'components/Shared/Avatar';
import ImportRulePopup from './ImportRulePopup';
import MissingPayerPopup from './MissingPayerPopup';
import RightSlideOut from '../Shared/RightSlideOut';
import Select from 'components/common/select/Select';
import AddManualClaimPopup from './AddManualClaimPopup';
import PendingApprovalPopup from './PendingApprovalPopup';
import { BUTTON_TYPE } from 'components/common/button/Button';
import MissingManualClaimInfo from './MissingManualClaimInfo';
import NavigationToggle from './NavigationToggle/NavigationToggle';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';
import MissingRenderingProviderPopup from './MissingRenderingProviderPopup';
import DeniedClaimsTableContainer from './SearchDeniedClaims/DeniedClaimsTable';
import ButtonDropdown from 'components/common/dropdown/ButtonDropdown/ButtonDropdown';
import DenialClaimInformationPopup from 'components/DenialQueue/DenialTable/DenialClaimInformationPopup';

import uploadIcon from 'img/upload-2.svg';

import { DENIALS_QUEUE_LOAD_EVENT_NAME } from 'constants/performanceLogging';
import {
  USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS,
  USER_DENIALS_QUEUE_FILTER_VALUE,
} from 'constants/localStorage';

import { interpolate } from 'helpers/string';
import { extractObjectMap } from 'helpers/object';
import { startSentryTransaction } from 'helpers/performanceLogging';
import ActionLogs from './ActinLogs';

import {
  FILTER_KEYS,
  applyGlobalFilterForActionLogs,
} from 'components/DenialQueue/ActinLogs/hooks';
import {
  fetchAgentOptions,
  fetchDenialsRuleOptions,
  fetchPayerOptions,
  fetchProviderOptions,
  fetchDenialsReasonOptions,
  fetchPracticeGroupOptions,
  fetchDenialsRemarkOptions,
  fetchDenialsCptCodeOptions,
  fetchDenialsPracticeOptions,
  fetchDenialsRevenueCodesOptions,
  fetchDenialsProcedureModifierOptions,
} from 'redux/actions/dropdownOptionsActions';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';
import DenialTable from './DenialTable/DenialTable';

import { getFormattedDate } from 'helpers/dateFormats';
import {
  fetchNotesCounts,
  fetchSopAndRuleInfo,
} from 'components/Submissions/DeliveryTracking/hooks';
import { handleErrors } from 'API/Config';

export const DEFAULT_FILTERS = {
  agents: null,
  payer: null,
  search: null,
  provider: null,
  patient_name: null,
  claim: null,
  date_added_from: null,
  date_added_to: null,
  date_archived_from: null,
  date_archived_to: null,
  patient_number: null,
  edi_payment_date: null,
  reason_code: null,
  practice_identifier: null,
  cpt_code: null,
  cpt_modifiers_code: null,
  applied_rule: null,
  remark_codes: null,
  service_date_from: null,
  service_date_to: null,
  zero_pay: false,
  action_completed_by: null,
  action_created_by: null,
  is_manual_claim: false,
  revenue_code: null,
};

export const MARK_COMPLETE = 1;
export const MARK_INCOMPLETE = 0;
const DEFAULT_PAGE_COUNTS = {
  denialsCounts: 0,
  archivedCounts: 0,
  actionLogsTodoCounts: 0,
  actionLogsCompletedCounts: 0,
};

export const ASSIGNMENT_LABELS = {
  unassigned: 'Unassigned',
  assignedToMe: 'Assigned to Me',
  assignedToOthers: 'Assigned to Others',
};

const SOURCE_LABELS = {
  edi: 'EDI',
  manualClaims: 'Manually Added',
};

class DenialQueueContainer extends React.Component {
  constructor(props) {
    super(props);
    const zeroPay =
      props?.userInfo?.clientConfig?.[
        CLIENT_CONFIG_KEYS.DQ_DEFAULT_ZERO_PAY_FILTER
      ] === true;
    this.DQ_DEFAULT_FILTERS = { ...DEFAULT_FILTERS, zero_pay: zeroPay };
    const isArchived = this.isArchivedPage();

    this.state = {
      denials: [],
      activePage: 1,
      totalDenialsCount: 0,
      isFetchingDenials: false,
      filters: this.DQ_DEFAULT_FILTERS,
      isFetchingFilterOptions: false,
      importRuleCount: 0,
      sortBy: {
        column: isArchived ? 'updatedAt' : 'createdAt',
        direction: 'desc',
      },
      isFiltersLocked: false,
      isAddDenialContainerOpen: false,
      deniedClaimsFilterOptionsCounts: {
        payers: [],
        providers: [],
        reason: [],
        cptCodes: [],
        procedureModifiers: [],
        revenueCodes: [],
        practices: [],
        remark: [],
        rule: [],
        agents: [],
      },
      isDeniedClaimsFilterOptionsFetching: false,
      missingImportClaimInfo: {
        claimId: null,
        isSubmitting: false,
        isMissingProvider: false,
        isMissingPayer: false,
        assignedRenderingProviderId: null,
        requestedRenderingProviderInfo: {},
        assignedPayerId: null,
        requestedPayerInfo: {},
      },
      missingRenderingProviderPopup: {
        isOpen: false,
        providerName: null,
        practiceId: null,
      },
      missingPayerPopup: {
        isOpen: false,
        payerName: null,
      },
      pendingApprovalPopup: {
        isOpen: false,
      },
      importRulePopup: {
        isOpen: false,
      },
      isRequestingPayersAndProviders: false,
      isAppealingDeniedClaim: false,
      selectedClaimInfos: [],
      claimAssignmentPopup: {
        isOpen: false,
        selectedClaims: [],
        markComplete: false,
        selectedAgents: [],
      },
      codesInfoPopup: {
        isOpen: false,
        codes: {
          remarkCodes: [],
          reasonCodes: [],
        },
        claimInfo: {
          claimId: null,
        },
      },
      denialInformationPopup: {
        isOpen: false,
        claimControlNumber: null,
        claimId: null,
      },
      action: null,
      initialRuleCount: [],
      pageCounts: DEFAULT_PAGE_COUNTS,
      // Extra filters for all 3 tabs
      globalFilters: {
        assignedToMe: true,
        unassigned: true,
        assignedToOthers: true,
      },
      sourceFilters: {
        edi: true,
        manualClaims: true,
      },
      isCreatingAppeal: {
        selectedRowId: null,
        isMedicalRecord: false,
      },
      isExportingCSV: false,
      showAddManualClaimPop: false,
      missingManualClaimInfo: {
        isOpen: false,
        manualClaimId: null,
        isMissingClaimNumber: false,
        isMissingClaimControlNumber: false,
      },
      notesCount: {},
      notesLatestCreatedTime: null,
      sopAndRuleInfo: {},
      isBulkClaimSelected: false,
    };

    this.userPracticeId = '';
    this.userSecretKey = '';
  }

  openMissingPayerPopup = (claim, cb) => {
    this.setState(
      {
        missingPayerPopup: {
          isOpen: true,
          payerName: claim.payerName || '',
        },
      },
      cb
    );
  };

  closeMissingPayerPopup = (cb) =>
    this.setState(
      {
        missingPayerPopup: {
          isOpen: false,
          payerName: null,
        },
      },
      cb
    );

  openRenderingProviderPopup = (claim, cb) => {
    const practiceIdentifier = claim.practiceId || '';
    const appealioPractice = this.props.userPractices.find(
      (practice) => practice.practiceIdentifier === practiceIdentifier
    );
    this.setState(
      {
        missingRenderingProviderPopup: {
          isOpen: true,
          providerName: claim.providerName || '',
          practiceId: appealioPractice ? appealioPractice.id : '',
        },
      },
      cb
    );
  };

  closeMissingRenderingProviderPopup = (cb) =>
    this.setState(
      {
        missingRenderingProviderPopup: {
          isOpen: false,
          providerName: null,
          practiceId: null,
        },
      },
      cb
    );

  openPendingApprovalPopup = (cb) =>
    this.setState(
      {
        pendingApprovalPopup: {
          isOpen: true,
        },
      },
      cb
    );

  closePendingApprovalPopup = (cb) =>
    this.setState(
      {
        pendingApprovalPopup: {
          isOpen: false,
        },
      },
      cb
    );

  setDefaultCodeInfoPopup = () => {
    this.setState({
      codesInfoPopup: {
        isOpen: false,
        codes: {
          remarkCodes: [],
          reasonCodes: [],
        },
        claimInfo: {
          claimId: null,
        },
      },
    });
  };

  openCodesInfoPopup = ({
    remarkCodes = [],
    reasonCodes = [],
    claimId = '',
  }) => {
    this.setState({
      codesInfoPopup: {
        isOpen: true,
        codes: {
          remarkCodes,
          reasonCodes,
        },
        claimInfo: {
          claimId,
        },
      },
    });
  };

  setActivePage = (activePage, cb) =>
    this.mounted && this.setState({ activePage }, cb);

  setIsFetchingDenials = (isFetchingDenials, cb) =>
    this.mounted && this.setState({ isFetchingDenials }, cb);

  setDenials = (denials, cb) => this.mounted && this.setState({ denials }, cb);

  setTotalDenialsCount = (totalDenialsCount) =>
    this.mounted && this.setState({ totalDenialsCount });

  setFilters = (filters, cb) => {
    if (this.mounted) {
      this.setState({ filters }, () => {
        cb && cb();
        if (this.state.isFiltersLocked) {
          this.handleLockFilters();
        }
      });
    }
  };

  setIsFetchingFilterOptions = (isFetchingFilterOptions, cb) =>
    this.mounted && this.setState({ isFetchingFilterOptions }, cb);

  setIsAddDenialContainerOpen = (isAddDenialContainerOpen, cb) =>
    this.mounted &&
    this.setState(
      {
        isAddDenialContainerOpen,
      },
      cb
    );

  setDenialInformationFlyover = (denialInformationPopup, cb) =>
    this.mounted &&
    this.setState(
      {
        denialInformationPopup,
      },
      cb
    );

  setSelectedClaimInfos = (selectedClaimInfos, cb) =>
    this.mounted && this.setState({ selectedClaimInfos }, cb);

  setClaimAssignmentPopup = (claimAssignmentPopup, cb) =>
    this.mounted && this.setState({ claimAssignmentPopup }, cb);

  setPageCounts = (pageCounts, cb) =>
    this.mounted && this.setState({ pageCounts }, cb);

  resetPageCounts = () =>
    this.mounted && this.setState({ pageCounts: DEFAULT_PAGE_COUNTS });

  handleApiErrors = async (error, shouldRedirect = true) => {
    handleError(error);

    const errorResponse = error.response;

    if (shouldRedirect && errorResponse.status === 400) {
      this.props.actions.replace(routes.SUBMISSIONS);
    }
  };

  setInitialFilters = (queryParams, cb) => {
    const { flyover, search: querySearch, reasonCode, rule } = queryParams;
    const search = querySearch && !flyover ? querySearch : null;
    const userId = this.props.userInfo.id;

    const shouldLockFilter = localStorage.getItem(
      interpolate(USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS, { userId })
    );

    const filters = {
      ...this.DQ_DEFAULT_FILTERS,
      reason_code: reasonCode || null,
      applied_rule: rule || null,
      search,
      zero_pay: search ? false : this.DQ_DEFAULT_FILTERS?.zero_pay,
    };

    if (shouldLockFilter === 'true') {
      const storedFilters =
        JSON.parse(
          localStorage.getItem(
            interpolate(USER_DENIALS_QUEUE_FILTER_VALUE, { userId })
          )
        ) || {};
      this.setState({ isFiltersLocked: true });
      this.setFilters({ ...filters, ...storedFilters }, cb);
    } else {
      this.setFilters(filters, cb);
    }
  };

  /**
   * Handles filters change confirmation
   * @param {String} key
   * @param {String} value
   */
  onFilterChange = (filters) => {
    const { filters: prevFilters } = this.state;
    const newFilters = {
      ...prevFilters,
      ...filters,
    };
    this.setFilters(newFilters, () => {
      this.resetAndFetchDenials();
    });
  };

  toggleFiltersLock = () => {
    const { isFiltersLocked, filters } = this.state;
    const userId = this.props.userInfo.id;

    const shouldLockKey = interpolate(
      USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS,
      { userId }
    );
    const filterValueKey = interpolate(USER_DENIALS_QUEUE_FILTER_VALUE, {
      userId,
    });

    if (isFiltersLocked) {
      localStorage.setItem(shouldLockKey, 'false');
      localStorage.removeItem(filterValueKey);
    } else {
      localStorage.setItem(shouldLockKey, 'true');
      localStorage.setItem(filterValueKey, JSON.stringify(filters));
    }

    this.setState({ isFiltersLocked: !isFiltersLocked });
  };

  handleLockFilters = () => {
    const { filters } = this.state;
    localStorage.setItem(
      interpolate(USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS, {
        userId: this.props.userInfo.id,
      }),
      'true'
    );
    localStorage.setItem(
      interpolate(USER_DENIALS_QUEUE_FILTER_VALUE, {
        userId: this.props.userInfo.id,
      }),
      JSON.stringify(filters)
    );
    this.setState({ isFiltersLocked: true });
  };

  handleUnlockFilters = () => {
    localStorage.setItem(
      interpolate(USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS, {
        userId: this.props.userInfo.id,
      }),
      'false'
    );
    localStorage.removeItem(
      interpolate(USER_DENIALS_QUEUE_FILTER_VALUE, {
        userId: this.props.userInfo.id,
      })
    );
    this.setState({ isFiltersLocked: false });
  };

  resetBulkClaimSelected = () => {
    if (this.props.isSelectAllOptionAvailable) {
      this.setState({
        isBulkClaimSelected: false,
      });
    }
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.userPracticeId = this.props.userInfo.practice || '';
    this.userSecretKey = this.props.userInfo.secretKey || '';

    this.fetchAndSetDeniedClaimsFilterOptions();
    if (this.props.queryParams.flyover === 'true') {
      this.setIsAddDenialContainerOpen(true);
    }
    this.setInitialFilters(this.props.queryParams, this.fetchAndSetDenials);
    this.fetchAndSetImportRulesCount();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.state.isBulkClaimSelected
    ) {
      this.resetBulkClaimSelected();
    }
    if (this.props.location.search !== prevProps.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      const action = params.get('action');
      const actionId = params.get('actionId');

      if (
        (action === GLOBAL_SEARCH_CLAIM_ACTIONS.ARCHIVE && actionId) ||
        (action === GLOBAL_SEARCH_CLAIM_ACTIONS.ASSIGN && actionId)
      ) {
        this.fetchAndSetDenials();
      }
    }
  }

  extractSearchParams = (filters = {}) => {
    return Object.entries(filters).map(([key, value]) => ({ key, value }));
  };

  fetchAndSetDenialsQueueCounts = async (filters) => {
    try {
      const searchRequestBodyParams = this.extractSearchParams(filters);
      const isArchivedPage = this.isArchivedPage();
      const isRulesApplied = Boolean(get(filters, 'applied_rule'));
      const [deniedClaimsFilterOptionsCounts, rulesOnlyCount] =
        await Promise.all([
          DenialsAPI.fetchDenialsQueueCounts(
            searchRequestBodyParams,
            {},
            isArchivedPage
          ),
          isRulesApplied
            ? DenialsAPI.fetchDenialsQueueCounts(
                this.extractSearchParams(omit(filters, ['applied_rule'])),
                {},
                isArchivedPage
              )
            : {},
        ]);
      const nonEmptyFilters = pickBy(filters, identity);
      if (isEmpty(this.state.initialRuleCount)) {
        this.setState({
          initialRuleCount: deniedClaimsFilterOptionsCounts.ruleCounts,
        });
      }

      const shouldUseCurrentRuleCount =
        Boolean(get(filters, 'applied_rule')) &&
        Object.keys(nonEmptyFilters).length === 1;
      this.setState({
        deniedClaimsFilterOptionsCounts: {
          ...deniedClaimsFilterOptionsCounts,
          payers: deniedClaimsFilterOptionsCounts.payerCounts, // payers
          providers: deniedClaimsFilterOptionsCounts.providerCounts, // providers
          cptCodes: deniedClaimsFilterOptionsCounts.cptCodeCounts, // modifiers
          reason: deniedClaimsFilterOptionsCounts.reasonCodeCounts, // reason
          remark: deniedClaimsFilterOptionsCounts.remarkCodeCounts, // remark
          practices: deniedClaimsFilterOptionsCounts.practiceIdentifierCounts, // practice
          rule: shouldUseCurrentRuleCount
            ? this.state.initialRuleCount
            : isRulesApplied
            ? rulesOnlyCount.ruleCounts
            : deniedClaimsFilterOptionsCounts.ruleCounts, // rules
          procedureModifiers:
            deniedClaimsFilterOptionsCounts.cptModifiersCodeCounts, // procedure modifier
          agents: deniedClaimsFilterOptionsCounts.assignedAgentsCounts, // Agents
          revenueCodes: deniedClaimsFilterOptionsCounts.revenueCodeCounts, // Revenue Codes
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  addCountToDenialQueueFilterOptions = (filterOptions) => {
    const extractLabelSuffix = (countValue) => {
      if (!countValue) return '(0)';
      return `(${countValue})`;
    };
    const extractOptionWithCountFromData = (option, countMap) => {
      if (option.label.toLowerCase() === 'all') {
        return option;
      }

      return {
        ...option,
        label: `${option.label} ${extractLabelSuffix(countMap[option.value])}`,
        count: countMap[option.value],
      };
    };

    const extractLabelSuffixForAgent = (countValue, allCountData) => {
      return `(${countValue + allCountData})`;
    };

    // add agents count
    const extractOptionWithCountFromAgents = (option, countMap) => {
      if (option.label.toLowerCase() === 'all') {
        return option;
      }
      const allCountData = countMap?._all_ || 0;
      return {
        ...option,
        label: `${option.label} ${extractLabelSuffixForAgent(
          countMap[option.value] || 0,
          allCountData
        )}`,
        count: countMap[option.value] + allCountData,
      };
    };

    /**
     * Get the specific count of particular object
     * @param {*} array
     * @param {objectKey} objectKey
     * @returns response
     */
    const extractObjectMapFromData = (array, objectKey) =>
      array.reduce((acc, cur) => {
        const key = cur[objectKey];

        return {
          ...acc,
          [key]: cur.count,
        };
      }, {});

    // payers
    const payersIdentifierMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.payers,
      'payerMasterId'
    );
    const payersWithCount = filterOptions.payers?.data.map((option) =>
      extractOptionWithCountFromData(option, payersIdentifierMap)
    );

    // providers
    const providerCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.providers,
      'providerMasterId'
    );
    const providersWithCount = filterOptions.providers?.data.map((option) =>
      extractOptionWithCountFromData(option, providerCountMap)
    );

    // procedure
    const cptModifiersCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.procedureModifiers,
      'cptModifiersCode'
    );
    const cptModifiersWithCount = filterOptions.procedureModifiers?.data.map(
      (option) => extractOptionWithCountFromData(option, cptModifiersCountMap)
    );

    // Reason
    const reasonCodeMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.reason,
      'reasonCode'
    );
    const reasonCodesWithCount = filterOptions.reasonCodes?.data.map((option) =>
      extractOptionWithCountFromData(option, reasonCodeMap)
    );

    const revenueCodesCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.revenueCodes,
      'revenueCode'
    );
    const revenueCodesWithCount = filterOptions.revenueCodes?.data.map(
      (option) => extractOptionWithCountFromData(option, revenueCodesCountMap)
    );

    // practices
    const practicesCodeMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.practices,
      'practiceIdentifier'
    );
    const practicesWithCount = filterOptions.practices?.data.map((option) =>
      extractOptionWithCountFromData(option, practicesCodeMap)
    );

    // remark
    const remarkCodeCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.remark,
      'remarkCode'
    );
    const remarkCodeWithCount = filterOptions.remarkCodes?.data.map((option) =>
      extractOptionWithCountFromData(option, remarkCodeCountMap)
    );

    // modifier
    const cptCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.cptCodes,
      'cptCode'
    );
    const cptCodeWithCount = filterOptions.cptCodes?.data.map((option) =>
      extractOptionWithCountFromData(option, cptCountMap)
    );

    // rules
    const rulesCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.rule,
      'appliedRule'
    );
    const rulesWithCount = filterOptions.rules?.data.map((option) =>
      extractOptionWithCountFromData(option, rulesCountMap)
    );

    // agents
    const agentsCountMap = extractObjectMapFromData(
      this.state.deniedClaimsFilterOptionsCounts.agents,
      'agent'
    );
    const agentsWithCount = filterOptions.agents?.data.map((option) =>
      extractOptionWithCountFromAgents(option, agentsCountMap)
    );

    const sortOptions = (options) =>
      orderBy(
        options,
        [
          (option) => {
            if (option.label.toLowerCase().trim() === 'all') {
              return undefined;
            }
            if (option.value === ALL_AGENTS_OPTION_VALUE) {
              return undefined;
            }
            return option.count || 0;
          },
        ],
        ['desc']
      );

    return {
      ...filterOptions,
      payers: {
        data: sortOptions(payersWithCount),
        isFetching: filterOptions?.payers?.isFetching,
      },
      providers: {
        data: sortOptions(providersWithCount),
        isFetching: filterOptions?.providers?.isFetching,
      },
      practices: {
        data: sortOptions(practicesWithCount),
        isFetching: filterOptions?.practices?.isFetching,
      },
      remarkCodes: {
        data: sortOptions(remarkCodeWithCount),
        isFetching: filterOptions?.remarkCodes?.isFetching,
      },
      reasonCodes: {
        data: sortOptions(reasonCodesWithCount),
        isFetching: filterOptions?.reasonCodes?.isFetching,
      },
      procedureModifiers: {
        data: sortOptions(cptModifiersWithCount),
        isFetching: filterOptions?.procedureModifiers?.isFetching,
      },
      cptCodes: {
        data: sortOptions(cptCodeWithCount),
        isFetching: filterOptions?.cptCodes?.isFetching,
      },
      rules: {
        data: sortOptions(rulesWithCount),
        isFetching: filterOptions?.rules?.isFetching,
      },
      agents: {
        data: sortOptions(agentsWithCount),
        isFetching: filterOptions?.agents?.isFetching,
      },
      revenueCodes: {
        data: sortOptions(revenueCodesWithCount),
        isFetching: filterOptions?.revenueCodes?.isFetching,
      },
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !isEqual(this.props.location.pathname, nextProps.location.pathname) ||
      !isEqual(this.props.queryParams, nextProps.queryParams)
    ) {
      if (nextProps.queryParams && nextProps.queryParams.flyover === 'true') {
        this.setIsAddDenialContainerOpen(true);
      } else {
        setTimeout(() => {
          this.setInitialFilters(
            nextProps.queryParams,
            this.fetchAndSetDenials
          );
          this.setIsAddDenialContainerOpen(false);
        }, 200);
      }
    }

    if (
      this.props.location.pathname !== nextProps.location.pathname &&
      this.isArchivedPage(nextProps.location.pathname)
    ) {
      this.setState({
        sortBy: {
          column: 'updatedAt',
          direction: 'desc',
        },
      });
    }
  }

  extractSortParamForRequest = () => {
    const { sortBy } = this.state;

    if (!sortBy.column) {
      return '';
    }

    const column = snakeCase(sortBy.column);
    return sortBy.direction !== 'desc' ? `${column}` : `-${column}`;
  };

  attachPracticeNameToDeniedClaim = (deniedClaim) => {
    const practiceIdentifier = deniedClaim.practiceId;

    const practiceName = practiceIdentifier
      ? this.props.userPracticesNameMap[practiceIdentifier] || ''
      : '';

    return {
      ...deniedClaim,
      practiceName,
    };
  };

  applyGlobalFilterForDenials = (filters) => {
    const agentFilters = [];
    const { edi, manualClaims } = this.state.sourceFilters;
    if (this.state.globalFilters.assignedToMe) {
      agentFilters.push(this.props.userInfo.username);
    }
    if (this.state.globalFilters.unassigned) {
      agentFilters.push('n/a');
    }

    if (this.state.globalFilters.assignedToOthers) {
      agentFilters.push('others');
    }

    if (filters?.agents) {
      return filters;
    }

    return {
      ...filters,
      agents: agentFilters,
      ...(!(edi && manualClaims) && {
        is_manual_claim: manualClaims,
      }),
    };
  };

  /**
   * Checks if the current page is an archived page.
   * @returns {boolean} true if the current page is an archived page, false otherwise.
   */
  isArchivedPage = (pathname) => {
    const ARCHIVED_PATH_NAMES = [
      routes.DENIED_QUEUE_ARCHIVED,
      routes.DENIED_QUEUE_LOGS_ARCHIVED,
      routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
    ];

    return ARCHIVED_PATH_NAMES.includes(
      pathname || this.props.location.pathname
    );
  };

  /**
   * Checks if the current page is an action log page.
   * @returns {boolean} true if the current page is an action log page, false otherwise.
   */
  isActionLogPage = () => {
    const ACTION_LOG_PATH_NAMES = [
      routes.DENIED_QUEUE_LOGS_ARCHIVED,
      routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
      routes.DENIED_QUEUE_LOGS,
      routes.DENIED_QUEUE_COMPLETED_LOGS,
    ];

    return ACTION_LOG_PATH_NAMES.includes(this.props.location.pathname);
  };

  fetchAndSetNotesCounts = async () => {
    const { denials } = this.state;
    const ids = denials.map((denial) => denial.id);
    try {
      const { notesCount, notesLatestCreatedTime } = await fetchNotesCounts(
        ids,
        false
      );
      this.setState({ notesCount, notesLatestCreatedTime });
    } catch (error) {}
  };

  fetchAndSetSopAndRuleInfo = async () => {
    const { denials } = this.state;
    const ids = denials
      .filter((denial) => denial.appliedRule)
      .map((denial) => denial.id);
    const sopAndRuleInfo = await fetchSopAndRuleInfo(ids, false);
    this.setState({ sopAndRuleInfo });
  };

  /**
   * Fetches and sets denials.
   */
  fetchAndSetDenials = async () => {
    if (this.isActionLogPage()) {
      return;
    }
    const sentryTransaction = startSentryTransaction(
      DENIALS_QUEUE_LOAD_EVENT_NAME,
      this.props.userInfo
    );

    try {
      const { filters, activePage } = this.state;
      this.setIsFetchingDenials(true);

      const offset = (activePage - 1) * TABLE_PAGE_LIMIT;

      const nonEmptyFilters = pickBy(filters, identity);
      const sort = this.extractSortParamForRequest();
      const practiceIdentifier = get(filters, 'practice_identifier');

      const [denialsRes, actionLogsCountRes] = await Promise.all([
        DenialsAPI.fetchDenials({
          offset,
          filters: this.applyGlobalFilterForDenials(nonEmptyFilters),
          sort,
          practiceId: this.userPracticeId,
          secretKey: this.userSecretKey,
          practiceIdentifier,
          fetchArchivedItems: this.isArchivedPage(),
        }),
        DenialsAPI.fetchDenials({
          filters: this.applyGlobalFilterForDenials({}),
          completed: 1,
          action: 1,
          onlyTotal: true,
          fetchArchivedItems: this.isArchivedPage(),
        }),
      ]);
      const { rows: denials, total } = denialsRes;

      this.setState((prevState) => ({
        pageCounts: {
          ...prevState.pageCounts,
          ...{
            denialsCounts: total,
            ...(this.isActionLogPage()
              ? {}
              : {
                  actionLogsTodoCounts: actionLogsCountRes.total.todo,
                  actionLogsCompletedCounts: actionLogsCountRes.total.completed,
                }),
          },
        },
      }));

      const denialsWithPracticeName = denials.map(
        this.attachPracticeNameToDeniedClaim
      );
      this.fetchAndSetDenialsQueueCounts(
        this.applyGlobalFilterForDenials(nonEmptyFilters)
      );
      this.setDenials(denialsWithPracticeName, () => {
        this.fetchAndSetNotesCounts();
        this.fetchAndSetSopAndRuleInfo();
      });
      this.setTotalDenialsCount(total);

      this.setIsFetchingDenials(false);
    } catch (error) {
      this.setIsFetchingDenials(false);
      this.handleApiErrors(error);
    } finally {
      sentryTransaction?.finish();
    }
  };

  /**
   * Set filters for bulk denials update
   */
  updateBulkDenialsInfo = async (agentValues, action, actionType) => {
    if (this.isActionLogPage()) {
      return;
    }

    try {
      const { filters } = this.state;

      const nonEmptyFilters = pickBy(filters, identity);
      const practiceIdentifier = get(filters, 'practice_identifier');
      const isArchive = this.isArchivedPage();
      const params = {
        filters: this.applyGlobalFilterForDenials(nonEmptyFilters),
        practiceId: this.userPracticeId,
        secretKey: this.userSecretKey,
        practiceIdentifier,
        agents: agentValues,
        reason: action,
        ...(isArchive && { isArchive }),
      };

      if (actionType) {
        await bulkAssignCompletedActions(params);
        toast.success({
          title: 'Success',
          message: actionType
            ? `Action has been completed successfully.`
            : 'Action successfully assigned.',
        });
      } else {
        await bulkAssignActionAndAgents(params);
        toast.success({
          title: 'Success',
          message: `Agent(s) successfully assigned.`,
        });
        toast.success({
          title: 'Success',
          message: `Action successfully assigned.`,
        });
      }
      this.fetchAndSetDenials();
      this.setSelectedClaimInfos([]);
      this.resetBulkClaimSelected();
    } catch (error) {
      handleErrors(error);
    }
  };

  /**
   * Set filters for bulk denials update
   */
  archiveBulkClaims = async () => {
    if (this.isActionLogPage()) {
      return;
    }

    try {
      const { filters } = this.state;

      const nonEmptyFilters = pickBy(filters, identity);
      const practiceIdentifier = get(filters, 'practice_identifier');
      const params = {
        filters: this.applyGlobalFilterForDenials(nonEmptyFilters),
        practiceId: this.userPracticeId,
        secretKey: this.userSecretKey,
        practiceIdentifier,
        isArchive: this.isArchivedPage(),
      };
      await bulkAssignArchiveAction(params);
    } catch (error) {
      handleErrors(error);
    }
  };

  fetchAndSetImportRulesCount = async () => {
    try {
      this.setState({
        importRuleCount: 0,
      });

      const {
        data: { count: importRuleCount },
      } = await fetchImportRulesCount();

      this.setState({
        importRuleCount,
      });
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Fetches and sets options for denied claims.
   */
  fetchAndSetDeniedClaimsFilterOptions = async () => {
    this.props.actions.fetchPayerOptions(); // new appeals payer
    this.props.actions.fetchProviderOptions(); // new appeals provider
    this.props.actions.fetchDenialsReasonOptions();
    this.props.actions.fetchDenialsRemarkOptions();
    this.props.actions.fetchDenialsRevenueCodesOptions();
    this.props.actions.fetchDenialsCptCodeOptions();
    this.props.actions.fetchDenialsProcedureModifierOptions();
    this.props.actions.fetchDenialsRuleOptions();
    this.props.actions.fetchAgentOptions();
    this.props.actions.fetchDenialsPracticeOptions();
    if (isAuthorizedForPracticeGroup(this.props.userInfo)) {
      this.props.actions.fetchPracticeGroupOptions();
    }
  };

  /**
   * Opens add denial container.
   */
  openAddDenialContainer = () => this.setIsAddDenialContainerOpen(true);

  /**
   * Opens claim confirmation flyover in denial table
   */

  openClaimInformationFlyover = debounce((rowData) => {
    if (!rowData.manualClaimId) {
      const denialInformationPopup = {
        isOpen: true,
        claimId: rowData.claim || null,
        claimControlNumber: rowData.claimControlNumber || null,
      };

      this.setDenialInformationFlyover(denialInformationPopup);
    }
  }, 300);

  /**
   * Closes add denial container.
   */
  closeAddDenialContainer = () => {
    this.props.actions.replace();
    this.setIsAddDenialContainerOpen(false);
    this.fetchAndSetDenialsQueueCounts();
  };

  /**
   * Closes denialInformation popover
   */

  closeDenialInformationFlyover = () => {
    const denialInformationPopup = {
      isOpen: false,
    };
    this.setDenialInformationFlyover(denialInformationPopup);
  };

  /**
   * Remove denied claims from the denied claims on the state.
   *
   * @param {Object} claim
   */
  removeDenialFromDenialsList = (claim) => {
    const { denials, totalDenialsCount } = this.state;

    const denialsWithoutAppealedClaim = denials.filter(
      (denial) => !isEqual(denial, claim)
    );

    this.setDenials(denialsWithoutAppealedClaim);
    this.setTotalDenialsCount(totalDenialsCount - 1);
  };

  findDeniedClaimById = (claimId) =>
    this.state.denials.find((denial) => denial.claim === claimId);

  handleMissingImportClaimInfoError = ({
    claim,
    isMissingPayer,
    isMissingProvider,
  }) => {
    this.setState({
      missingImportClaimInfo: {
        claim,
        isMissingProvider,
        isMissingPayer,

        assignedRenderingProviderId: null,
        requestedRenderingProviderInfo: {},

        assignedPayerId: null,
        requestedPayerInfo: {},
      },
    });

    if (isMissingProvider) {
      this.openRenderingProviderPopup(claim);
    } else {
      this.openMissingPayerPopup(claim);
    }
  };

  handleArchiveDeniedClaims = async (deniedItemIds) => {
    if (this.state.isBulkClaimSelected) {
      await this.archiveBulkClaims();
    } else {
      await DenialsAPI.archiveDeniedClaim(deniedItemIds);
    }

    toast.success({
      title: 'Success',
      message: 'Claim(s) archived successfully.',
    });
    this.setSelectedClaimInfos([]);
    this.fetchAndSetDenials();
  };

  handleUnarchiveDeniedClaims = async (deniedItemIds) => {
    if (this.state.isBulkClaimSelected) {
      await this.archiveBulkClaims();
    } else {
      await DenialsAPI.unArchiveDeniedClaim(deniedItemIds);
    }

    toast.success({
      title: 'Success',
      message: 'Claim(s) moved to Active Denials Queue.',
    });
    this.setSelectedClaimInfos([]);
    this.fetchAndSetDenials();
  };

  /**
   * Handles appeal denied claim.
   *
   * @param {Object} claim
   */
  handleAppealDeniedClaim = async (claim, extraParams = {}) => {
    try {
      const { claim: claimNumber, claimControlNumber } = claim;
      const requestBody = {
        ...pickBy(extraParams, identity),
        claimNumber,
        claimControlNumber,
        ...(this.state.isCreatingAppeal.isMedicalRecord && {
          isMedicalRecord: true,
        }),
      };

      this.setState({
        isAppealingDeniedClaim: true,
      });

      const { appeal_id: appealId } = await DenialsAPI.appealDeniedClaim(
        requestBody
      );

      toast.success({
        title: 'Success',
        message: `${
          this.state.isCreatingAppeal.isMedicalRecord ? 'Record' : 'Appeal'
        } of claim id ${claimNumber} is now in progress.`,
      });

      this.setState({
        isAppealingDeniedClaim: false,
      });

      this.onRenderingProviderAssignSuccess(appealId);
    } catch (error) {
      this.setState({
        isAppealingDeniedClaim: false,
      });

      if (error.response.status === 404) {
        const errorBody = await error.response.json();
        const missingEntities = get(errorBody, 'missing_entity', []);

        if (isEmpty(missingEntities)) {
          return handleError(error);
        }

        const isMissingPayer = missingEntities.includes('PAYER');
        const isMissingProvider = missingEntities.includes('PROVIDER');

        return this.handleMissingImportClaimInfoError({
          claim,
          isMissingPayer,
          isMissingProvider,
        });
      }

      this.handleApiErrors(error, false);
    }
  };

  handleManualClaim = async (claim, params) => {
    const { claim: claimNumber, claimControlNumber, manualClaimId } = claim;
    const { isMedicalRecord } = params;
    if (isMedicalRecord && !claimNumber) {
      return this.setState({
        missingManualClaimInfo: {
          manualClaimId,
          isOpen: true,
          isMissingClaimNumber: true,
          isMissingClaimControlNumber: false,
          isMedicalRecord,
        },
      });
    }

    if (!isMedicalRecord && !claimControlNumber) {
      return this.setState({
        missingManualClaimInfo: {
          isOpen: true,
          manualClaimId,
          isMissingClaimNumber: false,
          isMissingClaimControlNumber: true,
          isMedicalRecord,
        },
      });
    }

    this.applyManualClaim(
      manualClaimId,
      isMedicalRecord ? claimNumber : claimControlNumber,
      params
    );
  };

  applyManualClaim = async (manualClaimId, claimNumber, params) => {
    try {
      const requestBody = {
        ...pickBy(params, identity),
        manualClaimId,
      };

      const { appeal_id: appealId } = await appealManualClaim(requestBody);
      toast.success({
        title: 'Success',
        message: `${
          this.state.isCreatingAppeal.isMedicalRecord ? 'Record' : 'Appeal'
        } of claim id ${claimNumber} is now in progress.`,
      });

      this.setAppealDataAndRedirect(appealId);
    } catch (error) {
      handleError(error);
    }
  };

  onRenderingProviderAssignSuccess = (appeal) => {
    this.closeMissingRenderingProviderPopup();
    this.setAppealDataAndRedirect(appeal);
  };

  onRequestAddRenderingProviderSuccess = () => {
    this.closeMissingRenderingProviderPopup(this.openPendingApprovalPopup);
  };

  setAppealDataAndRedirect = async (appealId) => {
    const appeal = await CreateAppealAPI.getAppealById(appealId);

    this.props.actions.setAppealData({
      appeals: [
        {
          ...appeal,
          step: 0,
        },
      ],
      name: appeal.patientName,
    });

    this.props.actions.push('/createappeal/patientinfo');
  };

  /**
   * Handles close denied claim.
   *
   * @param {Object} claim
   * @param {Boolean} shouldRemoveFromDenialsFlyover
   */
  handleCloseDeniedClaim = async (
    selectedClaims,
    markComplete = MARK_INCOMPLETE,
    action = ''
  ) => {
    try {
      const requestBody = {
        claims: selectedClaims.map((claim) => {
          return {
            claim: claim.claim,
            claimControlNumber: claim.claimControlNumber,
            id: claim.id,
          };
        }),
        action: {
          reason: action,
          complete: markComplete ? MARK_COMPLETE : MARK_INCOMPLETE,
        },
      };

      await DenialsAPI.closeDeniedClaim(requestBody);

      this.fetchAndSetDenials();
      this.setSelectedClaimInfos([]);

      toast.success({
        title: 'Success',
        message: markComplete
          ? 'Action completed successfully.'
          : 'Action successfully assigned.',
      });
    } catch (error) {
      this.handleApiErrors(error, false);
    }
  };

  /**
   * assigns agents to claims
   *
   * @param {Object} claims
   * @param {Object} agents
   * @param {Boolean}
   */
  assignAgents = async (claims, agents) => {
    try {
      const requestBody = {
        ids: claims,
        agents,
      };
      await DenialsAPI.assignAgents(requestBody);

      this.fetchAndSetDenials();
      this.setSelectedClaimInfos([]);

      toast.success({
        title: 'Success',
        message: 'Agent(s) assigned successfully',
      });
    } catch (error) {
      this.handleApiErrors(error, false);
    }
  };

  /**
   * Resets search string filters.
   *
   * @param {Function} cb Callback function
   */
  resetSearchStringFilters = (cb) => {
    const newFilters = {
      ...this.DQ_DEFAULT_FILTERS,
    };
    this.setFilters(newFilters, cb);
  };

  /**
   * Handles search string filter change.
   *
   * @param {String} key
   * @param {String} value
   */
  onSearchStringFilterChange = (key, value) => {
    const cb = () =>
      this.onFilterChange({ ...this.state.filters, [key]: value });
    this.resetSearchStringFilters(cb);
  };

  resetAndFetchDenials = () => {
    this.setState(
      {
        activePage: 1,
        denials: [],
        totalDenialsCount: 0,
      },
      this.fetchAndSetDenials
    );
  };

  /**
   * function to handle the input change
   */
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  /**
   * function to set claim removal reason
   */

  setAction = (action) =>
    this.setState({
      action,
    });

  /**
   * Handles sort table click.
   *
   * @param {String} column
   */
  onSortTableClick = (column) => {
    const { sortBy } = this.state;

    if (sortBy.column === column) {
      const newSortByDirection = sortBy.direction === 'desc' ? 'asc' : 'desc';

      const newSortBy = {
        column,
        direction: newSortByDirection,
      };

      this.setState({ sortBy: newSortBy });
    } else {
      const newSortBy = {
        column,
        direction: 'asc',
      };

      this.setState({
        sortBy: newSortBy,
      });
    }
    this.resetAndFetchDenials();
  };

  /**
   * Handles successful move to denials queue.
   */
  onMoveToDenialsQueueSuccess = () => {
    this.closeAddDenialContainer();

    this.resetAndFetchDenials();
  };

  /**
   * Resets claim assignment dialog
   */

  resetClaimAssignmentPopup = () => {
    const claimAssignmentPopup = {
      isOpen: false,
      markComplete: false,
      selectedClaims: [],
      selectedAgents: [],
    };

    this.setClaimAssignmentPopup(claimAssignmentPopup);
  };

  openClaimAssignmentPopup = (claims, markComplete = false) => {
    const claimAssignmentPopup = {
      isOpen: true,
      selectedClaims: claims,
      markComplete,
      selectedAgents: [],
    };

    this.setClaimAssignmentPopup(claimAssignmentPopup);
  };

  openAddManualClaimPopup = () =>
    this.setState((prev) => ({
      ...prev,
      showAddManualClaimPop: true,
    }));

  closeAddManualClaimPopup = (fetchDenials) =>
    this.setState(
      (prev) => ({ ...prev, showAddManualClaimPop: false }),
      () => {
        if (fetchDenials) {
          this.fetchAndSetDenials();
        }
      }
    );

  renderActionList = () => {
    return (
      <div className="bulk-delete-reason-container">
        <ActionList
          setAction={this.setAction}
          datacy="denial-queue-container-ReasonToRemove"
        />
      </div>
    );
  };

  getCompletedByOption = () => [
    {
      label: this.props.userInfo.firstName + ' ' + this.props.userInfo.lastName,
      value: this.props.userInfo.username,
    },
  ];

  renderAgentList = () => {
    const { selectedClaims } = this.state.claimAssignmentPopup;
    return (
      <div className="bulk-delete-reason-container mb-16">
        <AgentList
          selectedClaims={selectedClaims}
          setAgent={this.setClaimAssignmentPopup}
          assignAgentDialog={this.state.claimAssignmentPopup}
          isFetchingAgents={!this.props.isAppealioUsersFetched}
          datacy="denial-queue-container-agentList"
        />
      </div>
    );
  };

  renderAssignClaimsDialog() {
    const { claimAssignmentPopup, action } = this.state;
    const { selectedClaims, isOpen, markComplete, selectedAgents } =
      claimAssignmentPopup;
    const isActionEmpty = isNil(action);
    const handleAssignButtonClick = () => {
      const claimIds = selectedClaims.map(({ id }) => id);
      const agentValues = selectedAgents
        .filter(({ value }) => value)
        .map(({ value }) => value);
      const actionType = markComplete ? MARK_COMPLETE : MARK_INCOMPLETE;

      // Handle Bulk Update logic
      if (this.state.isBulkClaimSelected) {
        this.updateBulkDenialsInfo(agentValues, action, actionType);
      } else {
        if (!markComplete) {
          this.assignAgents(claimIds, agentValues);
        }
        if (!isActionEmpty) {
          this.handleCloseDeniedClaim(selectedClaims, actionType, action);
        }
      }

      this.resetClaimAssignmentPopup();
    };

    const disableHandleAssignButton = markComplete
      ? isActionEmpty
      : isActionEmpty &&
        selectedClaims.length === 1 &&
        isEqual(
          selectedAgents.map(({ value }) => value),
          selectedClaims[0].agentsUsername
        );

    const title = canAssignAgent(this.props.userInfo)
      ? `Assign Action and/or Agent(s)`
      : ` Assign Action`;

    if (isOpen) {
      return (
        <ScreenDimmerComponent>
          <div className={'appealio-popup appealio-action-popup'}>
            <div className="appealio-action-popup__header">
              <h4 className="appealio-action-popup__title">
                {markComplete ? 'Mark as Completed' : title}
              </h4>
            </div>
            <div className="appealio-action-popup__content">
              <p className="appealio-action-popup__details">
                You have selected{' '}
                <span className="text-bold">
                  {' '}
                  {this.state.isBulkClaimSelected
                    ? this.state.totalDenialsCount
                    : selectedClaims.length}{' '}
                </span>
                Claim(s).
              </p>
              <p className="fw-bold mb-2">
                {markComplete ? 'What action was completed?' : 'Select action:'}
              </p>
              {this.renderActionList()}

              {markComplete ? (
                <div>
                  <p className="fw-bold mb-2 mt-12">Mark as completed by:</p>
                  <Select
                    options={this.getCompletedByOption()}
                    value={this.getCompletedByOption()}
                    isMulti
                    hideMultiValueRemove
                    isDisabled
                    className="mb-16"
                  />
                </div>
              ) : (
                canAssignAgent(this.props.userInfo) && (
                  <>
                    <p className="fw-bold mb-2 mt-12">
                      Select agent(s) for assignment.
                    </p>
                    {this.renderAgentList()}
                  </>
                )
              )}

              <button
                className={`ap-button mt-12 ap-button--secondary ap-button--block ${
                  disableHandleAssignButton ? 'ap-button--disabled' : ''
                }`}
                onClick={handleAssignButtonClick}
                disabled={disableHandleAssignButton}
              >
                {markComplete ? 'Mark as completed' : 'Assign'}
              </button>
              <button
                className="ap-button ap-button--tertiary-outline ap-button--block"
                onClick={this.resetClaimAssignmentPopup}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScreenDimmerComponent>
      );
    }

    return '';
  }

  renderManualClaimAdditionPopup() {
    const { showAddManualClaimPop } = this.state;
    if (showAddManualClaimPop) {
      return (
        <AddManualClaimPopup
          userInfo={this.props.userInfo}
          fetchDenials={this.fetchAndSetDenials}
          closeAddManualClaimPopup={this.closeAddManualClaimPopup}
          dropdownOptions={this.props.dropdownOptions}
        />
      );
    }

    return '';
  }

  /**
   * Returns client info.
   * @returns {String}
   */
  renderClientInfo = () => {
    const clientName = this.props.userInfo.clientName
      ? `${this.props.userInfo.clientName} Denials`
      : '';

    return clientName;
  };

  handleClearFilterClick = () => {
    this.handleUnlockFilters();
    this.setState(
      {
        denials: [],
        totalDenialsCount: 0,
        filters: this.DQ_DEFAULT_FILTERS,
      },
      this.fetchAndSetDenials
    );
  };

  /**
   * Handle page change.
   */
  onPageChange = (page) => {
    const { isFetchingDenials } = this.state;

    if (!isFetchingDenials && page) {
      this.setDenials([], () => {
        this.setActivePage(page, this.fetchAndSetDenials);
      });
    }
  };

  requestNewPayerAndRenderingProvider = async (
    payerInfo,
    renderingProviderInfo
  ) => {
    const requests = [];

    if (!isEmpty(payerInfo)) {
      requests.push(DenialsAPI.requestAddPayer(payerInfo));
    }

    if (!isEmpty(renderingProviderInfo)) {
      requests.push(
        DenialsAPI.requestAddRenderingProvider(renderingProviderInfo)
      );
    }

    try {
      this.setState({
        isRequestingPayersAndProviders: true,
      });

      await Promise.all(requests);

      this.resetPopupsAndMissingImportClaimInfo(() =>
        this.openPendingApprovalPopup()
      );

      this.setState({
        isRequestingPayersAndProviders: false,
      });
    } catch (error) {
      handleError(error);
      this.setState({
        isRequestingPayersAndProviders: false,
      });
    }
  };

  handleMissingImportClaimInfo = async (missingImportClaimInfo) => {
    this.setState({ missingImportClaimInfo });

    const isProviderDataAvailable =
      !missingImportClaimInfo.isMissingProvider ||
      missingImportClaimInfo.assignedRenderingProviderId ||
      !isEmpty(missingImportClaimInfo.requestedRenderingProviderInfo);

    const isPayerDataAvailable =
      !missingImportClaimInfo.isMissingPayer ||
      missingImportClaimInfo.assignedPayerId ||
      !isEmpty(missingImportClaimInfo.requestedPayerInfo);

    if (!isPayerDataAvailable) {
      this.closeMissingRenderingProviderPopup();
      return this.openMissingPayerPopup(missingImportClaimInfo.claim);
    }

    if (!isProviderDataAvailable) {
      this.closeMissingPayerPopup();
      return this.openRenderingProviderPopup(missingImportClaimInfo.claim);
    }

    const hasAssignedPayerAndProvider =
      (!missingImportClaimInfo.isMissingPayer ||
        missingImportClaimInfo.assignedPayerId) &&
      (!missingImportClaimInfo.isMissingProvider ||
        missingImportClaimInfo.assignedRenderingProviderId);

    if (hasAssignedPayerAndProvider) {
      await this.handleAppealDeniedClaim(missingImportClaimInfo.claim, {
        providerId: +missingImportClaimInfo.assignedRenderingProviderId,
        payerId: +missingImportClaimInfo.assignedPayerId,
      });

      return;
    }

    this.requestNewPayerAndRenderingProvider(
      missingImportClaimInfo.requestedPayerInfo,
      missingImportClaimInfo.requestedRenderingProviderInfo
    );
  };

  handleAssignMissingPayerId = (payerId) => {
    this.handleMissingImportClaimInfo({
      ...this.state.missingImportClaimInfo,
      assignedPayerId: payerId,
    });
  };

  handleRequestNewPayer = (requestedPayerInfo) => {
    this.handleMissingImportClaimInfo({
      ...this.state.missingImportClaimInfo,
      requestedPayerInfo,
    });
  };

  handleAssignMissingRenderingProvider = (assignedRenderingProviderId) => {
    this.handleMissingImportClaimInfo({
      ...this.state.missingImportClaimInfo,
      assignedRenderingProviderId,
    });
  };

  handleRequestNewRenderingProvider = (requestedRenderingProviderInfo) => {
    this.handleMissingImportClaimInfo({
      ...this.state.missingImportClaimInfo,
      requestedRenderingProviderInfo,
    });
  };

  resetPopupsAndMissingImportClaimInfo = (cb) => {
    this.setState(
      {
        missingImportClaimInfo: {
          claimId: null,
          isMissingProvider: false,
          isMissingPayer: false,

          assignedRenderingProviderId: null,
          requestedRenderingProviderInfo: {},

          assignedPayerId: null,
          requestedPayerInfo: {},
        },
        missingRenderingProviderPopup: {
          isOpen: false,
          providerName: null,
          practiceId: null,
        },
        missingPayerPopup: {
          isOpen: false,
          payerName: null,
        },
      },
      cb
    );
  };

  fetchAndSetRulesAndRulesOptions = async () => {
    this.props.actions.fetchDenialsRuleOptions();
  };

  closeImportRulePopup = () =>
    this.setState(
      {
        importRulePopup: {
          isOpen: false,
        },
      },
      this.fetchAndSetRulesAndRulesOptions,
      this.fetchAndSetDenials()
    );

  openImportRulePopup = () =>
    this.setState({
      importRulePopup: {
        isOpen: true,
      },
    });

  renderPopups = () => {
    if (this.state.pendingApprovalPopup.isOpen) {
      return (
        <PendingApprovalPopup
          onClosePressed={() => this.closePendingApprovalPopup()}
        />
      );
    }

    if (this.state.importRulePopup.isOpen) {
      return (
        <ImportRulePopup
          dropdownOptions={this.props.dropdownOptions}
          onClosePressed={this.closeImportRulePopup}
          clientPartitionId={this.userPracticeId}
          openAddDenialContainer={this.openAddDenialContainer}
          userRole={this.props.userInfo.role}
          userInfo={this.props.userInfo}
          datacy="denial-queue-container-ImportRulePopup"
        />
      );
    }

    if (this.state.missingPayerPopup.isOpen) {
      return (
        <MissingPayerPopup
          payerName={this.state.missingPayerPopup.payerName}
          onAssignPayer={this.handleAssignMissingPayerId}
          onRequestNewPayer={this.handleRequestNewPayer}
          onClosePressed={this.resetPopupsAndMissingImportClaimInfo}
          isSubmittingData={
            this.state.isRequestingPayersAndProviders ||
            this.state.isAppealingDeniedClaim
          }
          datacy="denial-queue-container-MissingPayerPopup"
        />
      );
    }

    if (this.state.missingRenderingProviderPopup.isOpen) {
      return (
        <MissingRenderingProviderPopup
          providerName={this.state.missingRenderingProviderPopup.providerName}
          practiceId={this.state.missingRenderingProviderPopup.practiceId}
          integrationType={this.props.integrationType}
          onAssignRenderingProvider={this.handleAssignMissingRenderingProvider}
          onClosePressed={this.resetPopupsAndMissingImportClaimInfo}
          isSubmittingData={
            this.state.isRequestingPayersAndProviders ||
            this.state.isAppealingDeniedClaim
          }
          datacy="denial-queue-container-MissingRenderingProviderPopup"
        />
      );
    }

    if (this.state.codesInfoPopup.isOpen) {
      return (
        <CodesInfoPopup
          onClosePressed={this.setDefaultCodeInfoPopup}
          claimId={this.state.codesInfoPopup.claimInfo.claimId}
          remarkCodes={this.state.codesInfoPopup.codes.remarkCodes}
          reasonCodes={this.state.codesInfoPopup.codes.reasonCodes}
          datacy="denial-queue-container-CodesInfoPopup"
        />
      );
    }

    if (this.state.missingManualClaimInfo.isOpen) {
      return (
        <MissingManualClaimInfo
          onClosePressed={() =>
            this.setState((prev) => ({
              ...prev,
              missingManualClaimInfo: {
                ...prev.missingManualClaimInfo,
                isOpen: false,
              },
            }))
          }
          claimInfo={this.state.missingManualClaimInfo}
          applyManualClaim={this.applyManualClaim}
        />
      );
    }
  };

  extractFilterFilterOptions = (deniedClaimsFilterOptions, appliedFilters) => {
    if (!appliedFilters.practice_identifier) {
      return deniedClaimsFilterOptions;
    }

    const providers = deniedClaimsFilterOptions.providers?.data.filter(
      ({ practiceIdentifier }) =>
        !practiceIdentifier ||
        practiceIdentifier === appliedFilters.practice_identifier
    );

    return {
      ...deniedClaimsFilterOptions,
      providers: {
        data: providers,
        isFetching: deniedClaimsFilterOptions.providers?.isFetching,
      },
    };
  };

  mapToSelectedClaimInfo = (claim) => ({
    id: claim.id,
    claim: claim.claim,
    claimControlNumber: claim.claimControlNumber || '',
  });

  extractUniqueClaimInfos = (denials) =>
    uniqWith(denials.map(this.mapToSelectedClaimInfo), isEqual);

  isAllClaimsSelected = () => {
    const { denials, selectedClaimInfos } = this.state;

    const uniqueClaimInfos = this.extractUniqueClaimInfos(denials);

    return (
      selectedClaimInfos.length > 0 &&
      uniqueClaimInfos.length === selectedClaimInfos.length
    );
  };

  onSelectAllDeniedClaimsClicked = () => {
    const { denials, selectedClaimInfos } = this.state;

    const isSomeClaimsSelected = selectedClaimInfos.length > 0;
    const uniqueClaimInfos = this.extractUniqueClaimInfos(denials);

    const selectAllClaims =
      !this.isAllClaimsSelected() && !isSomeClaimsSelected;

    const newSelectedClaimInfos = selectAllClaims ? uniqueClaimInfos : [];

    this.setSelectedClaimInfos(newSelectedClaimInfos);
    this.resetBulkClaimSelected();
  };

  onSelectClaimsOnCurrentPage = () => {
    const { denials } = this.state;
    const uniqueClaimInfos = this.extractUniqueClaimInfos(denials);
    this.setSelectedClaimInfos(uniqueClaimInfos);
  };

  /**
   * Toggles selected claim info from the state.
   *
   * @param {String} claim
   * @param {String} claimControlNumber
   */
  handleToggleClaimSelection = (id, claim, claimControlNumber) => {
    if (this.state.isBulkClaimSelected) return;
    const newSelectedClaimInfos = xorWith(
      this.state.selectedClaimInfos,
      [
        {
          id,
          claim,
          claimControlNumber,
        },
      ],
      isEqual
    );

    this.setSelectedClaimInfos(newSelectedClaimInfos);
  };

  handleRowIdSelection = (rowId) =>
    this.setState((prev) => ({
      isCreatingAppeal: {
        ...prev.isCreatingAppeal,
        selectedRowId: rowId,
      },
    }));

  handleBulkSelect = () => {
    this.setState({
      isBulkClaimSelected: !this.state.isBulkClaimSelected,
    });
  };

  selectAllInCurrentPage = () => {};

  handleIsCreatingAppeal = (isMedicalRecord) =>
    this.setState((prev) => ({
      isCreatingAppeal: {
        ...prev.isCreatingAppeal,
        isMedicalRecord,
      },
    }));

  updateActionLogPageCounts = async (
    filters,
    logType,
    shouldUpdateCompletedCount = false,
    shouldUpdateTodoCount = true
  ) => {
    const searchRequestBodyParams = Object.entries(
      applyGlobalFilterForActionLogs(
        filters,
        this.state.globalFilters,
        this.props.userInfo.username,
        this.state.sourceFilters
      )
    ).map(([key, value]) => {
      if (key === 'agents' && value && !Array.isArray(value)) {
        return { key, value: [value] };
      }
      return { key, value };
    });

    const search = filter(
      searchRequestBodyParams,
      (o) => o.value || o.value === 0
    ).map(({ key, value }) => ({
      key: FILTER_KEYS[key] || key,
      value:
        key === FILTER_KEYS.pmNotesCopied
          ? value === PM_NOTE_STATUS.COPIED
          : value,
    }));

    const { total } = await DenialsAPI.fetchDenials({
      offset: 0,
      filters: search.reduce((result, obj) => {
        return { ...result, [obj.key]: obj.value };
      }, {}),
      completed: logType.completed,
      practiceId: this.userPracticeId,
      secretKey: this.userSecretKey,
      action: 1,
      onlyTotal: true,
      fetchArchivedItems: this.isArchivedPage(),
    });

    this.setState((prev) => ({
      ...prev,
      pageCounts: {
        ...prev.pageCounts,
        ...(logType.completed === 0 &&
          shouldUpdateTodoCount && { actionLogsTodoCounts: total.todo }),
        ...((logType.completed === 1 || shouldUpdateCompletedCount) && {
          actionLogsCompletedCounts: total.completed,
        }),
      },
    }));
  };

  updateDenialsTotalCount = async () => {
    const { total: denialsCounts } = await DenialsAPI.fetchDenials({
      offset: 0,
      filters: this.applyGlobalFilterForDenials({}),
      practiceId: this.userPracticeId,
      secretKey: this.userSecretKey,
      onlyTotal: true,
      fetchArchivedItems: this.isArchivedPage(),
    });
    this.setState((prev) => ({
      ...prev,
      pageCounts: {
        ...prev.pageCounts,
        denialsCounts,
      },
    }));
  };

  handleGlobalFilterChange = (key, value) => {
    const activeGlobalFiltersCount = Object.values(
      this.state.globalFilters
    ).filter((value) => value).length;

    if (activeGlobalFiltersCount === 1 && !value) {
      return;
    }

    this.setState(
      (prev) => ({
        ...prev,
        globalFilters: {
          ...prev.globalFilters,
          [key]: value,
        },
      }),
      this.fetchAndSetDenials
    );
  };

  handleSourceFilterChange = (key, value) => {
    const activeSourceFiltersCount = Object.values(
      this.state.sourceFilters
    ).filter((value) => value).length;

    if (activeSourceFiltersCount === 1 && !value) {
      return;
    }

    this.setState(
      (prev) => ({
        ...prev,
        sourceFilters: {
          ...prev.sourceFilters,
          [key]: value,
        },
      }),
      this.fetchAndSetDenials
    );
  };

  renderActionLog = (completed = true) => {
    if (
      this.isArchivedPage() &&
      !isUserAuthorizedForAutoArchiveDQ(this.props.userInfo)
    ) {
      return this.props.actions.replace(routes.DENIED_QUEUE_LOGS);
    }

    if (this.state.importRulePopup.isOpen) {
      return null;
    }

    return (
      <ActionLogs
        logType={{
          completed: completed ? 1 : 0,
        }}
        filterOptions={this.props.dropdownOptions}
        updateActionLogPageCounts={this.updateActionLogPageCounts}
        updateDenialsTotalCount={this.updateDenialsTotalCount}
        globalFilters={this.state.globalFilters}
        sourceFilters={this.state.sourceFilters}
        isArchivedPage={this.isArchivedPage()}
        isBulkClaimSelected={this.state.isBulkClaimSelected}
        onClickBulkSelect={this.handleBulkSelect}
        isSelectAllOptionAvailable={this.props.isSelectAllOptionAvailable}
        resetBulkClaimSelected={this.resetBulkClaimSelected}
        pageCounts={this.state.pageCounts}
        selectAllInCurrentPage={this.selectAllInCurrentPage}
      />
    );
  };

  renderDenialTable = () => {
    if (
      this.isArchivedPage() &&
      !isUserAuthorizedForAutoArchiveDQ(this.props.userInfo)
    ) {
      return this.props.actions.replace(routes.DENIED_QUEUE);
    }

    if (this.state.importRulePopup.isOpen) {
      return null;
    }

    const {
      denials,
      sortBy,
      filters,
      isFetchingDenials,
      totalDenialsCount,
      selectedClaimInfos,
    } = this.state;
    const extractedFilterOptions = this.addCountToDenialQueueFilterOptions(
      this.extractFilterFilterOptions(this.props.dropdownOptions, filters)
    );
    const pageCount = Math.ceil(totalDenialsCount / TABLE_PAGE_LIMIT);
    const isAllClaimsSelected = this.isAllClaimsSelected();

    const attachAgentsNameToDeniedClaims = (denial, users) => {
      const agents = denial.agents;
      const agentsUsername = denials.agents;
      const userMapByUsername = extractObjectMap(users, 'username', 'name');
      if (agents.includes(UNASSIGNED_OPTION.value) || !agents.length) {
        return {
          ...denial,
          agents: [UNASSIGNED_AGENT_LABEL],
          agentsUsername,
        };
      }

      const { firstName, lastName } = this.props.userInfo;
      if (!agents || agents.includes(ALL_AGENTS_OPTION_VALUE)) {
        const priorityAgent = filters.agents
          ? userMapByUsername[filters.agents[0]]
          : `${firstName} ${lastName}`;
        return {
          ...denial,
          agents: [priorityAgent, 'All Other agents'],
          agentsUsername: ALL_AGENTS_OPTION_VALUE,
        };
      }

      const sortedAgentByFilterApplied = filters.agents
        ? _sortBy(denial.agents, (username) =>
            username === filters.agents[0] ? 1 : 2
          )
        : agents;

      const agentNames = sortedAgentByFilterApplied
        .map((agentUsername) => userMapByUsername[agentUsername])
        .filter((agentName) => agentName);

      return {
        ...denial,
        agents: agentNames,
        agentsUsername: denial.agents,
      };
    };

    const denialsWithAgentName = denials.map((denial) => {
      return attachAgentsNameToDeniedClaims(denial, this.props.users);
    });

    const downloadDqReport = async () => {
      const currentDate = getFormattedDate(new Date());
      this.setState({ isExportingCSV: true });
      const isArchived = this.isArchivedPage();
      const fileName = isArchived
        ? `archived-dq-${currentDate}.csv`
        : `dq-${currentDate}.csv`;
      const sort = this.extractSortParamForRequest();
      const searchRequestBodyParams = Object.entries(
        applyGlobalFilterForActionLogs(
          filters,
          this.state.globalFilters,
          this.props.userInfo.username,
          this.state.sourceFilters
        )
      ).map(([key, value]) => {
        if (key === 'agents' && value && !Array.isArray(value)) {
          return { key, value: [value] };
        }
        return { key, value };
      });

      const search = filter(
        searchRequestBodyParams,
        (o) => o.value || o.value === 0
      ).map(({ key, value }) => ({ key: FILTER_KEYS[key] || key, value }));

      try {
        const res = await DenialsAPI.downloadReportForDenialsQueue({
          filters: search.reduce((result, obj) => {
            return { ...result, [obj.key]: obj.value };
          }, {}),
          sort,
          search,
          practiceId: this.userPracticeId,
          secretKey: this.userSecretKey,
          onlyExport: 1,
          fetchArchivedItems: isArchived,
          fileName,
          mimeType: 'text/csv',
        });
        this.setState({ isExportingCSV: false });
        toast.success({
          title: 'Success',
          message: EXPORT_CSV_MESSAGE,
        });
        return res;
      } catch (error) {
        this.setState({ isExportingCSV: false });
      }
    };
    return (
      <React.Fragment>
        <div
          className="d-flex flex-direction--column justify-content-center"
          datacy="denial-table-container"
        >
          <DenialTable
            userRole={this.props.userInfo.role}
            denials={denialsWithAgentName}
            sortBy={sortBy}
            onSortTableClick={this.onSortTableClick}
            onRowClick={this.openClaimInformationFlyover}
            filters={filters}
            isAllClaimsSelected={isAllClaimsSelected}
            isFetchingDenials={isFetchingDenials}
            isFetchingAgents={!this.props.isAppealioUsersFetched}
            onFilterChange={this.onFilterChange}
            onSearchStringFilterChange={this.onSearchStringFilterChange}
            handleAppealDeniedClaim={this.handleAppealDeniedClaim}
            handleClearFilterClick={this.handleClearFilterClick}
            filterOptions={extractedFilterOptions}
            totalDenialsCount={totalDenialsCount}
            selectedClaimInfos={selectedClaimInfos}
            onSelectAllDeniedClaimsClicked={this.onSelectAllDeniedClaimsClicked}
            handleToggleClaimSelection={this.handleToggleClaimSelection}
            handleAssignClaims={this.openClaimAssignmentPopup}
            onCodesCellClick={this.openCodesInfoPopup}
            activePage={this.state.activePage}
            pageCount={pageCount}
            onPageChange={this.onPageChange}
            userInfo={this.props.userInfo}
            fetchDenials={this.fetchAndSetDenials}
            isCreatingAppeal={this.state.isCreatingAppeal}
            handleRowIdSelection={this.handleRowIdSelection}
            handleIsCreatingAppeal={this.handleIsCreatingAppeal}
            isArchivedPage={this.isArchivedPage()}
            defaultFilters={this.DQ_DEFAULT_FILTERS}
            datacy="denial-queue-container-DenialTableContainer"
            handleArchiveDeniedClaims={this.handleArchiveDeniedClaims}
            handleUnarchiveDeniedClaims={this.handleUnarchiveDeniedClaims}
            claimsCountOnPage={denials.length}
            downloadDqReport={downloadDqReport}
            isExportingCSV={this.state.isExportingCSV}
            openAddManualClaimPopup={this.openAddManualClaimPopup}
            handleManualClaim={this.handleManualClaim}
            notesCount={this.state.notesCount}
            notesLatestCreatedTime={this.state.notesLatestCreatedTime}
            fetchNotesCount={this.fetchAndSetNotesCounts}
            sopAndRuleInfo={this.state.sopAndRuleInfo}
            toggleFiltersLock={this.toggleFiltersLock}
            isFiltersLocked={this.state.isFiltersLocked}
            isBulkClaimSelected={this.state.isBulkClaimSelected}
            isSelectAllOptionAvailable={this.props.isSelectAllOptionAvailable}
            onClickBulkSelect={this.handleBulkSelect}
            onSelectClaimsOnCurrentPage={this.onSelectClaimsOnCurrentPage}
          />
        </div>
        <RightSlideOut
          isOpen={this.state.denialInformationPopup.isOpen}
          onDimRegionPressed={this.closeDenialInformationFlyover}
          datacy="denial-queue-container-popup-open-RightSlideOut"
        >
          <DenialClaimInformationPopup
            closePopup={this.closeDenialInformationFlyover}
            denialClaimInformation={this.state.denialInformationPopup}
            datacy="denial-queue-container-DenialClaimInformationPopup"
          />
        </RightSlideOut>
        {this.renderAssignClaimsDialog()}
        {this.renderManualClaimAdditionPopup()}
      </React.Fragment>
    );
  };

  renderArchivedUnarchivedToggle = () => {
    if (!isUserAuthorizedForAutoArchiveDQ(this.props?.userInfo)) return null;

    const activeInactiveMap = {
      [routes.DENIED_QUEUE]: routes.DENIED_QUEUE_ARCHIVED,
      [routes.DENIED_QUEUE_LOGS]: routes.DENIED_QUEUE_LOGS_ARCHIVED,
      [routes.DENIED_QUEUE_COMPLETED_LOGS]:
        routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
    };

    const [currentActivePath, currentArchivedPath] = Object.entries(
      activeInactiveMap
    ).find(([key, value]) =>
      [key, value].includes(this.props.location.pathname)
    );

    const options = [
      {
        label: 'Active Worklists',
        value: currentActivePath,
        icon: ActiveIcon,
      },
      {
        label: 'Archive',
        value: currentArchivedPath,
        icon: ArchiveIcon,
      },
    ];
    const activeItem = this.isArchivedPage() ? options[1] : options[0];

    return (
      <NavigationToggle
        className="mr-8"
        options={options}
        activeOption={activeItem}
        onOptionClick={(option) => {
          this.props.actions.replace(option.value);
          this.setSelectedClaimInfos([]);
        }}
      />
    );
  };

  renderTabItem(label, route, activeRoutes, count) {
    const isActive = activeRoutes.includes(this.props.location.pathname);

    return (
      <div
        className={classnames('appealio-tab-filter__item', {
          'background-grey-4': isActive && this.isArchivedPage(),
          'appealio-tab-filter__item--active': isActive,
        })}
        onClick={() => {
          this.setFilters(
            !this.state.isFiltersLocked
              ? this.DQ_DEFAULT_FILTERS
              : this.state.filters,
            this.resetAndFetchDenials
          );
          this.props.actions.replace(
            this.isArchivedPage() ? route.archived : route.default
          );
        }}
      >
        {label} ({count})
      </div>
    );
  }

  render() {
    const { importRuleCount } = this.state;

    const isUserIsAuthorizedForEraUpload = isUserIsAuthorizedForManualEraUpload(
      this.props.userInfo
    );

    const { denialsCounts, actionLogsTodoCounts, actionLogsCompletedCounts } =
      this.state.pageCounts;

    return (
      <div className="denials-queue-container" datacy={this.props.datacy}>
        <div className="page__header page__header--sm justify-content--space-between">
          <div className="page__header--left d-flex">
            {this.renderArchivedUnarchivedToggle()}
            <ButtonDropdown
              onFilterChange={this.handleSourceFilterChange}
              filters={this.state.sourceFilters}
              labels={SOURCE_LABELS}
              dropdownLabel="Source:"
              className="mr-8"
            />
            <ButtonDropdown
              onFilterChange={this.handleGlobalFilterChange}
              filters={this.state.globalFilters}
              labels={ASSIGNMENT_LABELS}
            />
          </div>
          <div className="page__header--right">
            {isUserIsAuthorizedForEraUpload && (
              <Button
                title="Upload ERAs(835)"
                icon={uploadIcon}
                className="width-180 mr-16"
                type={BUTTON_TYPE.SECONDARY}
                iconClassName="mr-4"
                onClick={() =>
                  this.props.actions.replace(routes.MANUAL_ERA_UPLOAD)
                }
                datacy="upload-era-Button"
              />
            )}
            <Button
              icon={chargerIcon}
              title={'Rules'}
              type={BUTTON_TYPE.SECONDARY}
              className="mr-16"
              onClick={this.openImportRulePopup}
              datacy="automated-rules-Button"
            >
              {importRuleCount > 0 && (
                <Avatar
                  withBorder={false}
                  name={importRuleCount.toString()}
                  size={22}
                  shouldRenderFullName={true}
                  className="ml-4"
                  datacy="denial-queue-container-Avatar"
                />
              )}
            </Button>

            <Button
              title={DENIALS_FLYOVER_TITLE}
              type={BUTTON_TYPE.TERTIARY}
              iconClassName="mr-4"
              onClick={this.openAddDenialContainer}
              datacy="select-from-all-eras-Button"
            />
          </div>
        </div>

        <div className="appealio-tab-filter">
          <div className="d-flex align-items--baseline">
            {this.renderTabItem(
              this.isArchivedPage() ? 'Archived DQ' : 'Denials Queue',
              {
                default: routes.DENIED_QUEUE,
                archived: routes.DENIED_QUEUE_ARCHIVED,
              },
              [routes.DENIED_QUEUE, routes.DENIED_QUEUE_ARCHIVED],
              denialsCounts
            )}
          </div>
          <div className="d-flex align-items--baseline ml-8">
            {this.renderTabItem(
              this.isArchivedPage() ? 'Archived Pending' : 'Pending Actions',
              {
                default: routes.DENIED_QUEUE_LOGS,
                archived: routes.DENIED_QUEUE_LOGS_ARCHIVED,
              },
              [routes.DENIED_QUEUE_LOGS, routes.DENIED_QUEUE_LOGS_ARCHIVED],
              actionLogsTodoCounts
            )}
          </div>
          <div className="d-flex align-items--baseline ml-8">
            {this.renderTabItem(
              this.isArchivedPage()
                ? 'Archived Completed'
                : 'Completed Actions',
              {
                default: routes.DENIED_QUEUE_COMPLETED_LOGS,
                archived: routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
              },
              [
                routes.DENIED_QUEUE_COMPLETED_LOGS,
                routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
              ],
              actionLogsCompletedCounts
            )}
          </div>
        </div>

        <Switch>
          <Route
            exact
            path={routes.DENIED_QUEUE}
            render={() => (
              <React.Fragment>{this.renderDenialTable()}</React.Fragment>
            )}
          />
          <Route
            exact
            path={routes.DENIED_QUEUE_ARCHIVED}
            render={() => (
              <React.Fragment>{this.renderDenialTable()}</React.Fragment>
            )}
          />
          <Route
            exact
            path={routes.DENIED_QUEUE_LOGS_ARCHIVED}
            render={() => (
              <React.Fragment>{this.renderActionLog(false)}</React.Fragment>
            )}
          />
          <Route
            exact
            path={routes.DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED}
            render={() => (
              <React.Fragment>{this.renderActionLog(true)}</React.Fragment>
            )}
          />
          <Route
            exact
            path={routes.DENIED_QUEUE_LOGS}
            render={() => this.renderActionLog(false)}
          />
          <Route
            exact
            path={routes.DENIED_QUEUE_COMPLETED_LOGS}
            render={() => this.renderActionLog(true)}
          />
          <Redirect to={routes.DENIED_QUEUE} />
        </Switch>
        <RightSlideOut
          isOpen={this.state.isAddDenialContainerOpen}
          onDimRegionPressed={this.closeAddDenialContainer}
          datacy="denial-queue-container-open-RightSlideOut"
        >
          <DeniedClaimsTableContainer
            userPracticeId={this.userPracticeId}
            userSecretKey={this.userSecretKey}
            integrationType={this.props.integrationType}
            onMoveToDenialsQueueSuccess={this.onMoveToDenialsQueueSuccess}
            deniedClaimsFilterOptions={this.props.dropdownOptions}
            userPracticesNameMap={this.props.userPracticesNameMap}
            onCodesCellClick={this.openCodesInfoPopup}
            userInfo={this.props.userInfo}
            searchParam={this.props.queryParams.search}
            datacy="denial-queue-container-DeniedClaimsTableContainer"
          />
        </RightSlideOut>
        {this.renderPopups()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo(state);
  const agentDropdownOptions = getDropdownOptions(
    state,
    DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
  );

  const users = agentDropdownOptions?.data || [];
  const isAppealioUsersFetched = agentDropdownOptions?.isFetching === false;

  const { integrationType } = userInfo;
  const userPractices = get(userInfo, 'relatedPractices', []);
  const queryParams = parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  });
  const userPracticesNameMap = userPractices.reduce((acc, practice) => {
    const { practiceIdentifier, name } = practice;

    return {
      ...acc,
      [practiceIdentifier]: name,
    };
  }, {});

  const dropdownOptions = {
    rules: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_RULE_OPTIONS
    ),
    payers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PAYER_OPTIONS
    ),
    providers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PROVIDER_OPTIONS
    ),
    reasonCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REASON_OPTIONS
    ),
    remarkCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REMARK_OPTIONS
    ),
    cptCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_CPT_CODE_OPTIONS
    ),
    agents: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
    ),
    procedureModifiers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PROCEDURE_MODIFIER_OPTIONS
    ),
    practices: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
    practiceGroups: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PRACTICE_GROUP_OPTIONS
    ),
    revenueCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REVENUE_CODE_OPTIONS
    ),
  };

  const isSelectAllOptionAvailable =
    userInfo?.clientConfig?.enable_select_all_for_dq || false;

  return {
    users,
    userInfo,
    queryParams,
    userPractices,
    integrationType,
    userPracticesNameMap,
    isAppealioUsersFetched,
    dropdownOptions,
    isSelectAllOptionAvailable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
      replace,
      setAppealData,
      fetchAgentOptions,
      fetchDenialsRuleOptions,
      fetchPayerOptions,
      fetchProviderOptions,
      fetchDenialsReasonOptions,
      fetchDenialsRemarkOptions,
      fetchDenialsRevenueCodesOptions,
      fetchDenialsCptCodeOptions,
      fetchDenialsPracticeOptions,
      fetchPracticeGroupOptions,
      fetchDenialsProcedureModifierOptions,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DenialQueueContainer);
