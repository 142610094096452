import classnames from 'classnames';
import { connect } from 'react-redux';
import { HiPlus } from 'react-icons/hi';
import ReactTooltip from 'react-tooltip';
import { CgSearch } from 'react-icons/cg';
import ReactDOMServer from 'react-dom/server';
import { AiOutlineRight } from 'react-icons/ai';
import { MdOutlineEventNote } from 'react-icons/md';
import React, { useMemo, useCallback, useState } from 'react';
import { isInteger, isEmpty, omit, debounce, get } from 'lodash';

import binIcon from 'img/bin-white.svg';
import chargerIcon from 'img/charger-1.svg';
import settingsIcon from 'img/settings.svg';
import completeIcon from 'img/complete.svg';
import addCircleIcon from 'img/add-circle.svg';
import heartRedIcon from 'img/heart-red.svg';
import filterIconRed from 'img/filter-icon-red.svg';
import searchIcon from 'img/search-icon-white.svg';
import searchIconRed from 'img/search-icon-red.svg';
import manualDenialClaimIcon from 'img/manual-claim-dq.svg';

import { moneyFormatter } from 'helpers/money';
import {
  getPageCountMessage,
  extractArrayAndRemoveEmptyValues,
  extractArrayFromCommaSeparatedString,
  isDateWithinPastDays,
} from 'helpers/utils';
import { bindActionCreators } from 'redux';

import { calculateDateDifference, getFormattedDate } from 'helpers/dateFormats';

import Avatar from 'components/Shared/Avatar';
import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import DenialPopupFilter from './DenialPopupFilter';
import AppealioTable from 'components/common/table';
import SelectAllClaims from '../ActinLogs/SelectAllClaims';
import SearchBox from 'components/common/search/SearchBox';
import { BUTTON_TYPE } from 'components/common/button/Button';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import DotAnimation from 'components/common/dotAnimation/DotAnimation';
import DownloadClaimsCsv from 'components/common/button/DownloadClaimsCSV';
import TablePagination from 'components/common/pagination/TablePagination';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

import { SEARCH_GENERIC_KEY } from 'constants/options';
import { PAYER_API_TYPE, UNASSIGNED_AGENT_LABEL } from 'constants/appConstants';

import {
  isUserAuthorizedForAutoArchiveDQ,
  isUHCClaimStatusCheckEnabledForUser,
  isAvailityClaimStatusCheckEnabledForUser,
} from 'Auth/FeatureFlags';

import { openClaimStatusRpaWidget } from 'redux/actions/rpaWidgetActions';
import { openUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';
import { getUHCClaimStatusSearchDataForDenialsQueue } from 'components/GlobalSearch/hook';

import NotesPopup from '../Notes';
import SopInfoIcon from 'components/Shared/SopInfoIcon';
import LockFilterButton from 'components/Shared/LockFilterButton';
import {
  getSopIds,
  hasSopIds,
} from 'components/Submissions/DeliveryTracking/hooks';

const CustomCell = (props) => {
  const { value } = props;

  const isEmptyString = value === '--' || !value;
  const cellValue = isEmptyString ? 'N/A' : value;
  const toolTip = isEmptyString || cellValue === 'N/A' ? '' : value;

  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={toolTip}>{cellValue}</span>
    </div>
  );
};

const PayerCell = ({ row }) => {
  const { appealsPayerName, payerName } = row.original;

  return <CustomCell value={appealsPayerName || payerName} />;
};

const BuildArrayCell = ({
  value: array,
  isLink = false,
  hideDataTip = false,
}) => {
  if (isEmpty(array)) {
    const handleClick = (e) => {
      if (!isLink) return;

      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div onClick={handleClick} className="appealio-table__custom-cell">
        <span data-tip={!hideDataTip ? 'None' : null}>None</span>
      </div>
    );
  }

  const firstArrayItem = array[0];
  const arrayLength = array.length;

  const dataToRender =
    arrayLength === 1
      ? firstArrayItem
      : `${firstArrayItem} +${arrayLength - 1}`;

  const dataTip = array.length === 1 ? array : array.join(', ');

  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__custom-cell--is-link': isLink,
      })}
    >
      <span data-for="array-tooltip" data-tip={!hideDataTip ? dataTip : null}>
        {dataToRender}
      </span>
      {<ReactTooltip id="array-tooltip" effect="solid" place="top" />}
    </div>
  );
};

const BuildDateArrayCell = ({ value: array }) => {
  if (isEmpty(array)) {
    return <div className="appealio-table__custom-cell">N/A</div>;
  }
  const formattedDate = array.map((date) => getFormattedDate(date));

  return <BuildArrayCell value={formattedDate} />;
};

const BuildArrayStringLinkCell = ({ value }) => {
  const array = extractArrayAndRemoveEmptyValues(value);

  return <BuildArrayCell value={array} isLink />;
};
const BuildProviderCell = ({ row }) => {
  const data = row.original;
  const providerName = data.providerName;
  const practiceName = data.practiceName || '';

  return (
    <div className={'appealio-table__custom-cell'}>
      <span data-tip={providerName} data-for="practice-provider-tooltip">
        {providerName}
      </span>
      <br />
      {practiceName && (
        <span data-tip={practiceName} data-for="practice-provider-tooltip">
          ({practiceName})
        </span>
      )}
      <ReactTooltip
        id="practice-provider-tooltip"
        className="interactive-tooltip"
        delayHide={600}
        effect="solid"
        place="left"
      />
    </div>
  );
};

const BuildProcedureModifierCell = ({ row }) => {
  const data = row.original;
  const cptCodes = extractArrayFromCommaSeparatedString(data.cptCode || '');
  const modifiers = extractArrayFromCommaSeparatedString(
    data.cptModifiersCode || ''
  );
  const revenueCodes = data.revenueCodes || [];

  const formatProcedureModifier = () => {
    if (!cptCodes.length && !modifiers.length && !revenueCodes.length) {
      return {
        procedureDisplay: 'None',
        revenueDisplay: 'None',
      };
    }

    const hasMultipleCPTs = cptCodes.length > 1;
    const hasMultipleModifiers = modifiers.length > 1;
    const hasMultipleRevCodes = revenueCodes.length > 1;

    let procedureDisplay = cptCodes.length ? cptCodes[0] : 'None';

    if (hasMultipleCPTs) {
      procedureDisplay += `+${cptCodes.length - 1}`;
    }

    if (modifiers.length > 0) {
      procedureDisplay += ` / ${modifiers[0]}`;
      if (hasMultipleModifiers) {
        procedureDisplay += `+${modifiers.length - 1}`;
      }
    }

    let revenueDisplay = 'None';
    if (revenueCodes.length > 0) {
      revenueDisplay = revenueCodes[0];
      if (hasMultipleRevCodes) {
        revenueDisplay += `+${revenueCodes.length - 1}`;
      }
    }

    return {
      procedureDisplay,
      revenueDisplay,
    };
  };

  const { procedureDisplay, revenueDisplay } = formatProcedureModifier();

  const renderTooltipContent = () => {
    return (
      <div>
        <div className="mb-8">
          Procedure(s): {isEmpty(cptCodes) ? 'None' : cptCodes.join(', ')}
        </div>
        <div className="mb-8">
          Modifier(s): {isEmpty(modifiers) ? 'None' : modifiers.join(', ')}
        </div>
        <div>
          Rev. Code(s):{' '}
          {isEmpty(revenueCodes) ? 'None' : revenueCodes.join(', ')}
        </div>
      </div>
    );
  };

  const dataTipContent = ReactDOMServer.renderToStaticMarkup(
    renderTooltipContent()
  );

  return (
    <div>
      <div
        data-for={`procedure-modifier-tooltip-${data.id}`}
        data-tip={dataTipContent}
        data-html={true}
      >
        <div>{procedureDisplay}</div>
        <div>({revenueDisplay})</div>
      </div>
      <ReactTooltip
        effect="solid"
        place="top"
        id={`procedure-modifier-tooltip-${data.id}`}
      />
    </div>
  );
};

const BuildDeadlineEobDateCell = ({ row }) => {
  const data = row.original;

  const { ediPaymentDate, appealDeadline } = data;

  const eobDate = ediPaymentDate ? getFormattedDate(ediPaymentDate) : null;
  const deadline = appealDeadline ? getFormattedDate(appealDeadline) : null;
  const daysUntilDeadline =
    eobDate && deadline
      ? calculateDateDifference(getFormattedDate(new Date()), deadline)
      : null;

  const isDeadlinePassed = daysUntilDeadline <= 0;
  return (
    <div className={'appealio-table__custom-cell'}>
      <span
        {...(!deadline ? {} : { 'data-tip': deadline })}
        data-for="eob-date-tooltip"
      >
        {daysUntilDeadline
          ? `${isDeadlinePassed ? 0 : daysUntilDeadline} Day(s)`
          : 'N/A'}
      </span>
      <br />
      {eobDate && (
        <span
          {...(!eobDate ? {} : { 'data-tip': eobDate })}
          data-for="eob-date-tooltip"
        >
          ({eobDate || 'N/A'})
        </span>
      )}
      <ReactTooltip
        id="eob-date-tooltip"
        className="interactive-tooltip"
        delayHide={600}
        effect="solid"
        place="left"
      />
    </div>
  );
};

const BuildPatientCell = ({ row, sopAndRuleInfo }) => {
  const { patientName, patientNumber, appliedRule } = row.original;

  return (
    <div
      className={
        'appealio-table__custom-cell d-flex align-items-center justify-content--space-between'
      }
    >
      <div className="appealio-table__custom-cell">
        <span data-tip={patientName} className="table-span--fixed-width">
          {patientName}
        </span>
        <br />
        {patientNumber && (
          <span data-tip={patientNumber}>({patientNumber})</span>
        )}
      </div>
      {appliedRule && (
        <React.Fragment>
          {hasSopIds(sopAndRuleInfo, row.original.id) ? (
            <SopInfoIcon
              appliedRule={appliedRule}
              sopIds={getSopIds(sopAndRuleInfo, row.original.id)}
            />
          ) : (
            <div className="table-rule-icon">
              <img
                src={chargerIcon}
                data-for="rule-name-tooltip"
                alt="applied rule"
                data-tip={`Applied Rule: ${appliedRule}`}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                id="rule-name-tooltip"
                className="sop-info-tooltip-background sop-info-tooltip-background--width-auto"
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const BuildAmountCell = ({ row }) => {
  const billedAmount = row.original.billedAmount;
  const paymentAmount = row.original.paymentAmount;

  const formattedBilledAmount = isInteger(billedAmount)
    ? billedAmount
    : parseFloat(billedAmount).toFixed(2);
  const formattedBilledAmountText = moneyFormatter().format(
    formattedBilledAmount
  );

  const formattedPaymentAmount = isInteger(paymentAmount)
    ? paymentAmount
    : parseFloat(paymentAmount).toFixed(2);
  const formattedPaymentAmountText = moneyFormatter().format(
    formattedPaymentAmount
  );
  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={formattedBilledAmountText}>
        {formattedBilledAmountText}
      </span>
      <br />
      <span data-tip={formattedPaymentAmountText}>
        ({formattedPaymentAmountText})
      </span>
    </div>
  );
};

const BuildDateCell = ({ value: date }) => {
  const formattedDate = getFormattedDate(date, 'll');
  const createdDate = formattedDate.split(',')[0];

  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={formattedDate}>{createdDate}</span>
      <ReactTooltip effect="solid" place="top" />
    </div>
  );
};

const canUseClaimSearchAPI = (denial, userInfo) => {
  const isUhcClaim =
    denial.payerApi === PAYER_API_TYPE.UHC &&
    isUHCClaimStatusCheckEnabledForUser(userInfo) &&
    denial.claimControlNumber &&
    denial.billingProviderTaxId &&
    denial.ediPayerId;

  const isAvailityClaim =
    denial.payerApi === PAYER_API_TYPE.AVAILITY &&
    isAvailityClaimStatusCheckEnabledForUser(userInfo) &&
    denial.claimControlNumber;
  return isUhcClaim || isAvailityClaim;
};

const DenialActionButton = (props) => {
  const {
    rowData,
    handleRowIdSelection,
    isCreatingAppeal,
    handleAppealDeniedClaim,
    handleIsCreatingAppeal,
    isArchivedPage,
    userInfo,
    handleArchiveDeniedClaims,
    handleUnarchiveDeniedClaims,
    handleManualClaim,
  } = props;
  const [isAppealingDeniedClaim, setIsAppealingDeniedClaim] = useState(false);

  const handleAppealClick = debounce(async (e, isMedicalRecord = false) => {
    e.preventDefault();
    e.stopPropagation();
    setIsAppealingDeniedClaim(true);
    handleIsCreatingAppeal(isMedicalRecord);
    const params = {
      ...(isMedicalRecord && { isMedicalRecord }),
    };
    rowData.manualClaimId
      ? await handleManualClaim(rowData, params)
      : await handleAppealDeniedClaim(rowData, params);

    setIsAppealingDeniedClaim(false);
  }, 600);

  const rowId = rowData.id;

  const handleArchiveStatusChange = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isArchivedPage) {
        handleUnarchiveDeniedClaims([rowId]);
      } else {
        handleArchiveDeniedClaims([rowId]);
      }
    },
    [
      isArchivedPage,
      rowId,
      handleUnarchiveDeniedClaims,
      handleArchiveDeniedClaims,
    ]
  );

  return (
    <div
      className="ap-dropmenu__wrapper"
      onClick={(e) => {
        e.stopPropagation();
        handleRowIdSelection(
          isCreatingAppeal.selectedRowId === rowData.id ? null : rowData.id
        );
      }}
    >
      <HiPlus
        size={22}
        className={classnames('mt-2 d-block center-block', {
          'is-active--icon': isCreatingAppeal.selectedRowId === rowData.id,
        })}
        style={{ color: '#5E6871' }}
      />
      {isCreatingAppeal.selectedRowId === rowData.id && (
        <OutsideClickWrapper
          handleOutsideClick={() => {
            if (isCreatingAppeal.selectedRowId) {
              handleRowIdSelection(null);
            }
          }}
        >
          <div className="ap-dropmenu ap-dropmenu--visible">
            <button
              className="ap-dropmenu__item"
              disabled={isAppealingDeniedClaim}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleAppealClick(e, true);
              }}
            >
              <span>Create Record</span>
              <span>
                <AiOutlineRight />
              </span>
            </button>

            <button
              className="ap-dropmenu__item"
              disabled={isAppealingDeniedClaim}
              onClick={handleAppealClick}
            >
              <span>Create Appeal</span>
              <span>
                <AiOutlineRight />
              </span>
            </button>
            {isUserAuthorizedForAutoArchiveDQ(userInfo) && (
              <button
                className="ap-dropmenu__item"
                onClick={handleArchiveStatusChange}
              >
                {!isArchivedPage ? 'Archive' : 'Unarchive'}
              </button>
            )}
          </div>
        </OutsideClickWrapper>
      )}
    </div>
  );
};

const ClaimSearchButton = (props) => {
  const {
    rowData,
    openUhcClaimStatusWidget,
    openAvailityClaimStatusWidget,
    userInfo,
  } = props;

  const isAvailityClaim =
    rowData.payerApi === PAYER_API_TYPE.AVAILITY &&
    isAvailityClaimStatusCheckEnabledForUser(userInfo) &&
    rowData.claimControlNumber;

  const handleClaimStatusCheckClick = async (e) => {
    e.stopPropagation();

    if (!canUseClaimSearchAPI(rowData, userInfo)) {
      return;
    }
    if (isAvailityClaim) {
      return openAvailityClaimStatusWidget({
        claimControlNumber: rowData.claimControlNumber,
        ediPayerId: rowData.ediPayerId,
      });
    }

    return openUhcClaimStatusWidget(
      getUHCClaimStatusSearchDataForDenialsQueue(rowData, userInfo)
    );
  };

  const canUseClaimSearch = canUseClaimSearchAPI(rowData, userInfo);

  return (
    <>
      {canUseClaimSearch && (
        <CgSearch
          size={22}
          data-tip={`Claim Status Check via ${
            isAvailityClaim ? `Availity` : `UHC`
          }`}
          onClick={handleClaimStatusCheckClick}
          className="appeal__action--appeal"
          datacy="denial-table-BsPencil"
        />
      )}
      <ReactTooltip effect="solid" place="top" />
    </>
  );
};

const ProviderNameCellHeader = () => {
  return (
    <div>
      Provider
      <br />
      (Practice)
    </div>
  );
};

const ProcedureModifierCellHeader = () => {
  return (
    <div>
      Proc. / Mod.
      <br />
      (Rev Code)
    </div>
  );
};

const DeadlineEobDateCellHeader = () => {
  return (
    <div>
      Until Deadline
      <br />
      (EOB Date)
    </div>
  );
};

const BilledAmountCellHeader = () => {
  return (
    <div>
      Billed Amt
      <br />
      (Payment Amt)
    </div>
  );
};

const ClaimCell = (props) => {
  const { value } = props;
  const isEmptyString = value === '--' || !value;
  const cellValue = isEmptyString ? 'N/A' : value;
  const toolTip = isEmptyString ? '' : value;
  const randomId = Math.random().toString(36).slice(2, 9);
  return (
    <div
      className="appealio-table__custom-cell"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div data-tip={toolTip} data-for={randomId} data-delay-hide="400">
        {cellValue}
      </div>
      <ReactTooltip
        effect="solid"
        className="interactive-tooltip"
        place="top"
        id={randomId}
      />
    </div>
  );
};

const DenialTable = React.memo(
  ({
    userRole,
    denials,
    filters,
    filterOptions,
    onSortTableClick,
    selectedClaimInfos,
    handleAppealDeniedClaim,
    handleAssignClaims,
    isFetchingDenials,
    isAllClaimsSelected,
    onSelectAllDeniedClaimsClicked,
    handleToggleClaimSelection,
    onCodesCellClick,
    onRowClick,
    totalDenialsCount,
    pageCount,
    activePage,
    onPageChange,
    claimsCountOnPage,
    onFilterChange,
    handleClearFilterClick,
    onSearchStringFilterChange,
    userInfo,
    isCreatingAppeal,
    handleRowIdSelection,
    handleIsCreatingAppeal,
    actions: { openUhcClaimStatusWidget, openAvailityClaimStatusWidget },
    handleArchiveDeniedClaims,
    handleUnarchiveDeniedClaims,
    isArchivedPage,
    isFetchingAgents,
    defaultFilters,
    downloadDqReport,
    isExportingCSV,
    openAddManualClaimPopup,
    handleManualClaim,
    notesCount,
    notesLatestCreatedTime,
    fetchNotesCount,
    sopAndRuleInfo,
    isFiltersLocked,
    toggleFiltersLock,
    isBulkClaimSelected,
    onClickBulkSelect,
    isSelectAllOptionAvailable,
    onSelectClaimsOnCurrentPage,
  }) => {
    const { rules: rulesOptions } = filterOptions;
    const [selectedFavoriteFilter, setSelectedFavoriteFilter] = useState(null);

    const [showSearchBox, setShowSearchBox] = useState(false);
    const [showDenialFilters, setShowDenialFilters] = useState(false);
    const [notesPopup, setNotesPopup] = useState({
      isOpen: false,
      claimId: '',
      denialId: '',
    });

    const notesPopupQueryParams = useMemo(() => {
      return {
        archive: isArchivedPage ? 1 : 0,
      };
    }, [isArchivedPage]);

    const onCloseNotesPopup = () => {
      setNotesPopup({
        isOpen: false,
        appealId: '',
        claimId: '',
      });
      fetchNotesCount();
    };

    const handleCodeCellClick = useCallback(
      (rowData) => {
        const { reasonCodeArray, remarkCodesArray, claim: claimId } = rowData;
        onCodesCellClick({
          reasonCodes: extractArrayAndRemoveEmptyValues(reasonCodeArray),
          remarkCodes: extractArrayAndRemoveEmptyValues(remarkCodesArray),
          claimId,
        });
      },
      [onCodesCellClick]
    );

    const isFilterEmpty = Object.keys(filters).every(
      (filterKey) => filters[filterKey] === defaultFilters[filterKey]
    );

    const handleAppliedRuleChange = (rule) =>
      onFilterChange({
        ...filters,
        applied_rule: rule.value,
      });

    const selectedRuleOption =
      rulesOptions?.data?.find(
        (option) => filters.applied_rule === option.value
      ) || null;

    const {
      search,
      patient_name: patientName,
      claim,
      patient_number: patientNumber,
    } = filters;

    const lastSearchStringSubmitted =
      search || patientName || claim || patientNumber || '';

    const handleOnSearchStringFilterChange = (value) =>
      onSearchStringFilterChange(SEARCH_GENERIC_KEY, value);

    const debounceHandleOnSearchStringFilterChange = debounce(
      handleOnSearchStringFilterChange,
      500
    );

    const isFiltersApplied = !Object.values(omit(filters, 'search')).every(
      (x) => x === null || x === '' || x === undefined || x === false
    );

    const appliedFiltersCount = Object.values(
      omit(
        filters,
        'search',
        'service_date_to',
        'date_archived_to',
        'date_added_to'
      )
    ).filter((item) => item).length;

    const ActionButtonCell = useCallback(
      (props) => {
        return (
          <DenialActionButton
            rowData={props.row.original}
            handleAppealDeniedClaim={handleAppealDeniedClaim}
            handleManualClaim={handleManualClaim}
            datacy="denial-table-DenialActionButton"
            isCreatingAppeal={isCreatingAppeal}
            handleIsCreatingAppeal={handleIsCreatingAppeal}
            handleRowIdSelection={handleRowIdSelection}
            isArchivedPage={isArchivedPage}
            handleArchiveDeniedClaims={handleArchiveDeniedClaims}
            handleUnarchiveDeniedClaims={handleUnarchiveDeniedClaims}
            userInfo={userInfo}
          />
        );
      },
      [
        handleAppealDeniedClaim,
        isCreatingAppeal,
        handleIsCreatingAppeal,
        handleRowIdSelection,
        isArchivedPage,
        handleArchiveDeniedClaims,
        handleUnarchiveDeniedClaims,
        userInfo,
        handleManualClaim,
      ]
    );

    const AgentCell = useCallback(
      (props) => {
        const agents = props?.row?.original?.agents || [];

        const isUnassignedClaim =
          agents?.length === 1 && agents[0] === UNASSIGNED_AGENT_LABEL;

        if (isUnassignedClaim || !isFetchingAgents) {
          return <BuildArrayCell value={agents} />;
        }

        return <DotAnimation className="ml-12" />;
      },
      [isFetchingAgents]
    );

    const SelectCell = useCallback(
      (props) => {
        const rowData = props.row.original;
        const selectedClaimInfo = selectedClaimInfos.find(
          ({ id }) => rowData.id === id
        );

        const isChecked = isBulkClaimSelected || selectedClaimInfo;

        return (
          <div>
            <input
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                handleToggleClaimSelection(
                  rowData.id,
                  rowData.claim,
                  rowData.claimControlNumber
                );
              }}
              checked={isChecked}
              type="checkbox"
              className="denials-queue-td-checkbox"
              datacy={`denials-table-claim-selection-checkbox-${rowData.claimControlNumber}`}
            />
          </div>
        );
      }, // eslint-disable-next-line
      [selectedClaimInfos, handleToggleClaimSelection, isBulkClaimSelected]
    );

    // eslint-disable-next-line
    const NoteCell = (props) => {
      const claimId = props?.row?.original?.claim;
      const denialId = props?.row?.original?.id;
      const count = get(notesCount, denialId);

      const latestCreatedTime = get(notesLatestCreatedTime, denialId);
      const isNotesRecent = isDateWithinPastDays(latestCreatedTime, 7);

      return (
        <div
          className="appealio-table__custom-cell"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={classnames('custom-badge', {
              'custom-badge--border-0': !count,
              'custom-badge--primary': isNotesRecent,
            })}
            onClick={() => {
              setNotesPopup({
                isOpen: true,
                claimId,
                denialId,
              });
            }}
          >
            <MdOutlineEventNote
              size="20"
              className={classnames('cursor-pointer custom-badge__icon')}
            />
            <span> {count > 0 && count} </span>
          </div>
        </div>
      );
    };

    const ClaimSearchCell = useCallback(
      (props) => {
        return (
          <ClaimSearchButton
            rowData={props.row.original}
            userInfo={userInfo}
            openUhcClaimStatusWidget={openUhcClaimStatusWidget}
            datacy="denial-table-claimSearchButton"
            openAvailityClaimStatusWidget={openAvailityClaimStatusWidget}
          />
        );
      },
      [userInfo, openUhcClaimStatusWidget, openAvailityClaimStatusWidget]
    );

    const columns = useMemo(
      () => [
        {
          Header: '',
          accessor: 'select',
          disableSortBy: true,
          Cell: SelectCell,
          width: 35,
        },
        {
          Header: '',
          accessor: 'action',
          width: 40,
          sortKey: '',
          disableSortBy: true,
          className: 'appealio-table__custom-cell--visible',
          Cell: ActionButtonCell,
        },
        {
          Header: 'Patient',
          accessor: 'patientName',
          className: 'appealio-table-cell__border--right',
          width: 120,
          sortKey: 'patientLastName',
          Cell: (props) => (
            <BuildPatientCell {...props} sopAndRuleInfo={sopAndRuleInfo} />
          ),
        },
        {
          Header: '',
          accessor: 'claimSearch',
          width: 30,
          sortKey: '',
          disableSortBy: true,
          Cell: ClaimSearchCell,
          className: 'pr-0',
        },
        {
          Header: '',
          accessor: 'isNotesRecent',
          width: 80,
          sortKey: '',
          disableSortBy: true,
          Cell: NoteCell,
          className: 'pr-0',
        },
        {
          Header: 'Claim ID',
          accessor: 'claim',
          width: 90,
          sortKey: 'claim',
          Cell: ClaimCell,
          className: 'pl-0',
        },
        {
          Header: 'Service Date',
          accessor: 'serviceDate',
          width: 100,
          sortKey: 'minServiceDate',
          Cell: BuildDateArrayCell,
        },
        {
          Header: BilledAmountCellHeader,
          accessor: 'billedAmount',
          width: 115,
          sortKey: 'billedAmount',
          Cell: BuildAmountCell,
        },
        {
          Header: 'Payer',
          accessor: 'payerName',
          width: 80,
          sortKey: 'payerName',
          Cell: PayerCell,
        },
        {
          Header: ProviderNameCellHeader,
          accessor: 'providerName',
          width: 100,
          sortKey: 'providerName',
          Cell: BuildProviderCell,
          disableSortBy: true,
        },
        {
          Header: ProcedureModifierCellHeader,
          accessor: 'cptCode',
          width: 100,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildProcedureModifierCell,
        },
        {
          Header: 'Reason',
          accessor: 'reasonCodeArray',
          width: 80,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildArrayStringLinkCell,
          onCellClick: handleCodeCellClick,
        },
        {
          Header: 'Remark',
          accessor: 'remarkCodesArray',
          width: 70,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildArrayStringLinkCell,
          onCellClick: handleCodeCellClick,
        },
        {
          Header: 'Assigned To',
          accessor: 'agents',
          width: 95,
          sortKey: '',
          disableSortBy: true,
          Cell: AgentCell,
        },
        {
          Header: DeadlineEobDateCellHeader,
          accessor: 'deadline',
          width: 110,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildDeadlineEobDateCell,
        },
        {
          Header: !isArchivedPage ? 'Date Added' : 'Date Archived',
          accessor: !isArchivedPage ? 'createdAt' : 'updatedAt',
          className: 'appealio-table-cell__border--left',
          width: 100,
          sortKey: !isArchivedPage ? 'createdAt' : 'updatedAt',
          Cell: BuildDateCell,
        },
      ],
      [
        SelectCell,
        ActionButtonCell,
        ClaimSearchCell,
        NoteCell,
        handleCodeCellClick,
        AgentCell,
        isArchivedPage,
        sopAndRuleInfo,
      ]
    );

    const defaultSortBy = useMemo(
      () => ({ id: isArchivedPage ? 'updatedAt' : 'createdAt', desc: true }),
      [isArchivedPage]
    );

    const onTableSort = useCallback(
      (sortBy) => {
        if (!sortBy) return;
        return onSortTableClick(sortBy.id);
      },
      [onSortTableClick]
    );

    const isSomeClaimsSelected = selectedClaimInfos.length > 0;
    const checkboxTitleBulkAction =
      !isAllClaimsSelected && !isSomeClaimsSelected
        ? 'Select All'
        : 'Clear All';

    const selectedText = useMemo(() => {
      if (isBulkClaimSelected) {
        return `${totalDenialsCount} Selected`;
      }

      return selectedClaimInfos.length > 1
        ? `${selectedClaimInfos.length} Selected`
        : `1 Selected`;
    }, [isBulkClaimSelected, selectedClaimInfos.length, totalDenialsCount]);

    const claimText =
      totalDenialsCount === 0 || totalDenialsCount > 1 ? 'Claims' : 'Claim';
    const claimCountText =
      totalDenialsCount > 0
        ? `${getPageCountMessage(
            activePage,
            claimsCountOnPage,
            totalDenialsCount
          )} ${claimText}`
        : `${totalDenialsCount} ${claimText}`;

    const assignmentButtonTitle = `Assign`;

    const onExportCSVPressed = async () => {
      await downloadDqReport();
    };

    return (
      <div className="denial-table-container">
        <div
          className={classnames('appealio-table__action-bar row no-gutter', {
            'background-grey-4': isArchivedPage,
          })}
        >
          <div className="appealio-table__checkbox-action col-md-5">
            <React.Fragment>
              {denials.length > 0 && (
                <input
                  className="select-all-checkbox"
                  data-tip={checkboxTitleBulkAction}
                  onChange={onSelectAllDeniedClaimsClicked}
                  type="checkbox"
                  checked={isAllClaimsSelected}
                  ref={(el) =>
                    el &&
                    (el.indeterminate =
                      !isAllClaimsSelected && isSomeClaimsSelected)
                  }
                  datacy="select-all-checkbox-input"
                />
              )}

              <ReactTooltip effect="solid" />
              {!isArchivedPage && selectedClaimInfos.length < 1 && (
                <Button
                  title={''}
                  type={BUTTON_TYPE.SECONDARY}
                  icon={manualDenialClaimIcon}
                  className="ml-12 add-manual-denial-claim-btn"
                  onClick={openAddManualClaimPopup}
                  dataTip="Add Claim to DQ"
                  datacy="denial-table-manual-dq-Button"
                />
              )}

              {selectedClaimInfos.length > 0 && (
                <div className="d-flex align-items-center">
                  {!isArchivedPage ? (
                    <React.Fragment>
                      <Button
                        title={assignmentButtonTitle}
                        type={BUTTON_TYPE.SECONDARY}
                        iconClassName="mr-4"
                        icon={addCircleIcon}
                        className="ml-12"
                        onClick={() => {
                          const selectedDenials = denials.filter((denial) => {
                            return selectedClaimInfos.some((claim) => {
                              return claim.id === denial.id;
                            });
                          });
                          handleAssignClaims(selectedDenials);
                        }}
                        datacy="denial-table-assign-action-Button"
                      />
                      <Button
                        title="Completed"
                        type={BUTTON_TYPE.SECONDARY}
                        iconClassName="mr-4 py-5"
                        icon={completeIcon}
                        className="ml-12"
                        onClick={() => {
                          const selectedDenials = denials.filter((denial) => {
                            return selectedClaimInfos.some((claim) => {
                              return claim.id === denial.id;
                            });
                          });
                          handleAssignClaims(selectedDenials, true);
                        }}
                        datacy="denial-table-assign-action-Button"
                      />
                    </React.Fragment>
                  ) : null}

                  {isUserAuthorizedForAutoArchiveDQ(userInfo) && (
                    <Button
                      title={isArchivedPage ? 'Unarchive' : 'Archive'}
                      type={BUTTON_TYPE.SECONDARY}
                      iconClassName="mr-4"
                      icon={binIcon}
                      className="ml-12"
                      onClick={() => {
                        const ids = selectedClaimInfos.map(({ id }) => id);
                        if (isArchivedPage) {
                          handleUnarchiveDeniedClaims(ids);
                        } else {
                          handleArchiveDeniedClaims(ids);
                        }
                      }}
                      datacy="denial-table-assign-action-Button"
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          </div>

          {showSearchBox && (
            <SearchBox
              value={lastSearchStringSubmitted}
              onSearch={debounceHandleOnSearchStringFilterChange}
            />
          )}

          <div className="d-flex justify-content--space-between align-items-center col-md-4">
            <div className="d-flex align-items-center">
              <Dropdown
                className="width-140 mr-28"
                name="rule"
                placeholder="Filter by Rule"
                onChange={handleAppliedRuleChange}
                value={selectedRuleOption}
                isClearable={false}
                options={filterOptions?.rules?.data || []}
                isLoading={filterOptions?.rules?.isFetching}
                hideLabel
                datacy="filter-by-rule-Dropdown"
                toolTip={
                  selectedRuleOption && selectedRuleOption.value
                    ? selectedRuleOption.label
                    : ''
                }
              />
              <Button
                title=""
                icon={search ? searchIconRed : searchIcon}
                className="mr-20"
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setShowSearchBox(!showSearchBox)}
                datacy="more-filters-search-Button"
              />

              <Button
                title=""
                icon={isFiltersApplied ? filterIconRed : settingsIcon}
                className="appealio-filter-button"
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setShowDenialFilters(true)}
                datacy="more-filters-search-Button"
              >
                {selectedFavoriteFilter ? <img src={heartRedIcon} /> : null}
                {!selectedFavoriteFilter && appliedFiltersCount ? (
                  <Avatar
                    withBorder={false}
                    name={appliedFiltersCount.toString()}
                    size={16}
                    shouldRenderFullName={true}
                    datacy="denial-queue-container-Avatar"
                  />
                ) : null}
              </Button>

              <LockFilterButton
                className="ml-8"
                isLocked={isFiltersLocked}
                onClick={toggleFiltersLock}
              />

              {!isFilterEmpty && (
                <div
                  onClick={() => {
                    handleClearFilterClick();
                    setSelectedFavoriteFilter(null);
                  }}
                  className="denial-table__filter-clear"
                >
                  Reset
                </div>
              )}
            </div>
          </div>
          {/* Download csv  */}
          {!showSearchBox && (
            <div className="dq-download_report-container">
              <LoadingBlockHelper isLoading={isFetchingDenials}>
                <DownloadClaimsCsv
                  tableName={`Current Tab (${
                    isArchivedPage ? 'Archived Denials Queue' : 'Denials Queue'
                  } - ${totalDenialsCount})`}
                  onClick={onExportCSVPressed}
                  isLoading={isExportingCSV}
                  userInfo={userInfo}
                />
              </LoadingBlockHelper>
            </div>
          )}
          <div className="appealio-table__action-bar-pagination col-md-5">
            <div className="d-flex flex-direction--column">
              <span className="denial-table-count">
                {selectedClaimInfos.length ? selectedText : claimCountText}
              </span>
              <span>
                {selectedClaimInfos.length > 0 && (
                  <div className="d-flex justify-content--space-between align-items-center mr-12">
                    <SelectAllClaims
                      isSelectAllOptionAvailable={
                        selectedClaimInfos.length && isSelectAllOptionAvailable
                      }
                      totalDenialsCount={totalDenialsCount}
                      isBulkClaimSelected={isBulkClaimSelected}
                      onClickBulkSelect={onClickBulkSelect}
                      claimCountOnCurrentPage={denials.length}
                      selectAllClaimsOnCurrentPage={onSelectClaimsOnCurrentPage}
                    />
                  </div>
                )}
              </span>
            </div>
            {totalDenialsCount && pageCount > 1 ? (
              <div className="d-flex justify-content-center">
                <TablePagination
                  pageCount={pageCount}
                  activePage={activePage}
                  onPageChange={onPageChange}
                  datacy="denial-queue-container-Pagination"
                />
              </div>
            ) : null}
          </div>
        </div>
        <AppealioTable
          columns={columns}
          onSort={onTableSort}
          data={denials}
          isLoading={isFetchingDenials}
          className={classnames('denied-claims-table', {
            'appealio-table-body--grey-1-bg': isArchivedPage,
          })}
          wrapperClassName="dashboard-min-height"
          onRowClick={onRowClick}
          defaultSortBy={defaultSortBy}
          datacy="denial-table-AppealioTable"
        />
        {showDenialFilters && (
          <DenialPopupFilter
            userRole={userRole}
            userInfo={userInfo}
            setSelectedFavoriteFilter={setSelectedFavoriteFilter}
            selectedFavoriteFilter={selectedFavoriteFilter}
            setShowDenialFilters={setShowDenialFilters}
            filters={filters}
            onFilterChange={onFilterChange}
            handleClearFilterClick={handleClearFilterClick}
            filterOptions={filterOptions}
            defaultFilters={defaultFilters}
            isArchivedPage={isArchivedPage}
          />
        )}
        {notesPopup.isOpen && (
          <NotesPopup
            dqId={notesPopup.denialId}
            claimId={notesPopup.claimId}
            claimNumber={notesPopup.claimNumber}
            userInfo={userInfo}
            agents={filterOptions.agents?.data}
            setShowNotesPopup={onCloseNotesPopup}
            isAppeal={false}
            queryParams={notesPopupQueryParams}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

function mapStateToProps(state, ownProps) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openClaimStatusRpaWidget,
        openUhcClaimStatusWidget,
        openAvailityClaimStatusWidget,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DenialTable);
