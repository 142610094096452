import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ViewAppealDetailsButton from '../Shared/ViewAppealDetailsButton';
import DropdownListFilter from '../Shared/DropdownListFilterForKeyValue';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';
import LettersAndFormSelectWithCustomDropdown from './LettersAndFormSelectWithCustomDropdown';

import { DOCUMENT_CATEGORY } from 'constants/appConstants';

export default class CreateAppealSubHeader extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    agentsDropdownOptions: PropTypes.array,
    displayStatus: PropTypes.bool,
    displayAgentDropdown: PropTypes.bool,
    onAppealDetailsClicked: PropTypes.func,
    onBillingAgentChange: PropTypes.func,
    actions: PropTypes.object,
    step: PropTypes.number,
  };

  static defaultProps = {
    data: {
      patientName: '--',
      patient: {
        providerName: '--',
      },
      payerName: '--',
      status: '--',
      agent: null,
    },
    agentsDropdownOptions: [],
    displayStatus: true,
    displayAgentDropdown: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedAgentID: null,
    };
  }

  getHeaderCell(title, value) {
    return (
      <div className="createappeal-subheader__cell">
        <div className="createappeal-subheader__cell-title">{title}:</div>
        <div className="createappeal-subheader__cell-value" datacy={title}>
          {value || 'N/A'}
        </div>
      </div>
    );
  }

  getLetterDropdownOptionsAndCount(letters) {
    const appealLetter = letters
      .filter((letter) => letter.letter_type === 'html')
      .map((letter) => ({
        label: letter.name,
        value: letter.id,
        templateId: letter.template_id,
      }));

    const appealForms = letters
      .filter((letter) => letter.letter_type === 'pdf')
      .map((letter) => ({
        label: letter.name,
        value: letter.id,
        templateId: letter.template_id,
      }));

    const lettersDropdownOptions = [];

    if (appealLetter.length > 0) {
      lettersDropdownOptions.push({
        label: 'Appeal Letter',
        options: appealLetter,
      });
    }

    if (appealForms.length > 0) {
      lettersDropdownOptions.push({
        label: 'Appeal Forms',
        options: appealForms,
      });
    }

    return {
      options: lettersDropdownOptions,
      count: appealLetter.length + appealForms.length,
      appealLetterCount: appealLetter.length,
      appealFormsCount: appealForms.length,
    };
  }

  render() {
    const {
      practiceName = '',
      providerName = '',
      claimNumber: payerClaimId = '',
      submitterClaimId = '',
      payer: { name: payerName = '' },
      letters = [],
      documents = [],
    } = this.props.data;

    const {
      options: lettersDropdownOptions,
      count,
      appealLetterCount,
      appealFormsCount,
    } = this.getLetterDropdownOptionsAndCount(letters);

    const documentsAttached = documents
      .filter((doc) =>
        [
          DOCUMENT_CATEGORY.APPEAL_ATTACHMENT,
          DOCUMENT_CATEGORY.EOB,
          DOCUMENT_CATEGORY.CMS1500,
        ].includes(doc.documentCategory)
      )
      .map((doc) => ({
        label: doc.linkPath.split('/').pop(),
        value: doc.id,
      }));
    const allOptions = lettersDropdownOptions.flatMap((group) => group.options);

    const handleAssociateLettersOrFormsRedirection = (letterId, templateId) => {
      const { hasUnsavedLetterChanges } = this.props;

      if (hasUnsavedLetterChanges) {
        this.props.actions.setPendingLetterSwitch(letterId, templateId);
      } else {
        this.props.actions.replace({
          pathname: '/createappeal/appealletter',
          search: `?edit=${letterId}&templateId=${templateId}`,
        });
      }
    };

    return (
      <div className="createappeal-subheader" datacy="createappealSubheader">
        <div className="createappeal-subheader__cell-container">
          <div className="createappeal-subheader__titlecell">
            <div
              className="createappeal-subheader__titlecell-text"
              datacy="subheaderPatientName"
            >
              {this.props.data.patientName}
            </div>
          </div>
          {practiceName && this.getHeaderCell('Practice', practiceName)}
          {providerName && this.getHeaderCell('Provider', providerName)}
          {payerName && this.getHeaderCell('Payer', payerName)}
          {this.getHeaderCell(' Claim ID', submitterClaimId)}
          {this.getHeaderCell('Payer Claim ID', payerClaimId)}
          {this.props.displayStatus &&
            this.getHeaderCell('Status', this.props.data.status)}
          {this.renderAppealDetailsButton()}
        </div>
        <div className="createappeal-subheader__attached_letters-container d-inline-flex align-item-center">
          <div className="createappeal-subheader__attached_letters__title mr-8">
            Package
          </div>
          <div className="createappeal-subheader__attached_letters">
            <div className="createappeal-subheader__attached_letters__dropdown">
              <LettersAndFormSelectWithCustomDropdown
                input={{ value: allOptions }}
                disableOptionSelection={true}
                options={lettersDropdownOptions}
                labelForAllSelectedOption={
                  count > 0
                    ? `${appealLetterCount} Letter(s) and ${appealFormsCount} Form(s)`
                    : `No Letters or Forms`
                }
                isDisabled={allOptions.length === 0}
                handleAssociateLettersOrFormsRedirection={
                  handleAssociateLettersOrFormsRedirection
                }
                step={this.props.step}
              />
            </div>

            <div className="ml-8 createappeal-subheader__attached_documents__dropdown">
              <CustomDropdown
                isMulti
                options={documentsAttached}
                input={{ value: documentsAttached }}
                disableOptionSelection={true}
                labelForAllSelectedOption={
                  documentsAttached.length > 0
                    ? `${documentsAttached.length} Attachments`
                    : 'No Attachments'
                }
                placeholder="No Attachments"
                hideClearAllButton
                isDisabled={documentsAttached.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAppealDetailsButton() {
    if (this.props.data.appealRound > 1) {
      return <ViewAppealDetailsButton onClick={this.onDetailsClicked} />;
    }
  }

  renderAgentDropdown() {
    if (!this.props.agentsDropdownOptions) {
      return;
    }

    if (this.props.agentsDropdownOptions.length === 1) {
      return this.props.agentsDropdownOptions[0].value;
    }

    return (
      <DropdownListFilter
        placeholder="Select Agent"
        dropdownOptions={this.props.agentsDropdownOptions}
        maxStringLength={40}
        shouldTrimOptionLength={true}
        onDropdownFilterChange={this.onAgentDropdownAgentsChanged}
        selectedKey={this.state.selectedAgentID || this.props.data.agent.id}
        dropdownIndexSelected={this.props.agentsDropdownOptions.findIndex(
          (a) =>
            a.key === this.props.data.agent.id ||
            a.key === this.state.selectedAgentID
        )}
        input={this}
        error={this}
        allowNoneItem={false}
      />
    );
  }

  onDetailsClicked = () => {
    this.props.onAppealDetailsClicked();
  };

  onAgentDropdownAgentsChanged = (agentID) => {
    if (this.props.onBillingAgentChange && agentID) {
      this.setState({ selectedAgentID: agentID });
      this.props.onBillingAgentChange(agentID);
    }
  };
}
