import React from 'react';

import SearchResults from './SearchResults';
import SearchHistory from './SearchHistory';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import { SEARCH_QUERY_CHARACTER_OFFSET } from './hooks';

const SearchResultContainer = (props) => {
  const {
    searchQuery,
    handleSearchHistoryItemClick,
    searchHistoryData,
    isSearchHistoryLoading,
    counts,
    userInfo,
    isLoadingCount,
    handleRedirect,
    fetchCounts,
    handleActionForCount,
    closeSearch,
    searchResultsTab,
    activeParentTab,
    activeChildTab,
  } = props;

  return (
    <div className="global-search-v2__search-result-container">
      {!searchQuery &&
        !isSearchHistoryLoading &&
        searchHistoryData.length > 0 && (
          <div className="global-search-v2__search-result global-search-v2__search-result--results">
            <SearchHistory
              searchHistoryData={searchHistoryData}
              handleSearchHistoryItemClick={handleSearchHistoryItemClick}
            />
          </div>
        )}
      {searchQuery && searchQuery.length >= SEARCH_QUERY_CHARACTER_OFFSET && (
        <div className="global-search-v2__search-result global-search-v2__search-result--results">
          {isLoadingCount ? (
            <LoadingIndicator showing />
          ) : (
            <SearchResults
              counts={counts}
              closeSearch={closeSearch}
              userInfo={userInfo}
              isLoadingCount={isLoadingCount}
              searchQuery={searchQuery}
              handleRedirect={handleRedirect}
              fetchCounts={fetchCounts}
              handleActionForCount={handleActionForCount}
              searchResultsTab={searchResultsTab}
              activeChildTab={activeChildTab}
              activeParentTab={activeParentTab}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResultContainer;
