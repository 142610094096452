import { debounce } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import {
  Field,
  change,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';

import * as toast from '../Shared/toast';
import Dropdown from '../Shared/Dropdown';
import CheckBox from '../Shared/CheckBox';
import { renderField } from '../Shared/textfield';
import AppealioPopup from '../Shared/AppealioPopup';
import { FIELD_REQUIRED_STRING } from '../Shared/Errors/errorMessages';

import AppealsAPI from '../../API/AppealsAPI';
import { handleError } from '../../helpers/errorHandler';

const FORM_TYPE = {
  ASSIGN_PAYER: 'ASSIGN_PAYER',
  ADD_PAYER: 'ADD_PAYER',
};

class MissingPayerPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFormType: FORM_TYPE.ASSIGN_PAYER,
      renderingPayerOptions: [],
      selectedPayer: null,
      isFetchingPayerOptions: false,
      isAssigningPayer: false,
    };
  }

  setSelectedFormType = (selectedFormType) =>
    this.setState({ selectedFormType });

  setSelectedPayer = (selectedPayer) => this.setState({ selectedPayer });

  componentDidMount = async () => {
    toast.warning({
      title: 'Info',
      message: 'Missing Payer',
    });

    this.fetchAndSetRenderingPayerOptions();
  };

  fetchAndSetRenderingPayerOptions = async () => {
    try {
      const payersList = await AppealsAPI.getPayersOptions();

      this.setState({
        isFetchingPayerOptions: true,
      });

      const renderingPayerOptions = payersList.map((payer) => ({
        label: String(payer.name),
        value: payer.id,
      }));

      this.setState({
        renderingPayerOptions,
        isFetchingPayerOptions: false,
      });
    } catch (error) {
      this.setState({
        isFetchingPayerOptions: false,
      });

      handleError(error);
    }
  };

  validateForm = (values) => {
    const requiredFields = [
      {
        field: 'name',
        label: 'Name',
      },
    ];

    const errors = requiredFields.reduce((acc, { field, label }) => {
      if (values.get(field)) {
        return acc;
      }

      return {
        ...acc,
        [field]: `${label} ${FIELD_REQUIRED_STRING}`,
      };
    }, {});

    if (Object.keys(errors).length === 0) {
      return;
    }

    throw new SubmissionError(errors);
  };

  onRequestAddPayerFormSubmit = async (values) => {
    this.validateForm(values);

    const payload = {
      ...values.toJS(),
    };

    this.props.onRequestNewPayer(payload);
  };

  handleSelectedFormTypeChange = (e) => {
    this.setSelectedFormType(e.target.value);
  };

  assignPayer = async () => {
    const { selectedPayer } = this.state;

    if (!selectedPayer) {
      return;
    }

    const { value: selectedPayerId } = selectedPayer;

    this.props.onAssignPayer(selectedPayerId);
  };

  render() {
    const {
      submitting: isSubmittingAddPayer,
      invalid: isAddRenderingFormInvalid,
    } = this.props;

    const { isAssigningPayer, selectedPayer } = this.state;

    const isAssignPayerFormEnabled =
      this.state.selectedFormType === FORM_TYPE.ASSIGN_PAYER;
    const isAddPayerFormEnabled =
      this.state.selectedFormType === FORM_TYPE.ADD_PAYER;

    const isAssignPayerBtnDisabled =
      !selectedPayer ||
      isAssigningPayer ||
      isSubmittingAddPayer ||
      !isAssignPayerFormEnabled ||
      this.props.isSubmittingData === true;

    const isAddPayerSubmitFormDisabled =
      isSubmittingAddPayer ||
      isAddRenderingFormInvalid ||
      isAssigningPayer ||
      !isAddPayerFormEnabled ||
      this.props.isSubmittingData === true;

    return (
      <AppealioPopup
        title="Missing Payer"
        className="missing-payer-popup"
        onClosePressed={this.props.onClosePressed}
        datacy="missing-payer-AppealioPopup"
      >
        {this.props.payerName && (
          <div className="previous-payer-info mb-8">
            {this.props.integrationType} Payer Name: {this.props.payerName}
          </div>
        )}
        <div className="add-payer-form">
          <div className="assign-new-payer">
            <div>
              <label
                className="add-payer-label"
                htmlFor={FORM_TYPE.ASSIGN_PAYER}
              >
                <input
                  className="mr-8"
                  type="radio"
                  id={FORM_TYPE.ASSIGN_PAYER}
                  value={FORM_TYPE.ASSIGN_PAYER}
                  onChange={this.handleSelectedFormTypeChange}
                  checked={isAssignPayerFormEnabled}
                  datacy="assign-payer-radio-input"
                />
                Confirm Existing Payer
              </label>
              <div className="add-payer-sub-label">
                Please confirm the Payer, if available.
              </div>
            </div>
            <div
              className={classnames({
                'opacity-50': !isAssignPayerFormEnabled,
              })}
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown
                className="mb-22"
                placeholder="Select Payer"
                isLoading={this.state.isFetchingPayerOptions}
                options={this.state.renderingPayerOptions}
                onChange={this.setSelectedPayer}
                value={selectedPayer}
                disabled={!isAssignPayerFormEnabled}
                datacy="select-payer-Dropdown"
              />

              <button
                disabled={isAssignPayerBtnDisabled}
                onClick={debounce(this.assignPayer, 600)}
                className={classnames('add-payer-submit-button', {
                  'add-payer-submit-button--disabled': isAssignPayerBtnDisabled,
                })}
                datacy="assign-payer-button"
              >
                Assign
              </button>
            </div>
          </div>

          <div className="payer-vertical-divider">
            <div className="line"></div>
            <div className="wordwrapper">
              <div className="word">OR</div>
            </div>
          </div>

          <div className="add-new-payer">
            <div>
              <label className="add-payer-label" htmlFor={FORM_TYPE.ADD_PAYER}>
                <input
                  className="mr-8"
                  type="radio"
                  id={FORM_TYPE.ADD_PAYER}
                  value={FORM_TYPE.ADD_PAYER}
                  onChange={this.handleSelectedFormTypeChange}
                  checked={isAddPayerFormEnabled}
                  datacy="add-payer-radio-input"
                />
                Add New Payer
              </label>
              <div className="add-payer-sub-label">
                If your payer is not listed you can add a new payer.{' '}
              </div>
            </div>

            <form
              onSubmit={this.props.handleSubmit(
                this.onRequestAddPayerFormSubmit
              )}
              className={classnames({
                'opacity-50': !isAddPayerFormEnabled,
              })}
            >
              <div className="row no-gutter">
                <div className="col-xs-12">
                  <Field
                    label="Payer Name:"
                    name="name"
                    required={true}
                    component={renderField}
                    datacy="payer-name-Field"
                  />
                </div>
              </div>

              <div className="row no-gutter">
                <div className="col-xs-12">
                  <Field
                    label="Payer Number:"
                    name="payerNumber"
                    required={false}
                    component={renderField}
                    datacy="payer-number-Field"
                  />
                </div>
              </div>

              <div className="row no-gutter">
                <div className="col-xs-12">
                  <Field
                    label="Edi Payer ID:"
                    name="ediPayerId"
                    required={false}
                    component={renderField}
                    datacy="edi-payer-id-Field"
                  />
                </div>
              </div>

              <div className="row no-gutter mb-50">
                <div className="col-xs-12">
                  <Field
                    required={false}
                    title="Is worker compensation payer"
                    datacy="worker-compensation-Field"
                    component={() => (
                      <CheckBox
                        title="Is Worker Compensation Payer:"
                        name="isWorkerCompensationPayer"
                        checked={this.props.isWorkerCompensationPayer}
                        datacy="is-worker-compensation-Checkbox"
                        onChange={() => {
                          this.props.change(
                            'isWorkerCompensationPayer',
                            !this.props.isWorkerCompensationPayer
                          );
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="row no-gutter">
                <div className="col-xs-12">
                  <button
                    type="submit"
                    className={classnames('add-payer-submit-button mt-8', {
                      'add-payer-submit-button--disabled':
                        isAddPayerSubmitFormDisabled,
                    })}
                    disabled={isAddPayerSubmitFormDisabled}
                    datacy="missing-payer-popup-submit-button"
                  >
                    Add New Payer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </AppealioPopup>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('request_payer_addition');
  const isWorkerCompensationPayer = selector(
    state,
    'isWorkerCompensationPayer'
  );

  return {
    isWorkerCompensationPayer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ change }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'request_payer_addition',
    initialValues: {
      isWorkerCompensationPayer: false,
    },
  })(MissingPayerPopup)
);
