import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';

import './style.css';

import searchIcon from 'img/search-black.svg';
import arrowBackIcon from 'img/keyboard-arrow-return.svg';

const SearchBox = ({
  onSearch,
  selectedHistoryTerm,
  debounceDelay = 500,
  hideIcon = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useMemo(() => {
    return debounce((value) => {
      onSearch(value);
    }, debounceDelay);
  }, [onSearch, debounceDelay]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    setSearchTerm(selectedHistoryTerm);
  }, [selectedHistoryTerm]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value;
      debouncedSearch(value);
    }
  };

  const handleClick = () => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  };

  return (
    <div className="global-search-v2__input-container">
      {!hideIcon && (
        <div className="global-search-v2__input-icon">
          <img src={searchIcon} alt="Search" />
        </div>
      )}
      <input
        type="text"
        className="global-search-v2__input"
        placeholder="Search Appealio"
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus
      />
      <button
        className="btn ap-button--secondary search-box__submit-btn"
        datacy="search-button"
        onClick={handleClick}
      >
        <img src={arrowBackIcon} alt="enter" />
        <span className="ml-8">Enter</span>
      </button>
    </div>
  );
};

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  debounceDelay: PropTypes.number,
};

export default SearchBox;
