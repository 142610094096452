import { get } from 'lodash';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './successModalStyle.css';

import ScheduleForm from './ScheduleForm';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import CrossWalkInfo from './CrossWalkInfo';
import ScheduleInformation from './ScheduleInformation';
import AppealioPopup from 'components/Shared/AppealioPopup';

import { formatToMoney } from 'helpers/money';

import { addClaimToStatusTracker } from 'API/PayerAPI';

import { CLAIM_STATUS_TRACKER } from 'constants/routes';
import { CLAIM_STATUS_CHECK_CRON_OPTIONS } from 'constants/options';

const TitleText = ({ title, className }) => (
  <div className={classnames('success-modal-title', className)}>{title}</div>
);
const Value = ({ value, isBold, className }) => (
  <div
    className={classnames('success-modal-value', className, {
      'success-modal-value-bold': isBold,
    })}
  >
    {value}
  </div>
);

const ClaimData = ({
  label,
  value,
  labelClassName = 'col-lg-6',
  valueClassName = 'col-lg-6',
}) => {
  return (
    <div className="row mb-4">
      <div className={labelClassName}>
        <Value value={label} isBold />
      </div>
      <div className={valueClassName}>
        <Value value={value} />
      </div>
    </div>
  );
};

const ClaimDetailView = ({ claimInfo }) => {
  return (
    <div className="claim-detail-view">
      <div>
        <TitleText title="Claim Details" className="mb-12" />
        <CrossWalkInfo data={get(claimInfo, 'claimSummary.clmXWalkData', [])} />
        <div>
          <ClaimData
            label="Payer Claim ID"
            value={claimInfo.claimNumber}
            labelClassName="col-lg-3"
            valueClassName="col-lg-9"
          />
        </div>
        <div className="row mb-12">
          <div className="col-lg-6">
            <ClaimData
              label="Received Date"
              value={get(claimInfo, 'claimSummary.receivedDt')}
            />
            <ClaimData
              label="Service Date From"
              value={get(claimInfo, 'claimSummary.firstSrvcDt')}
            />
          </div>
          <div className="col-lg-6">
            <ClaimData
              label="Processed Date"
              value={get(claimInfo, 'claimSummary.processedDt')}
            />
            <ClaimData
              label="Service Date To"
              value={get(claimInfo, 'claimSummary.lastSrvcDt')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ClaimData
              label="Total Charged Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.totalChargedAmt')
              )}
            />
            <ClaimData
              label="Patient Not Covered Amt"
              value={formatToMoney(get(claimInfo, 'claimSummary.ptntNoCovAmt'))}
            />
            <ClaimData
              label="Total Allowed Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.totalAllowdAmt')
              )}
            />
            <ClaimData
              label="Total Copay Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.totalCopayAmt')
              )}
            />
            <ClaimData
              label="Total Paid Amt"
              value={formatToMoney(get(claimInfo, 'claimSummary.totalPaidAmt'))}
            />
            <ClaimData
              label="Network Status"
              value={
                get(claimInfo, 'claimSummary.networkStatus') === 'I'
                  ? 'In Network'
                  : 'Out Of Network'
              }
            />
          </div>
          <div className="col-lg-6">
            <ClaimData
              label="Provider Write Off Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.provWriteOffAmt')
              )}
            />
            <ClaimData
              label="Provider Not Covered Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.provNotCovAmt')
              )}
            />
            <ClaimData
              label="Deductible Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.deductibleAmt')
              )}
            />
            <ClaimData
              label="Total Coinsurance Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.totalCoinsAmt')
              )}
            />
            <ClaimData
              label="Total Patient Responsible Amt"
              value={formatToMoney(
                get(claimInfo, 'claimSummary.totalPtntRespAmt')
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <TitleText title="Check Info" className="mt-12 mb-12" />
        {get(claimInfo, 'claimSummary.chkInfo', []).length === 0 ? (
          <div>No check info</div>
        ) : (
          <div>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-2">
                <Value value="Check Number" isBold />
              </div>
              <div className="col-lg-2">
                <Value value="Check Amt" isBold />
              </div>
              <div className="col-lg-3">
                <Value value="Payment Issue Date" isBold />
              </div>
              <div className="col-lg-2">
                <Value value="Draft Amt" isBold />
              </div>
            </div>
            {get(claimInfo, 'claimSummary.chkInfo', []).map((item, idx) => (
              <div className="row" key={idx}>
                <div className="col-lg-1">{idx + 1}</div>
                <div className="col-lg-2">
                  <Value value={get(item, 'chkNbr')} />
                </div>
                <div className="col-lg-2">
                  <Value value={formatToMoney(get(item, 'chkAmt'))} />
                </div>
                <div className="col-lg-3">
                  <Value value={get(item, 'paymentIssueDt')} />
                </div>
                <div className="col-lg-2">
                  <Value value={formatToMoney(get(item, 'draftAmt'))} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SuccessModal = (props) => {
  const {
    claimInfo,
    setClaimInfo,
    onCloseClick,
    appealioInfo,
    showQuickActions = true,
  } = props;

  const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);
  const history = useHistory();

  const onSubmitForm = async (data) => {
    const { taxId, ediPayerId, claimNumber } = appealioInfo;
    const { schedule, noOfRetries } = data.toJS();
    try {
      await addClaimToStatusTracker(
        claimNumber,
        taxId,
        ediPayerId,
        schedule.value,
        noOfRetries
      );
      toast.success({
        title: '',
        message:
          'Your Claim has been successfully added to the Status Tracker.',
      });

      setClaimInfo({
        ...claimInfo,
        appealioClaimSchedule: {
          ...claimInfo.appealioClaimSchedule,
          schedule: schedule.value,
          noOfRetries,
        },
      });
    } catch (error) {
      toast.error({
        title: '',
        message:
          'Sorry, we could not add this Claim to the Status Tracker. Please try again later.',
      });
    }
    setIsScheduleFormOpen(false);
  };

  const appealioClaimSchedule = claimInfo && claimInfo.appealioClaimSchedule;

  const handleViewHistoryClick = () => {
    history.push({
      pathname: CLAIM_STATUS_TRACKER,
      search: `?id=${appealioClaimSchedule.id}&openHistory=true`,
    });
    onCloseClick();
  };

  return (
    <AppealioPopup
      title="Claim Status Details"
      className="claim-status-detail-popup uhc-claim-status-check-success-popup"
      isPopupAboveFlyover={true}
    >
      <div>
        <div
          className={classnames(
            'd-flex align-items-center justify-content--space-between',
            {
              'mb-12 mt-12': !showQuickActions,
            }
          )}
        >
          <div className="uhc-claim-message">
            {showQuickActions && (
              <div className="uhc-claim-message__title">
                We found your claim!
              </div>
            )}
            <div>
              {showQuickActions ? 'Latest' : ''} Claim Status for{' '}
              <strong>
                {`${claimInfo.memberInfo.ptntFn} ${claimInfo.memberInfo.ptntLn}`}
                ’s [Claim #: {claimInfo.claimNumber}]
              </strong>{' '}
              for charged amount{' '}
              <strong>${claimInfo.claimSummary.totalChargedAmt}</strong> is{' '}
              <strong>{claimInfo.claimStatus}</strong> as of{' '}
              <strong>{get(claimInfo, 'claimSummary.statusEfctDt')}</strong>.
            </div>
          </div>
          <div className="uhc-claim-status-check-close-btn-container">
            <Button type="secondary-outline" onClick={onCloseClick}>
              Close
            </Button>
          </div>
        </div>
        {showQuickActions && (
          <div className="claim-status-tracker-action-container">
            {isScheduleFormOpen ? (
              <ScheduleForm
                onSubmit={onSubmitForm}
                setIsScheduleFormOpen={setIsScheduleFormOpen}
              />
            ) : !appealioClaimSchedule.schedule ? (
              <Button
                type="secondary"
                className="add-claim-status-tracker"
                onClick={() => setIsScheduleFormOpen(true)}
              >
                Schedule Automatic Status Checks
              </Button>
            ) : (
              <ScheduleInformation
                appealioClaimSchedule={appealioClaimSchedule}
                onSubmit={onSubmitForm}
                initialValues={{
                  noOfRetries: appealioClaimSchedule.noOfRetries,
                  schedule: CLAIM_STATUS_CHECK_CRON_OPTIONS.find(
                    (option) => option.value === appealioClaimSchedule.schedule
                  ),
                }}
                onViewHistoryClick={handleViewHistoryClick}
              />
            )}
          </div>
        )}
      </div>
      <ClaimDetailView claimInfo={claimInfo} />
    </AppealioPopup>
  );
};

export default SuccessModal;
