import React from 'react';
const SearchHistory = (props) => {
  const { searchHistoryData, handleSearchHistoryItemClick } = props;

  return (
    <div className="global-search-v2__last-search-items">
      <div className="global-search-v2__last-search-item">
        <div className="global-search-v2__last-search-item-title">
          Recent Searches
        </div>
        <div className="global-search-v2__last-search-item-content">
          <ul className="global-search-v2__last-search-item-list">
            {searchHistoryData.map((item) => (
              <li
                key={item.id}
                className="global-search-v2__last-search-item-list-item"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSearchHistoryItemClick(item);
                }}
              >
                {item.searchText}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchHistory;
