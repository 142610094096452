export const APPEALIO_SUPPORT_EMAIL =
  process.env.REACT_APP_APPEALIO_SUPPORT_EMAIL || '';

export const APPEALIO_SITE_MAINTENANCE_MODE =
  process.env.REACT_APP_SITE_MAINTENANCE_MODE === 'true';

export const USER_ROLES = {
  CLIENT_USER: 1,
  CLIENT_ADMIN: 2,
  CLIENT_SUPERVISOR: 3,
};

export const USER_ROLES_VALUE = {
  AGENT: 'Agent',
  MANAGER: 'Manager',
  SUPERVISOR: 'Supervisor',
};
export const USER_ROLE_LABEL = {
  [USER_ROLES.CLIENT_USER]: USER_ROLES_VALUE.AGENT,
  [USER_ROLES.CLIENT_ADMIN]: USER_ROLES_VALUE.MANAGER,
  [USER_ROLES.CLIENT_SUPERVISOR]: USER_ROLES_VALUE.SUPERVISOR,
};
export const INTEGRATION_TYPE = {
  APM: 'APM',
  EDI: 'EDI',
};

export const USER_ROLES_ENUM = {
  [USER_ROLES_VALUE.AGENT]: 1,
  [USER_ROLES_VALUE.MANAGER]: 2,
  [USER_ROLES_VALUE.SUPERVISOR]: 3,
};

export const CLIENT_SERVICES = {
  ANALYTICS: 'analytics',
  DENIAL_MANAGEMENT: 'claims',
  PCE: 'estimator',
  RPA: 'rpa',
  STANDALONE_SUBMISSION: 'package',
  CMS_1500_MERGE: 'cms-1500-merge',
  UB_04_MERGE: 'ub-04-merge',
  APPEALS_RECORDS_SUBMISSIONS: 'submissions',
};

export const RULE_STATUSES = {
  ACTIVE: 'AC',
  PAUSED: 'PA',
  ARCHIVED: 'AR',
};

export const RULE_STATUSES_LABEL = {
  [RULE_STATUSES.ACTIVE]: 'Active',
  [RULE_STATUSES.PAUSED]: 'Paused',
  [RULE_STATUSES.ARCHIVED]: 'Archived',
};

export const ALL_OPTION_LABEL = 'All';

export const TABLE_PAGE_LIMIT = 25;
export const SETTINGS_PAGE_LIMIT = 15;
export const SEARCH_CLAIMS_PAGE_LIMIT = 50;

export const APPEALS_MEDICAL_RECORDS_TOGGLE_OPTIONS = {
  option1: 'Records',
  option2: 'Appeals',
};

export const APPEAL_STEPS = {
  PATIENT_INFO: 0,
  DENIAL_INFO: 1,
  LETTERS_AND_FORM: 2,
  DOCUMENTS: 3,
  REVIEW: 4,
  SUBMIT_CONFIRM: 5,
};

export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  UPDATE: 'update',
  DELETE: 'delete',
};

// used in appeals table
export const INDEX_VIEW_DETAILS = 0;
export const INDEX_DUPLICATE_CLAIM = 1;
export const INDEX_RESPONSE = 2;
export const INDEX_VIEW_NOTES = 3;
export const INDEX_CLOSE_CLAIM = 4;
export const INDEX_RE_APPEAL = 5;

export const REASONS_CHARACTER_LIMIT = 25;
export const SUBMISSIONS_REASONS_CHARACTER_LIMIT = 25;

export const ONBOARDING = 'ONBOARDING';
export const ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE';

export const ACCOUNT_SETTINGS = {
  USERS: 'users',
  PAYERS: 'payers',
  CLINICS: 'clinics',
  PRACTICES: 'practices',
  TEMPLATES: 'templates',
  BILLING_PROVIDERS: 'billingProviders',
  RENDERING_PROVIDERS: 'renderingProviders',
};

export const RENDERING_OR_BILLING_TYPE = {
  RENDERING: 1,
  BILLING: 2,
};

export const FILE_SIZE_IN_MB = 1024;
export const FILE_SIZE_LIMIT_10_MB = 10240;

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const RPA_LOCALSTORAGE_KEYS = {
  UHC_PAGE_ID: 'UHC_PAGE_ID',
  AVAILITY_PAGE_ID: 'AVAILITY_PAGE_ID',
};

export const UNASSIGNED_OPTION = {
  label: 'Unassigned',
  value: 'n/a',
};

export const DELIVERY_STATUS = {
  DELIVERED: 'Delivered',
};

export const DELIVERY_TYPES = {
  STANDALONE: 'Standalone',
};

export const UHC_DIRECT_TO_PAYER = {
  LABEL: 'Direct to Payer',
  ABBR: '_def_d2p',
};

export const UHC_CLAIMS_STATUS = {
  FINALIZED: 'Finalized',
  PENDING: 'Pending',
  DENIED: 'Denied',
  MULTIPLE: 'Multiple Statuses',
  IN_PROCESS: 'In Process',
};

export const PAYER_API_TYPE = {
  UHC: 'UHC',
  AVAILITY: 'AVAILITY',
};

export const CONTACT_TYPE = {
  RETURN_ADDRESS: 'RETURN_ADDRESS',
  DESTINATION_ADDRESS: 'DESTINATION_ADDRESS',
};

export const UNASSIGNED_AGENT_LABEL = 'Unassigned';

export const CLIENT_CONFIG_KEYS = {
  DQ_AUTO_ARCHIVE_DAYS: 'dq_auto_archive_days',
  DQ_DEFAULT_ZERO_PAY_FILTER: 'dq_default_zero_pay_filter',
  DD_AGENT_ASSIGNMENT_BY_AGENT: 'dq_agent_assignment_by_agent',
  DQ_ANALYTICS: 'dq_analytics',
  PRACTICE_GROUP: 'practice_group',
  CLIENT_LOGO: 'client_logo',
  SUBMIT_PACKAGE_VIA_EMAIL: 'submit_package_via_email',
  TEAMS: 'teams',
  MERGE_PROOF_OF_SUBMISSION: 'merge_proof_of_submission',
};

export const ACTIONS = {
  WRITE_OFF: 'Write-off',
  RESOLVED_VIA_PHONE: 'Resolved via phone',
  APPEALED_OUTSIDE_OF_APPEALIO: 'Appealed outside of Appealio',
  CLAIM_CORRECTED_IN_PM: 'Claim corrected in PM',
  ROUTINE_ADJUSTMENTS: 'Routine adjustments',
  CLAIM_ALREADY_PAID: 'Claim already paid',
  OTHERS: 'Others',
};

export const UNGROUPED_PRACTICE_NAME = 'UnGrouped';

export const SOURCE_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Imported',
    value: 'imported',
  },
];

export const APPEAL_RECORD_ACTIONS = ['Appeal', 'Record'];

export const ALL_VALUE = '_all_';

export const ALL_AGENTS_OPTION_VALUE = ALL_VALUE;

export const ZERO_PAY_RULE_OPTIONS = [
  {
    label: 'Across Entire Claim',
    value: 'ENTIRE_CLAIM',
  },
  {
    label: 'Any Claim Line',
    value: 'ANY_CLAIM_LINE',
  },
];

export const ALLOW_RULE_PAGINATION = '1';

export const SUBMISSION_TYPE = {
  APPEAL: 'Appeal',
  RECORD: 'Record',
  STANDALONE: 'Standalone',
  RECONSIDERATION: 'Reconsideration',
};

export const SUBMISSION_STATUS = {
  SUBMITTED: 'Submitted',
  IN_PROGRESS: 'In Progress',
};

export const EXPORT_OPTION = { ALL_CLAIMS: Number(1), CURRENT_TAB: Number(2) };

export const DENIALS_FLYOVER_TITLE = 'All Unflagged Claims';

export const EXPORT_CSV_MESSAGE = 'Data has been successfully exported.';

export const PAYER_CONTACT_TYPES = {
  MAIL: 'Mail',
  FAX: 'Fax',
  PAYER_PORTAL: 'Payer Portal',
  EMAIL: 'Email',
};

export const CLIENT_LOGO_CONFIG = {
  AQKODE: 'AQKODE',
};

export const APPEAL_DOCUMENT_CATEGORY = {
  EOB: 'eob',
};

export const MERGE_TOOL_TYPE = {
  CMS_1500: 'CMS-1500',
  UB_04: 'UB-04',
};

export const SUCCESSFUL_DENIED_OPTIONS = [
  {
    label: 'Successful',
    value: 'Successful',
  },
  {
    label: 'Denied',
    value: 'Denied',
  },
];

export const PM_NOTE_STATUS = {
  COPIED: 'Copied',
  UNCOPIED: 'Uncopied',
};

export const PM_NOTE_STATUS_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: PM_NOTE_STATUS.COPIED,
    value: PM_NOTE_STATUS.COPIED,
  },
  {
    label: PM_NOTE_STATUS.UNCOPIED,
    value: PM_NOTE_STATUS.UNCOPIED,
  },
];

export const ACTION_LOG_TAB_VALUES = {
  PENDING: 0,
  COMPLETED: 1,
};

export const REMINDER_STATUS = {
  PAST_DUE: 'pastDue',
  TODAY: 'today',
  UPCOMING: 'upcoming',
};

export const DOCUMENT_CATEGORY = {
  APPEAL_ATTACHMENT: 'appeal_attachment',
  EOB: 'eob',
  CMS1500: 'cms1500',
  EMAIL_ATTACHMENT: 'email_attachment',
};

export const DENIAL_ACTION_CONFIG_TYPE = {
  AUTO_REMINDER: 'auto_reminder',
};

export const TOTAL_CREATE_SUBMISSION_STEPS = {
  APPEAL: 5,
  RECORD: 4,
};

export const COLORED_PRINT_MAIL = {
  COLOR: 'Color',
};

export const DOUBLE_SIDE = {
  DOUBLE: 'Double',
};

export const REMINDER_STATUS_OPTIONS = {
  REMINDER_STATUS: 'reminderStatus',
};

export const CLAIM_IMPORTED_FROM_TYPE = {
  FLYOVER: 'FLYOVER',
  DENIALS_QUEUE: 'DENIALS_QUEUE',
};

export const GLOBAL_SEARCH_CLAIM_ACTIONS = {
  ARCHIVE: 'ARCHIVE',
  ASSIGN: 'ASSIGN',
  UNARCHIVE: 'UNARCHIVE',
  DELETE: 'DELETE',
  RECORD_RESPONSE: 'RECORD_RESPONSE',
  ADD_TO_DQ: 'ADD_TO_DQ',
};
